import { inputLabelClasses, selectClasses, styled } from '@mui/material'
import { Select } from 'curbo-components-library'

export const StyledSelect = styled(Select)({
  width: '90%',
  height: '29px',
  [`.${selectClasses.select}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px',
  },
})

export const formControlStyles: React.CSSProperties = {
  width: '100%',
  height: '29px',
  [`.${inputLabelClasses.root}`]: {
    top: '-9px',
  },
}
