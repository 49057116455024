import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Typography } from '@mui/material'
import { createPictureList } from 'curbo-components-library'

import Carousel, { Image } from 'components/Common/Carousel'

import {
  maxAllowedSizePerFileInKb,
  maxAllowedTotalSizeInKb,
} from 'constants/fileSizes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledButton, StyledTextFieldContainer } from './style'

type MultiImageInputProps = {
  title: string
  pictures: Image[]
  updatePictures: (newVehicleInteriorPictures: Image[]) => void
  pictureFiles: File[]
  updatePictureFiles: (newPictureFiles: File[]) => void
  /**
   * Boolean depicting if pictures are going to be removable
   */
  picturesDeletable?: boolean
  /**
   * Carousel dimensions
   */
  width?: string | number

  /**
   * Function to update error status if less than 2 images are selected or no picture is selected for single file mode
   */
  setFileAmountError?: (error: boolean) => void

  /**
   * Function to update error status if a picture file was more than 3MB or if the total size is more than 20MB
   */
  setFileSizeError?: (error: boolean, type?: 'totalSize') => void

  /**
   * ID to identify the rendered input element. Necessary if more than a multi image input is being used
   */
  inputId: string

  /**
   * Cypress tag identifier
   */
  testId?: string
}

const MultiImageInput = ({
  title,
  pictures,
  pictureFiles,
  updatePictures,
  updatePictureFiles,
  picturesDeletable,
  width,
  setFileAmountError,
  setFileSizeError,
  inputId,
  testId,
}: MultiImageInputProps) => {
  const [picturesSrc, setPicturesSrc] = useState<Image[]>(pictures || [])
  const [filesList, setFilesList] = useState<File[]>(pictureFiles)
  const { text } = useTranslation(textFiles.GENERAL)

  const {
    noFiles: noFilesText,
    chooseFiles: chooseFilesText,
    selectedFiles: selectedFilesText,
  } = text.fileInput

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    const newFiles = files && files.length > 0 ? files : null
    function readFileAsText(file: File) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader()

        fr.onload = () => {
          resolve(fr.result)
        }

        fr.onerror = () => {
          reject(fr)
        }

        fr.readAsDataURL(file)
      })
    }

    if (newFiles) {
      const readers: Promise<unknown>[] = []
      const newFilesList: File[] = [...filesList]
      let sizeError = false
      let multiSizeError = false
      let totalFileSize = 0
      newFilesList.forEach((file) => {
        totalFileSize += file.size
      })
      /**
       * Converting each file from FileList type to File[] in order to be iterable
       */
      Array.from(newFiles).forEach((file) => {
        /**
         * Explanation: Here, we check if the file that is trying to be saved at the moment has an adequate size (< 3mb),
         * then, we check if it fits in the current files list, because the maximum size is 20mb.
         * If it does, then we push it in the newFilesList array.
         * If not, then the corresponding error is rendered. Please, check the Photos component in Vehicle Creation for reference
         */
        if (file.size <= maxAllowedSizePerFileInKb) {
          if (
            file.size + totalFileSize <= maxAllowedTotalSizeInKb &&
            newFilesList.length <= 20
          ) {
            totalFileSize = file.size + totalFileSize

            readers.push(readFileAsText(file))
            newFilesList.push(file)
          } else {
            multiSizeError = true
          }
        } else {
          sizeError = true
        }
      })

      if (sizeError && setFileSizeError) {
        setFileSizeError(true)
      }

      if (multiSizeError && setFileSizeError) {
        setFileSizeError(true, 'totalSize')
      }

      if (!sizeError && !multiSizeError && setFileSizeError) {
        setFileSizeError(false)
        setFileSizeError(false, 'totalSize')

        Promise.all(readers).then((values) => {
          const newPictures: string[] = values.map((value) => value as string)
          setPicturesSrc([...picturesSrc, ...createPictureList(newPictures)])
          updatePictures([...picturesSrc, ...createPictureList(newPictures)])
        })

        updatePictureFiles(newFilesList)
        setFilesList(newFilesList)

        if (setFileAmountError && newFilesList.length < 2)
          setFileAmountError(true)
        if (setFileAmountError && newFilesList.length >= 2)
          setFileAmountError(false)
      }
    }
  }

  const handleDelete = (id: string) => {
    let newImages: Image[] = []
    newImages = picturesSrc.filter((currentImage) => currentImage.id !== id)
    if (updatePictures) {
      updatePictures(newImages)
      setPicturesSrc(newImages)
    }
    if (setFileAmountError && newImages.length < 2) setFileAmountError(true)
  }

  return (
    <StyledTextFieldContainer title={title}>
      {picturesSrc ? (
        <Carousel
          imageList={picturesSrc}
          imageHeight={120}
          imageWidth={120}
          onDelete={
            picturesDeletable
              ? (id) => {
                  handleDelete(id)
                }
              : undefined
          }
          width={width}
        />
      ) : null}

      <input
        type="file"
        name="input"
        id={`images-input-${inputId}`}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={imageHandler}
        multiple
        data-cy={testId ? `${testId}-input` : undefined}
      />
      <StyledButton
        htmlFor={`images-input-${inputId}`}
        sx={{ marginTop: '20px', width: 'auto' }}
        data-cy={testId ? `${testId}-button` : undefined}
      >
        <AddIcon /> <span>{chooseFilesText}</span>
      </StyledButton>
      <Typography variant="body2" sx={{ fontSize: '12px', marginTop: '6px' }}>
        {picturesSrc && picturesSrc.length > 0
          ? `${picturesSrc.length} ${selectedFilesText}`
          : noFilesText}
      </Typography>
    </StyledTextFieldContainer>
  )
}

export default MultiImageInput
