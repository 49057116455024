import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import InspectionCreationPage from 'pages/Inspection/Creation'
import InspectionDetailPage from 'pages/Inspection/Detail'
import InspectionListingPage from 'pages/Inspection/Listing'

import { INSPECTION_SUB_ROUTES } from 'constants/routes'

export const InspectionRouter = () => (
  <Switch>
    <Route
      exact
      path={INSPECTION_SUB_ROUTES.INSPECTION_LISTING}
      component={InspectionListingPage}
    />
    <Route
      path={INSPECTION_SUB_ROUTES.INSPECTION_CREATION}
      component={InspectionCreationPage}
    />
    <Route
      path={INSPECTION_SUB_ROUTES.INSPECTION_DETAIL}
      component={InspectionDetailPage}
    />
    <Redirect to={INSPECTION_SUB_ROUTES.INSPECTION_LISTING} />
  </Switch>
)
