import React from 'react'
import { Tooltip } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {
  cypressToggleButtonFalse,
  cypressToggleButtonTrue,
} from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledToggleButton } from './style'

export type ToggleProps = {
  /**
   *current toggle value
   */
  value: boolean
  /**
   *function to toggle current value
   */
  handleValueChange:
    | ((value: boolean) => void)
    | React.Dispatch<React.SetStateAction<boolean>>
  /*
   * ToggleButton width
   */
  width?: number | string
  /*
   * ToggleButton height
   */
  height?: number | string

  /*
   * Adds cypress tag to toggle buttons
   */
  testId?: string
  /*
   * Affirmative option label
   */
  trueLabel?: string
  /*
   * Negative option label
   */
  falseLabel?: string
}

const Toggle = ({
  value,
  width = '76px',
  height = '38px',
  handleValueChange,
  testId,
  falseLabel = 'No',
  trueLabel = 'Yes',
}: ToggleProps) => {
  const {
    text: {
      subSteps: { firstStep: translation },
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: boolean
  ) => {
    if (newStatus !== null) {
      handleValueChange(newStatus)
    }
  }

  return (
    <ToggleButtonGroup value={value} exclusive onChange={handleChange}>
      <StyledToggleButton
        sx={{ width, height }}
        value
        data-cy={testId ? `${testId}-${cypressToggleButtonTrue}` : undefined}
      >
        {trueLabel}
      </StyledToggleButton>

      <StyledToggleButton
        sx={{ width, height }}
        value={false}
        data-cy={testId ? `${testId}-${cypressToggleButtonFalse}` : undefined}
      >
        <Tooltip title={translation.toggleDescription} placement="bottom" arrow>
          <span>{falseLabel}</span>
        </Tooltip>
      </StyledToggleButton>
    </ToggleButtonGroup>
  )
}

export default Toggle
