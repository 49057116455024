/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { TypedDocumentNode } from '@apollo/client'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import {
  Avatar,
  ExtendedStatus,
  Language,
  PublicationStatusMap,
  SelectItem,
  Tooltip,
} from 'curbo-components-library'

import PublicationStatusTag from 'components/Operation/CarSettings/Common/PublicationStatusTag'

import { commonColumnsText, commonListColumns } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import {
  baseDefaultFields,
  createBaseFieldSelectItems,
  createBaseFieldSelectLabels,
} from 'utils/operation/base/listing'
import {
  brandDefaultFields,
  createBrandFieldSelectItems,
  createBrandFieldSelectLabels,
} from 'utils/operation/brand/listing'
import {
  createModelFieldSelectItems,
  createModelFieldSelectLabels,
  ModelDefaultFields,
} from 'utils/operation/model/listing'
import {
  createTrimFieldSelectItems,
  createTrimFieldSelectLabels,
  TrimDefaultFields,
} from 'utils/operation/trimLevel/listing'

import {
  LIST_BRANDS,
  LIST_COUNTRY_VERSIONS,
  LIST_MODELS,
  LIST_TRIM_LEVELS,
} from 'graphQL/CarSettings/Listing/queries'

import {
  BaseColumnField,
  ModelColumnField,
  TrimColumnField,
} from './columnField'

export type CarSettingsKey =
  | 'brand'
  | 'model'
  | 'country-version'
  | 'trim-level'

type CarSettingsFeatures = {
  textFile: string
  selectFields: string[]
  createFieldSelectItems: (text: any) => SelectItem[]
  createFieldSelectLabels: (text: any) => Record<string, string>
  query: TypedDocumentNode
}

export const carSettingsListingFields: Record<
  CarSettingsKey,
  CarSettingsFeatures
> = {
  brand: {
    textFile: textFiles.BRAND_LISTING,
    selectFields: brandDefaultFields,
    createFieldSelectItems: createBrandFieldSelectItems,
    createFieldSelectLabels: createBrandFieldSelectLabels,
    query: LIST_BRANDS,
  },
  model: {
    textFile: textFiles.MODEL_LISTING,
    selectFields: ModelDefaultFields,
    createFieldSelectItems: createModelFieldSelectItems,
    createFieldSelectLabels: createModelFieldSelectLabels,
    query: LIST_MODELS,
  },
  'country-version': {
    textFile: textFiles.COUNTRY_VERSION_LISTING,
    selectFields: baseDefaultFields,
    createFieldSelectItems: createBaseFieldSelectItems,
    createFieldSelectLabels: createBaseFieldSelectLabels,
    query: LIST_COUNTRY_VERSIONS,
  },
  'trim-level': {
    textFile: textFiles.TRIM_LISTING,
    selectFields: TrimDefaultFields,
    createFieldSelectItems: createTrimFieldSelectItems,
    createFieldSelectLabels: createTrimFieldSelectLabels,
    query: LIST_TRIM_LEVELS,
  },
}

export const createCarSettingsColumns = (
  route: CarSettingsKey,
  statusModel: typeof PublicationStatusMap,
  selectedLanguage: Language,
  text: commonColumnsText,
  tableText: any,
  statusList?: ExtendedStatus[],
  selectedFields?: string[]
): GridColDef[] => {
  const {
    id: idColumn,
    isoDate: dateColumn,
    status: statusColumn,
    standard: standardColumn,
    picture: pictureColumn,
  } = commonListColumns(
    statusModel,
    selectedLanguage,
    text,
    undefined,
    undefined,
    statusList
  )

  const publicationStatusColumn: GridColDef = {
    ...statusColumn,
    sortable: true,
    hide: !selectedFields?.includes(statusColumn.field),
    renderCell: (params: GridRenderCellParams) => {
      const { status } = params.row
      const isPublished = status === PublicationStatusMap.PUBLISHED

      return (
        <PublicationStatusTag
          published={isPublished}
          text={status ? text.status[status] : ''}
          smallSize
        />
      )
    },
  }
  const pictureHideColumn: GridColDef = {
    ...pictureColumn,
    hide: !selectedFields?.includes(pictureColumn.field),
  }

  const idHideColumn: GridColDef = {
    ...idColumn,
    hide: !selectedFields?.includes(idColumn.field),
  }
  const dateHideColumn: GridColDef = {
    ...dateColumn,
    field: BaseColumnField.CREATED_AT,
    hide: !selectedFields?.includes(BaseColumnField.CREATED_AT),
    headerName: tableText.createdAt,
  }

  const baseNameHideColumn: GridColDef = {
    ...standardColumn,
    field: BaseColumnField.NAME,
    hide: !selectedFields?.includes(BaseColumnField.NAME),
    headerName: tableText.name,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip>{params.row.name}</Tooltip>
    ),
  }

  const columns: Record<CarSettingsKey, GridColDef[]> = {
    brand: [
      pictureHideColumn,
      idHideColumn,
      {
        ...standardColumn,
        field: BaseColumnField.NAME,
        hide: !selectedFields?.includes(BaseColumnField.NAME),
        headerName: tableText.name,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip>{params.row.name}</Tooltip>
        ),
      },
      dateHideColumn,
      publicationStatusColumn,
    ],
    model: [
      {
        ...pictureColumn,
        hide: !selectedFields?.includes(pictureColumn.field),
        renderCell: (params: GridRenderCellParams) => {
          const { brand } = params.row
          return (
            <Avatar
              image={brand && brand.picture}
              alt={params.row.inspectorName}
              style={{
                width: '40px',
                height: '40px',
              }}
            />
          )
        },
      },
      idHideColumn,
      {
        ...standardColumn,
        field: ModelColumnField.BRAND_NAME,
        hide: !selectedFields?.includes(ModelColumnField.BRAND_NAME),
        headerName: tableText.brandName,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const { brand } = params.row
          return <Tooltip>{brand ? brand.name : ''}</Tooltip>
        },
      },
      {
        ...standardColumn,
        hide: !selectedFields?.includes(ModelColumnField.NAME),
        field: ModelColumnField.NAME,
        headerName: tableText.name,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip>{params.row.name}</Tooltip>
        ),
      },
      dateHideColumn,
      publicationStatusColumn,
    ],
    'country-version': [
      idHideColumn,
      baseNameHideColumn,
      dateHideColumn,
      publicationStatusColumn,
    ],
    'trim-level': [
      {
        ...idColumn,
        sortable: false,
        hide: !selectedFields?.includes(idColumn.field),
      },
      {
        ...standardColumn,
        field: TrimColumnField.NAME,
        headerName: tableText.name,
        sortable: false,
        hide: !selectedFields?.includes(TrimColumnField.NAME),
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip>{params.row.name}</Tooltip>
        ),
      },
      {
        ...standardColumn,
        field: TrimColumnField.CAR_NAME,
        headerName: tableText.carName,
        sortable: false,
        hide: !selectedFields?.includes(TrimColumnField.CAR_NAME),
        renderCell: (params: GridRenderCellParams) => {
          const { model } = params.row
          return (
            <Tooltip>
              {model ? `${model.brand.name} ${model.name}` : ''}
            </Tooltip>
          )
        },
      },
      {
        ...standardColumn,
        sortable: false,
        field: TrimColumnField.YEAR,
        headerName: tableText.year,
        hide: !selectedFields?.includes(TrimColumnField.YEAR),
      },
      {
        ...dateColumn,
        sortable: false,
        field: TrimColumnField.CREATED_AT,
        headerName: tableText.createdAt,
        hide: !selectedFields?.includes(TrimColumnField.CREATED_AT),
      },
      {
        ...publicationStatusColumn,
        sortable: false,
      },
    ],
  }

  return columns[route]
}

export const trimStaticFields = ['carModel', 'status']
