export const meridiamOptions = [
  {
    name: 'AM',
    value: 'AM',
  },
  {
    name: 'PM',
    value: 'PM',
  },
]

export enum InspectionColumnField {
  ID = 'id',
  DATE = 'date',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  CAR_NAME = 'carName',
}

export const pendingHash = '#pending'
