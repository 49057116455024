import React from 'react'
import {
  ButtonNavigator,
  categoriesWidth,
  ColorBox,
  Select,
  useWindowDimensions,
} from 'curbo-components-library'
import { useFormik } from 'formik'
import * as yup from 'yup'

import MultipleSelect from 'components/Inventory/Creation/Common/MultipleSelect'

import { VEHICLE_CREATION_OPTION } from 'constants/PreInventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { SecondStepModel } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/PreInventory/creation'

import {
  BoxContainer,
  sliderFormProps,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const VehicleDetail = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryBack,
  handleSecondaryContinue,
  vehicleSelectOptions,
  colorList,
  countryVersionList,
}: VehicleCreationProps) => {
  const { width } = useWindowDimensions()
  const {
    text: {
      subSteps: { secondStep: translation },
      buttons,
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const validationSchema = yup.object().shape({
    vinNumber: yup
      .string()
      .length(17, validationText.errorMessageRequiredLength.replace('%d', 17)),
    chassisNumber: yup
      .string()
      .min(6, validationText.errorMessageAtleast.replace('%d', 6))
      .max(10, validationText.errorMessageMaxCharacters.replace('%d', 11))
      .when('vinNumber', {
        is: (vinNumber: string) => !vinNumber || vinNumber.length === 0,
        then: yup.string().required(translation.vinChassisValidation),
        otherwise: yup.string(),
      }),
    licensePlate: yup.string().required(validationText.fieldRequired),
    categories: yup
      .array()
      .of(yup.string())
      .min(1, validationText.errorRequiredItems.replace('%d', 1)),
    countryVersion: yup.string().required(validationText.fieldRequired),
    exteriorColor: yup.string().required(validationText.fieldRequired),
    interiorColor: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<SecondStepModel>({
    initialValues: vehicleData.vehicleInformation.secondStep,
    validationSchema,
    onSubmit: (values) => {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
        payload: {
          ...vehicleData.vehicleInformation,
          secondStep: values,
        },
      })
      handleSecondaryContinue()
    },
  })

  const getColor = (colorId: string) => {
    const color = colorList.find(
      (currentColor) => currentColor.value === colorId
    )

    return color ? color.hexCode : ''
  }

  const handleCategoryRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    const newCategories = [...formik.values.categories]
    newCategories.splice(index, 1)
    formik.setFieldValue('categories', newCategories)
  }

  return (
    <StyledContainer>
      <StyledForm
        sx={{
          ...sliderFormProps,
          maxWidth: width ? `${categoriesWidth(width)} !important` : '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <div>
          <StyledTextFieldContainer title={translation.countryVersion}>
            <Select
              options={countryVersionList}
              onChange={formik.handleChange}
              label={translation.countryVersion}
              value={formik.values.countryVersion}
              name="countryVersion"
              sx={{ fontSize: 12, height: 40 }}
              error={
                formik.touched.countryVersion &&
                Boolean(formik.errors.countryVersion)
              }
            />
            {formik.touched.countryVersion && formik.errors.countryVersion ? (
              <StyledErrorMessage text={formik.errors.countryVersion} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.interiorColor}>
            <BoxContainer>
              <Select
                options={colorList}
                onChange={formik.handleChange}
                label={translation.interiorColor}
                value={formik.values.interiorColor}
                name="interiorColor"
                sx={{ fontSize: 12, height: 40, width: '90% !important' }}
                error={
                  formik.touched.interiorColor &&
                  Boolean(formik.errors.interiorColor)
                }
              />
              <ColorBox
                hexCode={getColor(formik.values.interiorColor)}
                height={38}
                width="20%"
              />
            </BoxContainer>
            {formik.touched.interiorColor && formik.errors.interiorColor ? (
              <StyledErrorMessage text={formik.errors.interiorColor} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.exteriorColor}>
            <BoxContainer>
              <Select
                options={colorList}
                onChange={formik.handleChange}
                label={translation.exteriorColor}
                value={formik.values.exteriorColor}
                name="exteriorColor"
                sx={{ fontSize: 12, height: 40, width: '90% !important' }}
                error={
                  formik.touched.exteriorColor &&
                  Boolean(formik.errors.exteriorColor)
                }
              />
              <ColorBox
                hexCode={getColor(formik.values.exteriorColor)}
                height={38}
                width="20%"
              />
            </BoxContainer>
            {formik.touched.exteriorColor && formik.errors.exteriorColor ? (
              <StyledErrorMessage text={formik.errors.exteriorColor} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.chassisNumber}>
            <StyledTextField
              variant="outlined"
              name="chassisNumber"
              value={formik.values.chassisNumber}
              placeholder={translation.chassisNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.chassisNumber &&
                Boolean(formik.errors.chassisNumber)
              }
            />
            {formik.touched.chassisNumber && formik.errors.chassisNumber ? (
              <StyledErrorMessage text={formik.errors.chassisNumber} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer
            sx={{ maxWidth: '280px !important' }}
            title={translation.vinNumber}
          >
            <StyledTextField
              variant="outlined"
              name="vinNumber"
              value={formik.values.vinNumber}
              placeholder={translation.vinNumber}
              onChange={formik.handleChange}
              fullWidth
              error={
                formik.touched.vinNumber && Boolean(formik.errors.vinNumber)
              }
            />
            {formik.touched.vinNumber && formik.errors.vinNumber ? (
              <StyledErrorMessage text={formik.errors.vinNumber} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.licensePlate}>
            <StyledTextField
              variant="outlined"
              name="licensePlate"
              value={formik.values.licensePlate}
              placeholder={translation.licensePlate}
              onChange={formik.handleChange}
              error={
                formik.touched.licensePlate &&
                Boolean(formik.errors.licensePlate)
              }
            />
            {formik.touched.licensePlate && formik.errors.licensePlate ? (
              <StyledErrorMessage text={formik.errors.licensePlate} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer
            sx={{ flexGrow: 1, maxWidth: '100% !important' }}
            title={translation.category}
          >
            <MultipleSelect
              options={vehicleSelectOptions.categories}
              onChange={formik.handleChange}
              label={translation.category}
              values={formik.values.categories}
              name="categories"
              error={
                formik.touched.categories && Boolean(formik.errors.categories)
              }
              onRemove={handleCategoryRemove}
            />
            {formik.touched.categories && formik.errors.categories ? (
              <StyledErrorMessage
                text={
                  typeof formik.errors.categories === 'string'
                    ? formik.errors.categories
                    : ''
                }
              />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        translation={buttons}
        previousFunction={handleSecondaryBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehicleDetail
