import {
  buttonBaseClasses,
  inputBaseClasses,
  selectClasses,
  styled,
} from '@mui/material'
import { calendarPickerClasses } from '@mui/x-date-pickers'
import { Box, colors, FONT_WEIGHT, TextField } from 'curbo-components-library'

export const StyledDatePickerContainer = styled(Box)({
  width: '100%',

  '& > div': {
    backgroundColor: 'transparent',
  },
  '& > div > div > div': {
    margin: '0 auto',
  },
  [`& .${calendarPickerClasses.viewTransitionContainer}`]: {
    '& > div': {
      '> div:nth-of-type(2)': {
        minHeight: '200px',
      },
    },
  },
  [`& .${calendarPickerClasses.root}`]: {
    margin: 0,
  },
  [`& .${calendarPickerClasses.root} > div:nth-of-type(1)`]: {
    '& > div:nth-of-type(1)': {
      position: 'absolute',
      width: 283,
      display: 'flex',
      justifyContent: 'center',
    },
    '> div:nth-of-type(2)': {
      width: '100%',
      justifyContent: 'space-between',
      paddingRight: '10px',
    },
  },
  '& .Mui-selected:hover': {
    backgroundColor: colors.blue,
    color: `${colors.commonWhite} !important`,
  },
})

export const StyledTextField = styled(TextField)({
  width: '90%',
  height: '26px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.gray,
  div: {
    height: '100%',
    fontSize: '14px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${buttonBaseClasses.root}`]: {
    marginRight: '-4px',
    padding: '0px',
  },

  input: {
    height: '26px',
    fontSize: '14px',
    padding: '0 14px',
  },
})
