import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import DistributionCalendarPage from 'pages/Inspector/Calendar'
import InspectorDetailPage from 'pages/Inspector/Detail'
import InspectorListingPage from 'pages/Inspector/Listing'

import { INSPECTOR_SUB_ROUTES } from 'constants/routes'

export const InspectorRouter = () => (
  <Switch>
    <Route
      exact
      path={INSPECTOR_SUB_ROUTES.INSPECTOR_LISTING}
      component={InspectorListingPage}
    />
    <Route
      path={INSPECTOR_SUB_ROUTES.INSPECTOR_DETAIL}
      component={InspectorDetailPage}
    />
    <Route
      path={INSPECTOR_SUB_ROUTES.INSPECTOR_CALENDAR}
      component={DistributionCalendarPage}
    />
    <Redirect to={INSPECTOR_SUB_ROUTES.INSPECTOR_LISTING} />
  </Switch>
)
