import { gql } from '@apollo/client'

import {
  INSPECTION_WEEK_SCHEDULE_FRAGMENT,
  TEST_DRIVE_WEEK_SCHEDULE_FRAGMENT,
} from './queries'

export const UPDATE_TEST_DRIVE_WEEK_CALENDAR = gql`
  ${TEST_DRIVE_WEEK_SCHEDULE_FRAGMENT}
  mutation updateSelfTestDriveWeekCalendar(
    $input: UpdateSelfTestDriveWeekCalendarInput!
  ) {
    data: updateSelfTestDriveWeekCalendar(input: $input) {
      ...TestDriveWeekScheduleFragment
    }
  }
`

export const UPDATE_INSPECTIONS_WEEK_CALENDAR = gql`
  ${INSPECTION_WEEK_SCHEDULE_FRAGMENT}
  mutation updateSelfInspectionWeekCalendarr(
    $input: UpdateSelfInspectionWeekCalendarInput!
  ) {
    data: updateSelfInspectionWeekCalendarr(input: $input) {
      ...InspectionWeekScheduleFragment
    }
  }
`
