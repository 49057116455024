import { LEAD_CREATION_OPTION } from 'constants/Lead/creation'
import {
  LeadCreationAction,
  LeadCreationModel,
} from 'models/services/customerRelationship/lead/creation'

export const leadCreationReducer = (
  state: LeadCreationModel,
  action: LeadCreationAction
): LeadCreationModel => {
  switch (action.type) {
    case LEAD_CREATION_OPTION.UPDATE_GENERAL_INFORMATION: {
      const { payload } = action

      return {
        ...state,
        generalInformation: payload,
      }
    }

    case LEAD_CREATION_OPTION.UPDATE_CAR_INFORMATION: {
      const { payload } = action

      return {
        ...state,
        carInformation: payload,
      }
    }

    case LEAD_CREATION_OPTION.UPDATE_APPOINTMENT_INFORMATION: {
      const { payload } = action

      return {
        ...state,
        appointmentInformation: payload,
      }
    }

    case LEAD_CREATION_OPTION.UPDATE_CLIENT_EXPECTATION_INFORMATION: {
      const { payload } = action

      return {
        ...state,
        clientExpInformation: payload,
      }
    }
    case LEAD_CREATION_OPTION.UPDATE_ACTION_INFORMATION:
      return { ...state, vehicleInformation: action.payload }

    default:
      return state
  }
}
