import React from 'react'
import {
  DetailStatusTag,
  PublicationStatusEnum,
  StatusStyles,
  StatusTag,
} from 'curbo-components-library'

type PublicationStatusTagProps = {
  published: boolean
  text: string
  smallSize?: boolean
}

const PublicationStatusTag = ({
  published,
  text,
  smallSize,
}: PublicationStatusTagProps) => {
  const { backgroundColor, color } =
    StatusStyles[
      published
        ? PublicationStatusEnum.PUBLISHED
        : PublicationStatusEnum.UNPUBLISHED
    ]

  return smallSize ? (
    <StatusTag backgroundColor={backgroundColor} color={color}>
      {text}
    </StatusTag>
  ) : (
    <DetailStatusTag
      backgroundColor={backgroundColor}
      color={color}
      text={text}
    />
  )
}

export default PublicationStatusTag
