import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { InputBaseComponentProps, SelectChangeEvent } from '@mui/material'
import {
  BaseIdEntity,
  CurboSpot,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  GenericUpdateVariable,
  getIsoDate,
  LoadingAnimation,
  Option,
  useNotification,
  validateGraphQLErrorCode,
} from 'curbo-components-library'
import { getDay, parseISO } from 'date-fns'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Accordion from 'components/Common/Accordion'
import { SchedulingError } from 'components/Inspection/Creation/Scheduling'
import DatePickerCell from 'components/Inventory/Common/DatePickerCell'
import InformationCell from 'components/Inventory/Common/InformationCell'
import NumberInput from 'components/Inventory/Common/NumberInput'
import SelectCell from 'components/Inventory/Common/SelectCell'

import { DAY_ENUM, emptyWeekCalendar, weekDay } from 'constants/date'
import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import { meridiamOptions } from 'constants/inspection'
import { initialSchedulingErrors } from 'constants/Lead/detail'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { InspectionWeekCalendar } from 'models/services/curboSpot'
import {
  BasePrice,
  ExpectedTimes,
  FetchBasePriceVariables,
  PriceSetting,
} from 'models/services/customerRelationship/lead/creation'
import {
  LeadDetail,
  SellMyCarInfo,
  SimpleCar,
  UpdateCaseInput,
  UpdateSellMyCarInput,
} from 'models/services/customerRelationship/lead/detail'
import { getDisabledDayNumbers } from 'utils/calendarUtils'
import { parseIsoStringToDate } from 'utils/date'
import { getInitialMeridiam } from 'utils/Lead/detail'

import {
  GET_CURBO_SPOTS,
  GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID,
} from 'graphQL/Common/Dealer/queries'
import {
  FETCH_CAR_BASE_PRICE,
  FETCH_EXPECTED_TIMES,
  FETCH_PRICE_SETTING,
} from 'graphQL/CustomerRelationship/Lead/Creation/queries'
import { UPDATE_SELL_MY_CAR } from 'graphQL/CustomerRelationship/Lead/Detail/mutations'

import { StyledGrid } from 'styles/inventory/detail'

import Appointment from '../Appointment'

type FormikModel = {
  insuranceNumber: string
  insuranceDueDate: Date | null
  insuranceInspectionReportNumber: string
  insuranceInspectionDate: Date | null
  ownershipCardNumber: string
  ownershipCardDueDate: Date | null
  hasLegalImpedance: number
  hasTrafficAccidents: number
  hasTrafficInfrigements: number
  hasInsuranceReport: number
  lastTaxPaymentDate: Date | null
  price: string
  expectedTime: string
}

// 1 will signal answer to be No, and 2 will signal answer to be yes
// can't use 0 currently because of select label that thinks 0 is false
const parseBoolean = (value?: boolean): number => {
  if (!value) return 1

  return 2
}

const parseNumberIntoBoolean = (value: number): boolean => {
  if (value === 1) return false

  return true
}

const formatSellMyCarIntoFormikModel = (
  sellMyCarInfo: SellMyCarInfo | null,
  sellMyCarData: SimpleCar
): FormikModel => {
  return {
    insuranceNumber: sellMyCarInfo?.insuranceNumber || '',
    insuranceDueDate: parseIsoStringToDate(sellMyCarInfo?.insuranceDueDate),
    insuranceInspectionReportNumber:
      sellMyCarInfo?.insuranceInspectionReportNumber || '',
    insuranceInspectionDate: parseIsoStringToDate(
      sellMyCarInfo?.insuranceInspectionDate
    ),
    ownershipCardNumber: sellMyCarInfo?.ownershipCardNumber || '',
    ownershipCardDueDate: parseIsoStringToDate(
      sellMyCarInfo?.ownershipCardDueDate
    ),
    hasLegalImpedance: parseBoolean(sellMyCarInfo?.hasLegalImpedance),
    hasTrafficAccidents: parseBoolean(sellMyCarInfo?.hasTrafficAccidents),
    hasTrafficInfrigements: parseBoolean(sellMyCarInfo?.hasTrafficInfrigements),
    hasInsuranceReport: parseBoolean(sellMyCarInfo?.hasInsuranceReport),
    lastTaxPaymentDate: parseIsoStringToDate(sellMyCarInfo?.lastTaxPaymentDate),
    price: String(sellMyCarData.price),
    expectedTime: sellMyCarData.expectedTime?.id || '',
  }
}

type SellMyCarProps = {
  sellMyCarInfo: SellMyCarInfo | null
  edit: boolean
  cancelChanges: boolean
  saveChanges: boolean
  handleResetChanges: () => void
  handleCloseEdit: () => void
  handleUpdateCase: (value: UpdateCaseInput) => Promise<boolean | LeadDetail>
  sellMyCarData: SimpleCar
}

const SellMyCarSection = ({
  edit,
  sellMyCarInfo,
  cancelChanges,
  saveChanges,
  handleCloseEdit,
  handleResetChanges,
  handleUpdateCase,
  sellMyCarData,
}: SellMyCarProps) => {
  const {
    text: { sellMyCar: translation, callToAction: ctaTranslation },
  } = useTranslation(textFiles.LEAD_DETAIL)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const [selectedLanguage] = useLocale()
  const { show } = useNotification()
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''

  // client expectation
  const [expectedTimes, setExpectedTimes] = useState<Option[]>([])
  const [carBasePrice, setCarBasePrice] = useState<number>(1)
  const [priceSetting, setPriceSetting] = useState<PriceSetting>({
    marketPrice: 1,
    curboPrice: 1,
  })

  // appointment
  const [curboSpots, setCurboSpots] = useState<CurboSpot[]>([])
  const [weekCalendarData, setWeekCalendarData] =
    useState<InspectionWeekCalendar | null>(null)
  const [disabledDays, setDisabledDays] = useState<number[]>([])
  const [address, setAddress] = useState<Address | undefined>(undefined)
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(null)
  const [dateKey, setDateKey] = useState<DAY_ENUM>(DAY_ENUM.MONDAY)
  const [meridiam, setMeridiam] = useState<string>(meridiamOptions[0].value)
  const [time, setTime] = useState<string>('')
  const [errors, setErrors] = useState<SchedulingError>(initialSchedulingErrors)

  const booleanOptions: Option[] = [
    {
      name: ctaTranslation.noOption,
      value: 1,
    },
    {
      name: ctaTranslation.yesOption,
      value: 2,
    },
  ]

  const setFieldData = useCallback((currentData: SimpleCar) => {
    const {
      inspectionHour,
      address: sellMyCarAddress,
      curboSpot,
      date,
      latitude,
      longitude,
      name,
    } = currentData
    setAddress({
      address: sellMyCarAddress || '',
      id: curboSpot ? curboSpot.id : '',
      lat: latitude || 0,
      lng: longitude || 0,
      name: name || '',
      originFromSpot: !!curboSpot,
    })
    if (date) {
      const parsedDate = parseISO(date)
      const dateNumber = getDay(parsedDate)
      const dayKey = weekDay[dateNumber]
      setAppointmentDate(parsedDate)
      setDateKey(dayKey)
    } else {
      setAppointmentDate(null)
    }
    setMeridiam(
      inspectionHour
        ? getInitialMeridiam(inspectionHour)
        : meridiamOptions[0].value
    )
    setTime(inspectionHour?.value || '')
  }, [])

  const { loading: expectedTimeLoading } = useQuery<
    GenericData<ExpectedTimes[]>
  >(FETCH_EXPECTED_TIMES, {
    onCompleted(response) {
      const responseData = response.data
      setExpectedTimes(
        responseData.map((expectedTime) => {
          const { label, id } = expectedTime
          return {
            name: label,
            value: id,
          }
        })
      )
    },
  })

  useQuery<
    GenericData<BasePrice[]>,
    GenericInputVariable<FetchBasePriceVariables>
  >(FETCH_CAR_BASE_PRICE, {
    variables: {
      input: {
        where: {
          carModel: sellMyCarData.carModel.id as string,
          year: sellMyCarData.year,
          trimLevel: (sellMyCarData?.trimLevel?.id as string) || '',
        },
      },
    },
    onCompleted(response) {
      if (response.data.length > 0) {
        const carBase = response.data[0].price
        setCarBasePrice(carBase)
      }
    },
  })

  useQuery<GenericData<PriceSetting>>(FETCH_PRICE_SETTING, {
    onCompleted(response) {
      if (response.data) {
        setPriceSetting(response.data)
      }
    },
  })

  const { loading: curboSpotsLoading } = useQuery<
    GenericData<CurboSpot[]>,
    FilterInputVariable
  >(GET_CURBO_SPOTS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCurboSpots(response.data)
    },
  })

  useQuery<
    GenericData<InspectionWeekCalendar>,
    GenericInputVariable<string | null>
  >(GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID, {
    variables: {
      input: address && address.originFromSpot ? address.id : null,
    },
    onCompleted(response) {
      const responseWeekCalendar = { ...response.data }
      setWeekCalendarData(responseWeekCalendar)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        ENTITY_NOT_FOUND_ERROR
      )

      if (errorExists) {
        setWeekCalendarData(emptyWeekCalendar)
      }
    },
  })

  const [updateSellMyCar] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateSellMyCarInput>
  >(UPDATE_SELL_MY_CAR, {
    onCompleted() {
      handleResetChanges()
      handleCloseEdit()
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.updateFail,
      })
    },
  })

  const handleUpdateSellMyCarData = async (input: UpdateSellMyCarInput) => {
    try {
      const response = await updateSellMyCar({
        variables: {
          input: {
            where: {
              id: sellMyCarData.id,
            },
            data: input,
          },
        },
      })
      if (response.errors) return false
      return true
    } catch {
      return false
    }
  }

  const minPrice = carBasePrice * priceSetting.marketPrice
  const maxPrice = carBasePrice * priceSetting.curboPrice

  const validationSchema = yup.object().shape({
    insuranceNumber: yup.string(),
    insuranceDueDate: yup
      .date()
      .nullable()
      .typeError(validationText.invalidDate),
    insuranceInspectionReportNumber: yup.string(),
    insuranceInspectionDate: yup
      .date()
      .nullable()
      .typeError(validationText.invalidDate),
    ownershipCardNumber: yup.string(),
    ownershipCardDueDate: yup
      .date()
      .nullable()
      .typeError(validationText.invalidDate),
    hasLegalImpedance: yup.number().required(validationText.fieldRequired),
    hasTrafficAccidents: yup.number().required(validationText.fieldRequired),
    hasTrafficInfrigements: yup.number().required(validationText.fieldRequired),
    hasInsuranceReport: yup.number().required(validationText.fieldRequired),
    lastTaxPaymentDate: yup
      .date()
      .nullable()
      .typeError(validationText.invalidDate),
    expectedTime: yup.string().required(validationText.fieldRequired),
    price: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<FormikModel>({
    initialValues: formatSellMyCarIntoFormikModel(sellMyCarInfo, sellMyCarData),
    validationSchema,
    onSubmit: async (values) => {
      if (!address || !appointmentDate || !time) {
        setErrors({
          address: !address && true,
          calendar: !appointmentDate && true,
          time: !time && true,
          inspector: false,
        })
        handleResetChanges()
        return
      }

      const {
        hasInsuranceReport,
        hasLegalImpedance,
        hasTrafficAccidents,
        hasTrafficInfrigements,
        insuranceDueDate,
        insuranceInspectionDate,
        insuranceInspectionReportNumber,
        insuranceNumber,
        lastTaxPaymentDate,
        ownershipCardDueDate,
        ownershipCardNumber,
        expectedTime,
        price,
      } = values

      if (formik.dirty) {
        const updateResponse = await handleUpdateCase({
          sellMyCarInfo: {
            hasInsuranceReport: parseNumberIntoBoolean(hasInsuranceReport),
            hasLegalImpedance: parseNumberIntoBoolean(hasLegalImpedance),
            hasTrafficAccidents: parseNumberIntoBoolean(hasTrafficAccidents),
            hasTrafficInfrigements: parseNumberIntoBoolean(
              hasTrafficInfrigements
            ),
            insuranceDueDate: getIsoDate(insuranceDueDate),
            insuranceInspectionDate: getIsoDate(insuranceInspectionDate),
            insuranceInspectionReportNumber:
              insuranceInspectionReportNumber || undefined,
            insuranceNumber: insuranceNumber || undefined,
            lastTaxPaymentDate: getIsoDate(lastTaxPaymentDate),
            ownershipCardDueDate: getIsoDate(ownershipCardDueDate),
            ownershipCardNumber: ownershipCardNumber || undefined,
          },
        })
        const updateSmcResponse = await handleUpdateSellMyCarData({
          address: address.address,
          date: getIsoDate(appointmentDate) || '',
          latitude: address.lat,
          longitude: address.lng,
          name: address.name,
          inspectionHour: time,
          curboSpot: address.originFromSpot ? address.id : undefined,
          expectedTime,
          price: parseFloat(price),
        })

        if (updateResponse && updateSmcResponse) {
          handleResetChanges()
          handleCloseEdit()
        }
      } else {
        handleResetChanges()
      }
    },
  })

  const handleDateChange = (newDate: Date | null, name?: string) => {
    if (!name) return

    formik.setValues({
      ...formik.values,
      [name]: newDate,
    })
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    setAppointmentDate(null)
    setTime('')
  }

  const handleAppointmentDateChange = (newDate: Date | null) => {
    if (newDate) {
      const dateNumber = getDay(newDate)
      const dayKey = weekDay[dateNumber]
      setDateKey(dayKey)
      const storedDate = new Date(newDate.setHours(0, 0, 0, 0))
      setAppointmentDate(storedDate)
    }
  }

  const onDateAccept = () => {
    setTime('')
  }

  const handleHourChange = (event: SelectChangeEvent<unknown>) => {
    const selectedHour = event.target.value as string
    setTime(selectedHour)
  }

  const handleMeridiamChange = (event: SelectChangeEvent<unknown>) => {
    const selectedMeridiam = event.target.value as string
    setMeridiam(selectedMeridiam)
    setTime('')
  }

  useEffect(() => {
    setFieldData(sellMyCarData)
  }, [sellMyCarData, setFieldData])

  useEffect(() => {
    if (weekCalendarData) {
      // here we are trying to get the number of the days that doesn't have any schedule
      // to disable them in the calendar
      const disabledDayNumbers: number[] =
        getDisabledDayNumbers(weekCalendarData)
      setDisabledDays(disabledDayNumbers)
    }
  }, [weekCalendarData])

  // Removing formik as a dependency since it causes infinite numbers
  // of re-render
  useEffect(() => {
    if (saveChanges) {
      formik.handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChanges])

  useEffect(() => {
    if (cancelChanges) {
      formik.resetForm({
        values: formatSellMyCarIntoFormikModel(sellMyCarInfo, sellMyCarData),
      })
      handleResetChanges()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelChanges, sellMyCarInfo, handleResetChanges, sellMyCarData])

  if (expectedTimeLoading || curboSpotsLoading || !weekCalendarData)
    return <LoadingAnimation showAnimation />

  return (
    <>
      {sellMyCarData && (
        <Accordion
          defaultExpanded
          description={translation.perspectiveOnCarValue}
          title={translation.saleExpectation}
        >
          <StyledGrid>
            <InformationCell
              label={translation.minPrice}
              value={minPrice}
              endAdornment={priceCurrency}
              thousandSeparator
            />
            <InformationCell
              label={translation.maxPrice}
              value={maxPrice}
              endAdornment={priceCurrency}
              thousandSeparator
            />
            <InformationCell
              label={translation.clientExpectation}
              value={formik.values.price}
              endAdornment={priceCurrency}
              thousandSeparator
              edit={edit}
              name="price"
              onChange={formik.handleChange}
              inputComponent={
                NumberInput as unknown as FunctionComponent<InputBaseComponentProps>
              }
              error={formik.touched.price && Boolean(formik.errors.price)}
              errorText={formik.errors.price}
            />
            <SelectCell
              label={translation.timeExpectation}
              value={formik.values.expectedTime}
              options={expectedTimes}
              defaultOption={{ name: '', value: '' }}
              edit={edit}
              onChange={formik.handleChange}
              name="expectedTime"
            />
          </StyledGrid>
        </Accordion>
      )}
      <Accordion
        defaultExpanded
        description={translation.legalInformationDescription}
        title={translation.legalInformationTitle}
      >
        <StyledGrid>
          <InformationCell
            label={translation.soatNumberLabel}
            value={formik.values.insuranceNumber}
            edit={edit}
            onChange={formik.handleChange}
            name="insuranceNumber"
            error={
              formik.touched.insuranceNumber &&
              Boolean(formik.errors.insuranceNumber)
            }
            errorText={formik.errors.insuranceNumber}
          />
          <DatePickerCell
            edit={edit}
            label={translation.soatExpiration}
            error={
              formik.touched.insuranceDueDate &&
              Boolean(formik.errors.insuranceDueDate)
            }
            name="insuranceDueDate"
            errorText={formik.errors.insuranceDueDate}
            value={formik.values.insuranceDueDate}
            handleDateChange={handleDateChange}
            language={selectedLanguage.code}
          />
          <InformationCell
            label={translation.insuranceInspectionReportNumberLabel}
            value={formik.values.insuranceInspectionReportNumber}
            edit={edit}
            onChange={formik.handleChange}
            name="insuranceInspectionReportNumber"
            error={
              formik.touched.insuranceInspectionReportNumber &&
              Boolean(formik.errors.insuranceInspectionReportNumber)
            }
            errorText={formik.errors.insuranceInspectionReportNumber}
          />
          <DatePickerCell
            edit={edit}
            label={translation.insuranceInspectionDateLabel}
            error={
              formik.touched.insuranceInspectionDate &&
              Boolean(formik.errors.insuranceInspectionDate)
            }
            name="insuranceInspectionDate"
            errorText={formik.errors.insuranceInspectionDate}
            value={formik.values.insuranceInspectionDate}
            handleDateChange={handleDateChange}
            language={selectedLanguage.code}
          />
          <InformationCell
            label={translation.ownerShipCardLabel}
            value={formik.values.ownershipCardNumber}
            edit={edit}
            onChange={formik.handleChange}
            name="ownershipCardNumber"
            error={
              formik.touched.ownershipCardNumber &&
              Boolean(formik.errors.ownershipCardNumber)
            }
            errorText={formik.errors.ownershipCardNumber}
          />
          <DatePickerCell
            edit={edit}
            label={translation.expirationCardLabel}
            error={
              formik.touched.ownershipCardDueDate &&
              Boolean(formik.errors.ownershipCardDueDate)
            }
            name="ownershipCardDueDate"
            errorText={formik.errors.ownershipCardDueDate}
            value={formik.values.ownershipCardDueDate}
            handleDateChange={handleDateChange}
            language={selectedLanguage.code}
          />
          <SelectCell
            label={translation.garmentLabel}
            value={formik.values.hasLegalImpedance}
            defaultOption={booleanOptions[0]}
            options={booleanOptions}
            name="hasLegalImpedance"
            edit={edit}
            onChange={formik.handleChange}
          />
          <SelectCell
            label={translation.trafficAccidentsLabel}
            value={formik.values.hasTrafficAccidents}
            defaultOption={booleanOptions[0]}
            options={booleanOptions}
            name="hasTrafficAccidents"
            edit={edit}
            onChange={formik.handleChange}
          />
          <SelectCell
            label={translation.subpoenasLabel}
            value={formik.values.hasTrafficInfrigements}
            defaultOption={booleanOptions[0]}
            options={booleanOptions}
            name="hasTrafficInfrigements"
            edit={edit}
            onChange={formik.handleChange}
          />
          <SelectCell
            label={translation.insuranceLabel}
            value={formik.values.hasInsuranceReport}
            defaultOption={booleanOptions[0]}
            options={booleanOptions}
            name="hasInsuranceReport"
            edit={edit}
            onChange={formik.handleChange}
          />
          <DatePickerCell
            edit={edit}
            label={translation.lastTaxPaymentLabel}
            error={
              formik.touched.lastTaxPaymentDate &&
              Boolean(formik.errors.lastTaxPaymentDate)
            }
            name="lastTaxPaymentDate"
            errorText={formik.errors.lastTaxPaymentDate}
            value={formik.values.lastTaxPaymentDate}
            handleDateChange={handleDateChange}
            language={selectedLanguage.code}
          />
        </StyledGrid>
      </Accordion>
      <Appointment
        disabledDays={disabledDays}
        weekCalendarData={weekCalendarData}
        curboSpots={curboSpots}
        disabled={!edit}
        appointmentDate={appointmentDate}
        handleAppointmentDateChange={handleAppointmentDateChange}
        onDateAccept={onDateAccept}
        address={address}
        dateKey={dateKey}
        handleAddressChange={handleAddressChange}
        handleHourChange={handleHourChange}
        handleMeridiamChange={handleMeridiamChange}
        meridiam={meridiam}
        time={time}
        errors={errors}
      />
    </>
  )
}

export default SellMyCarSection
