import React, { FunctionComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {
  InputAdornment,
  InputBaseComponentProps,
  Typography,
} from '@mui/material'
import {
  Autocomplete,
  AutocompleteItem,
  BaseEntity,
  Box,
  Button,
  ButtonNavigator,
  cypressBackButton,
  ExtendedStatus,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'curbo-components-library'

import { StyledContainer as StyledToggleContainer } from 'components/Common/Toggle/style'
import NumberValueInput from 'components/Inventory/Common/NumberValueInput'

import { VEHICLE_CREATION_OPTION } from 'constants/PreInventory/creation'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { ModelTrimYears } from 'models/services/car'
import { FirstStepModel } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/PreInventory/creation'

import { GET_INSPECTION_MODELS } from 'graphQL/Common/CarFeature/queries'
import {
  GET_INSPECTION_TRIM_LEVELS,
  GET_INSPECTION_TRIM_YEARS,
} from 'graphQL/Inspection/Creation/queries'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

import Toggle from './ToggleToolltip'

type VehicleInfoError = {
  make: boolean
  model: boolean
  year: boolean
  trim: boolean
  status: boolean
  basePrice: boolean
}

const initialErrors: VehicleInfoError = {
  make: false,
  model: false,
  year: false,
  trim: false,
  status: false,
  basePrice: false,
}

const GeneralInformation = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryContinue,
  brands,
  statusList,
}: VehicleCreationProps) => {
  const {
    vehicleInformation: { firstStep },
  } = vehicleData
  const { currentModelList, currentTrimLevelList, currentYearList } = firstStep
  const [isNew, setIsNew] = useState<boolean>(true)
  const [vehicleInfo, setVehicleInfo] = useState<FirstStepModel>(firstStep)
  const [modelsList, setModelsList] = useState<BaseEntity[]>(currentModelList)
  const [trimYearsList, setTrimYearsList] =
    useState<BaseEntity[]>(currentYearList)
  const [trimLevelsList, setTrimLevelsList] =
    useState<BaseEntity[]>(currentTrimLevelList)
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''
  const history = useHistory()

  const [errors, setErrors] = useState<VehicleInfoError>(initialErrors)

  const {
    text: {
      subSteps: { firstStep: translation },
      buttons,
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const [fetchModels, { loading: modelsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    FilterInputVariable
  >(GET_INSPECTION_MODELS, {
    onCompleted(response) {
      setModelsList(response.data)
    },
  })

  const [fetchYears, { loading: yearsLoading }] = useLazyQuery<
    GenericData<ModelTrimYears>,
    GenericInputVariable<string>
  >(GET_INSPECTION_TRIM_YEARS, {
    onCompleted(response) {
      setTrimYearsList(
        response.data.trimYears.map((trimYear) => {
          return {
            name: String(trimYear),
            id: trimYear,
          }
        })
      )
    },
  })

  const [fetchTrims, { loading: trimsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    FilterInputVariable
  >(GET_INSPECTION_TRIM_LEVELS, {
    onCompleted(response) {
      setTrimLevelsList(response.data)
    },
  })

  const goBack = () => {
    history.push(routes.PRE_INVENTORY_LISTING)
  }

  const handleSelectBrand = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        make: value,
        model: null,
        trimLevel: null,
        vehicleYear: null,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        make: false,
      }
    })
    fetchModels({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            brand_eq: value.id,
          },
        },
      },
    })
  }

  const handleSelectModel = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        model: value,
        vehicleYear: null,
        trimLevel: null,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        model: false,
      }
    })
    fetchYears({
      variables: {
        input: value.id as string,
      },
    })
  }

  const handleSelectYear = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleYear: value,
        trimLevel: null,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        year: false,
      }
    })

    fetchTrims({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            year: value.id,
            carModel: vehicleInfo.model?.id,
          },
        },
      },
    })
  }

  const handleSelectTrim = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        trimLevel: value,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        trim: false,
      }
    })
  }

  const handleSelectStatus = (value: ExtendedStatus) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        status: value,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        status: false,
      }
    })
  }

  const handleChange = (value: number) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        basePrice: value,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        basePrice: false,
      }
    })
  }

  const handleBlur = () => {
    if (vehicleInfo.basePrice < 1) {
      setVehicleInfo((prevInfo) => {
        return {
          ...prevInfo,
          basePrice: 1,
        }
      })
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          basePrice: false,
        }
      })
    }
  }

  const handleSubmit = () => {
    const { make, model, vehicleYear, trimLevel, status, basePrice } =
      vehicleInfo

    if (!make || !model || !trimLevel || !vehicleYear) {
      setErrors({
        make: !make && true,
        model: !model && true,
        year: !vehicleYear && true,
        trim: !trimLevel && true,
        status: !status && true,
        basePrice: !basePrice && true,
      })
      return
    }
    setErrors(initialErrors)
    updateVehicleData({
      type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
      payload: {
        ...vehicleData.vehicleInformation,
        firstStep: {
          currentModelList: modelsList,
          currentTrimLevelList: trimLevelsList,
          currentYearList: trimYearsList,
          make,
          model,
          trimLevel,
          vehicleYear,
          status,
          basePrice,
        },
      },
    })
    handleSecondaryContinue()
  }

  const getVehicleName = () => {
    const { make, model, trimLevel, vehicleYear } = vehicleInfo

    if (make && model && trimLevel && vehicleYear)
      return `${make.name} ${model.name} ${vehicleYear.name} ${trimLevel.name}`

    return ''
  }

  return (
    <StyledContainer>
      <StyledToggleContainer
        title={translation.toggleTitle}
        description={translation.toggleDescription}
      >
        <Toggle
          value={isNew}
          trueLabel={buttons.trueLabel}
          falseLabel={buttons.falseLabel}
          handleValueChange={setIsNew}
          testId="new"
        />
      </StyledToggleContainer>
      <StyledForm>
        {isNew ? (
          <div>
            <StyledTextFieldContainer title={translation.make}>
              <Autocomplete
                options={brands}
                onChangeHandler={handleSelectBrand}
                placeholder={translation.make}
                value={vehicleInfo.make}
                disablePortal={false}
                error={errors.make}
                testId="makes-autocomplete"
              />
              {errors.make && (
                <StyledErrorMessage text={validationText.fieldRequired} />
              )}
            </StyledTextFieldContainer>

            <StyledTextFieldContainer title={translation.model}>
              <Autocomplete
                options={modelsList}
                onChangeHandler={handleSelectModel}
                placeholder={translation.model}
                value={vehicleInfo.model}
                disabled={modelsLoading || !vehicleInfo.make}
                disablePortal={false}
                error={errors.model}
                testId="models-autocomplete"
              />
              {errors.model && (
                <StyledErrorMessage text={validationText.fieldRequired} />
              )}
            </StyledTextFieldContainer>

            <StyledTextFieldContainer title={translation.vehicleYear}>
              <Autocomplete
                options={trimYearsList}
                onChangeHandler={handleSelectYear}
                placeholder={translation.vehicleYear}
                value={vehicleInfo.vehicleYear}
                disabled={yearsLoading || !vehicleInfo.model}
                disablePortal={false}
                error={errors.year}
                testId="years-autocomplete"
              />
              {errors.year && (
                <StyledErrorMessage text={validationText.fieldRequired} />
              )}
            </StyledTextFieldContainer>

            <StyledTextFieldContainer title={translation.trimLevel}>
              <Autocomplete
                options={trimLevelsList}
                onChangeHandler={handleSelectTrim}
                placeholder={translation.trimLevel}
                value={vehicleInfo.trimLevel}
                disabled={trimsLoading || !vehicleInfo.vehicleYear}
                disablePortal={false}
                error={errors.trim}
                testId="trims-autocomplete"
              />
              {errors.trim && (
                <StyledErrorMessage text={validationText.fieldRequired} />
              )}
            </StyledTextFieldContainer>

            <StyledTextFieldContainer title={translation.vehicleName}>
              <StyledTextField
                variant="outlined"
                name="vehicleName"
                value={getVehicleName()}
                placeholder={translation.vehicleName}
                disabled
              />
            </StyledTextFieldContainer>

            <StyledTextFieldContainer title={translation.status}>
              <Autocomplete
                options={statusList}
                onChangeHandler={(value: AutocompleteItem) => {
                  handleSelectStatus(value as ExtendedStatus)
                }}
                placeholder={translation.status}
                value={vehicleInfo.status}
                disablePortal={false}
                error={errors.status}
                testId="statuses-autocomplete"
              />
              {errors.status && (
                <StyledErrorMessage text={validationText.fieldRequired} />
              )}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={translation.basePriceLabel}>
              <StyledTextField
                variant="outlined"
                name="basePrice"
                value={vehicleInfo.basePrice}
                placeholder={translation.basePriceLabel}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="body2">{priceCurrency}</Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange(Number(e.target.value))}
                onBlur={handleBlur}
                error={Boolean(errors.basePrice)}
              />
              {errors.basePrice ? (
                <StyledErrorMessage text={validationText.fieldRequired} />
              ) : null}
            </StyledTextFieldContainer>
          </div>
        ) : null}
      </StyledForm>
      {isNew ? (
        <ButtonNavigator
          translation={buttons}
          isFirstStep
          nextFunction={handleSubmit}
        />
      ) : (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            size="small"
            onClick={goBack}
            data-cy={cypressBackButton}
            sx={{ width: '200px' }}
          >
            {translation.goBack}
          </Button>
        </Box>
      )}
    </StyledContainer>
  )
}

export default GeneralInformation
