import React from 'react'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Box, Title } from 'curbo-components-library'

import DistributionCalendar from 'components/Inspector/DistributionCalendar'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

const DistributionCalendarPage = () => {
  const { text } = useTranslation(textFiles.INSPECTOR_CALENDAR)
  const { general: translation } = text

  return (
    <Box width="100%" marginTop="-40px">
      <Title
        title={translation.title}
        subtitle={translation.description}
        icon={<PlaylistAddCheckIcon />}
      />
      <Box marginTop="25px">
        <DistributionCalendar />
      </Box>
    </Box>
  )
}

export default DistributionCalendarPage
