import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'
import {
  Avatar,
  Box,
  FilterInput,
  formatFullName,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
} from 'curbo-components-library'
import { isPast, parseISO } from 'date-fns'

import {
  InformationCard,
  InfoRowType,
} from 'components/Common/InformationCard/InformationCard'
import { ContactContainer } from 'components/Inspection/Detail/Client/ContactContainer'
import AbsenceManager, { LocalAbsenceType } from 'components/Inspector/Absence'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  InspectorAbsence,
  InspectorPersonalInformation,
} from 'models/services/inspector/detail'

import { GET_INSPECTOR_ABSENCES } from 'graphQL/Inspector/Detail/queries'

import {
  contentStyle,
  ImageBox,
  PersonBox,
  StyledBox,
  StyledContainer,
} from './style'

const sortAbsences = (absences: LocalAbsenceType[]) => {
  const orderedAbsences = absences.sort(
    (absence1, absence2) =>
      Number(parseISO(absence1.startDate)) -
      Number(parseISO(absence2.startDate))
  )
  const activeAbsences = orderedAbsences.filter(
    (item) => !isPast(parseISO(item.endDate))
  )
  const pastAbsences = orderedAbsences.filter((item) =>
    isPast(parseISO(item.endDate))
  )
  return {
    active: activeAbsences,
    past: pastAbsences,
  }
}

export type PersonalInfoProps = {
  inspectorData: InspectorPersonalInformation
}

const PersonalInfoTab = ({ inspectorData }: PersonalInfoProps) => {
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)

  const { personal: translation } = text

  const [activeAbsences, setActiveAbsences] = useState<LocalAbsenceType[]>([])
  const [pastAbsences, setPastAbsences] = useState<LocalAbsenceType[]>([])

  const getString = (input: null | string) => {
    return input === null ? 'N/A' : input
  }
  const informationRows: InfoRowType[] = [
    {
      icon: <PersonIcon />,
      title: formatFullName(inspectorData.name, inspectorData.lastName),
      subtitle: translation.personalName,
    },
    {
      icon: <LocationOnIcon />,
      title: getString(inspectorData.address),
      subtitle: translation.personalAddress,
    },
  ]

  const { loading: loadingAbsences, refetch: refetchAbsences } = useQuery<
    GenericData<InspectorAbsence[]>,
    GenericInputVariable<FilterInput>
  >(GET_INSPECTOR_ABSENCES, {
    variables: { input: { start: 0, where: { inspector: inspectorData.id } } },
    onCompleted(response) {
      const dataAbsence: LocalAbsenceType[] = response.data.map(
        (absence, index) => {
          return {
            key: index,
            id: absence.id,
            startDate: absence.startDate,
            endDate: absence.endDate,
            reason: absence.reason,
          }
        }
      )
      const absences = sortAbsences(dataAbsence)

      setActiveAbsences(absences.active)
      setPastAbsences(absences.past)
    },
  })

  return (
    <PersonBox>
      <StyledContainer
        title={translation.personalTitle}
        description={translation.personalSubtitle}
        contentStyle={{ border: 'none', paddingLeft: '0px' }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          columnGap="15px"
        >
          <Box display="flex" flexDirection="column" width="50%">
            <ImageBox>
              <Avatar
                height="330px"
                width="336px"
                variant="rounded"
                image={inspectorData.profilePicture}
              />
            </ImageBox>
          </Box>
          <Box display="flex" flexDirection="column" width="50%">
            <StyledBox>
              <InformationCard rows={informationRows} />
            </StyledBox>
            <ContactContainer
              phone={getString(inspectorData.telephoneNumber)}
              email={inspectorData.email}
              contentSx={{
                marginTop: '18px',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
              }}
            />
          </Box>
        </Box>
      </StyledContainer>
      <StyledContainer
        title={translation.absenceTitle}
        description={translation.absenceSubtitle}
        contentStyle={contentStyle}
        width="fit-content"
      >
        {loadingAbsences ? (
          <LoadingAnimation showAnimation={loadingAbsences} />
        ) : (
          <AbsenceManager
            inspectorId={inspectorData.id}
            activeAbsences={activeAbsences}
            pastAbsences={pastAbsences}
            refreshFunction={refetchAbsences}
          />
        )}
      </StyledContainer>
    </PersonBox>
  )
}

export default PersonalInfoTab
