import React from 'react'

import NameContainer from 'components/Detail/NameContainer'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { TrimDetailDataType } from 'models/services/operation/carSettings/detail'
import { getTrimMileage } from 'utils/operation/trimLevel/detail'

import { GridBox, StyledContainer } from 'styles/detail'

type Props = {
  information: TrimDetailDataType
}

const TrimMileage = ({ information }: Props) => {
  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const { power: translation } = text

  const nameInformation = getTrimMileage(
    translation.torque,
    translation.mileage,
    translation.milesLabel,
    information
  )

  return (
    <StyledContainer>
      <GridBox>
        <NameContainer
          title={translation.torque.title}
          description={translation.torque.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={nameInformation.torque}
          editDisabled
        />
        <NameContainer
          title={translation.mileage.title}
          description={translation.mileage.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={nameInformation.mileage}
          editDisabled
        />
      </GridBox>
    </StyledContainer>
  )
}

export default TrimMileage
