import { styled } from '@mui/material'
import { Box, colors } from 'curbo-components-library'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  backgroundColor: colors.commonWhite,
})

export const StyledContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})
