import axios from 'axios'

import {
  BACKEND_IMAGE_UPLOAD_ENDPOINT,
  BACKEND_IMAGES_UPLOAD_ENDPOINT,
} from 'constants/environment'
import { ACCESS_TOKEN_KEY } from 'constants/localStorage'

export async function uploadImageService(file: File, mainPicture?: boolean) {
  const formData: FormData = new FormData()
  formData.append('file', file)

  let token = ''
  if (typeof window !== undefined) {
    token =
      window.localStorage.getItem(ACCESS_TOKEN_KEY)?.replace(/"/g, '') || ''
  }

  return axios({
    method: 'POST',
    url: mainPicture
      ? BACKEND_IMAGE_UPLOAD_ENDPOINT
      : BACKEND_IMAGE_UPLOAD_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  })
}

export async function uploadImagesService(files: File[]) {
  const formData: FormData = new FormData()

  files.forEach((file) => {
    formData.append('files', file)
  })

  let token = ''
  if (typeof window !== undefined) {
    token =
      window.localStorage.getItem(ACCESS_TOKEN_KEY)?.replace(/"/g, '') || ''
  }

  return axios({
    method: 'POST',
    url: BACKEND_IMAGES_UPLOAD_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  })
}
