import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Box,
  FilterInputVariable,
  GenericData,
  LoadingAnimation,
  useNotification,
} from 'curbo-components-library'

import { SaveChangesBlock } from 'components/Operation/CarSettings/Common/SaveChangesBlock'

import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import {
  arrayOfNamedDays,
  emptyCheckedWeekCalendar,
} from 'constants/operation/schedule'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  CheckedWeekCalendar,
  CheckHourType,
  DaysPair,
  HoursPair,
  ScheduleModel,
  ScheduleType,
} from 'models/schedule'
import { HourTimeType } from 'models/services/curboSpot'

import {
  GET_INSPECTION_HOURS,
  GET_TEST_DRIVE_HOURS,
} from 'graphQL/Operations/OutOfSpot/queries'

import ScheduleItem from './ScheduleItem'
import { VehicleBox } from './style'

type Props = {
  schedules: ScheduleModel
  configurationLoading: Record<string, boolean>
  updateSchedules: (newSchedules: HoursPair) => Promise<boolean>
}

const Information = ({
  schedules,
  configurationLoading,
  updateSchedules,
}: Props) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const [selectedDay, setSelectedDay] = useState<DaysPair>({
    testDrive: 'monday',
    inspections: 'monday',
  })

  const [inspectionCheckedHours, setInspectionCheckedHours] =
    useState<CheckedWeekCalendar>(emptyCheckedWeekCalendar)
  const [testDriveCheckedHours, setTestDriveCheckedHours] =
    useState<CheckedWeekCalendar>(emptyCheckedWeekCalendar)

  const { show } = useNotification()

  const { text } = useTranslation(textFiles.SCHEDULE_MANAGEMENT)
  const { testDrive, inspection } = text

  const getCheckedWeek = (hoursArray: HourTimeType[], type: ScheduleType) => {
    const weekHours = { ...emptyCheckedWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      weekHours[day] = hoursArray.map((hour) => {
        return {
          ...hour,
          checked:
            !!schedules[type][day].find(
              (foundHour) => foundHour.value === hour.value
            ) || false,
        } as CheckHourType
      })
    })
    return weekHours
  }

  const { data: inspectionHours, loading: inspectionsHoursLoading } = useQuery<
    GenericData<HourTimeType[]>,
    FilterInputVariable
  >(GET_INSPECTION_HOURS, {
    variables: {
      input: {
        sort: {
          continentalTime: 'asc',
        },
      },
    },
    onCompleted: (response) => {
      const responseHours = response.data
      setInspectionCheckedHours(getCheckedWeek(responseHours, 'inspections'))
    },
    onError: () => {
      show({
        updatedSeverity: 'error',
        message: ENTITY_NOT_FOUND_ERROR,
      })
    },
  })

  const { data: testDriveHours, loading: testDriveHoursLoading } = useQuery<
    GenericData<HourTimeType[]>,
    FilterInputVariable
  >(GET_TEST_DRIVE_HOURS, {
    variables: {
      input: {
        sort: {
          continentalTime: 'asc',
        },
      },
    },
    onCompleted: (response) => {
      const responseHours = response.data
      setTestDriveCheckedHours(getCheckedWeek(responseHours, 'testDrive'))
    },
    onError: () => {
      show({
        updatedSeverity: 'error',
        message: ENTITY_NOT_FOUND_ERROR,
      })
    },
  })

  const handleResetChanges = () => {
    setTestDriveCheckedHours(getCheckedWeek(testDriveHours!.data, 'testDrive'))
    setInspectionCheckedHours(
      getCheckedWeek(inspectionHours!.data, 'inspections')
    )
    setHasUnsavedChanges(false)
  }

  const handleUpdateSchedules = async () => {
    const testDriveArray = { ...emptyCheckedWeekCalendar }
    const inspectionsArray = { ...emptyCheckedWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      testDriveArray[day] = [
        ...testDriveCheckedHours[day].filter((foundHour) => foundHour.checked),
      ]
      inspectionsArray[day] = [
        ...inspectionCheckedHours[day].filter((foundHour) => foundHour.checked),
      ]
    })
    const isCalendarUpdateSuccesful = await updateSchedules({
      testDrive: testDriveArray,
      inspections: inspectionsArray,
    })

    if (isCalendarUpdateSuccesful) {
      setHasUnsavedChanges(false)
    }
  }

  const handleChangeCheckedHours = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ScheduleType
  ) => {
    const selectedType =
      type === 'testDrive' ? testDriveCheckedHours : inspectionCheckedHours

    const selectedHour = selectedType[selectedDay[type]]

    const newValue = {
      ...selectedType,
      [selectedDay[type]]: selectedHour.map((hour) => {
        return {
          ...hour,
          checked: hour.value === e.target.value ? !hour.checked : hour.checked,
        }
      }),
    }

    if (type === 'testDrive') {
      setTestDriveCheckedHours(newValue)
    } else {
      setInspectionCheckedHours(newValue)
    }
    setHasUnsavedChanges(true)
  }

  const hourContainerSize =
    testDriveCheckedHours[selectedDay.testDrive].length >
    inspectionCheckedHours[selectedDay.inspections].length
      ? Math.ceil(testDriveCheckedHours[selectedDay.testDrive].length / 2)
      : Math.ceil(inspectionCheckedHours[selectedDay.inspections].length / 2)

  const isLoading = inspectionsHoursLoading || testDriveHoursLoading

  if (isLoading) return <LoadingAnimation showAnimation={isLoading} />

  return (
    <Box width="100%" sx={{ marginTop: '3rem' }}>
      <VehicleBox>
        <ScheduleItem
          hours={testDriveCheckedHours[selectedDay.testDrive]}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          handleChangeCheckbox={handleChangeCheckedHours}
          translation={testDrive}
          type="testDrive"
          hourContainerSize={hourContainerSize}
        />
        <ScheduleItem
          hours={inspectionCheckedHours[selectedDay.inspections]}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          handleChangeCheckbox={handleChangeCheckedHours}
          translation={inspection}
          type="inspections"
          hourContainerSize={hourContainerSize}
        />
      </VehicleBox>
      <SaveChangesBlock
        handleSaveChanges={handleUpdateSchedules}
        shouldRender={hasUnsavedChanges}
        resetState={handleResetChanges}
        submitLoading={
          configurationLoading.submitLoading ||
          configurationLoading.calendarLoading
        }
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      />
    </Box>
  )
}

export default Information
