import { Box, styled } from '@mui/material'
import { BREAK_POINTS, colors } from 'curbo-components-library'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  maxWidth: '1387px',
})

export const StyledBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 0rem',
  justifyContent: 'center',
  alignContent: 'center',
  marginTop: '50px',
  backgroundColor: colors.commonWhite,
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    padding: '2rem 3rem !important',
  },
})
