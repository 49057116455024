import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import {
  BaseIdEntity,
  Box,
  ButtonNavigator,
  formatDateLocalizedTime,
  GenericData,
  GenericInputVariable,
  getIsoDate,
  SummaryItem,
  useNotification,
} from 'curbo-components-library'

import { INSPECTION_SUB_ROUTES } from 'constants/routes'
import { InspectionOriginEnum } from 'constants/service'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { CreateInspectionInputType } from 'models/services/inspection/creation'
import { InspectionCreationProps } from 'utils/Inspection/creation'

import { CREATE_INSPECTION } from 'graphQL/Inspection/Creation/mutation'

import { StyledContainer, StyledItem } from './style'

const InspectionSummary = ({
  handleBack,
  inspectionData,
}: InspectionCreationProps) => {
  const { show } = useNotification()
  const history = useHistory()
  const { text: translation } = useTranslation(textFiles.INSPECTION_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [selectedLanguage] = useLocale()

  const [createInspection, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateInspectionInputType>
  >(CREATE_INSPECTION, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.summary.inspectionSuccess,
      })
      history.push(INSPECTION_SUB_ROUTES.INSPECTION_LISTING)
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.summary.inspectionFail,
      })
    },
  })

  const { vehicleInformation, schedulingInformation, dealerInformation } =
    inspectionData

  const { address, appointmentDate, inspector, inspectionDay, meridiam } =
    schedulingInformation

  const inspectionTime = inspectionDay
    ? `${inspectionDay.name} ${meridiam}`
    : ''

  const selectedTemplate = dealerInformation.template?.name

  const handleSubmit = () => {
    const car = vehicleInformation

    if (car) {
      const year = car.vehicleYear ? Number(car.vehicleYear.id) : 0
      const carModel = car.vehicleModel ? String(car.vehicleModel.id) : ''
      const trimLevel = car.vehicleTrimLevel || undefined
      const exteriorColor = car.exteriorColor
        ? String(car.exteriorColor.id)
        : ''
      const interiorColor = car.interiorColor
        ? String(car.interiorColor.id)
        : ''
      const date = getIsoDate(schedulingInformation.appointmentDate) || ''
      const appointmentInspector = schedulingInformation.inspector
        ? String(schedulingInformation.inspector.id)
        : ''
      const curboSpotId = address?.originFromSpot ? address.id : undefined
      const templateId = inspectionData.dealerInformation.template?.id
      const { price } = car
      createInspection({
        variables: {
          input: {
            car: {
              year,
              carModel,
              mileage: car.vehicleMileage,
              ...(trimLevel && {
                trimLevel: trimLevel.id as string,
              }),
              exteriorColor,
              interiorColor,
            },
            inspection: {
              date,
              ...(templateId && {
                type: templateId,
              }),
              inspector: appointmentInspector,
              origin: InspectionOriginEnum.DEALER,
              curboSpot: curboSpotId,
              hour: schedulingInformation.time,
              latitude: address?.lat,
              longitude: address?.lng,
              address: address?.address,
              price,
            },
          },
        },
      })
    }
  }

  const { id, vehicleYear, vehicleBrand, vehicleModel } = vehicleInformation
  const carName = `${vehicleYear?.name} ${vehicleBrand?.name} ${vehicleModel?.name}`

  return (
    <>
      <StyledContainer
        title={translation.summary.title}
        description={translation.summary.description}
      >
        <Box>
          <StyledItem title={translation.summary.inspectionTemplate}>
            <ul>
              <li key={selectedTemplate}>{selectedTemplate}</li>
            </ul>
          </StyledItem>
          <StyledItem title={translation.summary.vehicleToInspect}>
            <ul>
              <li key={id}>{carName}</li>
            </ul>
          </StyledItem>
          <SummaryItem
            title={translation.summary.inspectionDate}
            text={formatDateLocalizedTime(
              appointmentDate || new Date(),
              'P',
              selectedLanguage.code
            )}
            icon={<CalendarTodayIcon />}
          />
          <SummaryItem
            title={translation.summary.inspectionTime}
            text={inspectionTime}
            icon={<WatchLaterIcon />}
          />
          {inspector && (
            <SummaryItem
              title={translation.summary.inspectorName}
              text={`${inspector.name}`}
              icon={<PersonIcon />}
            />
          )}
          <SummaryItem
            title={translation.summary.inspectionLocation}
            text={schedulingInformation.address?.address || ''}
            icon={<LocationOnIcon />}
          />
        </Box>
        <ButtonNavigator
          isLastStep
          nextFunction={handleSubmit}
          previousFunction={handleBack}
          loading={submitLoading}
          translation={generalText.buttons}
        />
      </StyledContainer>
    </>
  )
}

export default InspectionSummary
