import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { IconButton, InputAdornment } from '@mui/material'
import {
  Avatar,
  Box,
  ButtonNavigator,
  colors,
  GenericData,
  GenericInputVariable,
  onlySpacesText,
} from 'curbo-components-library'

import { LEAD_CREATION_OPTION } from 'constants/Lead/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { LeadTestDriveCar, TestDriveCarResponse } from 'models/services/car'
import { LeadCreationProps } from 'utils/Lead/creation'

import { GET_CAR_BY_ID_OR_SLUG } from 'graphQL/CustomerRelationship/Lead/Creation/queries'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledFormCrm,
  StyledTextField,
} from 'styles/creation'

import { ImageBox, StyledTextFieldContainer } from './style'

type VehicleInfoError = {
  search: boolean
  brand: boolean
  model: boolean
  year: boolean
  trim: boolean
}

const initialErrors: VehicleInfoError = {
  search: false,
  brand: false,
  model: false,
  year: false,
  trim: false,
}

const VehicleDetail = ({
  leadData,
  handleSecondaryContinue,
  handleSecondaryBack,
  updateLeadData,
}: LeadCreationProps) => {
  const { vehicleInformation } = leadData

  const { text: translation } = useTranslation(textFiles.LEAD_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { vehicleDetail } = translation

  const [info, setInfo] = useState<LeadTestDriveCar>({
    id: vehicleInformation!.idOrSlug,
    brand: vehicleInformation!.brand,
    mainPicture: vehicleInformation!.mainPicture,
    model: vehicleInformation!.model,
    year: vehicleInformation!.year,
    trimLevel: vehicleInformation!.trim,
  })

  const [errors, setErrors] = useState<VehicleInfoError>(initialErrors)

  const [search, setSearch] = useState<string>(vehicleInformation!.idOrSlug)

  const [fetchCarById] = useLazyQuery<
    GenericData<TestDriveCarResponse>,
    GenericInputVariable<string>
  >(GET_CAR_BY_ID_OR_SLUG, {
    onCompleted(response) {
      if (response && response.data !== null) {
        setInfo((prevState) => ({
          ...prevState,
          id: response.data.id,
          brand: response.data.brand.name,
          model: response.data.model.name,
          mainPicture: response.data.mainPicture,
          year: response.data.year,
          trimLevel: response.data.trimLevel.name,
        }))
        setErrors({ ...errors, search: false })
      }
    },
    onError() {
      setErrors({ ...errors, search: true })
    },
  })

  const handleSubmit = () => {
    const { brand, mainPicture, model, year, trimLevel, id } = info

    if (!brand || !model || !year || !trimLevel) {
      setErrors({
        search: false,
        brand: !brand && true,
        model: !model && true,
        year: !year && true,
        trim: !trimLevel && true,
      })
      return
    }
    setErrors(initialErrors)
    handleSecondaryContinue()

    updateLeadData({
      type: LEAD_CREATION_OPTION.UPDATE_ACTION_INFORMATION,
      payload: {
        idOrSlug: id,
        brand,
        mainPicture,
        model,
        year,
        trim: trimLevel,
      },
    })
  }

  const handleBack = () => {
    handleSecondaryBack()
  }

  const handleAddCick = () => {
    if (!onlySpacesText(search)) {
      fetchCarById({
        variables: {
          input: search,
        },
      })
    }
  }

  return (
    <StyledContainer>
      <StyledFormCrm>
        <div
          style={{
            flexDirection: 'column',
          }}
        >
          <StyledTextFieldContainer title={vehicleDetail.idOrSlug}>
            <StyledTextField
              variant="outlined"
              name="search"
              placeholder={vehicleDetail.searchPlaceholder}
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddCick} edge="end">
                      <AddBoxIcon sx={{ color: colors.blue }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearch(e.target.value as string)}
            />
            {errors.search && (
              <StyledErrorMessage text={vehicleDetail.notAvailable} />
            )}
          </StyledTextFieldContainer>

          <Box display="flex" width="100%" columnGap="2.5rem">
            {info.mainPicture ? (
              <ImageBox>
                <Avatar
                  height="330px"
                  width="336px"
                  variant="rounded"
                  image={info.mainPicture}
                />
              </ImageBox>
            ) : null}
            <Box display="flex" flexWrap="wrap">
              {info.brand.length ? (
                <StyledTextFieldContainer title={vehicleDetail.brand}>
                  {info.brand}
                </StyledTextFieldContainer>
              ) : null}
              {info.model.length ? (
                <StyledTextFieldContainer title={vehicleDetail.model}>
                  {info.model}
                </StyledTextFieldContainer>
              ) : null}
              {info.year > 0 ? (
                <StyledTextFieldContainer title={vehicleDetail.year}>
                  {info.year}
                </StyledTextFieldContainer>
              ) : null}
              {info.trimLevel.length ? (
                <StyledTextFieldContainer title={vehicleDetail.trimLevel}>
                  {info.trimLevel}
                </StyledTextFieldContainer>
              ) : null}
            </Box>
          </Box>
        </div>
      </StyledFormCrm>
      <ButtonNavigator
        previousFunction={handleBack}
        nextFunction={handleSubmit}
        translation={generalText.buttons}
      />
    </StyledContainer>
  )
}

export default VehicleDetail
