import React from 'react'
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay'
import { colors } from 'curbo-components-library'

import { DAY_ENUM, weekNumber } from 'constants/date'
import { InspectionWeekCalendar } from 'models/services/curboSpot'

export const renderWeekPickerDay = (
  date: Date,
  selectedDates: Array<Date | null>,
  pickersDayProps: PickersDayProps<Date>
) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        color: colors.commonBlack,
        backgroundColor: 'transparent',
        fontSize: '12px',
        ':hover': {
          backgroundColor: colors.blue,
          color: colors.commonWhite,
        },
        ':focus': {
          backgroundColor: colors.blue,
        },
        ':target': {
          backgroundColor: colors.blue,
        },
        '& button:hover': {
          backgroundColor: colors.blue,
          color: colors.commonWhite,
        },
        '&.MuiPickersDay-dayOutsideMonth': {
          backgroundColor: 'transparent',
          color: colors.gray,
          cursor: 'auto',
        },
        '&.MuiPickersDay-dayOutsideMonth:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
          backgroundColor: colors.blue,
          color: `${colors.commonWhite} !important`,
        },
        '&.Mui-selected:hover': {
          backgroundColor: colors.blue,
          color: `${colors.commonWhite} !important`,
        },
        '&.MuiPickersDay-root:focus.Mui-selected': {
          backgroundColor: colors.blue,
          color: `${colors.commonWhite} !important`,
        },
      }}
      disableHighlightToday
      showDaysOutsideCurrentMonth
    />
  )
}

/**
 * Function that takes the schedule of a week and returns
 * the day that are empty
 */
export const getDisabledDayNumbers = (
  weekCalendar: InspectionWeekCalendar
): number[] => {
  const disabledDays: number[] = Object.keys(weekCalendar).reduce(
    (prevValue, key) => {
      if (key in weekCalendar && weekCalendar[key as DAY_ENUM].length === 0) {
        const newArray = [...prevValue, weekNumber[key as DAY_ENUM]]
        return newArray
      }
      return prevValue
    },
    [] as number[]
  )

  return disabledDays
}
