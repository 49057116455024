import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { TranslationType } from 'models/translation'

export const getInitialNameTranslation = (
  newTranslation: TranslationType | null
) => {
  const translations: TranslationItemType[] = []

  if (newTranslation) {
    if (newTranslation.es)
      translations.push({
        id: 1,
        name: 'Español',
        value: 'es',
        description: newTranslation.es,
      })
    if (newTranslation.en)
      translations.push({
        id: 2,
        name: 'English',
        value: 'en',
        description: newTranslation.en,
      })
  }
  return translations
}

/**
 * This function takes translation options that were selected at creation or update processes and separates them by language code,
 * ready to be sent in a mutation
 */
export const getTranslationsFilteredByLanguage = (
  translations: TranslationItemType[]
) => {
  return {
    valueEn: translations.find(
      (currentTranslation) => currentTranslation.value === 'en'
    ),
    valueEs: translations.find(
      (currentTranslation) => currentTranslation.value === 'es'
    ),
  }
}
