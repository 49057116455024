import { styled } from '@mui/material'
import { Container } from 'curbo-components-library'

export const ImgContainer = styled(Container)({
  width: '100%',
  padding: '0',
  paddingTop: '1rem !important',
})

export const StyledImg = styled('img')({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
})
