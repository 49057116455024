import React, { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { CSSObject, SelectChangeEvent } from '@mui/material'
import { Box, colors, Container, Option } from 'curbo-components-library'

import {
  FeatureBox,
  StyledBox,
  StyledContainer,
  StyledIcon,
  StyledSelect,
} from './style'

type Props = {
  options: Option[]
  information: string[]
  title: string
  description: string
  handleChangeFeatures?: (newInformation: string[]) => void
  height?: number | string
  width?: number | string
  containerSx?: CSSObject
  editDisabled?: boolean
}

const FeatureContainer = ({
  options,
  information,
  handleChangeFeatures,
  title,
  description,
  height,
  width,
  containerSx,
  editDisabled,
}: Props) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [info, setInfo] = useState<string[]>(information)

  const renderOption = (value: string): string => {
    return options.find((option) => option.value === value)!.name
  }

  const handleEdit = () => {
    if (!edit) {
      setEdit(true)
    } else {
      if (handleChangeFeatures) handleChangeFeatures(info)
      setEdit(false)
    }
  }

  const handleRemove = (e: React.MouseEvent, id: number) => {
    e.preventDefault()
    setInfo((prevState) => {
      const newInfo = [...prevState]
      newInfo.splice(id, 1)
      return newInfo
    })
  }

  return (
    <Container
      title={title}
      description={description}
      sx={containerSx}
      width={width}
    >
      <StyledBox height={height}>
        <StyledContainer height={height}>
          {!edit ? (
            <>
              {!editDisabled && (
                <StyledIcon onClick={handleEdit}>
                  <EditIcon
                    sx={{ color: colors.placeholderGray, fontSize: 16 }}
                  />
                </StyledIcon>
              )}
              <Box
                display="flex"
                flexWrap="wrap"
                sx={{
                  maxHeight: '75px',
                  overflow: 'auto',
                  gap: '7px',
                }}
              >
                {information.map((item) => (
                  <FeatureBox key={item} label={renderOption(item) || item} />
                ))}
              </Box>
            </>
          ) : (
            <>
              {!editDisabled && (
                <>
                  <StyledIcon
                    onClick={handleEdit}
                    sx={{ right: '50px !important' }}
                  >
                    <SaveIcon
                      sx={{ color: colors.placeholderGray, fontSize: 16 }}
                    />
                  </StyledIcon>
                  <StyledIcon onClick={() => setEdit(false)}>
                    <ClearIcon
                      sx={{ color: colors.placeholderGray, fontSize: 16 }}
                    />
                  </StyledIcon>
                </>
              )}
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                sx={{
                  '> div': {
                    width: '100%',
                  },
                }}
              >
                <StyledSelect
                  options={options}
                  value={info}
                  multiple
                  variant="outlined"
                  onChange={(event: SelectChangeEvent<unknown>) => {
                    setInfo(event.target.value as string[])
                  }}
                  renderValue={(selected: unknown) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '7px',
                        width: '100%',
                        minHeight: '23px',
                        overflowY: 'auto',
                      }}
                    >
                      {(selected as string[]).map((item, id) => (
                        <FeatureBox
                          key={item}
                          label={renderOption(item) || item}
                          clickable
                          onDelete={(e: React.MouseEvent) =>
                            handleRemove(e, id)
                          }
                          deleteIcon={
                            <ClearIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                        />
                      ))}
                    </Box>
                  )}
                />
              </Box>
            </>
          )}
        </StyledContainer>
      </StyledBox>
    </Container>
  )
}

export default FeatureContainer
