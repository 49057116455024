import React from 'react'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import AppsIcon from '@mui/icons-material/Apps'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import FlagIcon from '@mui/icons-material/Flag'

import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'

export const carMenuOptions = [
  {
    icon: <DirectionsCarIcon />,
    id: 1,
    translationKey: 'brands',
    url: CAR_SETTINGS_SUB_ROUTES.BRAND_LISTING,
  },
  {
    icon: <AirportShuttleIcon />,
    id: 2,
    translationKey: 'model',
    url: CAR_SETTINGS_SUB_ROUTES.MODEL_LISTING,
  },
  {
    icon: <AppsIcon />,
    id: 3,
    translationKey: 'trim',
    url: CAR_SETTINGS_SUB_ROUTES.TRIM_LISTING,
  },
  {
    icon: <FlagIcon />,
    id: 4,
    translationKey: 'country',
    url: CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_LISTING,
  },
]
