import { useContext } from 'react'

import { LocaleContext } from 'context/LocaleContext'

export const useTranslation = (file: string) => {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error('useTranslation must be used within a LocaleProvider')
  }

  const { selectedLanguage } = context

  /* eslint-disable global-require */
  /* eslint-disable import/no-dynamic-require */
  const text = selectedLanguage
    ? require(`../locales/${selectedLanguage.code}/${file}.json`)
    : require(`../locales/es/${file}.json`)

  return { text }
}

export default useTranslation
