import React, { useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { ClickAwayListener, CSSObject } from '@mui/material'
import MuiPopper from '@mui/material/Popper'
import {
  Box,
  CalendarRangeType,
  FilterButton,
  WeeklyRangeCalendarTabType,
} from 'curbo-components-library'

import WeeklyCalendarRange from 'components/Inspector/DistributionCalendar/WeeklyCalendarRange'

export type WeeklyRangeFilterProps = {
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRangeType>>
  dateRange: CalendarRangeType
  title: string
  sx?: CSSObject
  labelSx?: CSSObject
  calendarTabValue: WeeklyRangeCalendarTabType | boolean
  handleCalendarTabChange: (newValue: WeeklyRangeCalendarTabType) => void
}

const WeeklyRangeFilter = ({
  dateRange,
  setDateRange,
  title,
  sx,
  labelSx,
  calendarTabValue,
  handleCalendarTabChange,
}: WeeklyRangeFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <FilterButton
        id="fields-filter-popper"
        onClick={handleClick}
        text={title}
        icon={<DateRangeIcon fontSize="small" />}
        sx={sx}
        labelSx={labelSx}
        testId="date-range-button"
      />
      <MuiPopper
        id="fields-filter-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 2 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box marginTop="1.5rem">
            <WeeklyCalendarRange
              tabValue={calendarTabValue}
              handleTabChange={handleCalendarTabChange}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Box>
        </ClickAwayListener>
      </MuiPopper>
    </>
  )
}
export default WeeklyRangeFilter
