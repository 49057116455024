import React from 'react'
import { SelectChangeEvent } from '@mui/material'
import {
  Box,
  CurboSpot,
  DatePicker,
  TimePicker,
} from 'curbo-components-library'

import Accordion from 'components/Common/Accordion'
import LocationSection from 'components/Common/Location'
import { SchedulingError } from 'components/Inspection/Creation/Scheduling'

import { DAY_ENUM } from 'constants/date'
import { meridiamOptions } from 'constants/inspection'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { InspectionWeekCalendar } from 'models/services/curboSpot'

import { StyledErrorMessage } from 'styles/creation'

type AppointmentProps = {
  curboSpots: CurboSpot[]
  disabled: boolean
  weekCalendarData: InspectionWeekCalendar
  disabledDays: number[]
  appointmentDate: Date | null
  handleAppointmentDateChange: (date: Date | null) => void
  onDateAccept: () => void
  dateKey: DAY_ENUM
  address: Address | undefined
  handleAddressChange: (newAddress: Address | undefined) => void
  time: string
  handleHourChange: (event: SelectChangeEvent<unknown>) => void
  meridiam: string
  handleMeridiamChange: (event: SelectChangeEvent<unknown>) => void
  errors: SchedulingError
}

const Appointment = ({
  address,
  handleAddressChange,
  curboSpots,
  disabled,
  disabledDays,
  weekCalendarData,
  appointmentDate,
  handleAppointmentDateChange,
  onDateAccept,
  dateKey,
  time,
  handleHourChange,
  meridiam,
  handleMeridiamChange,
  errors,
}: AppointmentProps) => {
  const {
    text: { callToAction: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)
  const { appointment: appointmentTranslation } = translation
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  return (
    <Accordion
      defaultExpanded
      description={translation.appointmentDescription}
      title={translation.appointmentTitle}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridTemplateRows: '9fr 1fr',
          rowGap: '10px',
          justifyItems: 'end',
          marginTop: '1rem',
          columnGap: '15px',
        }}
      >
        <LocationSection
          showError={errors.address}
          translation={appointmentTranslation}
          address={address}
          handleAddressChange={handleAddressChange}
          containerStyling={{
            width: '100%',
            gridColumn: '1',
            gridRow: '1',
          }}
          curboSpots={curboSpots}
          disabled={disabled}
          circleCenter={
            address ? { lat: address.lat, lng: address.lng } : undefined
          }
        />
        <Box
          sx={{
            gridColumn: '2',
            gridRow: '1 / span 2',
          }}
        >
          <DatePicker
            value={appointmentDate}
            translation={appointmentTranslation}
            onChange={handleAppointmentDateChange}
            disabled={!address || disabled}
            shouldDisableDate={(disabledDate) =>
              disabledDays.includes(disabledDate.getDay())
            }
            onAccept={onDateAccept}
            startSameDay
          />
          {errors.calendar && (
            <StyledErrorMessage
              sx={{ marginBottom: '0.5rem' }}
              text={validationText.fieldRequired}
            />
          )}
          <TimePicker
            translation={appointmentTranslation}
            hourOptions={weekCalendarData[dateKey].filter((day) =>
              meridiam === 'AM' ? day.am : !day.am
            )}
            hour={time}
            hourChange={handleHourChange}
            meridiamOptions={meridiamOptions}
            meridiam={meridiam}
            meridiamChange={handleMeridiamChange}
            disabled={!appointmentDate || disabled}
          />
          {errors.time && (
            <StyledErrorMessage
              sx={{ marginBottom: '0.5rem' }}
              text={validationText.fieldRequired}
            />
          )}
        </Box>
      </Box>
    </Accordion>
  )
}

export default Appointment
