import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ApolloError, useQuery } from '@apollo/client'
import {
  formatFullName,
  generateTabItems,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
} from 'curbo-components-library'

import { DetailHeader } from 'components/CarSettings/Common/Detail/DetailHeader'
import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'
import PersonalInfoTab from 'components/Inspector/Detail/Personal'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { InspectorPersonalInformation } from 'models/services/inspector/detail'

import { GET_DEALER_INSPECTOR_BY_ID } from 'graphQL/Inspector/Detail/queries'

import { ContentContainer, Layout } from 'styles/inspection/detail'

const InspectorDetailPage = () => {
  const { inspectorId } = useParams<{ inspectorId: string }>()
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)
  const { general: translation } = text

  const [inspectorData, setInspectorData] = useState<
    InspectorPersonalInformation | undefined
  >()

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading: loadingInspectorData } = useQuery<
    GenericData<InspectorPersonalInformation>,
    GenericInputVariable<string>
  >(GET_DEALER_INSPECTOR_BY_ID, {
    variables: { input: inspectorId },
    onCompleted(response) {
      setInspectorData(response.data)
    },
    onError(error) {
      setApolloError(error)
    },
  })

  if (loadingInspectorData)
    return <LoadingAnimation showAnimation={loadingInspectorData} />

  if (apolloError) return <Redirect to={routes.NOT_FOUND_ERROR} />

  return inspectorData ? (
    <Layout>
      <DetailHeader
        title={`${translation.title} ${formatFullName(
          inspectorData?.name,
          inspectorData?.lastName
        )}`}
        backButtonText={translation.backButton}
        editable={false}
        status={inspectorData.status}
      />
      <DetailSubHeader text={translation.description} />
      <DetailNavTab tab={0} items={initialItems} />
      <ContentContainer>
        <TabPanel value={0} index={0}>
          <PersonalInfoTab inspectorData={inspectorData!} />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default InspectorDetailPage
