import React, { useState } from 'react'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Card, IconButton, TextField } from '@mui/material'
import { Box, FONT_WEIGHT, Modal } from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import {
  AddContainer,
  CardContainer,
  NoteContainer,
  StyledNoteCard,
  StyledNoteList,
  StyledText,
} from './style'

export type NotesProps = {
  /**
   ** All the strings representing the notes
   */
  data: string[]

  /**
   ** Handler for modifying the notes array
   */
  handleDataChange: (data: string[]) => void
}

const Notes = ({ data, handleDataChange }: NotesProps) => {
  const [creating, setCreating] = useState<boolean>(false)
  const [newNote, setNewNote] = useState<string>('')
  const [deletionModal, setDeletionModal] = useState<boolean>(false)
  const [selectedNote, setSelectedNote] = useState<number>(0)

  const { text } = useTranslation(textFiles.GENERAL)
  const { notesComponentText } = text

  const handleCloseModal = () => {
    setDeletionModal(false)
  }
  const handleOpenModal = (index: number) => {
    setSelectedNote(index)
    setDeletionModal(true)
  }

  const removeElement = () => {
    const newData = [...data]
    newData.splice(selectedNote, 1)
    handleDataChange(newData)
    handleCloseModal()
  }
  const addNote = (noteData: string) => {
    const newData = [...data]
    if (noteData !== '') {
      newData.unshift(noteData)
      handleDataChange(newData)
      setCreating(false)
    }
    setNewNote('')
  }

  return (
    <>
      <CardContainer variant="outlined">
        <Box
          style={{ padding: '1rem 0rem 1rem 1rem' }}
          height={200}
          width={333}
        >
          <StyledNoteList
            sx={{
              maxHeight: 200,
              minHeight: 200,
            }}
            subheader={<Card />}
          >
            {creating ? (
              <NoteContainer style={{ display: 'flex', flexDirection: 'row' }}>
                <StyledNoteCard>
                  <TextField
                    id="note-creator"
                    placeholder={notesComponentText.placeholder}
                    aria-label="note-content"
                    onChange={(e) => setNewNote(e.target.value)}
                    minRows={3}
                    variant="standard"
                    inputProps={{
                      maxLength: 102,
                      style: {
                        fontSize: '12px',
                        fontWeight: FONT_WEIGHT.MEDIUM,
                      },
                    }}
                    multiline
                    fullWidth
                    autoFocus
                  />
                </StyledNoteCard>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    <IconButton
                      onClick={() => {
                        addNote(newNote)
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        setCreating(false)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </NoteContainer>
            ) : null}
            {data.map((note, index) => (
              <NoteContainer key={`note-#${index + 1}`}>
                <StyledNoteCard>
                  <StyledText variant="subtitle1">{note}</StyledText>
                </StyledNoteCard>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <IconButton onClick={() => handleOpenModal(index)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </NoteContainer>
            ))}
          </StyledNoteList>
        </Box>
        <AddContainer>
          <IconButton
            color="primary"
            aria-label="create note"
            onClick={() => setCreating(true)}
            size="large"
          >
            <AddCircleRoundedIcon sx={{ fontSize: '2.1rem' }} />
          </IconButton>
        </AddContainer>
      </CardContainer>
      <Modal
        open={deletionModal}
        handleClose={handleCloseModal}
        enableBottom={{
          leftButtonText: notesComponentText.confirm,
          primaryFunction: removeElement,
          rightButtonText: notesComponentText.decline,
          secondaryFunction: handleCloseModal,
        }}
      >
        <Box textAlign="center" paddingTop="10px">
          <DeleteForeverIcon color="primary" />
          <StyledText variant="h6" width="250px" margin="auto">
            {notesComponentText.question}
          </StyledText>
        </Box>
      </Modal>
    </>
  )
}
export default Notes
