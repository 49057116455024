import {
  CalendarRangeType,
  ExtendedStatus,
  getSubDaysFromDate,
} from 'curbo-components-library'
import { endOfDay, startOfDay } from 'date-fns'

export enum LeadColumnField {
  ID = 'caseId',
  CLIENT = 'customer',
  EMAIL = 'customerEmail',
  PHONE = 'telephoneNumber',
  VEHICLE = 'car',
  CTA = 'type',
  SOURCE = 'origin',
  CREATED_AT = 'createdAt',
  PRICE = 'estimatedValue',
  STEP = 'leadStep',
  SPECIALIST = 'customerCareRepresentative',
}

export enum CaseStepEnum {
  LEAD_LOST = 'LEAD_LOST',
  LEAD_PENDING = 'LEAD_PENDING',
  LEAD_PROSPECTING = 'LEAD_PROSPECTING',
  LEAD_RECONTACT = 'LEAD_RECONTACT',
  LEAD_FINANCING = 'LEAD_FINANCING',
  LEAD_WON = 'LEAD_WON',
  NEW_LEAD = 'NEW_LEAD',
  NEW_LEAD_CRM = 'NEW_LEAD_CRM',
}

export enum CaseOriginEnum {
  ATOM = 'ATOM',
  BACKOFFICE = 'BACKOFFICE',
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  MOBILE = 'MOBILE',
  REFERRED = 'REFERRED',
  WALK_IN = 'WALK_IN',
  WEB = 'WEB',
  WHATSAPP = 'WHATSAPP',
}

export enum CaseTypeEnum {
  BUY = 'BUY',
  FIND_MY_CAR = 'FIND_MY_CAR',
  INFORMATIONAL = 'INFORMATIONAL',
  MAINTENANCE = 'MAINTENANCE',
  PREQUALIFY = 'PREQUALIFY',
  SELL_MY_CAR = 'SELL_MY_CAR',
  TEST_DRIVE = 'TEST_DRIVE',
}

export const kanbanhash = '#kanban-view'

export const leadStaticFields = [
  'leadStep',
  'type',
  'origin',
  'customerCareRepresentative',
]

export const defaultRange: CalendarRangeType = {
  fromDate: startOfDay(getSubDaysFromDate(7, new Date())),
  toDate: endOfDay(new Date()),
}

export const caseStepStatus = [
  {
    slug: CaseStepEnum.LEAD_WON,
    description: 'Leads converted and scheduled',
  },
  {
    slug: CaseStepEnum.NEW_LEAD,
    description: 'New request from the web/app or social',
  },
  {
    slug: CaseStepEnum.LEAD_PROSPECTING,
    description: 'Lead in the evaluation process to be converted',
  },
  {
    slug: CaseStepEnum.LEAD_LOST,
    description: 'Leads that cannot be converted',
  },
  {
    slug: CaseStepEnum.LEAD_PENDING,
    description: 'Lead awaiting response from Curbo',
  },
  {
    slug: CaseStepEnum.LEAD_RECONTACT,
    description: 'Lead that needs to be contacted again',
  },
]

export const defaultStepOption: ExtendedStatus = {
  description: 'Leads converted and scheduled',
  id: '62d0b11e99a56c84e1c5c533',
  name: 'Lead - Pending',
  slug: CaseStepEnum.LEAD_PENDING,
  backgroundColor: '#FFF7E6',
  textColor: '#F7AE14',
}
