import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material'
import {
  BaseIdEntity,
  CurboSpot,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  GenericUpdateVariable,
  getIsoDate,
  LoadingAnimation,
  useNotification,
  validateGraphQLErrorCode,
} from 'curbo-components-library'
import { getDay, parseISO } from 'date-fns'

import { SchedulingError } from 'components/Inspection/Creation/Scheduling'

import { DAY_ENUM, emptyWeekCalendar, weekDay } from 'constants/date'
import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import { meridiamOptions } from 'constants/inspection'
import { initialSchedulingErrors } from 'constants/Lead/detail'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { InspectionWeekCalendar } from 'models/services/curboSpot'
import {
  TestDrive,
  UpdateTestDriveInput,
} from 'models/services/customerRelationship/lead/detail'
import { getDisabledDayNumbers } from 'utils/calendarUtils'
import { getInitialMeridiam } from 'utils/Lead/detail'

import { GET_TEST_DRIVE_CURBO_SPOTS } from 'graphQL/Common/Dealer/queries'
import { UPDATE_TEST_DRIVE } from 'graphQL/CustomerRelationship/Lead/Detail/mutations'
import { GET_TEST_DRIVE_BY_ID } from 'graphQL/CustomerRelationship/Lead/Detail/queries'
import { GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID } from 'graphQL/Operations/OutOfSpot/queries'

import Appointment from '../Appointment'

type TestDriveSectionProps = {
  edit: boolean
  testDriveId: string
  cancelChanges: boolean
  saveChanges: boolean
  handleResetChanges: () => void
  handleCloseEdit: () => void
}

const TestDriveSection = ({
  edit,
  testDriveId,
  cancelChanges,
  saveChanges,
  handleResetChanges,
  handleCloseEdit,
}: TestDriveSectionProps) => {
  const { show } = useNotification()
  const {
    text: { general: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)

  const [curboSpots, setCurboSpots] = useState<CurboSpot[]>([])

  const [weekCalendarData, setWeekCalendarData] =
    useState<InspectionWeekCalendar | null>(null)
  const [disabledDays, setDisabledDays] = useState<number[]>([])
  const [address, setAddress] = useState<Address | undefined>(undefined)
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(null)
  const [dateKey, setDateKey] = useState<DAY_ENUM>(DAY_ENUM.MONDAY)
  const [meridiam, setMeridiam] = useState<string>(meridiamOptions[0].value)
  const [time, setTime] = useState<string>('')
  const [errors, setErrors] = useState<SchedulingError>(initialSchedulingErrors)
  const [testDriveData, setTestDriveData] = useState<TestDrive | null>(null)

  const setFieldData = (currentTestDrive: TestDrive) => {
    const {
      testDriveHours,
      address: testDriveAddress,
      curboSpot,
      date,
      isAtCurbo,
      latitude,
      longitude,
      name,
    } = currentTestDrive
    setAddress({
      address: testDriveAddress || '',
      id: curboSpot ? curboSpot.id : '',
      lat: latitude || 0,
      lng: longitude || 0,
      name,
      originFromSpot: isAtCurbo,
    })
    if (date) {
      const parsedDate = parseISO(date)
      const dateNumber = getDay(parsedDate)
      const dayKey = weekDay[dateNumber]
      setAppointmentDate(parsedDate)
      setDateKey(dayKey)
    } else {
      setAppointmentDate(null)
    }
    setMeridiam(getInitialMeridiam(testDriveHours))
    setTime(testDriveHours?.value || '')
  }

  const { loading: testDriveLoading } = useQuery<
    GenericData<TestDrive>,
    GenericInputVariable<string>
  >(GET_TEST_DRIVE_BY_ID, {
    variables: {
      input: testDriveId,
    },

    onCompleted(response) {
      setFieldData(response.data)
      setTestDriveData(response.data)
    },
  })

  useQuery<
    GenericData<InspectionWeekCalendar>,
    GenericInputVariable<string | null>
  >(GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID, {
    variables: {
      input: address && address.originFromSpot ? address.id : null,
    },
    onCompleted(response) {
      const responseWeekCalendar = { ...response.data }
      setWeekCalendarData(responseWeekCalendar)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        ENTITY_NOT_FOUND_ERROR
      )

      if (errorExists) {
        setWeekCalendarData(emptyWeekCalendar)
      }
    },
  })

  const { loading: curboSpotsLoading } = useQuery<
    GenericData<CurboSpot[]>,
    FilterInputVariable
  >(GET_TEST_DRIVE_CURBO_SPOTS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCurboSpots(response.data)
    },
  })

  const [updateTestDrive] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateTestDriveInput>
  >(UPDATE_TEST_DRIVE, {
    onCompleted() {
      handleResetChanges()
      handleCloseEdit()
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.updateFail,
      })
    },
  })

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    setAppointmentDate(null)
    setTime('')
  }

  const handleAppointmentDateChange = (newDate: Date | null) => {
    if (newDate) {
      const dateNumber = getDay(newDate)
      const dayKey = weekDay[dateNumber]
      setDateKey(dayKey)
      const storedDate = new Date(newDate.setHours(0, 0, 0, 0))
      setAppointmentDate(storedDate)
    }
  }

  const onDateAccept = () => {
    setTime('')
  }

  const handleHourChange = (event: SelectChangeEvent<unknown>) => {
    const selectedHour = event.target.value as string
    setTime(selectedHour)
  }

  const handleMeridiamChange = (event: SelectChangeEvent<unknown>) => {
    const selectedMeridiam = event.target.value as string
    setMeridiam(selectedMeridiam)
    setTime('')
  }

  useEffect(() => {
    if (weekCalendarData) {
      // here we are trying to get the number of the days that doesn't have any schedule
      // to disable them in the calendar
      const disabledDayNumbers: number[] =
        getDisabledDayNumbers(weekCalendarData)
      setDisabledDays(disabledDayNumbers)
    }
  }, [weekCalendarData])

  useEffect(() => {
    if (saveChanges) {
      if (!address || !appointmentDate || !time) {
        setErrors({
          address: !address && true,
          calendar: !appointmentDate && true,
          time: !time && true,
          inspector: false,
        })
        handleResetChanges()
        return
      }
      updateTestDrive({
        variables: {
          input: {
            data: {
              address: address.address,
              curboSpot: address.originFromSpot ? address.id : undefined,
              isAtCurbo: address.originFromSpot || false,
              date: getIsoDate(appointmentDate) || '',
              latitude: address.lat,
              longitude: address.lng,
              name: address.name,
              testDriveHours: time,
            },
            where: {
              id: testDriveId,
            },
          },
        },
      })
    }
  }, [
    saveChanges,
    updateTestDrive,
    testDriveId,
    address,
    appointmentDate,
    time,
    handleResetChanges,
  ])

  useEffect(() => {
    if (cancelChanges && testDriveData) {
      setFieldData(testDriveData)
      handleResetChanges()
    }
  }, [cancelChanges, testDriveData, handleResetChanges])

  if (testDriveLoading || curboSpotsLoading || !weekCalendarData)
    return <LoadingAnimation showAnimation />

  return (
    <Appointment
      disabledDays={disabledDays}
      weekCalendarData={weekCalendarData}
      curboSpots={curboSpots}
      disabled={!edit}
      appointmentDate={appointmentDate}
      handleAppointmentDateChange={handleAppointmentDateChange}
      onDateAccept={onDateAccept}
      address={address}
      dateKey={dateKey}
      handleAddressChange={handleAddressChange}
      handleHourChange={handleHourChange}
      handleMeridiamChange={handleMeridiamChange}
      meridiam={meridiam}
      time={time}
      errors={errors}
    />
  )
}

export default TestDriveSection
