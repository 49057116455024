import React from 'react'
import { ClickAwayListener, Popper, Typography } from '@mui/material'
import {
  Box,
  Button,
  colors,
  cypressCancelButton,
  cypressConfirmButton,
} from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledBox } from './style'

type RemoveItemPopperProps = {
  anchorEl: HTMLElement | null
  handleClosePopper: () => void
  handleConfirmRemove: () => void
  open: boolean
}

const RemoveItemPopper = ({
  anchorEl,
  handleClosePopper,
  handleConfirmRemove,
  open,
}: RemoveItemPopperProps) => {
  const {
    text: { translationText },
  } = useTranslation(textFiles.GENERAL)

  return (
    <Popper
      id="translation-popper"
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
    >
      <ClickAwayListener onClickAway={handleClosePopper}>
        <StyledBox>
          <Typography>{translationText.popperTitle}</Typography>
          <Box display="flex" justifyContent="space-evenly" marginTop="10px">
            <Button
              onClick={handleConfirmRemove}
              size="small"
              testId={cypressConfirmButton}
            >
              {translationText.confirm}
            </Button>
            <Button
              size="small"
              buttonType="info"
              sx={{
                backgroundColor: colors.background,
              }}
              onClick={handleClosePopper}
              testId={cypressCancelButton}
            >
              {translationText.decline}
            </Button>
          </Box>
        </StyledBox>
      </ClickAwayListener>
    </Popper>
  )
}

export default RemoveItemPopper
