import { gql } from '@apollo/client'

export const GET_INSPECTION_BY_ID = gql`
  query getDealerInspectionById($input: ID!) {
    data: getDealerInspectionById(id: $input) {
      id
      notes
      status {
        backgroundColor
        textColor
        name
        id
        slug
      }
      car {
        id
        owner {
          address
          email
          lastName
          name
          telephoneNumber
        }
        dealer {
          address
          name
          telephoneNumber
          dealerContacts {
            agent
            telephoneNumber
          }
        }
      }
    }
  }
`

export const GET_INSPECTION_CAR_BY_ID = gql`
  query getInspectionCar($input: ID!) {
    data: getDealerInspectionById(id: $input) {
      notes
      price
      id
      currencyCode
      car {
        id
        model {
          name
          id
          brand {
            id
            name
            icon
          }
        }
        year
        trim {
          name
          id
        }
      }
      steps {
        checkpoints {
          questions {
            pictures
          }
        }
      }
    }
  }
`

export const GET_INSPECTION_REPORT_BY_ID = gql`
  query getInspectionReport($input: ID!) {
    data: getDealerInspectionById(id: $input) {
      id
      steps {
        label
        id
        checkpoints {
          label
          id
          questions {
            value
            label
            id
            answer {
              correctAnswer
              doesntApply
              id
              label
            }
          }
        }
      }
      comment
      template {
        name
        id
      }
    }
  }
`

export const GET_INSPECTION_APPOINTMENT_BY_ID = gql`
  query getInspectionAppointment($input: ID!) {
    data: getDealerInspectionById(id: $input) {
      id
      date
      inspector {
        name
        id
        lastName
        profilePicture
      }
      hour {
        value: id
        name
        am
      }
      curboSpot {
        id
        latitude
        longitude
        address
        name
      }
      template {
        id
        name
        status
        visible
      }
      latitude
      longitude
      address
    }
  }
`

export const GET_TEMPLATES = gql`
  query getTemplates {
    data: getLatestTemplate {
      name
      id
      visible
      status
    }
  }
`
