import React, { useEffect, useState } from 'react'
import FlagIcon from '@mui/icons-material/Flag'
import { Box } from 'curbo-components-library'

import NameContainer from 'components/Detail/NameContainer'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/detail'
import { TranslationDetailDataType } from 'models/services/operation/carSettings/detail'
import { getInitialNameTranslation } from 'utils/translation'

import { StyledContainer } from 'styles/detail'

type CountryVersionInfoProps = {
  countryVersion: TranslationDetailDataType
}

const CountryVersionInformation = ({
  countryVersion,
}: CountryVersionInfoProps) => {
  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >([])
  const { text } = useTranslation(textFiles.COUNTRY_VERSION_DETAIL)
  const { general, countryVersionInformation: translation } = text
  const { name, nameTranslations } = countryVersion
  const initialNameInformation: NameInformation[] = [
    {
      id: '1',
      icon: <FlagIcon />,
      name,
      label: general.title,
    },
  ]

  const handleChangeTranslation = (translations: TranslationItemType[]) => {
    setAddedTranslations(translations)
  }

  useEffect(() => {
    setAddedTranslations(getInitialNameTranslation(nameTranslations))
  }, [nameTranslations])

  return (
    <StyledContainer>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        sx={{
          '> div': {
            padding: '15px 20px',
          },
        }}
      >
        <NameContainer
          title={translation.information}
          description={translation.informationDescription}
          information={initialNameInformation}
          width={399}
          editDisabled
        />
        <Translation
          addedTranslations={addedTranslations}
          handleChangeTranslation={handleChangeTranslation}
          editDisabled
        />
      </Box>
    </StyledContainer>
  )
}

export default CountryVersionInformation
