import { gql } from '@apollo/client'

export const GET_ORG_ROLES = gql`
  query getAssignableOrganizationRoles($input: FilterInput) {
    data: getAssignableOrganizationRoles(input: $input) {
      area {
        name
        slug
      }
      value: slug
      name
    }
  }
`
export const GET_USER_DETAIL_ORG_ROLES = gql`
  query getAssignableOrganizationRoles($input: FilterInput) {
    data: getAssignableOrganizationRoles(input: $input) {
      area {
        name
        slug
      }
      value: id
      name
      slug
    }
  }
`
export const GET_ORG_AREAS = gql`
  query getAssignableOrganizationRoleAreas {
    data: getAssignableOrganizationRoleAreas {
      name
      value: slug
    }
  }
`

export const GET_ASSIGNABLE_ROLES = gql`
  query getAssignableRoles($input: FilterInput) {
    data: getAssignableRoles(input: $input) {
      area
      value: id
      name
      slug
    }
  }
`
export const GET_ASSIGNABLE_AREAS = gql`
  query getAssignableRoleAreas {
    data: getAssignableRoleAreas {
      name
      value: slug
    }
  }
`
