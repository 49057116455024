import { CSSObject, styled } from '@mui/material'
import { Box, BREAK_POINTS, colors } from 'curbo-components-library'

export const StyledColorContainer = styled(Box)({
  width: '42px',
  height: '37px',
  border: `2px solid ${colors.commonWhite}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0.5rem',
})

export const extraTitleStyle: CSSObject = {
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    maxWidth: '340px',
  },
  [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
    maxWidth: '200px',
  },
}
