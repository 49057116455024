import { NameInformation } from 'models/car'
import { Role } from 'models/role'

import { ExtendedRole } from './General/UserManagement/detail'
import { ExtendedDealer } from './curboSpot'

export enum OrganizationTypeEnum {
  CURBO = 'CURBO',
  DEALER = 'DEALER',
}

export enum OrganizationStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EmployeeStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
}

export type Organization = {
  id: string
  name: string
  status: OrganizationStatusEnum
  type: OrganizationTypeEnum
}

export type User = {
  address: string
  email: string
  id: string
  lastName: string
  name: string
  profilePicture: string
  roles: Role[]
  telephoneNumber: string
  organization: Organization
}

export type UpdateSelfData = {
  updateSelf: User
}

export type UserInfoData = {
  self: User
}

export type DealerInfoData = {
  selfDealer: ExtendedDealer
}

export type LogInUserData = {
  signInUser: {
    user: User
    accessToken: string
  }
}

export type handleUpdateUserType = {
  newPersonalInfo?: NameInformation[]
  newContact?: NameInformation[]
  newRoles?: ExtendedRole[]
}

export type UpdateUserPayload = {
  address?: string
  documentId?: string
  drivingLicense?: string
  lastName?: string
  name?: string
  profilePicture?: string
  roles?: string[]
  telephoneNumber?: string
  status?: string
}

export type UserDetailData = {
  picture?: string
  personal: NameInformation[]
  contact: NameInformation[]
  roles: ExtendedRole[]
}
