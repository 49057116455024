import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { Typography } from '@mui/material'
import {
  addCommas,
  Box,
  ColorBox,
  colors,
  ExtendedAvatar,
  FONT_WEIGHT,
  InventorySlugStatusEnum,
} from 'curbo-components-library'
import { differenceInWeeks, intervalToDuration } from 'date-fns'

import CopyToClipboard from 'components/Common/CopyToClipboard'

import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { InventoryCar } from 'models/services/inventory/detail'

import placeholderCar from 'images/blueDefaultAvatar.png'

import {
  ImgContainer,
  InformationBox,
  StyledBrandImg,
  StyledContainer,
  StyledDataText,
  StyledDataTitle,
  VehicleBox,
} from './style'

type VehicleSummaryProps = {
  car: InventoryCar
}

const VehicleSummary = ({ car }: VehicleSummaryProps) => {
  const {
    text: { vehicleSummary: translation },
  } = useTranslation(textFiles.INVENTORY_DETAIL)
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''

  const {
    brand,
    categories,
    carModel,
    countryVersion,
    exteriorColor,
    interiorColor,
    mainPicture,
    slug,
    trimLevel,
    year,
    publishedAt,
    status,
    priceInfo,
  } = car

  let { days: dayInterval } = intervalToDuration({
    start: new Date(publishedAt),
    end: new Date(),
  })

  if (dayInterval && dayInterval > 7) {
    dayInterval -= Math.floor(dayInterval / 7) * 7
  }

  const weekInterval = differenceInWeeks(new Date(), new Date(publishedAt))

  return (
    <VehicleBox>
      <ImgContainer
        title={translation.vehicleThumbnailTitle}
        description={translation.vehicleThumbnailDescription}
        contentStyle={{
          height: '100%',
        }}
      >
        <Box>
          <section
            style={{ height: '100%', position: 'relative', width: '100%' }}
          >
            <Box
              display="flex"
              borderRadius="10px"
              padding="1rem"
              sx={{
                height: '365px',
                width: '365px',
                border: `1px solid ${colors.border}`,
              }}
            >
              <ExtendedAvatar
                image={mainPicture || placeholderCar}
                variant="rounded"
                height="100%"
                width="100%"
                sx={{
                  height: '100%',
                  width: '100%',
                }}
              />
            </Box>
          </section>
        </Box>
      </ImgContainer>
      <Box>
        <InformationBox>
          <StyledContainer
            title={translation.basicInformationTitle}
            description={translation.basicInformationDescription}
            contentStyle={{
              height: '100%',
            }}
            sx={{
              width: '350px',
            }}
          >
            <Box
              border={`1px solid ${colors.border}`}
              borderRadius="10px"
              padding="1rem"
            >
              <Box
                display="flex"
                flexDirection="column"
                borderRadius="10px"
                padding="2rem"
                sx={{ backgroundColor: colors.background, height: '465px' }}
              >
                {brand.icon && (
                  <StyledBrandImg
                    src={brand.icon}
                    srcSet={brand.icon}
                    alt="brand logo"
                    loading="lazy"
                  />
                )}
                <Box>
                  <StyledDataText variant="h5">{brand.name}</StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.makeLabel}
                  </StyledDataTitle>
                </Box>
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">{carModel.name}</StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.modelLabel}
                  </StyledDataTitle>
                </Box>
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">{year}</StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.yearLabel}
                  </StyledDataTitle>
                </Box>
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">{trimLevel.name}</StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.trimLabel}
                  </StyledDataTitle>
                </Box>
              </Box>
            </Box>
          </StyledContainer>
          <StyledContainer
            title={translation.catalogTitle}
            description={translation.catalogDescription}
            contentStyle={{
              height: '100%',
            }}
            sx={{
              width: '350px',
            }}
          >
            <Box
              border={`1px solid ${colors.border}`}
              borderRadius="10px"
              padding="1rem"
            >
              <Box
                display="flex"
                flexDirection="column"
                borderRadius="10px"
                padding="2rem"
                sx={{ backgroundColor: colors.background, height: '465px' }}
              >
                {categories.length > 0 ? (
                  <Box>
                    <StyledDataText variant="h5">
                      {categories.map((category) => category.name).join(', ')}
                    </StyledDataText>
                    <StyledDataTitle variant="body1">
                      {translation.categoriesLabel}
                    </StyledDataTitle>
                  </Box>
                ) : null}
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">
                    {countryVersion.name}
                  </StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.countryVersionLabel}
                  </StyledDataTitle>
                </Box>
                {status.slug === InventorySlugStatusEnum.AVAILABLE ? (
                  <Box marginTop="1.5rem">
                    <StyledDataText variant="h5">
                      {`${dayInterval} ${translation.days}`}
                      {weekInterval
                        ? `, ${weekInterval} ${translation.weeks}`
                        : null}
                    </StyledDataText>
                    <StyledDataTitle variant="body1">
                      {translation.timeOnInventory}
                    </StyledDataTitle>
                  </Box>
                ) : null}
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">
                    {`${priceCurrency} ${addCommas(priceInfo.basePrice)}`}
                  </StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.priceLabel}
                  </StyledDataTitle>
                </Box>
                <Box marginTop="1.5rem">
                  <Box alignItems="center" display="flex">
                    <ColorBox
                      height={27}
                      width={27}
                      styles={{
                        border: `1px solid ${colors.gray}`,
                        boxShadow: 'none',
                      }}
                      hexCode={exteriorColor.hexCode}
                    />
                    <StyledDataText marginLeft="1rem" variant="h5">
                      {exteriorColor.name}
                    </StyledDataText>
                  </Box>

                  <StyledDataTitle variant="body1">
                    {translation.exteriorColorLabel}
                  </StyledDataTitle>
                </Box>
                <Box marginTop="1.5rem">
                  <Box alignItems="center" display="flex">
                    <ColorBox
                      height={27}
                      width={27}
                      styles={{
                        border: `1px solid ${colors.gray}`,
                        boxShadow: 'none',
                      }}
                      hexCode={interiorColor.hexCode}
                    />
                    <StyledDataText marginLeft="1rem" variant="h5">
                      {interiorColor.name}
                    </StyledDataText>
                  </Box>

                  <StyledDataTitle variant="body1">
                    {translation.interiorColorLabel}
                  </StyledDataTitle>
                </Box>
              </Box>
            </Box>
          </StyledContainer>
        </InformationBox>
        <Box marginTop="1.5rem">
          <Box display="flex" justifyContent="flex-start">
            <Box display="flex">
              <DirectionsCarIcon
                fontSize="medium"
                sx={{ color: colors.black }}
              />
              <Typography
                variant="body2"
                color={colors.black}
                margin="0 1rem 0 0.25rem"
                fontWeight={FONT_WEIGHT.BOLD}
              >
                {'Slug: '}
                <CopyToClipboard color={colors.blue} textToCopy={slug} />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </VehicleBox>
  )
}

export default VehicleSummary
