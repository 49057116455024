import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { Typography } from '@mui/material'
import {
  BaseIdEntity,
  colors,
  DetailNavTab,
  ExtendedStatus,
  FilterInputVariable,
  formatFullName,
  generateTabItems,
  GenericData,
  GenericInputVariable,
  GenericUpdateVariable,
  LoadingAnimation,
  NavBarItem,
  Option,
  TabPanel,
  useNotification,
} from 'curbo-components-library'

import CopyToClipboard from 'components/Common/CopyToClipboard'
import CallToAction from 'components/CustomerRelationship/Lead/Detail/CallToAction'
import ClientInformation from 'components/CustomerRelationship/Lead/Detail/ClientInformation'
import ExternalHelpLink from 'components/CustomerRelationship/Lead/Detail/ExternalHelpLink'
import Overview from 'components/CustomerRelationship/Lead/Detail/Overview'
import VehicleInformation from 'components/CustomerRelationship/Lead/Detail/VehicleInformation'
import { DetailHeader } from 'components/Detail/DetailHeader'
import { DetailSubHeader } from 'components/Detail/DetailSubHeader'

import { CaseTypeEnum } from 'constants/Lead/listings'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/role'
import {
  DetailCar,
  ExternalHelpLinks,
  ExternalLink,
  LeadDetail,
  SimpleCar,
  UpdateCaseInput,
  UpdateCustomerInput,
} from 'models/services/customerRelationship/lead/detail'
import { ListBds } from 'models/services/customerRelationship/lead/listing'

import {
  UPDATE_CASE,
  UPDATE_CUSTOMER,
} from 'graphQL/CustomerRelationship/Lead/Common/mutations'
import {
  GET_BDS,
  GET_CASE_STEPS,
} from 'graphQL/CustomerRelationship/Lead/Common/queries'
import {
  GET_CAR_BY_ID,
  GET_CASE_BY_ID,
  GET_DEALER_SELL_MY_CAR_BY_ID,
  GET_FIND_THIS_CAR_BY_ID,
  GET_MAINTENANCE_BY_ID,
} from 'graphQL/CustomerRelationship/Lead/Detail/queries'
import { GET_EXTERNAL_HELP_LINKS } from 'graphQL/Setting/queries'

import { ContentContainer, Layout } from 'styles/detail'

const LeadDetailPage = () => {
  const history = useHistory()
  const { show } = useNotification()

  const { leadId } = useParams<{ leadId: string }>()
  const {
    text: { general: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)
  const { validateAllowedRoles } = useUser()

  const isSupervisor = validateAllowedRoles([
    UserRoles.ADMIN,
    UserRoles.CUSTOMER_CARE_SPECIALIST,
    UserRoles.CUSTOMER_CARE_SUPERVISOR,
  ])

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)
  const [leadData, setLeadData] = useState<LeadDetail | null>(null)
  const [carData, setCarData] = useState<DetailCar | null>(null)
  const [maintenanceCar, setMaintenanceCar] = useState<SimpleCar | null>(null)
  const [sellMyCarData, setSetMyCarData] = useState<SimpleCar | null>(null)
  const [findMyCarData, setFindMyCarData] = useState<SimpleCar | null>(null)
  const [caseStep, setCaseStep] = useState<ExtendedStatus | null>(null)
  const [caseStepList, setCaseStepList] = useState<ExtendedStatus[]>([])
  const [specialistOptions, setSpecialistOptions] = useState<Option[]>([])
  const [externalLinks, setExternalLinks] = useState<ExternalLink[]>([])

  const [tab, setTab] = useState<number>(0)

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading: leadLoading, refetch: refetchLead } = useQuery<
    GenericData<LeadDetail>,
    GenericInputVariable<string>
  >(GET_CASE_BY_ID, {
    variables: {
      input: leadId,
    },
    onCompleted(response) {
      setLeadData(response.data)
      setCaseStep({
        ...response.data.leadStep,
        description: 'A lead step',
      })
    },
    onError(error) {
      setApolloError(error)
    },
  })

  const { loading: externalLinkLoading } = useQuery<
    GenericData<ExternalHelpLinks>
  >(GET_EXTERNAL_HELP_LINKS, {
    onCompleted(response) {
      setExternalLinks(response.data.externalHelpLinks)
    },
  })

  const [getCarById, { called: getCarByIdCalled, loading: getCarLoading }] =
    useLazyQuery<GenericData<DetailCar>, GenericInputVariable<string>>(
      GET_CAR_BY_ID,
      {
        onCompleted(response) {
          setCarData(response.data)
        },
      }
    )

  const [
    getMaintenanceById,
    { called: getMaintenanceByIdCalled, loading: getMaintenanceByIdLoading },
  ] = useLazyQuery<GenericData<SimpleCar>, GenericInputVariable<string>>(
    GET_MAINTENANCE_BY_ID,
    {
      onCompleted(response) {
        setMaintenanceCar(response.data)
      },
    }
  )

  const [
    getDealerSellMyCarById,
    { called: sellMyCarCalled, loading: sellMyCarLoading },
  ] = useLazyQuery<GenericData<SimpleCar>, GenericInputVariable<string>>(
    GET_DEALER_SELL_MY_CAR_BY_ID,
    {
      onCompleted(response) {
        setSetMyCarData(response.data)
      },
    }
  )

  const [
    getFindThisCarById,
    { called: findThisCarCalled, loading: findThisCarLoading },
  ] = useLazyQuery<GenericData<SimpleCar>, GenericInputVariable<string>>(
    GET_FIND_THIS_CAR_BY_ID,
    {
      onCompleted(response) {
        setFindMyCarData(response.data)
      },
    }
  )

  const handleOnMutationCompleted = () => {
    refetchLead()
    show({
      updatedSeverity: 'success',
      message: translation.updateSuccess,
    })
  }

  const handleOnMutationError = () => {
    show({
      updatedSeverity: 'error',
      message: translation.updateError,
    })
  }

  const { loading: caseStepLoading } = useQuery<
    GenericData<ExtendedStatus[]>,
    FilterInputVariable
  >(GET_CASE_STEPS, {
    onCompleted(response) {
      setCaseStepList(response.data)
    },
    variables: {
      input: {
        where: {
          type: 'LEAD',
        },
        sort: { sequenceNumber: 'asc' },
      },
    },
  })

  const { loading: bdsLoading } = useQuery<ListBds>(GET_BDS, {
    onCompleted(response) {
      setSpecialistOptions(
        response.getBds.data.map((bds) => {
          const { name, lastName, value } = bds
          return {
            name: `${name} ${lastName}`,
            value,
          }
        })
      )
    },
  })

  const [updateCase, { loading: updateCaseLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateCaseInput>
  >(UPDATE_CASE, {
    onCompleted() {
      handleOnMutationCompleted()
    },
    onError() {
      handleOnMutationError()
    },
  })

  const [updateCustomer, { loading: updateCustomerLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateCustomerInput>
  >(UPDATE_CUSTOMER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
    onError() {
      handleOnMutationError()
    },
  })

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleTabValueChange = (value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleUpdateCase = async (input: UpdateCaseInput) => {
    try {
      const response = await updateCase({
        variables: {
          input: {
            where: {
              id: leadId,
            },
            data: input,
          },
        },
      })
      if (response.errors) return false
      const refetchResponse = await refetchLead()

      if (refetchResponse.error) return true
      return refetchResponse.data.data
    } catch {
      return false
    }
  }

  const handleUpdateStatus = (newCaseStep: ExtendedStatus) => {
    updateCase({
      variables: {
        input: {
          where: {
            id: leadId,
          },
          data: {
            leadStep: newCaseStep.slug,
          },
        },
      },
    })
  }

  const handleStatusChange = (newCaseStep: ExtendedStatus) => {
    if (newCaseStep !== caseStep) {
      handleUpdateStatus(newCaseStep)
    }
  }

  const handleUpdateCustomer = async (
    customer: UpdateCustomerInput,
    customerId: string
  ) => {
    try {
      const response = await updateCustomer({
        variables: {
          input: {
            where: {
              id: customerId,
            },
            data: customer,
          },
        },
      })
      if (response.errors) return false
      const refetchResponse = await refetchLead()

      if (refetchResponse.error) return true
      return refetchResponse.data.data.customer
    } catch {
      return false
    }
  }

  const handleCallLazyQueries = useCallback(
    (type: CaseTypeEnum, currentLeadData: LeadDetail) => {
      const { findMyCar, maintenance, sellMyCar } = currentLeadData
      switch (type) {
        case CaseTypeEnum.MAINTENANCE:
          if (!getMaintenanceByIdCalled && maintenance) {
            getMaintenanceById({
              variables: {
                input: maintenance.id,
              },
            })
          }
          break
        case CaseTypeEnum.SELL_MY_CAR:
          if (!sellMyCarCalled && sellMyCar) {
            getDealerSellMyCarById({
              variables: {
                input: sellMyCar.id,
              },
            })
          }
          break
        case CaseTypeEnum.FIND_MY_CAR:
          if (!findThisCarCalled && findMyCar) {
            getFindThisCarById({
              variables: {
                input: findMyCar.id,
              },
            })
          }
          break
        default:
          break
      }
    },
    [
      getMaintenanceById,
      getMaintenanceByIdCalled,
      getDealerSellMyCarById,
      sellMyCarCalled,
      findThisCarCalled,
      getFindThisCarById,
    ]
  )

  const getTabs = (
    tabs: NavBarItem[],
    currentLeadData: LeadDetail
  ): NavBarItem[] => {
    const { testDrive, sellMyCar } = currentLeadData

    if (testDrive || sellMyCar) return tabs

    return tabs.slice(0, 3)
  }

  const isUpdateLoading = updateCaseLoading || updateCustomerLoading

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })
      if (leadData) {
        const { testDrive, sellMyCar } = leadData
        if (testDrive || sellMyCar || initialValue !== 3) setTab(initialValue)
        else setTab(0)
      } else setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs, leadData])

  useEffect(() => {
    if (leadData) {
      const { car, type } = leadData
      if (car && !getCarByIdCalled)
        getCarById({
          variables: {
            input: car.id,
          },
        })
      handleCallLazyQueries(type, leadData)
    }
  }, [leadData, getCarByIdCalled, getCarById, handleCallLazyQueries])

  if (
    leadLoading ||
    caseStepLoading ||
    bdsLoading ||
    getCarLoading ||
    getMaintenanceByIdLoading ||
    sellMyCarLoading ||
    findThisCarLoading ||
    externalLinkLoading
  )
    return <LoadingAnimation showAnimation />

  if (apolloError) return <Redirect to={routes.NOT_FOUND_ERROR} />

  return leadData && caseStep ? (
    <Layout>
      <DetailHeader
        editable
        title={`LEAD: ${formatFullName(
          leadData.customer.name,
          leadData.customer.lastName
        )}`}
        handleStatusChange={handleStatusChange}
        loading={isUpdateLoading}
        statusList={caseStepList}
        status={caseStep}
        extraBoxContent={
          leadData.bant.score !== null
            ? {
                content: String(leadData.bant.score),
                title: 'BANT:',
              }
            : undefined
        }
        extraTitle={
          leadData.estimatedValue !== null
            ? `USD ${leadData.estimatedValue}`
            : undefined
        }
      />
      <DetailSubHeader
        component={
          <Typography variant="body2" color={colors.blue} marginLeft="0.5rem">
            {translation.idTitle}{' '}
            <CopyToClipboard textToCopy={leadData.caseId} />
          </Typography>
        }
        extraText={
          leadData.estimatedValue !== null
            ? translation.estimatedValueLabel
            : undefined
        }
      />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={getTabs(initialItems, leadData)}
      />
      <ContentContainer
        sx={{
          overflow: isUpdateLoading ? 'hidden' : 'auto',
          position: isUpdateLoading ? 'relative' : 'unset',
        }}
      >
        <LoadingAnimation
          showAnimation={isUpdateLoading}
          styles={{ position: 'absolute', zIndex: 100 }}
        />
        <TabPanel value={tab} index={0}>
          <Overview
            isEditable={isSupervisor}
            carData={carData}
            leadData={leadData}
            loading={isUpdateLoading}
            specialistOptions={specialistOptions}
            handleUpdateCase={handleUpdateCase}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ClientInformation
            isLoading={isUpdateLoading}
            leadData={leadData}
            handleUpdateCustomer={handleUpdateCustomer}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <VehicleInformation
            carData={carData}
            leadData={leadData}
            maintenanceCar={maintenanceCar}
            sellMyCarData={sellMyCarData}
            findMyCarData={findMyCarData}
          />
        </TabPanel>
        {leadData.testDrive || leadData.sellMyCar ? (
          <TabPanel value={tab} index={3}>
            <CallToAction
              leadData={leadData}
              loading={isUpdateLoading}
              carData={carData}
              handleUpdateCase={handleUpdateCase}
              handleTabChange={handleTabValueChange}
              sellMyCarData={sellMyCarData}
              handleCallLazyQueries={handleCallLazyQueries}
            />
          </TabPanel>
        ) : null}
        {externalLinks.length > 0 && <ExternalHelpLink links={externalLinks} />}
      </ContentContainer>
    </Layout>
  ) : null
}

export default LeadDetailPage
