import { gql } from '@apollo/client'

export const GET_TRIM_BY_ID = gql`
  query getTrimLevelById($input: ID!) {
    data: getTrimLevelById(id: $input) {
      backHeadRoom
      backLegRoom
      bodyStyle {
        name
        value: id
      }
      cargo
      cargoCapacity
      cylinders
      doors
      driveTrain {
        name
        value: id
      }
      engineDisplacement
      features {
        name
        value: id
      }
      frontHeadRoom
      frontLegRoom
      fuelCapacity
      fuelType {
        name
        value: id
      }
      height
      horsePower
      horsePowerRpm
      length
      model {
        name
        id
        brand {
          name
          id
          icon
        }
      }
      mpg
      mpgCity
      mpgHgw
      name
      seats
      status
      torque
      torqueRpm
      transmission {
        name
        value: id
      }
      width
      year
    }
  }
`
