import { GridCellValue } from '@mui/x-data-grid'
import { SelectItem } from 'curbo-components-library'

import { InspectionColumnField } from 'constants/inspection'
import { routes } from 'constants/routes'
import { FieldSelectTextType } from 'models/text/inspectionListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: InspectionColumnField.ID,
    },
    {
      label: text.inspectionDate,
      value: InspectionColumnField.DATE,
    },
    {
      label: text.createdAt,
      value: InspectionColumnField.CREATED_AT,
    },
    {
      label: text.carName,
      value: InspectionColumnField.CAR_NAME,
    },
    {
      label: text.status,
      value: InspectionColumnField.STATUS,
    },
    {
      label: text.actions,
      value: InspectionColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    date: text.inspectionDate,
    createdAt: text.createdAt,
    carName: text.carName,
    status: text.status,
    actions: text.actions,
  }
}

export const defaultFields = [
  InspectionColumnField.ID,
  InspectionColumnField.DATE,
  InspectionColumnField.CREATED_AT,
  InspectionColumnField.CAR_NAME,
  InspectionColumnField.STATUS,
  InspectionColumnField.ACTIONS,
]

export const buildInspectionRoute = (id: GridCellValue) => {
  const route = routes.INSPECTION_DETAIL
  return `${route.substring(0, route.indexOf(':'))}${id}`
}
