import { VehicleInfoError, VehicleInformationModel } from 'models/inspection'

export enum INSPECTION_CREATION_OPTION {
  UPDATE_DEALER_INFORMATION = 'updateDealerInformation',
  UPDATE_VEHICLE_INFORMATION = 'updateVehicleInformation',
  UPDATE_SCHEDULING_INFORMATION = 'updateSchedulingInformation',
}

export const initialErrors: VehicleInfoError = {
  brand: false,
  model: false,
  year: false,
  price: false,
  exteriorColor: false,
  interiorColor: false,
}

export const initialVehicleInfo: VehicleInformationModel = {
  vehicleBrand: null,
  vehicleModel: null,
  vehicleYear: null,
  vehicleTrimLevel: null,
  price: 0,
  currency: 'USD',
  vehicleMileage: 100000,
  exteriorColor: null,
  interiorColor: null,
  currentModelList: [],
  currentYearList: [],
  currentTrimLevelList: [],
  id: 0,
}
