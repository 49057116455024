import React from 'react'
import { Box } from 'curbo-components-library'

import Notes, { NoteType } from 'components/Common/Notes'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Dealer } from 'models/services/curboSpot'

import { PersonalContainer } from '../PersonalContainer'

import { ContactContainer } from './ContactContainer'
// import { InsightsContainer } from '../InsightsContainer'
import { ClientBox } from './style'

type ClientProps = {
  notes: NoteType[]
  handleNotesChange: (newNotes: NoteType[]) => void
  dealer: Dealer
}

const DealerInformation = ({
  notes,
  handleNotesChange,
  dealer,
}: ClientProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)
  const { name, address, dealerContacts } = dealer

  const { provider: translation } = text

  return (
    <ClientBox>
      <Box display="flex" flexDirection="column" width={493}>
        <PersonalContainer
          name={name}
          address={address}
          title={translation.dealerTitle}
          description={translation.dealerSubtitle}
          type="dealer"
        />
        {dealerContacts.length > 0 && (
          <ContactContainer
            name={dealerContacts[0].agent}
            phone={dealerContacts[0].telephoneNumber}
            email=""
          />
        )}
      </Box>
      <Notes
        data={notes}
        handleDataChange={handleNotesChange}
        height="391px"
        width="333px"
        containerProps={{ sx: { padding: '0.5rem 1.2rem !important' } }}
      />
    </ClientBox>
  )
}

export default DealerInformation
