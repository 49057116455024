import React from 'react'
import { Typography } from '@mui/material'
import { Box } from 'curbo-components-library'

import { FeatureBox } from '../styles'

type FeatureListProps = {
  values: string[]
  renderOption: (value: string) => string
}

const FeatureList = ({ values, renderOption }: FeatureListProps) => {
  if (values.length === 0) return <Typography variant="body2">-</Typography>

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      marginTop="5px"
      sx={{
        gap: '5px',
      }}
    >
      {values.map((value) => (
        <FeatureBox
          key={value}
          label={renderOption(value) || value}
          sx={{ '> span': { cursor: 'text' } }}
        />
      ))}
    </Box>
  )
}

export default FeatureList
