import { styled } from '@mui/material'
import { colors } from 'curbo-components-library'

export const QuestionList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  width: '100%',
  'li::before': {
    content: '"• "',
    color: colors.blue,
  },
  li: {
    width: '100%',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'flex-start',
  },
})
