import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { InputBaseComponentProps } from '@mui/material'
import {
  ButtonNavigator,
  ErrorMessage,
  GenericData,
  GenericInputVariable,
  Option,
  Select,
  useDebounce,
} from 'curbo-components-library'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import { NumberFormatCustom } from 'components/General/PhoneNumberFormat'

import { placeholderPhone } from 'constants/dealer'
import {
  initialGeneralInformation,
  LEAD_CREATION_OPTION,
} from 'constants/Lead/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  CTAEnum,
  GeneralLeadInformation,
  SearchUserResultType,
  SearchUserVariable,
} from 'models/services/customerRelationship/lead/creation'
import { onlySpacesText } from 'utils/filters'
import { LeadCreationProps } from 'utils/Lead/creation'

import { SEARCH_USER } from 'graphQL/CustomerRelationship/Lead/Creation/queries'

import {
  FormContent,
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from './style'

const GeneralInfo = ({
  leadData,
  updateLeadData,
  handleContinue,
}: LeadCreationProps) => {
  const { text } = useTranslation(textFiles.LEAD_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [userEmail, setUserEmail] = useState<string>('')
  const { mainStep, callToActions } = text
  const phoneValidation = validationText.errorMessagePhoneNumberDigits.replace(
    '%d',
    '10'
  )
  const ctaOptions: Option[] = Object.values(CTAEnum).map((value) => {
    return {
      name: callToActions[value],
      value,
    }
  })

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(validationText.invalidEmail)
      .required(validationText.fieldRequired),
    cta: yup.string().required(validationText.fieldRequired),
    name: yup.string().required(validationText.fieldRequired),
    lastName: yup.string().required(validationText.fieldRequired),
    phoneNumber: yup
      .string()
      .nullable()
      .required(validationText.fieldRequired)
      .length(10, phoneValidation),
  })
  const { generalInformation } = leadData
  const formik = useFormik<GeneralLeadInformation>({
    initialValues: {
      ...generalInformation,
    },
    validationSchema,
    onSubmit: (values) => {
      updateLeadData({
        type: LEAD_CREATION_OPTION.UPDATE_GENERAL_INFORMATION,
        payload: values,
      })
      handleContinue()
    },
  })

  const handleEmailChange = (email: string) => {
    setUserEmail(email)
    formik.setFieldValue('email', email)
  }

  const debouncedEmail = useDebounce(
    onlySpacesText(userEmail) ? '' : userEmail,
    1000
  )

  const [fetchUserEmails, { loading: userEmailLoading }] = useLazyQuery<
    GenericData<SearchUserResultType[]>,
    GenericInputVariable<SearchUserVariable>
  >(SEARCH_USER, {
    variables: {
      input: {
        where: {
          email: debouncedEmail,
        },
      },
    },
    onCompleted(response) {
      if (response.data.length > 0) {
        const result = response.data[0]
        Object.keys(result).forEach((key) => {
          if (key !== '__typename') {
            formik.setFieldValue(key, result[key])
          }
        })
      } else {
        Object.keys(initialGeneralInformation).forEach((key) => {
          formik.setFieldValue(key, initialGeneralInformation[key])
        })
        formik.setFieldValue('email', debouncedEmail)
      }
    },
  })

  useEffect(() => {
    if (debouncedEmail.length > 0) {
      fetchUserEmails()
    }
  }, [debouncedEmail, fetchUserEmails])

  return (
    <FormikProvider value={formik}>
      <StyledContainer>
        <StyledForm onSubmit={formik.handleSubmit}>
          <FormContent>
            <StyledTextFieldContainer title={mainStep.email}>
              <StyledTextField
                variant="outlined"
                name="email"
                type="email"
                autoComplete="off"
                value={formik.values.email}
                onChange={(e) => {
                  handleEmailChange(e.target.value)
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                inputProps={{ maxLength: 40 }}
              />
              {formik.touched.email && formik.errors.email ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.email}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={mainStep.name}>
              <StyledTextField
                variant="outlined"
                name="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="john@doe.com"
                inputProps={{
                  maxLength: 40,
                  autoComplete: 'off',
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
              {formik.touched.name && formik.errors.name ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.name}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={mainStep.lastName}>
              <StyledTextField
                variant="outlined"
                name="lastName"
                autoComplete="off"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder="Martinez Matos"
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                inputProps={{ maxLength: 40 }}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.lastName}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={mainStep.telephoneNumber}>
              <StyledTextField
                variant="outlined"
                name="phoneNumber"
                autoComplete="off"
                placeholder={placeholderPhone}
                value={formik.values.phoneNumber}
                InputProps={{
                  inputComponent:
                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                onChange={formik.handleChange}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.phoneNumber}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={mainStep.cta}>
              <Select
                options={ctaOptions}
                onChange={formik.handleChange}
                label={mainStep.cta}
                value={formik.values.cta}
                name="cta"
                sx={{ fontSize: 12, height: 40 }}
                error={formik.touched.cta && Boolean(formik.errors.cta)}
              />
              {formik.touched.cta && formik.errors.cta ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.cta}
                />
              ) : null}
            </StyledTextFieldContainer>
          </FormContent>
          <ButtonNavigator
            isFirstStep
            loading={userEmailLoading}
            translation={generalText.buttons}
            nextFunction={formik.handleSubmit}
          />
        </StyledForm>
      </StyledContainer>
    </FormikProvider>
  )
}

export default GeneralInfo
