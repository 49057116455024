import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import { SelectChangeEvent, Typography } from '@mui/material'
import {
  Box,
  Button,
  Container,
  cypressAddButton,
  Option,
  Select,
} from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { TranslationChangeEnum } from 'models/translation'

import { StyledList, StyledTextField } from './style'
import TranslationListItem, { TranslationItemType } from './TranslationListItem'

export const languageOptions: Option[] = [
  {
    value: 'es',
    name: 'Español',
  },
  {
    value: 'en',
    name: 'English',
  },
]

// function to take out the already added translation when the component renders
const initializeOptions = (
  options: Option[],
  addedTranslations: TranslationItemType[]
) => {
  const translationCode = addedTranslations.map((translation) => {
    return translation.value
  })

  const filteredOptions = options.filter(
    (option) => !translationCode.includes(option.value)
  )

  return filteredOptions
}

type TranslationProps = {
  /**
   * Array of added translations for the current feature
   */
  addedTranslations: TranslationItemType[]
  /**
   * Event triggered when a translation is added. Sends the current translation array
   * after a translation has been added
   */
  handleChangeTranslation: (
    translations: TranslationItemType[],
    type: TranslationChangeEnum
  ) => void
  /**
   * Paragraph describing the translation to be displayed in the description of the container
   */
  translationDescription?: string
  /**
   * If the translation can be modified or not
   */
  editDisabled?: boolean
}

const Translation = ({
  addedTranslations,
  handleChangeTranslation,
  translationDescription,
  editDisabled,
}: TranslationProps) => {
  const [options, setOptions] = useState<Option[]>([])
  const [selectedLanguage, setLanguage] = useState<string | number>(
    languageOptions.length > 0 ? languageOptions[0].value : ''
  )
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    languageOptions.length > 0 ? languageOptions[0] : undefined
  )
  const [textValue, setTextValue] = useState<string>('')

  const {
    text: { translationText, buttons: buttonText },
  } = useTranslation(textFiles.GENERAL)

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setLanguage(event.target.value as string)
  }

  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTextValue(event.target.value)
  }

  /**
   * Add the translation to the addedTranslations and remove the option from
   * the options array. Afterwards clean input and set the current selected option
   * to the first item if there's still options
   */
  const onAddTranslation = () => {
    const newAddedTranslation: TranslationItemType[] = [...addedTranslations]

    const newOptions = options.filter((option) => {
      if (option.value === selectedLanguage) {
        newAddedTranslation.push({
          ...option,
          id:
            newAddedTranslation.length === 0
              ? 1
              : newAddedTranslation[newAddedTranslation.length - 1].id + 1,
          description: textValue,
        })
        return undefined
      }
      return option
    })

    setTextValue('')
    setOptions(newOptions)
    if (newOptions.length > 0) setLanguage(newOptions[0].value)
    else setLanguage('')

    handleChangeTranslation(newAddedTranslation, TranslationChangeEnum.ADD)
  }

  /**
   * Removes the translation from the addedTranslations and add the option back to
   * the options array, if the option array is empty, we will set the selectedLanguage
   * back to the current item
   */
  const onRemoveTranslation = (id: string) => {
    const newTranslations = addedTranslations.filter((translation) => {
      if (translation.value === id) {
        setOptions((option) => {
          if (option.length === 0) {
            setLanguage(translation.value)
          }
          return [...option, translation]
        })

        return undefined
      }
      return translation
    })

    handleChangeTranslation(newTranslations, TranslationChangeEnum.REMOVE)
  }

  const onEditTranslation = (value: string, description: string) => {
    const newTranslation = addedTranslations.map((translation) => {
      if (value === translation.value) {
        return { ...translation, description }
      }
      return translation
    })
    handleChangeTranslation(newTranslation, TranslationChangeEnum.EDIT)
  }

  useEffect(() => {
    if (selectedLanguage && options.length > 0) {
      const currentOption = options.find(
        (option) => option.value === selectedLanguage
      )
      setSelectedOption(currentOption)
    }
  }, [selectedLanguage, options])

  useEffect(() => {
    setOptions(initializeOptions(languageOptions, addedTranslations))
  }, [addedTranslations])

  return (
    <Container
      title={translationText.title}
      description={translationDescription}
    >
      <Box>
        <StyledList>
          {addedTranslations.map((language) => (
            <TranslationListItem
              key={language.value}
              translationItem={language}
              handleRemoveTranslation={onRemoveTranslation}
              handleEditTranslation={onEditTranslation}
              placeholderText={translationText.placeholder}
              editDisabled={editDisabled}
            />
          ))}
        </StyledList>

        {options.length > 0 && !editDisabled ? (
          <Box display="flex" marginTop="16px">
            <Select
              value={selectedLanguage}
              options={options}
              onChange={handleSelectChange}
              icon={<TextFormatIcon />}
              sx={{
                width: '158px',
                height: '33px',
                marginRight: '10px',
              }}
              testId="translation-select"
            />
            <StyledTextField
              placeholder={`${translationText.placeholder} ${selectedOption?.name}`}
              variant="outlined"
              value={textValue}
              onChange={handleTextChange}
              name="translation"
            />
            <Button
              startIcon={<AddIcon />}
              buttonType="secondary"
              size="small"
              sx={{
                marginLeft: '10px',
              }}
              onClick={onAddTranslation}
              disabled={!textValue}
              testId={cypressAddButton}
            >
              {buttonText.add}
            </Button>
          </Box>
        ) : null}
        {editDisabled && addedTranslations.length === 0 ? (
          <Typography variant="body2">
            {translationText.noTranslation}
          </Typography>
        ) : null}
      </Box>
    </Container>
  )
}

export default Translation
