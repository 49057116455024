import { gql } from '@apollo/client'

export const UPDATE_CASE = gql`
  mutation updateCase($input: UpdateCaseInput!) {
    data: updateCase(input: $input) {
      id
    }
  }
`

export const UPDATE_CUSTOMER = gql`
  mutation updateCase($input: UpdateCustomerInput!) {
    data: updateCustomer(input: $input) {
      id
    }
  }
`
