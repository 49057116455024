import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { routes } from 'constants/routes'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/role'
import { grantPermissions } from 'utils/auth'

type RoleRouteProps = RouteProps & {
  allowedRoles: UserRoles[]
  hideInProduction?: boolean
}

/** Used if a route is private to certain roles
 * the usage would be
 * <RoleRoute allowedRoles={['admin', 'security', 'client']} {...restOfRouteProps} />
 * This component was created based on the link provided by Jason
 * https://stackoverflow.com/questions/60595658/how-to-implement-multi-role-based-authorization-in-react
 */
const RoleRoute = ({
  allowedRoles,
  hideInProduction,
  ...routerProps
}: RoleRouteProps) => {
  const { userRoles } = useUser()
  return grantPermissions(userRoles, allowedRoles, hideInProduction) ? (
    <Route {...routerProps} />
  ) : (
    <Redirect to={routes.UNAUTHORIZED_ERROR} />
  )
}

export default RoleRoute
