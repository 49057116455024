export enum TranslationChangeEnum {
  ADD = 'add',
  EDIT = 'edit',
  REMOVE = 'remove',
}

export type TranslationType = {
  en: string | null
  es: string | null
}
