import React, { useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Fade, Popper, Typography } from '@mui/material'
import { Box, colors, FONT_WEIGHT } from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Checkpoint } from 'models/services/inspection/detail'
import {
  CheckpointIcon,
  selectColor,
  selectQuestionColor,
} from 'utils/ProgressUtils'

import { QuestionList } from './style'

type CheckpointComponentProps = {
  checkpoint: Checkpoint
  index: number
  score: [number, number]
}

const CheckpointComponent = ({
  checkpoint,
  index,
  score,
}: CheckpointComponentProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)
  const { report: translation } = text
  const selectedColor = selectColor(score)

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handlePopperClose = () => {
    setOpen(false)
  }

  const maintainPopover = () => {
    setAnchorEl(anchorEl)
    setOpen(true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0.5rem 0',
        minHeight: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: FONT_WEIGHT.BOLD,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {CheckpointIcon(score, selectedColor)}
          {translation.evaluation.checkpoint} {index}
        </Typography>
        <Typography variant="body2" sx={{ marginLeft: '1.75rem' }}>
          {checkpoint.label}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: FONT_WEIGHT.SEMI_BOLD,
            color: selectedColor,
            marginLeft: '1.75rem',
          }}
        >
          {score[0]}/{score[1]}
        </Typography>
      </Box>
      <Box
        aria-owns={open ? 'checkpoint-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
        sx={{
          display: 'flex',
          height: '25px',
          marginLeft: '0.5rem',
          ':hover': {
            cursor: 'pointer',
          },
        }}
        data-cy={`see-questions-popover-${index}`}
      >
        <VisibilityIcon sx={{ color: colors.blue, marginRight: '0.5rem' }} />
        <Typography variant="subtitle1" color={colors.blue}>
          {translation.evaluation.seeQuestions}
        </Typography>
      </Box>
      <Popper
        id="checkpoint-popover"
        open={open}
        onMouseEnter={maintainPopover}
        onMouseLeave={handlePopperClose}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        style={{
          zIndex: 1500,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: colors.commonWhite,
                padding: '1rem',
                width: '440px',
                borderRadius: '6px',
                boxShadow: 1,
              }}
            >
              <Box sx={{ margin: '0', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>
                    {translation.evaluation.questionTitle.replace('%id', index)}
                  </Typography>
                  <Typography>{translation.evaluation.score}</Typography>
                </Box>
                <QuestionList>
                  {checkpoint.questions.map((question) => (
                    <li key={`${question.id}`}>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: '400px',
                          textAlign: 'left',
                          overflow: 'hidden',
                          marginLeft: '0.5rem',
                          // whiteSpace: 'nowrap',
                          // textOverflow: 'ellipsis',
                          fontWeight: FONT_WEIGHT.MEDIUM,
                        }}
                      >
                        {question.label}
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: FONT_WEIGHT.BOLD,
                          color: selectQuestionColor(question),
                          textAlign: 'center',
                          width: '35px',
                          height: '25px',
                          padding: '0 0.25rem',
                          backgroundColor: colors.background,
                          borderRadius: '4px',
                          border: `1px solid ${colors.background}`,
                          margin: '0 0 0 auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {question.answer && question.answer.doesntApply
                          ? 'N/A'
                          : question.value}
                      </Typography>
                    </li>
                  ))}
                </QuestionList>
              </Box>
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  )
}

export default CheckpointComponent
