import React from 'react'
import {
  outlinedInputClasses,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material'
import {
  colors,
  FONT_WEIGHT,
  inputBoxShadow,
  TextField,
} from 'curbo-components-library'

import Link from 'components/Common/Link'

export const StyledTextField = styled(TextField)({
  backgroundColor: 'white',
  boxShadow: inputBoxShadow,
  [`& .${outlinedInputClasses.input}`]: {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    padding: '14px',
  },
})

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.commonWhite,
    width: 426,
    maxWidth: 500,
  },
}))

export const StyledLink = styled(Link)({
  position: 'relative',
  top: '-10px',
  '&:hover': {
    textDecoration: 'none',
  },
})
