import React from 'react'
import { useQuery } from '@apollo/client'
import {
  ButtonNavigator,
  categoriesWidth,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
  Select,
  useWindowDimensions,
} from 'curbo-components-library'
import { useFormik } from 'formik'
import * as yup from 'yup'

import MultipleSelect from 'components/Inventory/Creation/Common/MultipleSelect'

import { VEHICLE_CREATION_OPTION } from 'constants/PreInventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { TrimDetailDataType } from 'models/services/CarSettings/detail'
import {
  FifthStepModel,
  FourthStepModel,
  SixthStepModel,
  ThirdStepModel,
} from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/PreInventory/creation'

import { GET_TRIM_BY_ID } from 'graphQL/Operations/Trim/Detail/queries'

import {
  sliderFormProps,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const getSpacingValues = (
  trim: TrimDetailDataType | null
): FourthStepModel | null => {
  if (!trim) return null

  return {
    backHeadRoom: trim.backHeadRoom ? trim.backHeadRoom : 1,
    backLegRoom: trim.backLegRoom ? trim.backLegRoom : 1,
    cargo: trim.cargo ? trim.cargo : 1,
    cargoCapacity: trim.cargoCapacity ? trim.cargoCapacity : 1,
    engineDisplacement: trim.engineDisplacement ? trim.engineDisplacement : 1,
    frontHeadRoom: trim.frontHeadRoom ? trim.frontHeadRoom : 1,
    frontLegRoom: trim.frontLegRoom ? trim.frontLegRoom : 1,
  }
}

const getMeasurementsValue = (
  trim: TrimDetailDataType | null
): FifthStepModel | null => {
  if (!trim) return null

  return {
    length: trim.length ? trim.length : 1,
    width: trim.width ? trim.width : 1,
    height: trim.height ? trim.height : 1,
    seats: trim.seats ? trim.seats : 1,
    doors: trim.doors ? trim.doors : 1,
  }
}

const getPowersValue = (
  trim: TrimDetailDataType | null
): SixthStepModel | null => {
  if (!trim) return null

  return {
    mpg: trim.mpg ? trim.mpg : 1,
    mpgCity: trim.mpgCity ? trim.mpgCity : 1,
    mpgHgw: trim.mpgHgw ? trim.mpgHgw : 1,
    fuelCapacity: trim.fuelCapacity ? trim.fuelCapacity : 1,
    cylinders: trim.cylinders ? trim.cylinders : 1,
    torque: trim.torque ? trim.torque : 1,
    torqueRpm: trim.torqueRpm ? trim.torqueRpm : 1,
    horsePower: trim.horsePower ? trim.horsePower : 1,
    horsePowerRpm: trim.horsePowerRpm ? trim.horsePowerRpm : 1,
  }
}

const initialValue: ThirdStepModel = {
  transmission: '',
  bodyStyle: '',
  driveTrain: '',
  fuelType: '',
  features: [],
  detailedTrimLevel: null,
}

const VehicleFeatures = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryBack,
  vehicleSelectOptions,
  handleSecondaryContinue,
}: VehicleCreationProps) => {
  const { width } = useWindowDimensions()
  const {
    text: {
      subSteps: { thirdStep: translation },
      buttons,
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const { trimLevel } = vehicleData.vehicleInformation.firstStep
  const trimId = trimLevel ? String(trimLevel.id) : ''

  const validationSchema = yup.object().shape({
    transmission: yup.string().required(validationText.fieldRequired),
    bodyStyle: yup.string().required(validationText.fieldRequired),
    driveTrain: yup.string().required(validationText.fieldRequired),
    fuelType: yup.string().required(validationText.fieldRequired),
    features: yup
      .array()
      .of(yup.string())
      .min(2, validationText.errorRequiredItems.replace('%d', 2)),
  })

  const formik = useFormik<ThirdStepModel>({
    initialValues: vehicleData.vehicleInformation.thirdStep || initialValue,
    validationSchema,
    onSubmit: (values) => {
      const { vehicleInformation } = vehicleData
      const { fourthStep, fifthStep, sixthStep } = vehicleInformation
      const spacingData =
        fourthStep || getSpacingValues(values.detailedTrimLevel)
      const measurementsData =
        fifthStep || getMeasurementsValue(values.detailedTrimLevel)
      const powersData = sixthStep || getPowersValue(values.detailedTrimLevel)

      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
        payload: {
          ...vehicleInformation,
          thirdStep: {
            bodyStyle: values.bodyStyle,
            detailedTrimLevel: values.detailedTrimLevel,
            driveTrain: values.driveTrain,
            features: values.features,
            fuelType: values.fuelType,
            transmission: values.transmission,
          },
          fourthStep: spacingData,
          fifthStep: measurementsData,
          sixthStep: powersData,
        },
      })
      handleSecondaryContinue()
    },
  })

  const { loading: trimLoading } = useQuery<
    GenericData<TrimDetailDataType>,
    GenericInputVariable<string>
  >(GET_TRIM_BY_ID, {
    variables: {
      input: trimId,
    },
    onCompleted(response) {
      if (!vehicleData.vehicleInformation.thirdStep) {
        const { data } = response
        const { bodyStyle, driveTrain, features, fuelType, transmission } = data
        formik.setValues({
          bodyStyle: bodyStyle.value as string,
          driveTrain: String(driveTrain.value),
          features: features.map((feature) => String(feature.value)),
          fuelType: String(fuelType.value),
          transmission: transmission.value as string,
          detailedTrimLevel: data,
        })
        updateVehicleData({
          type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
          payload: {
            ...vehicleData.vehicleInformation,
            fourthStep: null,
            fifthStep: null,
            sixthStep: null,
          },
        })
      }
    },
  })

  const handleFeatureyRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    const newFeatures = [...formik.values.features]
    newFeatures.splice(index, 1)
    formik.setFieldValue('features', newFeatures)
  }

  if (trimLoading) return <LoadingAnimation showAnimation />

  return (
    <StyledContainer>
      <StyledForm
        sx={{
          ...sliderFormProps,
          maxWidth: width ? `${categoriesWidth(width)} !important` : '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <div>
          <StyledTextFieldContainer title={translation.transmission}>
            <Select
              options={vehicleSelectOptions.transmissions}
              onChange={formik.handleChange}
              label={translation.transmission}
              value={formik.values.transmission}
              name="transmission"
              sx={{ fontSize: 12, height: 40 }}
              error={
                formik.touched.transmission &&
                Boolean(formik.errors.transmission)
              }
            />
            {formik.touched.transmission && formik.errors.transmission ? (
              <StyledErrorMessage text={formik.errors.transmission} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.bodyStyle}>
            <StyledTextField
              variant="outlined"
              value={
                vehicleSelectOptions.bodyStyles.find(
                  (bodyStyle) => bodyStyle.value === formik.values.bodyStyle
                )?.name || ''
              }
              placeholder={translation.bodyStyle}
              disabled
            />
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.driveTrain}>
            <Select
              options={vehicleSelectOptions.driveTrains}
              onChange={formik.handleChange}
              label={translation.driveTrain}
              value={formik.values.driveTrain}
              name="driveTrain"
              sx={{ fontSize: 12, height: 40 }}
              error={
                formik.touched.driveTrain && Boolean(formik.errors.driveTrain)
              }
            />
            {formik.touched.driveTrain && formik.errors.driveTrain ? (
              <StyledErrorMessage text={formik.errors.driveTrain} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.fuelType}>
            <Select
              options={vehicleSelectOptions.fuelTypes}
              onChange={formik.handleChange}
              label={translation.fuelType}
              value={formik.values.fuelType}
              name="fuelType"
              sx={{ fontSize: 12, height: 40 }}
              error={formik.touched.fuelType && Boolean(formik.errors.fuelType)}
            />
            {formik.touched.fuelType && formik.errors.fuelType ? (
              <StyledErrorMessage text={formik.errors.fuelType} />
            ) : null}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer
            sx={{ flexGrow: 1 }}
            title={translation.features}
          >
            <MultipleSelect
              options={vehicleSelectOptions.features}
              onChange={formik.handleChange}
              label={translation.features}
              values={formik.values.features}
              name="features"
              error={formik.touched.features && Boolean(formik.errors.features)}
              onRemove={handleFeatureyRemove}
            />
            {formik.touched.features && formik.errors.features ? (
              <StyledErrorMessage
                text={
                  typeof formik.errors.features === 'string'
                    ? formik.errors.features
                    : ''
                }
              />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        translation={buttons}
        previousFunction={handleSecondaryBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehicleFeatures
