import { gql } from '@apollo/client'

export const LIST_DEALER_INSPECTORS = gql`
  query listDealerInspectors($input: FilterInput) {
    listDealerInspectors(input: $input) {
      count
      data {
        id
        name
        lastName
        profilePicture
        createdAt
        status {
          id
          name
          backgroundColor
          textColor
        }
      }
    }
  }
`
export const GET_INSPECTOR_STATUSES = gql`
  query getInspectorStatuses($input: FilterInput) {
    data: getInspectorStatuses(input: $input) {
      backgroundColor
      description
      id
      name
      textColor
      slug
    }
  }
`
