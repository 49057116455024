import { VEHICLE_CREATION_OPTION } from 'constants/PreInventory/creation'
import {
  VehicleCreationAction,
  VehicleCreationDataType,
} from 'models/services/PreInventory/creation'

export const vehicleCreationReducer = (
  state: VehicleCreationDataType,
  action: VehicleCreationAction
): VehicleCreationDataType => {
  switch (action.type) {
    case VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION:
      return { ...state, vehicleInformation: action.payload }

    case VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_PHOTOS:
      return { ...state, vehiclePhotos: action.payload }
    default:
      return state
  }
}
