import { gql } from '@apollo/client'

export const USER_INFO = gql`
  query fetchUser {
    self {
      address
      documentId
      drivingLicense
      email
      id
      lastName
      name
      profilePicture
      telephoneNumber
      roles {
        id
        name
        description
        slug
      }
      organization {
        type
        status
        name
        id
      }
    }
  }
`

export const USER_DEALER = gql`
  query fetchDealer {
    selfDealer {
      id
      name
      latitude
      longitude
      address
      curboSpot {
        id
        name
        lat: latitude
        lng: longitude
        address
      }
      city {
        id
        name
        slug
        state {
          id
          name
          slug
        }
      }
    }
  }
`

export const GET_LEADS = gql`
  query fetchLeads {
    getLeads {
      customer {
        name
        telephoneNumber
      }
      car {
        title
      }
      origin
      createdAt
      estimatedValue
      leadStep {
        name
        backgroundColor
      }
    }
  }
`
