import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'

import NameContainer from 'components/Detail/NameContainer'
import { PaginationList } from 'components/General/PaginationList'
import ImageBox from 'components/Operation/CarSettings/Common/ImageBox'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Trim } from 'models/car'
import { ModelDetailDataType } from 'models/services/operation/carSettings/detail'

import CarImagePlaceholder from 'images/blueDefaultAvatar.png'

import { GridBox, StyledContainer } from 'styles/detail'

type ModelInformationProps = {
  model: ModelDetailDataType
  handlePageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void
  pageCount: number
  totalCount: number
  trimList: Trim[]
  trimsLoading: boolean
  actualPage: number
  pageSize: number
}

const ModelInformation = ({
  model,
  totalCount,
  pageCount,
  trimList,
  trimsLoading,
  actualPage,
  pageSize,
  handlePageChange,
}: ModelInformationProps) => {
  const { text } = useTranslation(textFiles.MODEL_DETAIL)
  const { modelInformation: translation } = text
  const { name, brand } = model

  const informationRows = [
    {
      id: '1',
      icon: <DirectionsCarIcon />,
      name,
      label: translation.nameLabel,
    },
    {
      id: '2',
      icon: <DirectionsCarIcon />,
      name: brand.name,
      label: translation.brandLabel,
    },
  ]

  return (
    <StyledContainer>
      <GridBox>
        <ImageBox
          height="100%"
          width="100%"
          variant="rounded"
          image={brand.icon || CarImagePlaceholder}
          containerSx={{
            height: '363px',
            width: '363px',
            marginTop: '1rem',
            padding: '1rem',
          }}
        />
        <NameContainer
          title={translation.information}
          description={translation.informationDescription}
          information={informationRows}
          width={399}
          containerSx={{
            padding: '1rem 0 0 !important',
          }}
          editDisabled
        />
        <PaginationList
          actualPage={actualPage}
          handlePageChange={handlePageChange}
          items={trimList.map((trim) => {
            return {
              ...trim,
              extraContent: trim.year,
            }
          })}
          pageCount={pageCount}
          totalCount={totalCount}
          loading={trimsLoading}
          pageSize={pageSize}
          title={translation.paginationTitle}
          description={translation.paginationDescription}
          countText={translation.paginationCount}
        />
      </GridBox>
    </StyledContainer>
  )
}

export default ModelInformation
