import { gql } from '@apollo/client'

export const CREATE_CAR = gql`
  mutation createCar($input: CreateCarInput!) {
    data: createCar(input: $input) {
      id
    }
  }
`

export const CREATE_DEALER_CAR = gql`
  mutation createDealerCar($input: CreateDealerCarInput!) {
    data: createDealerCar(input: $input) {
      id
    }
  }
`
