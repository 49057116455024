import React from 'react'
import { SelectChangeEvent, Typography } from '@mui/material'
import { Box, Checkbox, colors, Select } from 'curbo-components-library'

import { getDaysOptions } from 'constants/operation/curboSpot'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CheckHourType, DaysPair, ScheduleType } from 'models/schedule'

import { GridBox } from '../style'

import { StyledContainer } from './style'

type Props = {
  hours: CheckHourType[]
  selectedDay: DaysPair
  setSelectedDay: React.Dispatch<React.SetStateAction<DaysPair>>
  handleChangeCheckbox: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ScheduleType
  ) => void
  type: ScheduleType
  translation: Record<string, string>
  hourContainerSize: number
  testId?: string
}

const ScheduleItem = ({
  hours,
  selectedDay,
  setSelectedDay,
  handleChangeCheckbox,
  type,
  translation,
  hourContainerSize,
  testId,
}: Props) => {
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { weekDays } = generalText
  const days = getDaysOptions(weekDays)

  const handleChangeDay = (e: SelectChangeEvent<unknown>) => {
    setSelectedDay({
      ...selectedDay,
      [type]: e.target.value as string,
    })
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <StyledContainer
        title={translation.userBased}
        description={translation.userBasedDescription}
      />

      <Typography variant="body2" color={colors.black} marginBottom="1rem">
        {translation.selectDay}
      </Typography>
      <Select
        options={days}
        variant="outlined"
        name="weekDay"
        value={selectedDay[type]}
        onChange={(e) => handleChangeDay(e)}
        sx={{ fontSize: 12, height: 40, width: '100%' }}
      />
      <Typography variant="body2" color={colors.black} margin="1rem 0">
        {translation.selectHours}
      </Typography>
      <GridBox sx={{ gridTemplateRows: `repeat(${hourContainerSize}, 40px)` }}>
        {hours.map((hour) => (
          <Checkbox
            key={hour.value}
            value={hour.value}
            label={`${hour.name} ${hour.am ? 'am' : 'pm'}`}
            checked={hour.checked}
            onChange={(e) => handleChangeCheckbox(e, type)}
            testId={testId ? `${testId}-${hour.value}-checkbox` : undefined}
          />
        ))}
      </GridBox>
    </Box>
  )
}

export default ScheduleItem
