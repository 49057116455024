import gql from 'graphql-tag'

export const GET_INSPECTION_TRIM_YEARS = gql`
  query getInspectionModelById($input: ID!) {
    data: getInspectionModelById(id: $input) {
      trimYears
      id
      name
    }
  }
`

export const GET_INSPECTION_TRIM_LEVELS = gql`
  query getInspectionTrimLevels($input: FilterInput) {
    data: getInspectionTrimLevels(input: $input) {
      name
      id
    }
  }
`

export const GET_AVAILABLE_INSPECTORS = gql`
  query getAvailableInspectors($input: GetAvaliableInspectorsInput!) {
    data: getAvaliableInspectors(input: $input) {
      id
      name
      lastName
      profilePicture
    }
  }
`

export const GET_DEALER_INSPECTORS = gql`
  query getDealerInspectors($input: GetAvailableDealerInspectorsInput!) {
    data: getAvailableDealerInspectors(input: $input) {
      id
      name
      lastName
      profilePicture
    }
  }
`

export const GET_TEMPLATE_ID = gql`
  query getTemplate {
    data: getTemplate {
      id
    }
  }
`
