import { gql } from '@apollo/client'

export const DELETE_INSPECTION = gql`
  mutation deleteDealerInspection($input: ID!) {
    response: deleteDealerInspection(id: $input) {
      id
    }
  }
`

export const DELETE_INSPECTIONS = gql`
  mutation deleteDealerInspections($input: [ID!]!) {
    response: deleteDealerInspections(ids: $input) {
      id
    }
  }
`
