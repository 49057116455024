import { gql } from '@apollo/client'

export const UPDATE_CAR = gql`
  mutation updateCar($input: UpdateCarInput!) {
    data: updateCar(input: $input) {
      id
    }
  }
`

export const FILL_CAR_DATA = gql`
  mutation fillCarData($input: UpdateCarInput!) {
    data: fillCarData(input: $input) {
      id
    }
  }
`

export const SET_TO_REVIEW_CAR_STATUS = gql`
  mutation setToReviewCarStatus($input: ID!) {
    data: setToReviewCarStatus(id: $input) {
      id
    }
  }
`

export const REJECT_CAR_REVIEW = gql`
  mutation rejectCarReview($input: ID!) {
    data: rejectCarReview(id: $input) {
      id
    }
  }
`

export const SET_NOT_AVAILABLE_CAR_STATUS = gql`
  mutation setNotAvailableCarStatus($input: ID!) {
    data: setNotAvailableCarStatus(id: $input) {
      id
    }
  }
`
