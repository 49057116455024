// simulating async submit

import { FilteringOption } from 'curbo-components-library'

import { SidebarItem } from 'components/General/Sidebar'

import { ENVIRONMENT } from 'constants/environment'
import { UserRoles } from 'models/role'
import { grantPermissions } from 'utils/auth'

/**
 * This is a recursive function which does the following,
 * if the element is 'private' (has allowedRoles prop)
 * it checks wether the current user has permission to
 * access it if it doesn't it goes to the next element
 * of the list otherwise it adds it. We keep repeating
 * the same proccess until we have iterating throught all
 * the structure.
 *
 * Aditioanlly, if the item has hideInProduction as `true` and
 * the current environment is production, it will hide the item
 * even if `allowedRoles` is not defined
 */

export const filterSidebarItems = (
  items: SidebarItem[],
  userRoles: UserRoles[]
): SidebarItem[] => {
  const filteredItems: SidebarItem[] = []
  items.forEach((item: SidebarItem) => {
    if (
      item.allowedRoles ||
      (item.hideInProduction && ENVIRONMENT === 'production')
    ) {
      const itemAllowedRoles = item.allowedRoles || []
      if (
        !grantPermissions(userRoles, itemAllowedRoles, item.hideInProduction)
      ) {
        return
      }
    }
    if (item.children) {
      filteredItems.push({
        ...item,
        children: filterSidebarItems(item.children, userRoles),
      })
    } else {
      filteredItems.push(item)
    }
  })
  return filteredItems
}

export const formatStringIntoFilteringOption = (
  options: string[]
): FilteringOption[] => {
  const filteringOptions: FilteringOption[] = options.map((option) => {
    return {
      disabled: false,
      name: option,
      value: option,
    }
  })

  return filteringOptions
}
