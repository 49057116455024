import { Option } from 'curbo-components-library'

import { WeekDayEnum } from 'models/services/operation/curboSpot'

export const getDaysOptions = (
  translation: Record<string, string>
): Option[] => {
  return [
    {
      name: translation.monday,
      value: WeekDayEnum.MONDAY,
    },
    {
      name: translation.tuesday,
      value: WeekDayEnum.TUESDAY,
    },
    {
      name: translation.wednesday,
      value: WeekDayEnum.WEDNESDAY,
    },
    {
      name: translation.thursday,
      value: WeekDayEnum.THURSDAY,
    },
    {
      name: translation.friday,
      value: WeekDayEnum.FRIDAY,
    },
    {
      name: translation.saturday,
      value: WeekDayEnum.SATURDAY,
    },
    {
      name: translation.sunday,
      value: WeekDayEnum.SUNDAY,
    },
  ]
}
