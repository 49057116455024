import { meridiamOptions } from 'constants/inspection'
import { AppointmentHour } from 'models/services/customerRelationship/lead/detail'

export const getInitialMeridiam = (appointmentHour: AppointmentHour | null) => {
  if (!appointmentHour) return meridiamOptions[0].value

  if (appointmentHour.am) return 'AM'

  return 'PM'
}
