import { ENVIRONMENT } from 'constants/environment'
import { BackOfficeAllowedRoles, UserRoles } from 'models/role'
import {
  OrganizationStatusEnum,
  OrganizationTypeEnum,
  User,
} from 'models/services/user'

/**
 * Check if atleast one
 * of the roles of the user is inside
 * the permitted roles passed to it.
 */
export const grantPermissions = (
  clientRoles: UserRoles[],
  permittedRoles: UserRoles[],
  hideInProduction?: boolean
) => {
  if (hideInProduction && ENVIRONMENT === 'production') return false

  return clientRoles.some((role) => permittedRoles.includes(role))
}

/*
 * Check if the user roles are even allowed to access
 * the BackOffice
 */
export const grantAccess = (clientRoles: UserRoles[]) => {
  return clientRoles.some((role) => BackOfficeAllowedRoles.includes(role))
}

export const checkOrganization = (user: User) => {
  const { organization } = user

  if (!organization) return false

  const { status, type } = organization
  if (
    status === OrganizationStatusEnum.ACTIVE &&
    type === OrganizationTypeEnum.DEALER
  ) {
    return true
  }

  return false
}
