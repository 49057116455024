import React from 'react'
import { Typography } from '@mui/material'
import {
  Box,
  colors,
  ErrorMessage,
  formatDateLocalizedTime,
} from 'curbo-components-library'

import InputDatePicker from 'components/Common/InputDatePicker'

type DatePickerCellProps = {
  label: string
  error?: boolean
  errorText?: string
  labelError?: boolean
  edit?: boolean
  startAdornment?: React.ReactNode
  value: Date | null
  handleDateChange: (newDate: Date | null, name?: string) => void
  language?: string
  name?: string
}

const DatePickerCell = ({
  label,
  error,
  errorText,
  labelError,
  edit,
  startAdornment,
  value,
  handleDateChange,
  language,
  name,
}: DatePickerCellProps) => {
  return (
    <Box>
      <Typography
        color={labelError || error ? colors.lightRed : colors.black}
        variant="body1"
      >
        {label}
      </Typography>
      {!edit ? (
        <Box alignItems="center" display="flex">
          {startAdornment}
          <Typography variant="body2">
            {value ? formatDateLocalizedTime(value, 'P', language) : '-'}
          </Typography>
        </Box>
      ) : (
        <>
          <InputDatePicker
            date={value}
            error={error}
            name={name}
            onChange={(newDate) => {
              handleDateChange(newDate, name)
            }}
          />
          {error && errorText ? (
            <ErrorMessage
              sx={{
                paddingLeft: '0',
                marginTop: '0.25rem',
                alignItems: 'flex-start',
              }}
              text={errorText}
            />
          ) : null}
        </>
      )}
    </Box>
  )
}

export default DatePickerCell
