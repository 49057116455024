import React, { useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import { sleep } from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

export type CopyToClickBoardProps = {
  color?: string
  textToCopy: string
}

const CopyToClipboard = ({
  color = 'inherit',
  textToCopy,
}: CopyToClickBoardProps) => {
  const { text } = useTranslation(textFiles.GENERAL)
  const { copyToClipboard } = text
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(textToCopy)
    setClicked(true)
  }

  // Timeout to make it show clicked status until is not showing
  const handleLeave = async () => {
    await sleep(200)
    setClicked(false)
  }

  return (
    <Tooltip
      title={clicked ? copyToClipboard.click : copyToClipboard.hover}
      placement="bottom"
      onMouseLeave={handleLeave}
      arrow
    >
      <Button
        onClick={handleClick}
        style={{ padding: 0, margin: 0, fontSize: 'inherit', color }}
        data-cy="copy-to-clickboard-button"
      >
        {textToCopy}
      </Button>
    </Tooltip>
  )
}

export default CopyToClipboard
