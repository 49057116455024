import { gql } from '@apollo/client'

export const LIST_DEALER_LEADS = gql`
  query listDealerLeads($input: FilterInput!) {
    listDealerLeads(input: $input) {
      count
      data {
        id
        car {
          id
          name: title
          slug
        }
        caseId
        createdAt
        customer {
          name
          lastName
          telephoneNumber
        }
        customerEmail
        leadStep {
          id
          name
          slug
          type
          backgroundColor
          textColor
        }
        estimatedValue
        origin
        type
        customerCareRepresentative {
          value: id
          name
          lastName
        }
      }
    }
  }
`

export const GET_LEAD_STEPS = gql`
  query getLeadSteps($input: FilterInput!) {
    data: getLeadSteps(input: $input) {
      id
      name
      slug
      backgroundColor
      textColor
    }
  }
`
