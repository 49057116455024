import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { SxProps } from '@mui/system'
import {
  colors,
  cypressCancelButton,
  cypressEditButton,
  cypressSaveButton,
} from 'curbo-components-library'

import { StyledIcon } from '../style'

type EditButtonsProps = {
  editDisabled?: boolean
  edit: boolean
  disabled?: boolean
  handleEdit: () => void
  handleCancelEdit: () => void
  sx?: SxProps
}

const EditButtons = ({
  edit,
  handleEdit,
  disabled,
  editDisabled,
  handleCancelEdit,
  sx,
}: EditButtonsProps) => {
  if (editDisabled) return null

  return !edit ? (
    <StyledIcon
      disabled={disabled}
      onClick={handleEdit}
      data-cy={cypressEditButton}
      sx={sx}
    >
      <EditIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
    </StyledIcon>
  ) : (
    <>
      <StyledIcon
        onClick={handleEdit}
        sx={{ ...sx, right: '40px !important' }}
        data-cy={cypressSaveButton}
        disabled={disabled}
      >
        <SaveIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
      </StyledIcon>
      <StyledIcon
        onClick={handleCancelEdit}
        data-cy={cypressCancelButton}
        sx={sx}
        disabled={disabled}
      >
        <ClearIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
      </StyledIcon>
    </>
  )
}

export default EditButtons
