import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import { Button, colors, cypressBackButton } from 'curbo-components-library'

const GoBackButton: FC = ({ ...props }) => {
  const history = useHistory()

  const handleGoBack = () => {
    if (history.location.pathname.includes('#')) {
      history.go(-2)
    } else {
      history.goBack()
    }
  }

  return (
    <Button variant="text" onClick={handleGoBack} data-cy={cypressBackButton}>
      <Typography
        variant="h3"
        color={colors.blue}
        sx={{
          width: 'fit-content',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {props.children}
      </Typography>
    </Button>
  )
}
export default GoBackButton
