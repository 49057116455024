import { styled } from '@mui/material'
import { Box, colors } from 'curbo-components-library'

export const StyledContainer = styled(Box)({
  color: colors.black,
  backgroundColor: colors.background,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '10px 0 10px 10px',
  height: '100%',
  width: '294px',
  borderRadius: '3px',
})
