import React from 'react'

import NameContainer from 'components/Detail/NameContainer'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { TrimDetailDataType } from 'models/services/operation/carSettings/detail'
import { getTrimSpacing } from 'utils/operation/trimLevel/detail'

import { GridBox, StyledContainer } from 'styles/detail'

type Props = {
  information: TrimDetailDataType
}

const TrimSpacing = ({ information }: Props) => {
  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const { spacing: translation } = text

  const nameInformation = getTrimSpacing(
    translation.metering,
    translation.measurement,
    information
  )

  return (
    <StyledContainer>
      <GridBox>
        <NameContainer
          title={translation.metering.title}
          description={translation.metering.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={nameInformation.spacing}
          editDisabled
        />
        <NameContainer
          title={translation.measurement.title}
          description={translation.measurement.description}
          height="540px"
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={nameInformation.measurement}
          editDisabled
        />
      </GridBox>
    </StyledContainer>
  )
}

export default TrimSpacing
