import React, { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SaveIcon from '@mui/icons-material/Save'
import { Box, Typography } from '@mui/material'
import { colors, Container, useNotification } from 'curbo-components-library'

import {
  StyledBox,
  StyledContainer,
  StyledIcon,
} from 'components/CarSettings/Common/Detail/NameContainer/style'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { ExtendedRole } from 'models/services/General/UserManagement/detail'

import { RoleAndAreasListType, RoleFields } from './RoleField'

type RoleManagerProps = {
  loading: boolean
  selectedRoles: ExtendedRole[]
  handleChangeRoles: (roles: ExtendedRole[]) => void
} & RoleAndAreasListType

export const RolesManager = ({
  availableRoles,
  availableAreas,
  selectedRoles,
  loading,
  handleChangeRoles,
}: RoleManagerProps) => {
  const { text } = useTranslation(textFiles.USER_MANAGEMENT_DETAIL)
  const { roleManagement, notificationText } = text
  const { show } = useNotification()
  const [edit, setEdit] = useState<boolean>(false)
  const [localeRoles, setLocaleRoles] = useState<ExtendedRole[]>(selectedRoles)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false)

  const checkForDuplicates = () => {
    const valueArr = localeRoles.map((role) => {
      return role.value
    })
    const isDuplicate = valueArr.some((value, idx) => {
      return valueArr.indexOf(value) !== idx
    })

    if (isDuplicate) {
      show({
        updatedSeverity: 'error',
        message: notificationText.duplicateRoles,
      })
    } else {
      handleChangeRoles(localeRoles)
      setEdit(false)
    }
  }
  const handleEdit = () => {
    if (!edit) {
      setEdit(true)
    } else if (!saveDisabled) {
      checkForDuplicates()
    }
  }

  const handleClear = () => {
    setLocaleRoles(selectedRoles)
    setEdit(false)
    setSaveDisabled(false)
  }

  const handleSetNewRoles = (newRoles: ExtendedRole[]) => {
    setLocaleRoles(newRoles)
  }

  const handleDeleteRole = () => {
    const newRoles = [...localeRoles]
    newRoles.splice(selectedIndex, 1)
    setLocaleRoles(newRoles)
  }

  useEffect(() => {
    setLocaleRoles(selectedRoles)
  }, [selectedRoles])

  useEffect(() => {
    if (localeRoles.every((role) => role.value !== '')) {
      setSaveDisabled(false)
    } else {
      setSaveDisabled(true)
    }
  }, [localeRoles])

  return (
    <Container
      paddingX="0px !important"
      title={roleManagement.title}
      description={roleManagement.description}
    >
      <StyledBox>
        <StyledContainer paddingTop="2rem !important">
          {!edit ? (
            <StyledIcon disabled={loading} onClick={handleEdit}>
              <EditIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
            </StyledIcon>
          ) : (
            <>
              <StyledIcon
                onClick={handleEdit}
                disabled={saveDisabled}
                sx={{ right: '50px !important' }}
              >
                <SaveIcon
                  sx={{ color: colors.placeholderGray, fontSize: 16 }}
                />
              </StyledIcon>
              <StyledIcon onClick={handleClear}>
                <ClearIcon
                  sx={{ color: colors.placeholderGray, fontSize: 16 }}
                />
              </StyledIcon>
            </>
          )}
          <Box display="flex" flexDirection="row">
            <ManageAccountsIcon fontSize="large" color="primary" />
            <Typography
              marginLeft="1rem"
              variant="body1"
              fontWeight="bold"
              alignSelf="center"
            >
              {roleManagement.assignedRoles}
            </Typography>
          </Box>
          {!loading && (
            <RoleFields
              handleSetNewRoles={handleSetNewRoles}
              handleRemoveRole={handleDeleteRole}
              disabledAdd={saveDisabled}
              handleSelectIndex={(index: number) => setSelectedIndex(index)}
              edit={edit}
              availableAreas={availableAreas}
              availableRoles={availableRoles}
              roles={localeRoles}
            />
          )}
        </StyledContainer>
      </StyledBox>
    </Container>
  )
}
