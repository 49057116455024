import { InventorySlugStatusEnum } from 'curbo-components-library'

import { Image } from 'components/Common/Carousel'

const editablePicturesStatus: string[] = [InventorySlugStatusEnum.PENDING]

// functions for inventory gallery
export const canDeletePictures = (imageList: Image[], status: string) => {
  if (status === InventorySlugStatusEnum.PENDING) return true

  if (imageList.length > 2 && editablePicturesStatus.includes(status))
    return true

  return false
}

export const canEditPictures = (status: string) => {
  return !!editablePicturesStatus.includes(status)
}
