import { styled, Typography } from '@mui/material'
import {
  Box,
  BREAK_POINTS,
  colors,
  Container,
  FONT_WEIGHT,
} from 'curbo-components-library'

export const StyledBrandImg = styled('img')({
  alignSelf: 'center',
  objectFit: 'contain',
  height: '194px',
  width: '194px',
})

export const StyledDataText = styled(Typography)({
  color: colors.black,
  fontWeight: FONT_WEIGHT.BOLD,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

export const StyledDataTitle = styled(Typography)({
  color: colors.gray,
  fontWeight: FONT_WEIGHT.MEDIUM,
})

export const StyledContainer = styled(Container)({
  height: '100%',
  padding: '0',
  width: '350px',
})

export const ImgContainer = styled(Container)({
  height: '100%',
  padding: '0',
  width: '350px',
  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    width: '450px',
  },
})

export const VehicleBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '37% 30% 30%',
  columnGap: '15px',
  gridColumn: 'unset',

  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 50%',
    columnGap: 0,
    rowGap: '20px',

    '& > div:nth-of-type(1)': {
      gridColumn: '1/-1',
      width: '100%',
      ul: {
        height: 'auto',
      },
    },
  },
})

export const StyledCarouselContainer = styled(Box)({
  '& section': {
    width: '100%',
  },
})
