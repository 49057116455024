import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import {
  BaseIdEntity,
  Box,
  buildDetailRoute,
  Button,
  CalendarRangeType,
  checkSearchEmptiness,
  cleanFilters,
  Filter,
  FilterByCriteria,
  FilteringOption,
  generateFilterInput,
  GenericData,
  GenericUpdateVariable,
  getIsoDate,
  ListingFilterType,
  LoadingAnimation,
  serializeFilters,
  serializeRange,
  serializeSearch,
  Title,
  UrlParamNames,
  verifyParam,
} from 'curbo-components-library'
import { endOfDay, startOfDay } from 'date-fns'

import CaseCard from 'components/CustomerRelationship/Lead/Board/CaseCard'
import CaseTitle from 'components/CustomerRelationship/Lead/Board/CaseTitle'
import DateRangeFilter from 'components/Inspection/Listing/DateFilter'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { FiltersByCriteriaTranslationType } from 'models/filtersByCriteria'
import { UserRoles } from 'models/role'
import { FilterBoardInputVariable } from 'models/services/base'
import {
  ColumnData,
  ListLeadBoardData,
  UpdateCaseInput,
} from 'models/services/customerRelationship/lead/board'
import { ListBds } from 'models/services/customerRelationship/lead/listing'
import { formatStringIntoFilteringOption } from 'utils/basicUtil'
import { getLeadsFieldOrEntryOptions } from 'utils/CustomerRelationship/Lead/common'

import { GET_DEALER_CASE_BOARD } from 'graphQL/CustomerRelationship/Lead/Board/queries'
import { UPDATE_CASE } from 'graphQL/CustomerRelationship/Lead/Common/mutations'
import {
  GET_BDS,
  GET_CASE_ORIGIN_ENUMS,
  GET_CASE_TYPE_ENUMS,
} from 'graphQL/CustomerRelationship/Lead/Common/queries'

import { BoardContainer } from 'styles/board'
import { StyledTextField } from 'styles/inspection/listing'

type Columns = Record<string, ColumnData>

export type LeadCustomFilterOption = {
  type: FilteringOption[]
  origin: FilteringOption[]
  customerCareRepresentative: FilteringOption[]
}

const staticFields = ['type', 'origin', 'customerCareRepresentative']

const LeadBoardPage = () => {
  const { text } = useTranslation(textFiles.LEAD_BOARD)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const {
    filtersByCriteria,
  }: { filtersByCriteria: FiltersByCriteriaTranslationType } = text
  const { fieldOrEntryOptions: filterOptionText } = filtersByCriteria
  const location = useLocation()
  const { search } = location
  const { validateAllowedRoles } = useUser()
  const history = useHistory()

  const isSupervisor = validateAllowedRoles([
    UserRoles.ADMIN,
    UserRoles.CUSTOMER_CARE_SPECIALIST,
    UserRoles.CUSTOMER_CARE_SUPERVISOR,
  ])

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )
  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )
  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [searchInput, setSearchInput] = useState<string>(searchValue || '')
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )
  const [customFilterOptions, setCustomFilterOptions] =
    useState<LeadCustomFilterOption>({
      customerCareRepresentative: [],
      origin: [],
      type: [],
    })

  const [columns, setColumns] = useState<Columns | undefined>()
  const [cursor, setCursor] = useState<string | null>(null)
  const [totalCount, setTotalCount] = useState<number>(0)
  const hasTriggeredQuery = useRef<boolean>(false)
  const listInnerRef = useRef<HTMLDivElement | null>(null)
  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(
        dateRange.fromDate ? startOfDay(dateRange.fromDate) : null
      ),
      toDate: getIsoDate(dateRange.toDate ? endOfDay(dateRange.toDate) : null),
    }
  }, [dateRange])

  const fieldOrEntryOptions = getLeadsFieldOrEntryOptions(filterOptionText)

  const { loading: listLoading, fetchMore } = useQuery<
    ListLeadBoardData,
    FilterBoardInputVariable
  >(GET_DEALER_CASE_BOARD, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        boardType: 'LEAD',
        where: {
          text_search: searchValue,
          createdAt_gte: fromDate,
          createdAt_lte: toDate,
          ...cleanFilters(filterInput),
        },
      },
    },
    onCompleted(response) {
      setCursor(response.listData.cursor)
      setTotalCount(response.listData.count)
      const { data } = response.listData
      // if its first time rendering or a new filter is applied, construct the new columns
      if (!hasTriggeredQuery.current) {
        const responseColumns: Columns = data.reduce(
          (previousColumn, currentColumn) => {
            const {
              backgroundColor,
              caseData,
              count,
              id,
              name,
              textColor,
              slug,
            } = currentColumn

            const columnId = id
            const columnData: ColumnData = {
              backgroundColor,
              textColor,
              count,
              name,
              slug,
              items: caseData,
            }

            return { ...previousColumn, [columnId]: columnData }
          },
          {}
        )
        setColumns(responseColumns)
        hasTriggeredQuery.current = true
      } else {
        // otherwise just append more items to the corresponding columns
        setColumns((prevColumns) => {
          if (!prevColumns) return prevColumns
          const oldColumns: Columns = { ...prevColumns }
          const newColumns: Columns = data.reduce(
            (previousColumn, currentColumn) => {
              const { id } = currentColumn

              const columnId: string = id

              if (columnId in previousColumn) {
                return {
                  ...previousColumn,
                  [columnId]: {
                    ...previousColumn[columnId],
                    items: [
                      ...previousColumn[columnId].items,
                      ...currentColumn.caseData,
                    ],
                  },
                }
              }

              return previousColumn
            },
            oldColumns
          )

          return newColumns
        })
      }
    },
  })

  const { loading: typesLoading } = useQuery<GenericData<string[]>>(
    GET_CASE_TYPE_ENUMS,
    {
      onCompleted(response) {
        setCustomFilterOptions((prevCustomFilter) => {
          return {
            ...prevCustomFilter,
            type: formatStringIntoFilteringOption(response.data),
          }
        })
      },
    }
  )

  const { loading: originsLoading } = useQuery<GenericData<string[]>>(
    GET_CASE_ORIGIN_ENUMS,
    {
      onCompleted(response) {
        setCustomFilterOptions((prevCustomFilter) => {
          return {
            ...prevCustomFilter,
            origin: formatStringIntoFilteringOption(response.data),
          }
        })
      },
    }
  )

  const { loading: bdsLoading } = useQuery<ListBds>(GET_BDS, {
    onCompleted(response) {
      setCustomFilterOptions((prevCustomFilter) => {
        return {
          ...prevCustomFilter,
          customerCareRepresentative: response.getBds.data.map((option) => {
            return {
              disabled: false,
              name: `${option.name} ${option.lastName}`,
              value: option.value as string,
            }
          }),
        }
      })
    },
  })

  const [updateCase, { loading: updateLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateCaseInput>
  >(UPDATE_CASE)

  const handleAddNewLead = () => {
    history.push(routes.LEAD_CREATION)
  }

  const handleFetchMoreLead = () => {
    if (!cursor) return

    fetchMore({
      variables: {
        input: {
          boardType: 'LEAD',
          cursor,
          where: {
            text_search: searchValue,
            createdAt_gte: fromDate,
            createdAt_lte: toDate,
            ...cleanFilters(filterInput),
          },
        },
      },
      updateQuery: (
        prev,
        { fetchMoreResult }: { fetchMoreResult?: ListLeadBoardData }
      ) => {
        if (!fetchMoreResult) return prev

        return { ...prev, ...fetchMoreResult }
      },
    })
  }

  const handleScrollRefTop = () => {
    if (listInnerRef.current)
      listInnerRef.current.scroll({
        top: 0,
      })
  }

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const submitSearchValue = () => {
    hasTriggeredQuery.current = false
    handleScrollRefTop()
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    hasTriggeredQuery.current = false
    handleScrollRefTop()
    setDateRange(newDateRange)
  }

  const handleCardClick = useCallback(
    (id: string) => {
      history.push(buildDetailRoute(id, routes.LEAD_DETAIL))
    },
    [history]
  )

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    hasTriggeredQuery.current = false
    handleScrollRefTop()
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
  }

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight
    if (bottom) {
      handleFetchMoreLead()
    }
  }

  const handleUpdateLead = (id: string, input: UpdateCaseInput) => {
    updateCase({
      variables: {
        input: {
          where: {
            id,
          },
          data: input,
        },
      },
    })
  }

  const onDragEnd = (
    result: DropResult,
    currentColumns: Columns,
    setCurrentColumns: React.Dispatch<React.SetStateAction<Columns | undefined>>
  ) => {
    if (!result.destination) return
    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = currentColumns[source.droppableId]
      const destColumn = currentColumns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      // THIS LINE WILL BE ADDED WHEN ITEMS CAN BE PUT IN ANY ORDER
      // destItems.splice(destination.index, 0, removed)
      destItems.unshift(removed)
      handleUpdateLead(removed.id, {
        leadStep: destColumn.slug,
      })
      setCurrentColumns({
        ...currentColumns,
        [source.droppableId]: {
          ...sourceColumn,
          count: sourceColumn.count - 1,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          count: destColumn.count + 1,
          items: destItems,
        },
      })
    }
    // THIS LINE WILL BE ADDED WHEN ITEMS CAN BE PUT IN ANY ORDER
    // else {
    //   const column = columns[source.droppableId]
    //   const copiedItems = [...column.items]
    //   const [removed] = copiedItems.splice(source.index, 1)
    //   copiedItems.splice(destination.index, 0, removed)
    //   setColumns({
    //     ...columns,
    //     [source.droppableId]: {
    //       ...column,
    //       items: copiedItems,
    //     },
    //   })
    // }
  }

  if (typesLoading || bdsLoading || originsLoading || !columns)
    return <LoadingAnimation showAnimation />

  return (
    <Box width="70%">
      <Title
        title={text.title}
        subtitle={`${totalCount} ${text.description}`}
        icon={<PlaylistAddCheckIcon />}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        margin="1rem 0rem"
        width="100%"
      >
        {isSupervisor ? (
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddNewLead}
            size="medium"
          >
            {text.newLeadButton}
          </Button>
        ) : null}
        <Box display="flex">
          <Box marginRight="1rem" width="450px">
            <StyledTextField
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterCardText={filtersByCriteria}
              filtersByCriteriaText={{
                ...filtersByCriteria,
                fieldOrEntryOptions,
              }}
              labels={generalText.filterByCriteria}
              filterInput={filterInput}
              staticFields={staticFields}
              options={customFilterOptions}
            />
          </Box>
          <Box>
            <DateRangeFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              maxDate={null}
              popperSx={{
                zIndex: 100,
              }}
            />
          </Box>
        </Box>
      </Box>
      <BoardContainer
        sx={{
          opacity: listLoading || updateLoading ? '0.5' : 'unset',
          position: listLoading || updateLoading ? 'relative' : 'unset',
        }}
        onScroll={handleScroll}
        ref={listInnerRef}
      >
        <LoadingAnimation
          showAnimation={listLoading || updateLoading}
          styles={{
            height: '70%',
            width: '70%',
            position: 'fixed',
            zIndex: 100,
          }}
        />
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column]) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={columnId}
              >
                <CaseTitle
                  backgroundColor={column.backgroundColor}
                  textColor={column.textColor}
                  title={column.name}
                  count={column.count}
                />
                <div style={{ margin: 8 }}>
                  <Droppable
                    droppableId={columnId}
                    key={columnId}
                    isDropDisabled={!isSupervisor}
                  >
                    {(droppableProvided) => {
                      return (
                        <Box
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          sx={{
                            width: 310,
                            minHeight: 500,
                            padding: '0.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                                isDragDisabled={!isSupervisor}
                              >
                                {(draggableProvided, draggableSnapshot) => {
                                  return (
                                    <CaseCard
                                      borderColor={column.textColor}
                                      draggableProvided={draggableProvided}
                                      draggableSnapshot={draggableSnapshot}
                                      item={item}
                                      options={
                                        customFilterOptions.customerCareRepresentative
                                      }
                                      handleUpdateLead={handleUpdateLead}
                                      handleCardClick={() =>
                                        handleCardClick(item.id)
                                      }
                                    />
                                  )
                                }}
                              </Draggable>
                            )
                          })}
                          {droppableProvided.placeholder}
                        </Box>
                      )
                    }}
                  </Droppable>
                </div>
              </div>
            )
          })}
        </DragDropContext>
      </BoardContainer>
    </Box>
  )
}

export default LeadBoardPage
