import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent, Typography } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'
import {
  Box,
  buildDetailRoute,
  CalendarRangeType,
  cleanFilters,
  colors,
  cypressBackLink,
  DateRangeCalendarTabType,
  ExtendedStatus,
  FieldFilter,
  Filter,
  FilterByCriteria,
  FilterEntryVariableType,
  FilteringOption,
  FilteringOptionType,
  FilterInputVariable,
  generateFilterInput,
  GenericData,
  getIsoDate,
  ListingFilterType,
  PublicationStatusEnum,
  PublicationStatusMap,
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSortModel,
  StatusStyles,
  Table,
  Title,
  UrlParamNames,
  verifyParam,
} from 'curbo-components-library'
import { endOfDay, startOfDay } from 'date-fns'

import CarSettingDateRangeFilter from 'components/Operation/CarSettings/Common/CarSettingDateRangeFilter'

import {
  CarSettingsKey,
  carSettingsListingFields,
  createCarSettingsColumns,
  trimStaticFields,
} from 'constants/operation/carSettings/listing'
import { CAR_SETTINGS_ROUTE, CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { defaultStatusSortModel, selectItems } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import { ListSettingType } from 'models/services/operation/carSettings/listing'

import { GET_MODELS } from 'graphQL/Operations/Common/queries'

import { StyledLink } from 'styles/table'

type CarSettingsListingPageProps = {
  route: CarSettingsKey
}

const CarSettingsListingPage = ({ route }: CarSettingsListingPageProps) => {
  const {
    createFieldSelectItems,
    createFieldSelectLabels,
    selectFields,
    textFile,
    query,
  } = carSettingsListingFields[route]
  const detailRoute = `${CAR_SETTINGS_ROUTE}/${route}/detail/:carSettingsId`
  const { text } = useTranslation(textFile)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const {
    filtersByCriteria: { fieldOrEntryOptions: filterOptionText },
  } = text
  const [selectedLanguage] = useLocale()

  const location = useLocation()
  const history = useHistory()
  const { search } = location

  const defaultSortedFields = [...selectFields].sort()

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultStatusSortModel,
    serializeSortModel,
    defaultStatusSortModel
  )

  const [calendarTabValue, setCalendarTabValue] = useState<
    DateRangeCalendarTabType | boolean
  >(DateRangeCalendarTabType.ALL)
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const [data, setData] = useState<GridRowsProp>([])
  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)
  const [listCount, setListCount] = useState<number>(0)
  const [modelsList, setModelsList] = useState<FilteringOption[]>([])

  const statusList: ExtendedStatus[] = useMemo(
    () => [
      {
        backgroundColor:
          StatusStyles[PublicationStatusEnum.PUBLISHED].backgroundColor,
        textColor: StatusStyles[PublicationStatusEnum.PUBLISHED].color,
        id: PublicationStatusEnum.PUBLISHED,
        slug: PublicationStatusEnum.PUBLISHED,
        description: generalText.status.carSettingPublishedDescription,
        name: generalText.status[PublicationStatusEnum.PUBLISHED],
      },
      {
        backgroundColor:
          StatusStyles[PublicationStatusEnum.UNPUBLISHED].backgroundColor,
        textColor: StatusStyles[PublicationStatusEnum.UNPUBLISHED].color,
        id: PublicationStatusEnum.UNPUBLISHED,
        slug: PublicationStatusEnum.UNPUBLISHED,
        description: generalText.status.carSettingUnpublishedDescription,
        name: generalText.status[PublicationStatusEnum.UNPUBLISHED],
      },
    ],
    [generalText]
  )

  const fieldOrEntryOptions: Record<string, FilterEntryVariableType> = {
    id: {
      name: filterOptionText.id,
      type: 'string',
    },
    name: {
      name: filterOptionText.name,
      type: 'string',
    },
    status: {
      name: filterOptionText.status,
      type: 'string',
    },
    ...(route === 'trim-level' && {
      year: { name: filterOptionText.year, type: 'number' },
      carModel: { name: filterOptionText.carModel, type: 'string' },
    }),
  }

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(
        dateRange.fromDate ? startOfDay(dateRange.fromDate) : null
      ),
      toDate: getIsoDate(dateRange.toDate ? endOfDay(dateRange.toDate) : null),
    }
  }, [dateRange])

  const { loading } = useQuery<ListSettingType, FilterInputVariable>(query, {
    onCompleted(response) {
      const { count, data: currentData } = response.listData
      setListCount(count)
      setData(currentData)
    },
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          createdAt_gte: fromDate,
          createdAt_lte: toDate,
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const [columns, setColumns] = useState<GridColDef[]>([])

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(selectFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = selectFields.some(
          (selectField) => column.field === selectField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const getPageCount = () => {
    return Math.ceil(listCount / pageSize)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  const handleCalendarTabChange = (newValue: DateRangeCalendarTabType) => {
    setCalendarTabValue(newValue)
  }

  const handleCellClick = (params: GridCellParams) => {
    history.push(buildDetailRoute(params.row.id, detailRoute))
  }

  useEffect(() => {
    setColumns(
      createCarSettingsColumns(
        route,
        PublicationStatusMap,
        selectedLanguage,
        generalText,
        text.table,
        statusList,
        selectedFields
      )
    )
  }, [
    generalText,
    route,
    selectedFields,
    selectedLanguage,
    statusList,
    text.table,
  ])

  const [getModels, { loading: modelsLoading, called: modelsCalled }] =
    useLazyQuery<GenericData<FilteringOptionType[]>>(GET_MODELS, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        if (response.data) {
          setModelsList(response.data)
        }
      },
    })

  const handleFieldEntrySelect = (field: string) => {
    switch (field) {
      case 'carModel':
        if (!modelsCalled) {
          getModels()
        }
        break
      default:
        break
    }
  }

  const filtersLoading = modelsLoading

  return (
    <Box width="100%">
      <StyledLink
        testId={cypressBackLink}
        to={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU}
      >
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${generalText.buttons.back}`}</Typography>
      </StyledLink>
      <Title
        icon={<PlaylistAddCheckIcon />}
        subtitle={`${listCount} ${text.description}`}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="flex-end"
        marginTop="2rem"
        width="100%"
      >
        <Box display="flex">
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterInput={filterInput}
              statusList={statusList}
              staticFields={trimStaticFields}
              handleFieldEntrySelect={handleFieldEntrySelect}
              filterTypes={
                route === 'trim-level' ? { year: 'number' } : undefined
              }
              loadingSelect={filtersLoading}
              options={{
                carModel: modelsList,
              }}
              filtersByCriteriaText={{
                ...text.filtersByCriteria,
                fieldOrEntryOptions,
              }}
              filterCardText={text.filtersByCriteria}
              labels={generalText.filterByCriteria}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
              text={generalText.fieldFilter}
            />
          </Box>
          <Box>
            <CarSettingDateRangeFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              calendarTabValue={calendarTabValue}
              handleCalendarTabChange={handleCalendarTabChange}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        onCellClick={handleCellClick}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={loading}
        checkboxSelection={false}
        hideDownloadModal
        downloadModalText={generalText.downloadReport}
        filterCardText={text.filterByCriteria}
        text={generalText.tableText}
      />
    </Box>
  )
}
export default CarSettingsListingPage
