import React from 'react'
import { TableBody, TableCell } from '@mui/material'
import { formatDateLocalizedTime, NoDataBox } from 'curbo-components-library'

import { Day, Inspector } from 'models/calendar'

import { CalendarCell } from '../CalendarCell'
import InspectorCard from '../InspectorCard'
import { StyledRowBody } from '../style'

export type WeekDay = {
  name: string
  date: Date
}

type TableBodyProps = {
  page: number
  filteredData: Inspector[]
  rowsPerPage: number
  dayInNumbers: WeekDay[]
}

export const CalendarTableBody = ({
  page,
  rowsPerPage,
  filteredData,
  dayInNumbers,
}: TableBodyProps) => {
  const formatDay = (day: Date) => formatDateLocalizedTime(day, 'dd-MM-yyyy')

  const sortDays = (days: Day[]) => {
    const sorted = [
      ...days
        .filter((day) => day.date.getTime() >= dayInNumbers[0].date.getTime())
        .sort((a, b) => {
          return a.date.getTime() - b.date.getTime()
        }),
    ]

    const result: Day[] = []
    dayInNumbers.forEach((day) => {
      if (
        sorted.length > 0 &&
        formatDay(day.date) === formatDay(sorted[0].date)
      ) {
        result.push(sorted[0])
        sorted.shift()
      } else {
        result.push({ data: [], date: day.date, isAbsent: false })
      }
    })
    return result
  }

  return filteredData.length === 0 ? (
    <TableBody>
      <StyledRowBody>
        <TableCell colSpan={8} height="551px">
          <NoDataBox />
        </TableCell>
      </StyledRowBody>
    </TableBody>
  ) : (
    <TableBody sx={{ overflow: 'auto' }}>
      {(rowsPerPage > 0
        ? filteredData.slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage
          )
        : filteredData
      ).map((inspector) => (
        <StyledRowBody key={inspector.id}>
          <TableCell align="left">
            <InspectorCard
              name={inspector.name}
              image={inspector.profilePicture}
            />
          </TableCell>
          {sortDays(inspector.data).map((day) => {
            return (
              <CalendarCell
                key={`${inspector.id}-${inspector.name}-${day.date}`}
                {...day}
              />
            )
          })}
        </StyledRowBody>
      ))}
    </TableBody>
  )
}
