import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import BrandDetailPage from 'pages/Operation/CarSettings/Brand/Detail'
import CountryVersionDetailPage from 'pages/Operation/CarSettings/CountryVersion/Detail'
import CarSettingsHomePage from 'pages/Operation/CarSettings/Home'
import CarSettingsListingWrapper from 'pages/Operation/CarSettings/Listing/Wrapper'
import ModelDetailPage from 'pages/Operation/CarSettings/Model/Detail'
import TrimnDetailPage from 'pages/Operation/CarSettings/Trim/Detail'

import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'

export const CarSettingsRouter = () => {
  return (
    <Switch>
      <Route
        path={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_LISTING}
        component={CarSettingsListingWrapper}
      />
      <Route
        path={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU}
        component={CarSettingsHomePage}
      />
      <Route
        path={CAR_SETTINGS_SUB_ROUTES.BRAND_DETAIL}
        component={BrandDetailPage}
      />
      <Route
        path={CAR_SETTINGS_SUB_ROUTES.MODEL_DETAIL}
        component={ModelDetailPage}
      />
      <Route
        path={CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_DETAIL}
        component={CountryVersionDetailPage}
      />
      <Route
        path={CAR_SETTINGS_SUB_ROUTES.TRIM_DETAIL}
        component={TrimnDetailPage}
      />
      <Redirect to={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU} />
    </Switch>
  )
}
