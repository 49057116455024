import { gql } from '@apollo/client'

export const GET_CASE_BY_ID = gql`
  query getCaseById($input: ID!) {
    data: getCaseById(id: $input) {
      activeCallToActions {
        id
        name
        value: type
      }
      bant {
        score
        hasBudget {
          name: label
        }
        purchaseTimeFrame {
          name: label
        }
        decisionMakerKnoledge {
          name: label
        }
        knowDecisionMakerName {
          name: label
        }
        hasReasonToPurchase {
          name: label
        }
      }
      bantScore
      car {
        id
        title
        year
      }
      caseId
      customer {
        address
        birthDate
        documentType
        documentId
        email
        id
        name
        lastName
        telephoneNumber
        zipCode
      }
      customerCareRepresentative {
        id
        name
        lastName
      }
      estimatedValue
      findMyCar {
        id
      }
      id
      leadStep {
        backgroundColor
        textColor
        slug
        name
      }
      testDrive {
        id
      }
      type
      sellMyCar {
        id
      }
      sellMyCarInfo {
        insuranceNumber
        insuranceDueDate
        insuranceInspectionReportNumber
        insuranceInspectionDate
        ownershipCardNumber
        ownershipCardDueDate
        hasLegalImpedance
        hasTrafficAccidents
        hasTrafficInfrigements
        hasInsuranceReport
        lastTaxPaymentDate
      }
      maintenance {
        id
      }
      workingInfo {
        company
        experience
        experienceTimeUnit
      }
      financialAdvisor {
        id
        name
        lastName
      }
      sellRepresentative {
        id
        name
        lastName
      }
    }
  }
`

export const GET_CAR_BY_ID = gql`
  query getCarById($input: ID!) {
    data: getCarById(id: $input) {
      brand {
        icon
        name
      }
      carModel {
        name
      }
      categories {
        name
      }
      countryVersion {
        name
      }
      mainPicture
      trimLevel {
        name
      }
      year
      mileage
      exteriorColor {
        name
        hexCode
      }
      interiorColor {
        name
        hexCode
      }
      slug
      id
      certified
    }
  }
`

export const GET_DEALER_SELL_MY_CAR_BY_ID = gql`
  query getDealerSellMyCarById($input: ID!) {
    data: getDealerSellMyCarById(id: $input) {
      carModel {
        id
        brand {
          id
          name
          icon
        }
        name
      }
      trimLevel: trim {
        id
        name
      }
      year
      id
      price
      expectedTime {
        days
        id
        label
        timeType
      }
      address
      curboSpot {
        id
      }
      date
      latitude
      longitude
      name
      inspectionHour {
        am
        continentalTime
        name
        value: id
      }
    }
  }
`
export const GET_MAINTENANCE_BY_ID = gql`
  query getMaintenanceById($input: ID!) {
    data: getMaintenanceById(id: $input) {
      carModel: model {
        brand {
          id
          icon
          name
        }
        id
        name
      }
      trimLevel {
        id
        name
      }
      year
      id
    }
  }
`

export const GET_FIND_THIS_CAR_BY_ID = gql`
  query getFindThisCarById($input: ID!) {
    data: getFindThisCarById(id: $input) {
      carModel: model {
        brand {
          id
          icon
          name
        }
        id
        name
      }
      year
      id
    }
  }
`

export const GET_TEST_DRIVE_BY_ID = gql`
  query getTestDriveById($input: ID!) {
    data: getTestDriveById(id: $input) {
      address
      curboSpot {
        id
      }
      date
      isAtCurbo
      id
      latitude
      longitude
      name
      inspectionHour {
        am
        continentalTime
        name
        value: id
      }
      id
    }
  }
`
