import React from 'react'
import { Typography } from '@mui/material'
import {
  Box,
  colors,
  DetailStatusTag,
  ExtendedStatus,
  FONT_WEIGHT,
  GenericStatusChangePopper,
  StatusEntity,
} from 'curbo-components-library'

import GoBackButton from 'components/Common/GoBackButton'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { extraTitleStyle, StyledColorContainer } from './style'

type ExtraBoxContent = {
  title: string
  content: string
}

type DetailHeaderProps = {
  title: string
  status?: ExtendedStatus | StatusEntity
  editable: boolean
  statusList?: ExtendedStatus[]
  loading?: boolean
  handleStatusChange?: (newStatus: ExtendedStatus) => void
  extraBoxContent?: ExtraBoxContent
  extraTitle?: string
}
export const DetailHeader = ({
  title,
  status,
  statusList,
  editable,
  loading = false,
  handleStatusChange,
  extraBoxContent,
  extraTitle,
}: DetailHeaderProps) => {
  const { text } = useTranslation(textFiles.GENERAL)

  return (
    <>
      <GoBackButton>{text.buttons.backButton}</GoBackButton>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" paddingTop="0.5rem">
          <Typography
            variant="h3"
            color={colors.black}
            marginRight="1rem"
            sx={extraTitle ? extraTitleStyle : undefined}
          >
            {title}
          </Typography>
          <Box alignItems="center" display="flex">
            {status && (
              <DetailStatusTag
                backgroundColor={status.backgroundColor}
                color={status.textColor}
                text={status.name}
              />
            )}
            {editable && handleStatusChange && statusList && (
              <GenericStatusChangePopper
                status={status as ExtendedStatus}
                handleSaveStatus={handleStatusChange}
                statusList={statusList}
                loading={loading}
                text={{
                  saveButton: text.buttons.save,
                  cancelButton: text.buttons.cancel,
                  ...text.statusChangePopper,
                }}
              />
            )}
          </Box>
          {status && extraBoxContent && (
            <Box alignItems="center" display="flex" marginLeft="1.5rem">
              <Typography variant="body2" color={status.textColor}>
                {extraBoxContent.title}
              </Typography>
              <StyledColorContainer
                sx={{
                  color: status.textColor,
                  backgroundColor: status.backgroundColor,
                }}
              >
                <Typography
                  fontWeight={FONT_WEIGHT.BOLD}
                  variant="body2"
                  color={status.textColor}
                >
                  {extraBoxContent.content}
                </Typography>
              </StyledColorContainer>
            </Box>
          )}
        </Box>

        <Typography variant="h3" color={colors.blue} marginRight="1rem">
          {extraTitle}
        </Typography>
      </Box>
    </>
  )
}
