import { styled } from '@mui/material'
import { colors, FONT_WEIGHT } from 'curbo-components-library'

export const StyledLink = styled('a')({
  fontSize: '12px',
  color: colors.lightBlack,
  fontWeight: FONT_WEIGHT.MEDIUM,
  width: 'fit-content',
  marginBottom: '0.25rem',
})
