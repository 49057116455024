import CircleIcon from '@mui/icons-material/Circle'
import { ListItemIcon, styled } from '@mui/material'
import { colors } from 'curbo-components-library'

export const ListBullet = styled(CircleIcon)({
  fontSize: '0.5rem',
  color: colors.blue,
})

export const ListIcon = styled(ListItemIcon)({
  minWidth: '25px',
})
