import { styled } from '@mui/material'
import { Box, BREAK_POINTS } from 'curbo-components-library'

export const VehicleBox = styled(Box)({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '30% 30% 25%',
  columnGap: '30px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '45% 45%',
  },
})

export const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridAutoFlow: 'column',
  columnGap: '0px',
  width: '100%',
  height: 'auto',
  marginBottom: '1rem',
})
