import { autocompleteClasses, styled, TextField } from '@mui/material'
import { colors, FONT_WEIGHT } from 'curbo-components-library'

export const StyledTextField = styled(TextField)({
  [`.${autocompleteClasses.inputRoot}`]: {
    fontSize: '12px',
    color: colors.gray,
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
})
