import { gql } from '@apollo/client'

export const GET_INSPECTORS_CALENDAR = gql`
  query getDealerInspectorsCalendar(
    $input: DealerInspectorsCalendarFilterInput!
  ) {
    data: getDealerInspectorsCalendar(input: $input) {
      inspectors: data {
        id
        profilePicture
        name
        tags: data {
          isAbsent
          date
          info: data {
            origin
            total
          }
        }
      }
    }
  }
`
