export enum DateRangeCalendarTabType {
  YESTERDAY = 'yesterday',
  LAST_SEVEN_DAYS = 'lastSevenDays',
  LAST_THIRTY_DAYS = 'lastThirtyDays',
  LAST_MONTH = 'lastMonth',
  CUSTOM = 'custom',
  ALL = 'all',
  TODAY = 'TODAY',
  MONTH = 'MONTH',
}

export type CalendarRangeType = { fromDate: Date | null; toDate: Date | null }
