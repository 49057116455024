import React, { useRef } from 'react'
import { Box } from '@mui/material'
import { ExtendedAvatar } from 'curbo-components-library'
import useDraggableScroll from 'use-draggable-scroll'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledCarousel } from './style'

export type Image = {
  url: string
  id: string
}

export type CarouselProps = {
  /**
   * Image list to be rendered in the carousel
   */
  imageList: Image[]
  /**
   * Image dimensions
   */
  imageWidth: string | number
  imageHeight: string | number
  /**
   * Carousel dimensions
   */
  width?: string | number
  height?: string | number
  /**
   * Axis direction
   */
  axis?: 'vertical' | 'horizontal'
  /**
   * Prop that indicates whether or not a download option should be rendered within the Avatar
   */
  isDownloadable?: boolean
  /**
   * Optional prop that renders a delete button, calling the following method on
   * a specific image
   */
  onDelete?: (index: string) => void
}

const Carousel = ({
  imageList,
  width = 'auto',
  height = 'auto',
  imageWidth,
  imageHeight,
  isDownloadable,
  onDelete,
  axis = 'horizontal',
}: CarouselProps) => {
  const ref = useRef(null)

  const { onMouseDown } = useDraggableScroll(ref, { direction: axis })

  const horizontal = axis === 'horizontal'

  const handleDelete = (id: string) => {
    if (onDelete) onDelete(id)
  }

  const {
    text: { translationText },
  } = useTranslation(textFiles.GENERAL)

  return (
    <Box>
      <StyledCarousel
        flexDirection={horizontal ? 'row' : 'column'}
        width={width}
        height={height}
        ref={ref}
        onMouseDown={onMouseDown}
      >
        {imageList.map((image) => {
          return (
            <ExtendedAvatar
              height={imageHeight}
              width={imageWidth}
              sx={
                horizontal
                  ? {
                      marginRight: '20px',
                      marginBottom: '20px',
                      width: 'fit-content',
                      height: 'fit-content',
                    }
                  : {
                      marginBottom: '20px',
                      width: 'fit-content',
                      height: 'fit-content',
                    }
              }
              variant="rounded"
              image={image.url}
              isDownloadable={isDownloadable}
              onDelete={
                onDelete
                  ? () => {
                      handleDelete(image.id)
                    }
                  : undefined
              }
              key={image.id}
              isDraggable={false}
              translation={translationText}
            />
          )
        })}
      </StyledCarousel>
    </Box>
  )
}

export default Carousel
