import React from 'react'
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import SpaceBarIcon from '@mui/icons-material/SpaceBar'
import SpeedIcon from '@mui/icons-material/Speed'
import StraightenIcon from '@mui/icons-material/Straighten'

import { NameInformation } from 'models/detail'
import { TrimDetailDataType } from 'models/services/operation/carSettings/detail'

export const getTrimSpacing = (
  metering: Record<string, string>,
  measurement: Record<string, string>,
  information: TrimDetailDataType
): Record<string, NameInformation[]> => {
  const {
    frontLegRoom,
    backLegRoom,
    frontHeadRoom,
    backHeadRoom,
    cargoCapacity,
    cargo,
    length,
    height,
    width,
    doors,
    seats,
    engineDisplacement,
  } = information

  const centimeterUnit = 'cm'

  return {
    spacing: [
      {
        id: 1,
        icon: <AirlineSeatReclineExtraIcon />,
        label: metering.frontLegLabel,
        name: frontLegRoom,
        unit: centimeterUnit,
      },
      {
        id: 2,
        icon: <AirlineSeatReclineExtraIcon />,
        label: metering.backLegLabel,
        name: backLegRoom,
        unit: centimeterUnit,
      },
      {
        id: 3,
        icon: <StraightenIcon />,
        label: metering.frontHeadLabel,
        name: frontHeadRoom,
        unit: centimeterUnit,
      },
      {
        id: 4,
        icon: <StraightenIcon />,
        label: metering.backHeadLabel,
        name: backHeadRoom,
        unit: centimeterUnit,
      },
      {
        id: 5,
        icon: <SpaceBarIcon />,
        label: metering.cargoLabel,
        name: cargoCapacity,
        unit: 'cm³',
      },
      {
        id: 6,
        icon: <DirectionsCarIcon />,
        label: metering.weightLabel,
        name: cargo,
        unit: 'kg',
      },
    ],
    measurement: [
      {
        id: 1,
        icon: <StraightenIcon />,
        label: measurement.lengthLabel,
        name: length,
        unit: centimeterUnit,
      },
      {
        id: 2,
        icon: <StraightenIcon />,
        label: measurement.heightLabel,
        name: height,
        unit: centimeterUnit,
      },
      {
        id: 3,
        icon: <StraightenIcon />,
        label: measurement.widthLabel,
        name: width,
        unit: centimeterUnit,
      },
      {
        id: 4,
        icon: <DirectionsCarIcon />,
        label: measurement.doorLabel,
        name: doors,
      },
      {
        id: 5,
        icon: <AirlineSeatReclineExtraIcon />,
        label: measurement.seatLabel,
        name: seats,
      },
      {
        id: 6,
        icon: <DirectionsCarIcon />,
        label: measurement.engineLabel,
        name: engineDisplacement,
        unit: centimeterUnit,
      },
    ],
  }
}

export const getTrimMileage = (
  torque: Record<string, string>,
  mileage: Record<string, string>,
  milesLabel: string,
  information: TrimDetailDataType
): Record<string, NameInformation[]> => {
  const {
    torque: torqueInfo,
    torqueRpm,
    cylinders,
    horsePower,
    horsePowerRpm,
    fuelCapacity,
    mpg,
    mpgHgw,
    mpgCity,
  } = information

  return {
    torque: [
      {
        id: 1,
        icon: <DirectionsCarIcon />,
        label: torque.torqueLabel,
        name: torqueInfo,
        unit: '(nm)',
      },
      {
        id: 2,
        icon: <DirectionsCarIcon />,
        label: torque.rpmLabel,
        name: torqueRpm,
      },
      {
        id: 3,
        icon: <DirectionsCarIcon />,
        label: torque.cylindersLabel,
        name: cylinders,
      },
      {
        id: 4,
        icon: <DirectionsCarIcon />,
        label: torque.powerLabel,
        name: horsePower,
      },
      {
        id: 5,
        icon: <DirectionsCarIcon />,
        label: torque.powerRpmLabel,
        name: horsePowerRpm,
        unit: 'rpm',
      },
      {
        id: 6,
        icon: <DirectionsCarIcon />,
        label: torque.fuelLabel,
        name: fuelCapacity,
        unit: 'g',
      },
    ],
    mileage: [
      {
        id: 1,
        icon: <SpeedIcon />,
        label: mileage.mileageLabel,
        name: mpg,
        unit: milesLabel,
      },
      {
        id: 2,
        icon: <SpeedIcon />,
        label: mileage.highwayLabel,
        name: mpgHgw,
        unit: milesLabel,
      },
      {
        id: 3,
        icon: <SpeedIcon />,
        label: mileage.cityLabel,
        name: mpgCity,
        unit: milesLabel,
      },
    ],
  }
}
