import React from 'react'
import { Box } from 'curbo-components-library'

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`detail-tabpanel-${index}`}
      aria-labelledby={`detail-tab-${index}`}
      margin="-3% -clamp(25px, 5%, 70px)"
      {...other}
      width="100%"
      height="100%"
    >
      {value === index && <Box height="100%">{children}</Box>}
    </Box>
  )
}

export default TabPanel
