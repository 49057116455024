import React from 'react'
import BuildIcon from '@mui/icons-material/Build'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'

import NameContainer from 'components/Detail/NameContainer'
import ImageBox from 'components/Operation/CarSettings/Common/ImageBox'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/detail'
import { TrimDetailDataType } from 'models/services/operation/carSettings/detail'

import CarImagePlaceholder from 'images/blueDefaultAvatar.png'

import { GridBox, StyledContainer } from 'styles/detail'

type Props = {
  information: TrimDetailDataType
}

const TrimInformation = ({ information }: Props) => {
  const { model, name, year } = information

  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const {
    trim: { information: translation },
  } = text

  const nameInformation: NameInformation[] = [
    {
      id: 0,
      icon: <DirectionsCarIcon />,
      label: translation.brandLabel,
      name: model.brand.name,
    },
    {
      id: 1,
      icon: <DirectionsCarIcon />,
      label: translation.modelLabel,
      name: model.name,
    },
    {
      id: 2,
      icon: <BuildIcon />,
      label: translation.trimLabel,
      name,
    },
    {
      id: 3,
      icon: <CalendarTodayIcon />,
      label: translation.datageLabel,
      name: String(year),
    },
  ]

  return (
    <StyledContainer>
      <GridBox>
        <ImageBox
          height="100%"
          width="100%"
          variant="rounded"
          image={model.brand.icon || CarImagePlaceholder}
          containerSx={{
            height: '363px',
            width: '363px',
            marginTop: '1rem',
            padding: '1rem',
          }}
        />
        <NameContainer
          containerSx={{
            padding: '1rem 0 0 !important',
            maxWidth: '399px',
          }}
          information={nameInformation}
          editDisabled
          description={translation.description}
          title={translation.title}
        />
      </GridBox>
    </StyledContainer>
  )
}

export default TrimInformation
