import React from 'react'
import { TextFieldProps } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { StyledDatePickerContainer, StyledTextField } from './style'

type InputDatePickerProps = {
  date: Date | null
  onChange: (newDate: Date | null) => void
  onAccept?: (value: Date | null) => void
  name?: string
  error?: boolean
}

const InputDatePicker = ({
  date,
  onChange,
  onAccept,
  name,
  error,
}: InputDatePickerProps) => {
  return (
    <StyledDatePickerContainer>
      <DatePicker
        value={date}
        onChange={onChange}
        onAccept={onAccept}
        openTo="day"
        renderInput={(params: TextFieldProps) => (
          <StyledTextField error={error} name={name} {...params} />
        )}
      />
    </StyledDatePickerContainer>
  )
}
export default InputDatePicker
