import { gql } from '@apollo/client'

export const CREATE_ABSENCE = gql`
  mutation generateAbsence($input: CreateAbsenceInput!) {
    createAbsence(input: $input) {
      id
    }
  }
`
export const END_ABSENCE = gql`
  mutation endAbsence($input: ID!) {
    endAbsence(id: $input) {
      id
    }
  }
`

export const UPDATE_ABSENCE = gql`
  mutation updateAbsence($input: UpdateAbsenceInput!) {
    updateAbsence(input: $input) {
      id
    }
  }
`
