import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Container } from '@mui/material'
import {
  CloseButton,
  Modal,
  Toast,
  useNotification,
} from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { REPORT_ISSUE } from 'graphQL/Support/mutations'

import HelpForm from './form'

type ReportIssueData = {
  createSupport: {
    createdAt: string
    email: string
    id: string
    issue: string
    issueOrigin: string
  }
}

type ReportIssueVariables = {
  issueInput: {
    email: string
    issue: string
    issueOrigin: string
  }
}

export type HelpModalProps = {
  /**
   * Should show the modal?
   */
  show: boolean
  /**
   * Function to handle the close function
   */
  handleClose: () => void
  /**
   * Page where the issue was sent
   */
  originPage: string
}

const HelpModal = ({ show, handleClose, originPage }: HelpModalProps) => {
  const [toast, setToast] = useState<boolean>(false)
  const { text } = useTranslation(textFiles.ASK_FOR_HELP)

  const { show: showToast } = useNotification()
  const [descriptionText, setDescriptionText] = useState<string>(
    text.confirmationDescription
  )
  const [createIssue, { loading }] = useMutation<
    ReportIssueData,
    ReportIssueVariables
  >(REPORT_ISSUE, {
    onCompleted({ createSupport }) {
      const newText = descriptionText.replace('%d', createSupport.id)
      setDescriptionText(newText)
      showToast({
        message: newText,
        updatedSeverity: 'success',
      })
      handleClose()
    },
    onError(error) {
      console.error('Mutation Failed', error)
      showToast({
        updatedSeverity: 'error',
      })
    },
  })

  const handleCloseToast = () => {
    setToast(false)
  }

  const handleSubmit = (email: string, issue: string) => {
    createIssue({
      variables: {
        issueInput: {
          email,
          issue,
          issueOrigin: `BackOffice-v3-${originPage}`,
        },
      },
    })
  }

  return (
    <>
      <Modal open={show} handleClose={handleClose} size="large">
        <Container
          sx={{ paddingTop: '75px', paddingX: '95px' }}
          maxWidth={false}
          disableGutters
        >
          <CloseButton handleClose={handleClose} />
          <HelpForm handleSubmit={handleSubmit} loading={loading} />
        </Container>
      </Modal>
      <Toast
        showToast={toast}
        handleClose={handleCloseToast}
        icon={<CheckCircleOutlineIcon />}
        title={text.confirmationTitle}
        description={descriptionText}
      />
    </>
  )
}

export default HelpModal
