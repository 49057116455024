import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Typography } from '@mui/material'
import {
  Autocomplete,
  AutocompleteItem,
  BaseEntity,
  Box,
  colors,
  TooltipContent,
} from 'curbo-components-library'

import { StyledTooltip } from '../style'

export type TemplatePickerTextModel = {
  templateTitle: string
  loadingTemplates: string
  noTemplates: string
  templateInfo: {
    title: string
    completeTitle: string
    completeDescription: string
    partialTitle: string
    partialDescription: string
  }
}

export type TemplatePickerProps = {
  translation: TemplatePickerTextModel
  onChange: (value: AutocompleteItem) => void
  value: BaseEntity | null
  templates: BaseEntity[]
  disabled?: boolean
  loading?: boolean
}

const TemplatePicker = ({
  translation,
  onChange,
  value,
  templates,
  disabled,
  loading,
}: TemplatePickerProps) => {
  const title = translation.templateTitle || 'Inspection template'
  const loadingText = translation.loadingTemplates || 'Loading templates...'
  const { templateInfo } = translation
  const templateStatusList = [
    {
      id: 'comId',
      name: templateInfo.completeTitle,
      description: templateInfo.completeDescription,
      backgroundColor: '#FFFFFF',
      textColor: colors.lightBlue,
    },
    {
      id: 'partId',
      name: templateInfo.partialTitle,
      description: templateInfo.partialDescription,
      backgroundColor: '#FFFFFF',
      textColor: colors.lightBlue,
    },
  ]
  return (
    <Box marginBottom="1em">
      <Box display="flex">
        <Typography variant="body1">{title}*</Typography>
        <StyledTooltip
          title={
            <TooltipContent
              statusList={templateStatusList}
              title={templateInfo.title}
            />
          }
          sx={{ width: '426px', marginLeft: '5px' }}
        >
          <InfoIcon
            style={{
              height: '16px',
              width: '16px',
              marginLeft: 6,
              marginTop: 3,
              color: colors.lightGray,
            }}
          />
        </StyledTooltip>
      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          '& > div:nth-of-type(2)': {
            flexGrow: '2',
            width: '80%',
          },
        }}
      >
        <Autocomplete
          options={templates}
          onChangeHandler={onChange}
          placeholder={!loading ? title : loadingText}
          value={value}
          disabled={disabled || loading}
          noOptionsText={
            translation?.noTemplates || 'No templates for this time or place'
          }
          testId="templates-autocomplete"
        />
      </Box>
    </Box>
  )
}

export default TemplatePicker
