import React, { FunctionComponent, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  InputAdornment,
  InputBaseComponentProps,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import {
  addCommas,
  ButtonNavigator,
  FONT_WEIGHT,
  GenericData,
  GenericInputVariable,
  Option,
  Select,
} from 'curbo-components-library'

import NumberInput, { eventType } from 'components/General/NumberInput'

import {
  initialClientExpectationErrors,
  LEAD_CREATION_OPTION,
} from 'constants/Lead/creation'
import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import {
  BasePrice,
  ClientExpectationInfoError,
  ClientExpectationLeadInformation,
  FetchBasePriceVariables,
  PriceSetting,
} from 'models/services/customerRelationship/lead/creation'
import { LeadCreationProps } from 'utils/Lead/creation'

import {
  FETCH_CAR_BASE_PRICE,
  FETCH_PRICE_SETTING,
} from 'graphQL/CustomerRelationship/Lead/Creation/queries'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const PriceExpInfo = ({
  leadData,
  handleSecondaryBack,
  handleSecondaryContinue,
  expectedTimes,
  updateLeadData,
}: LeadCreationProps) => {
  const appSetting = useSetting()[2]
  const [carBasePrice, setCarBasePrice] = useState<number>(1)
  const [priceSetting, setPriceSetting] = useState<PriceSetting>({
    marketPrice: 1,
    curboPrice: 1,
  })
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''
  const [clientExpInfo, setClientExpInfo] =
    useState<ClientExpectationLeadInformation>(leadData.clientExpInformation!)
  const [errors, setErrors] = useState<ClientExpectationInfoError>(
    initialClientExpectationErrors
  )
  const expectedTimeOptions: Option[] = expectedTimes.map((expectTime) => {
    return {
      name: expectTime.label,
      value: expectTime.id,
    }
  })
  const { text: translation } = useTranslation(textFiles.LEAD_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { clientExpectationStep } = translation

  useQuery<
    GenericData<BasePrice[]>,
    GenericInputVariable<FetchBasePriceVariables>
  >(FETCH_CAR_BASE_PRICE, {
    variables: {
      input: {
        where: {
          carModel: (leadData.carInformation.carModel?.id as string) || '',
          year: Number(leadData.carInformation.carYear?.name || 0),
          trimLevel: (leadData.carInformation.carTrimLevel?.id as string) || '',
        },
      },
    },
    onCompleted(response) {
      if (response.data.length > 0) {
        const carBase = response.data[0].price
        setCarBasePrice(carBase)
      }
    },
  })

  useQuery<GenericData<PriceSetting>>(FETCH_PRICE_SETTING, {
    onCompleted(response) {
      if (response.data) {
        setPriceSetting(response.data)
      }
    },
  })

  const handlePriceChange = (e: eventType) => {
    const { target } = e
    const { value } = target
    if (value && Number(value) > 0) {
      setClientExpInfo((prevInfo) => {
        return {
          ...prevInfo,
          price: Number(value),
        }
      })
    }
  }

  const handleDurationChange = (e: SelectChangeEvent<unknown>) => {
    setClientExpInfo((prevInfo) => {
      return {
        ...prevInfo,
        duration: e.target.value as string,
      }
    })
  }

  const minPrice = carBasePrice * priceSetting.marketPrice
  const maxPrice = carBasePrice * priceSetting.curboPrice
  const handleSubmit = () => {
    const { price } = clientExpInfo
    if (!price) {
      setErrors({
        price: (!price || price < 1) && true,
      })
      return
    }
    setErrors(initialClientExpectationErrors)
    updateLeadData({
      type: LEAD_CREATION_OPTION.UPDATE_CLIENT_EXPECTATION_INFORMATION,
      payload: { ...clientExpInfo, minPrice, maxPrice },
    })
    handleSecondaryContinue()
  }
  return (
    <StyledContainer>
      <StyledForm sx={{ minHeight: '200px' }}>
        <div>
          <StyledTextFieldContainer title={clientExpectationStep.minPrice}>
            <Typography fontWeight={FONT_WEIGHT.MEDIUM} variant="body1">
              {`${addCommas(minPrice)} ${priceCurrency}$`}
            </Typography>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={clientExpectationStep.maxPrice}>
            <Typography fontWeight={FONT_WEIGHT.MEDIUM} variant="body1">
              {`${addCommas(maxPrice)} ${priceCurrency}$`}
            </Typography>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={clientExpectationStep.clientExpectation}
          >
            <StyledTextField
              variant="outlined"
              name="price"
              value={clientExpInfo.price}
              InputProps={{
                inputComponent:
                  NumberInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">USD</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={handlePriceChange}
              error={errors.price}
            />
            {errors.price ? (
              <StyledErrorMessage text={validationText.fieldRequired} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={clientExpectationStep.timeExpectation}
          >
            <Select
              options={expectedTimeOptions}
              onChange={(e) => handleDurationChange(e)}
              label={clientExpectationStep.timeExpectation}
              value={
                expectedTimeOptions.length > 0 ? clientExpInfo.duration : ''
              }
              name="expectedTime"
              sx={{ fontSize: 12, height: 40 }}
            />
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        previousFunction={handleSecondaryBack}
        nextFunction={handleSubmit}
        translation={generalText.buttons}
      />
    </StyledContainer>
  )
}

export default PriceExpInfo
