import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  BaseIdEntity,
  BodyContainerWithHeader,
  createSimpleStepperItems,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
  Option,
  useNotification,
  validateGraphQLErrorCode,
} from 'curbo-components-library'

import { BackContainer } from 'components/Common/BackContainer'

import { DUPLICATE_EMAIL_ERROR } from 'constants/error'
import { GENERAL_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import { USER_CREATION_OPTION } from 'constants/UserManagement/creation'
import useTranslation from 'hooks/useTranslation'
import { CreateOrgUserInput } from 'models/services/General/UserManagement/creation'
import {
  RolesOption,
  RolesOrganizationResponse,
  UserCreationAction,
  UserCreationModel,
} from 'models/userCreation'
import userCreationReducer from 'reducers/User/userCreationReducer'
import { USER_CREATION_STEPS as steps } from 'utils/User/creation'

import {
  GET_ORG_AREAS,
  GET_ORG_ROLES,
} from 'graphQL/UserManagement/Common/queries'
import { CREATE_ORG_USER } from 'graphQL/UserManagement/Creation/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialData: UserCreationModel = {
  userInformation: {
    name: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    address: '',
  },
  userRoles: {
    roles: [],
    options: [],
  },
}

const UserCreation = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [userData, dispatch] = useReducer(userCreationReducer, initialData)
  const [roles, setRoles] = useState<RolesOption[]>([])
  const [areas, setAreas] = useState<Option[]>([])
  const history = useHistory()

  const { text } = useTranslation(textFiles.USER_MANAGEMENT_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const { title, processDescription } = text

  const stepperItems = createSimpleStepperItems(text.stepper)

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const handleGoListing = () => {
    history.push(GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_LISTING)
  }

  const updateUserData = (action: UserCreationAction) => {
    dispatch(action)
  }

  const { loading: rolesLoading } = useQuery<
    GenericData<RolesOrganizationResponse[]>,
    FilterInputVariable
  >(GET_ORG_ROLES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      const transformedRoles: RolesOption[] = response.data.map((res) => {
        return {
          ...res,
          area: res.area.slug,
        } as RolesOption
      })
      setRoles(transformedRoles)
      updateUserData({
        type: USER_CREATION_OPTION.UPDATE_USER_ROLES,
        payload: {
          roles: [{ area: '', role: '' }],
          options: [[...transformedRoles]],
        },
      })
    },
  })

  const { loading: areasLoading } = useQuery<
    GenericData<Option[]>,
    FilterInputVariable
  >(GET_ORG_AREAS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setAreas(response.data)
    },
  })

  const [createUser, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateOrgUserInput>
  >(CREATE_ORG_USER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      handleGoListing()
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_EMAIL_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateEmail,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(async () => {
    const { userInformation, userRoles } = userData

    const { name, phoneNumber, lastName, address, email, password } =
      userInformation

    createUser({
      variables: {
        input: {
          name,
          lastName,
          telephoneNumber: phoneNumber || undefined,
          address: address || undefined,
          email,
          password,
          roles: userRoles.roles.map((role) => role.role),
        },
      },
    })
  }, [createUser, userData])

  if (rolesLoading || areasLoading)
    return <LoadingAnimation showAnimation={rolesLoading || areasLoading} />

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={title}
        subtitle={processDescription}
        stepperItems={stepperItems}
        currentStep={currentStep}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          updateUserData,
          userData,
          handleSubmit,
          submitLoading,
          roleOptions: roles,
          areaOptions: areas,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default UserCreation
