import { gql } from '@apollo/client'

export const LIST_BRANDS = gql`
  query listBrands($input: FilterInput) {
    listData: listBrands(input: $input) {
      count
      data {
        picture: icon
        id
        name
        createdAt
        status
      }
    }
  }
`

export const LIST_MODELS = gql`
  query listModels($input: FilterInput) {
    listData: listModels(input: $input) {
      count
      data {
        brand {
          name
          picture: icon
        }
        name
        createdAt
        id
        status
      }
    }
  }
`

export const LIST_TRIM_LEVELS = gql`
  query listTrimLevels($input: FilterInput) {
    listData: listTrimLevels(input: $input) {
      count
      data {
        id
        name
        model {
          id
          name
          brand {
            name
          }
        }
        features {
          id
          name
        }
        bodyStyle {
          id
          name
        }
        year
        status
        createdAt
      }
    }
  }
`

export const LIST_COUNTRY_VERSIONS = gql`
  query listCountryVersion($input: FilterInput) {
    listData: listCountryVersions(input: $input) {
      count
      data {
        id
        name
        createdAt
        status
      }
    }
  }
`
