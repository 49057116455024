import { gql } from '@apollo/client'

export const GET_DEALER_INSPECTOR_BY_ID = gql`
  query getInspectorPersonal($input: ID!) {
    data: getDealerInspectorById(id: $input) {
      address
      email
      id
      lastName
      name
      profilePicture
      telephoneNumber
      profilePicture
      status {
        backgroundColor
        textColor
        id
        name
      }
    }
  }
`

export const GET_INSPECTOR_ABSENCES = gql`
  query getAbsences($input: FilterInput!) {
    data: getAbsences(input: $input) {
      id
      reason {
        name
        slug
        id
      }
      startDate
      endDate
    }
  }
`

export const GET_ABSENCE_REASONS = gql`
  query listReasons {
    data: getAbsenceReasons {
      id
      name
      slug
    }
  }
`
