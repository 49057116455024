import { inputBaseClasses, selectClasses, styled } from '@mui/material'
import { colors, FONT_WEIGHT, Select } from 'curbo-components-library'

export const StyledSelect = styled(Select)({
  maxWidth: 255,
  border: 'none !important',
  borderRadius: 0,
  borderBottom: 'none',
  svg: {
    color: `${colors.blue} !important`,
  },
  [`.${selectClasses.select}`]: {
    marginLeft: 5,
    marginRight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: FONT_WEIGHT.BOLD,
    color: colors.blue,
    fontSize: '20px',
  },
  [`.${inputBaseClasses.input}`]: {
    paddingLeft: 0,
  },
})
