import { SelectItem } from 'curbo-components-library'

import { InventoryColumnField } from 'constants/Inventory/listing'
import { Car } from 'models/services/inventory/listing'
import { FieldSelectTextType } from 'models/text/InventoryListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: InventoryColumnField.ID,
    },
    {
      label: text.slug,
      value: InventoryColumnField.SLUG,
    },
    {
      label: text.picture,
      value: InventoryColumnField.PICTURE,
    },
    {
      label: text.vehicle,
      value: InventoryColumnField.VEHICLE,
    },
    {
      label: text.trim,
      value: InventoryColumnField.TRIM,
    },
    {
      label: text.price,
      value: InventoryColumnField.PRICE,
    },
    {
      label: text.mileage,
      value: InventoryColumnField.MILEAGE,
    },
    {
      label: text.createdAt,
      value: InventoryColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: InventoryColumnField.STATUS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    slug: text.slug,
    picture: text.picture,
    vehicle: text.vehicle,
    trim: text.trim,
    price: text.price,
    mileage: text.mileage,
    status: text.status,
  }
}

export const defaultFields = [
  InventoryColumnField.PICTURE,
  InventoryColumnField.VEHICLE,
  InventoryColumnField.TRIM,
  InventoryColumnField.PRICE,
  InventoryColumnField.MILEAGE,
  InventoryColumnField.STATUS,
]

export const formatInventories = (cars: Car[]) =>
  cars.map((car) => ({
    id: car.id,
    slug: car.slug,
    createdAt: car.createdAt,
    picture: car.mainPicture,
    vehicle: `${car.brand.name} ${car.carModel.name} ${car.year}`,
    trim: car.trimLevel.slug,
    price: car.listingPrice,
    mileage: car.mileage,
    status: car.status.slug.toLowerCase(),
    year: car.year,
    carModel: car.carModel.id,
    brand: car.brand.id,
    countryVersion: car.countryVersion.id,
    bodyStyle: car.bodyStyle.id,
    categories: [...car.categories],
    transmission: car.transmission.id,
    fuelType: car.fuelType.id,
    driveTrain: car.driveTrain.id,
    features: [...car.features],
    exteriorColor: car.exteriorColor.id,
    interiorColor: car.interiorColor.id,
    trimBox: {
      bodyStyle: car.bodyStyle.name,
      category: car.categories.length > 0 ? car.categories[0].name : '',
      fuelType: car.fuelType.name,
      exteriorColor: car.exteriorColor.hexCode,
      interiorColor: car.interiorColor.hexCode,
      countryVersion: car.countryVersion.name,
    },
  }))
