import React from 'react'
import MailIcon from '@mui/icons-material/Mail'
import { Box, Typography } from '@mui/material'
import {
  colors,
  cypressSubmitButton,
  TextField,
} from 'curbo-components-library'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'

import { StyledErrorMessage, StyledSendButton } from './style'

type HelpModel = {
  email: string
  issue: string
}

type HelpFormProps = {
  /**
   * Function to handle the modal submit function
   */
  handleSubmit: (email: string, issue: string) => void
  /*
   * Loading
   */
  loading: boolean
}

const HelpForm = ({ handleSubmit, loading }: HelpFormProps) => {
  const { text } = useTranslation(textFiles.ASK_FOR_HELP)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { user } = useUser()
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(validationText.invalidEmail)
      .required(validationText.fieldRequired),
    issue: yup
      .string()
      .required(validationText.fieldRequired)
      .min(5, validationText.errorMessageMinCharacters.replace('%d', 5))
      .max(500, validationText.errorMessageMaxCharacters.replace('%d', 500)),
  })

  const formik = useFormik<HelpModel>({
    initialValues: {
      email: user?.email || '',
      issue: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { email, issue } = values
      handleSubmit(email, issue)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h1">{text.issuesTitle}</Typography>
      <Typography sx={{ color: colors.gray }} variant="h6">
        {text.issuesSubtitle}
      </Typography>
      <Box sx={{ marginBottom: '37px' }}>
        <TextField
          type="email"
          id="email"
          name="email"
          value={formik.values.email}
          icon={<MailIcon />}
          sx={{ marginTop: '40px' }}
          fullWidth
          disabled={Boolean(user?.email)}
          placeholder={text.emailPlaceholder}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />
        {formik.touched.email && formik.errors.email ? (
          <StyledErrorMessage text={formik.errors.email} />
        ) : null}
      </Box>
      <Box>
        <TextField
          id="issue"
          name="issue"
          value={formik.values.issue}
          fullWidth
          multiline
          rows={4}
          placeholder={text.issuePlaceholder}
          onChange={formik.handleChange}
          error={formik.touched.issue && Boolean(formik.errors.issue)}
        />
        {formik.touched.issue && formik.errors.issue ? (
          <StyledErrorMessage text={formik.errors.issue} />
        ) : null}
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
        <StyledSendButton
          disabled={loading}
          size="large"
          type="submit"
          data-cy={cypressSubmitButton}
        >
          {text.issueSendButton}
        </StyledSendButton>
      </Box>
    </form>
  )
}
export default HelpForm
