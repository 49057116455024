import { styled } from '@mui/material'
import { Button, ErrorMessage } from 'curbo-components-library'

const StyledErrorMessage = styled(ErrorMessage)({
  alignSelf: 'flex-start',
  position: 'absolute',
})

const StyledSendButton = styled(Button)({
  width: '531px',
})

export { StyledErrorMessage, StyledSendButton }
