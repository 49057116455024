import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import HelpIcon from '@mui/icons-material/Help'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridSortModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid'
import {
  colors,
  ExtendedAvatar,
  ExtendedStatus,
  FONT_WEIGHT,
  formatDateLocalizedTime,
  InspectionStatusMap,
  InspectorStatusMap,
  InventoryStatusMap,
  Language,
  PublicationStatusMap,
  SelectItem,
  Status,
  StatusTag,
  TemplateStatusMap,
  Tooltip,
} from 'curbo-components-library'
import { parseISO } from 'date-fns'

import TooltipContent from 'components/Listing/TooltipContent'

import { StyledButton, StyledTooltip, StyledVerticalButton } from 'styles/table'

export const pageSizes = [10, 25, 50]

export const defaultSortModel: GridSortModel = [
  { field: 'createdAt', sort: 'desc' },
]

export const defaultStatusSortModel: GridSortModel = [
  { field: 'status', sort: 'asc' },
]

export const defaultPreInventorySortModel = {
  field: 'name',
  sort: 'asc',
}

export const selectItems: SelectItem[] = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
]

export const inspectorItems: SelectItem[] = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
]

export type commonColumnsText = {
  table: {
    id: string
    date: string
    actions: string
    delete: string
    status: string
    edit: string
    picture: string
    provider: string
    noDate: string
    make: string
    model: string
    trim: string
    noComment: string
  }
  status: Record<string, string>
}

export const commonListColumns = (
  statusModel:
    | typeof InspectorStatusMap
    | typeof TemplateStatusMap
    | typeof InspectionStatusMap
    | typeof PublicationStatusMap
    | typeof InventoryStatusMap
    | undefined,
  selectedLanguage: Language,
  text: commonColumnsText,
  handleEdit?: (id: GridCellValue) => void,
  handleDelete?: (id: GridRowId) => void,
  statusList?: ExtendedStatus[]
): Record<string, GridColDef> => {
  const availableColumns: Record<string, GridColDef> = {
    id: {
      field: 'id',
      headerName: text.table.id,
      resizable: false,
      sortable: true,
      hide: false,
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip>{params.row.id}</Tooltip>
      ),
    },
    date: {
      field: 'date',
      headerName: text.table.date,
      resizable: false,
      hide: false,
      type: 'date',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = formatDateLocalizedTime(
          params.value as Date,
          'PPP',
          selectedLanguage.code
        )
        return valueFormatted
      },
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip>
          {formatDateLocalizedTime(
            params.value as Date,
            'PPP',
            selectedLanguage.code
          )}
        </Tooltip>
      ),
    },
    isoDate: {
      field: 'date',
      headerName: text.table.date,
      resizable: false,
      hide: false,
      type: 'date',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <Tooltip>
            {formatDateLocalizedTime(
              parseISO(params.value as string),
              'PPP',
              selectedLanguage.code
            )}
          </Tooltip>
        ) : (
          text.table.noDate
        ),
    },
    picture: {
      field: 'picture',
      headerName: text.table.picture,
      resizable: false,
      hide: false,
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <ExtendedAvatar
          image={params.row.picture}
          alt={params.row.inspectorName}
          sx={{
            width: 'fit-content !important',
            height: 'fit-content !important',
          }}
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      ),
    },
    standard: {
      field: 'standard',
      resizable: false,
      hide: false,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    provider: {
      field: 'provider',
      headerName: text.table.provider,
      hide: false,
      resizable: false,
      flex: 3,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip>
          <>
            {params.row.showOrigin ? (
              <span
                style={{
                  color: colors.blue,
                  fontWeight: FONT_WEIGHT.MEDIUM,
                  marginRight: 8,
                }}
              >
                {params.row.origin}
              </span>
            ) : null}

            {params.row.provider}
          </>
        </Tooltip>
      ),
    },
    status: {
      field: 'status',
      headerName: text.table.status,
      hide: false,
      renderHeader: () => (
        <>
          {text.table.status}
          {statusList ? (
            <StyledTooltip
              title={
                <TooltipContent
                  statusList={statusList}
                  // tagSx={statusTagSx}
                />
              }
              sx={{ width: '426px' }}
            >
              <HelpIcon
                style={{
                  height: '12px',
                  width: '12px',
                  marginLeft: 6,
                }}
              />
            </StyledTooltip>
          ) : null}
        </>
      ),
      resizable: false,
      sortable: false,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Status variant={statusModel![String(params.row.status)]}>
            {text.status[String(params.row.status)]}
          </Status>
        )
      },
    },
    statusBackend: {
      field: 'status',
      headerName: text.table.status,
      hide: false,
      renderHeader: () => (
        <>
          {text.table.status}
          {statusList ? (
            <StyledTooltip
              title={
                <TooltipContent
                  statusList={statusList}
                  // tagSx={statusTagSx}
                />
              }
              sx={{ width: '426px' }}
            >
              <HelpIcon
                style={{
                  height: '12px',
                  width: '12px',
                  marginLeft: 6,
                }}
              />
            </StyledTooltip>
          ) : null}
        </>
      ),
      resizable: false,
      sortable: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const { textColor, name, backgroundColor } = params.row.status
        return (
          <StatusTag backgroundColor={backgroundColor} color={textColor}>
            {name}
          </StatusTag>
        )
      },
    },
    actions: {
      field: 'actions',
      type: 'actions',
      headerName: text.table.actions,
      resizable: false,
      sortable: false,
      headerAlign: 'left',
      hide: false,
      width: 200,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => [
        <div
          key={params.row.id}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {handleEdit ? (
            <StyledButton
              style={{ marginRight: '10px' }}
              onClick={(e) => {
                handleEdit(params.row.id)
                e.stopPropagation()
              }}
              data-cy={`edit-${params.row.id}-button`}
            >
              <EditIcon /> {text.table.edit}
            </StyledButton>
          ) : null}
          {handleDelete ? (
            <StyledButton
              onClick={(e) => {
                handleDelete(params.row.id)
                e.stopPropagation()
              }}
              data-cy={`delete-${params.row.id}-button`}
            >
              <DeleteIcon /> {text.table.delete}
            </StyledButton>
          ) : null}
        </div>,
      ],
    },
    verticalActions: {
      field: 'actions',
      type: 'actions',
      headerName: text.table.actions,
      resizable: false,
      sortable: false,
      headerAlign: 'left',
      hide: false,
      width: 140,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => [
        <div
          key={params.row.id}
          style={{
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
            height: 'fit-content',
          }}
        >
          {handleEdit ? (
            <StyledVerticalButton
              onClick={(e) => {
                handleEdit(params.row.id)
                e.stopPropagation()
              }}
              data-cy={`edit-${params.row.id}-button`}
            >
              <EditIcon style={{ paddingRight: '0.5rem' }} />
              {text.table.edit}
            </StyledVerticalButton>
          ) : null}
          {handleDelete && handleEdit && <span style={{ height: '10px' }} />}
          {handleDelete ? (
            <StyledVerticalButton
              onClick={(e) => {
                handleDelete(params.row.id)
                e.stopPropagation()
              }}
              data-cy={`delete-${params.row.id}-button`}
            >
              <DeleteIcon style={{ paddingRight: '0.5rem' }} />{' '}
              {text.table.delete}
            </StyledVerticalButton>
          ) : null}
        </div>,
      ],
    },
  }

  return availableColumns
}
