import React from 'react'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'

import { StyledInputContainer, StyledTextField } from './style'

type MapCoordinatesType = {
  address: Address | undefined
  setAddress: (address: Address | undefined) => void
}

const MapCoordinates = ({ address, setAddress }: MapCoordinatesType) => {
  const { text } = useTranslation(textFiles.GENERAL)
  const translation = text.mapLegend

  const handleLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (address) {
      setAddress({
        id: address?.id,
        lat: Number(e.target.value),
        lng: address?.lng,
        address: address?.address,
        name: address?.name,
        originFromSpot: address?.originFromSpot,
      })
    }
  }

  const handleLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (address) {
      setAddress({
        id: address?.id,
        lat: address?.lat,
        lng: Number(e.target.value),
        address: address?.address,
        name: address?.name,
        originFromSpot: address?.originFromSpot,
      })
    }
  }

  return (
    <StyledInputContainer>
      <StyledTextField
        placeholder={translation.latitude}
        onChange={handleLatitudeChange}
        value={address?.lat}
      />
      <StyledTextField
        placeholder={translation.longitude}
        onChange={handleLongitudeChange}
        value={address?.lng}
      />
    </StyledInputContainer>
  )
}

export default MapCoordinates
