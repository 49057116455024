import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'curbo-components-library'

const StyledCard = styled(Card)({
  backgroundColor: colors.background,
  paddingLeft: 20,
  paddingTop: 28,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '150px',
})

export { StyledCard }
