import React from 'react'
import { SelectChangeEvent, Typography } from '@mui/material'
import { Box, Option } from 'curbo-components-library'

import { formControlStyles, StyledSelect } from './styles'

type SelectCellProps = {
  label: string
  value?: string | number
  endAdornment?: string
  startAdornment?: React.ReactNode
  edit?: boolean
  onChange?: (event: SelectChangeEvent<unknown>) => void
  name?: string
  options: Option[]
  defaultOption: Option
}

const SelectCell = ({
  label,
  value,
  endAdornment,
  edit,
  onChange,
  name,
  options,
  startAdornment,
  defaultOption,
}: SelectCellProps) => {
  const renderOption = (): string => {
    if (options.length === 0) return defaultOption.name
    const findOption = options.find((option) => option.value === value)
    return typeof findOption === 'undefined'
      ? defaultOption.name
      : findOption.name
  }

  return (
    <Box>
      <Typography variant="body1">{label}</Typography>
      {!edit ? (
        <Box alignItems="center" display="flex">
          {startAdornment}
          <Typography variant="body2">{renderOption()}</Typography>
        </Box>
      ) : (
        <StyledSelect
          name={name}
          value={value}
          onChange={onChange}
          endAdornment={endAdornment}
          variant="outlined"
          label={label}
          options={options.length > 0 ? options : [defaultOption]}
          formControlStyles={formControlStyles}
        />
      )}
    </Box>
  )
}

export default SelectCell
