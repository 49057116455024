import { SelectItem } from 'curbo-components-library'

import { BaseColumnField } from 'constants/operation/carSettings/columnField'
import { BrandFieldSelectTextType } from 'models/text/carSettingsListing'

export const createBrandFieldSelectItems = (
  text: BrandFieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.picture,
      value: BaseColumnField.PICTURE,
    },
    {
      label: text.id,
      value: BaseColumnField.ID,
    },
    {
      label: text.name,
      value: BaseColumnField.NAME,
    },
    {
      label: text.createdAt,
      value: BaseColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: BaseColumnField.STATUS,
    },
  ]
}

export const createBrandFieldSelectLabels = (
  text: BrandFieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    picture: text.picture,
    createdAt: text.createdAt,
    status: text.status,
    name: text.name,
  }
}

export const brandDefaultFields = [
  BaseColumnField.ID,
  BaseColumnField.PICTURE,
  BaseColumnField.NAME,
  BaseColumnField.CREATED_AT,
  BaseColumnField.STATUS,
]
