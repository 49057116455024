import { Chip, selectClasses, styled } from '@mui/material'
import { colors, Select } from 'curbo-components-library'

export const StyledSelect = styled(Select)({
  width: '100%',
  height: 'auto',
  minHeight: '29px',
  [`.${selectClasses.select}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px',
  },
})

export const FeatureBox = styled(Chip)({
  backgroundColor: colors.cyan,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-evenly',
  width: 'auto',
  padding: '0 0.5rem',
  height: '27px',
  borderRadius: 4,
  margin: '0 3px 1px 0px',
})
