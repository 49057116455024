import {
  styled,
  Table,
  tableCellClasses,
  TableContainer,
  TableRow,
  tableRowClasses,
} from '@mui/material'
import { colors } from 'curbo-components-library'

export const StyledTableContainer = styled(TableContainer)({
  '& ul': { padding: 0 },
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.blue,
    borderRadius: '5px',
  },
})

export const StyledTable = styled(Table)({
  marginLeft: '5px',
  width: '250px',
  borderSpacing: '0 5px',
  borderCollapse: 'separate',
  [`& .${tableCellClasses.root}`]: {
    borderBottom: 'none',
  },
  [`& .${tableCellClasses.head}`]: {
    backgroundColor: colors.backgroundGray,
  },
})

export const StyledRow = styled(TableRow)({
  [`& .${tableRowClasses.root}`]: {
    td: { borderRadius: '4px' },
  },
})

export const RowBodyCell = {
  backgroundColor: colors.lightWhite,
  boxShadow: 'none',
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '6px',
}
