import { formControlClasses, styled } from '@mui/material'
import { Box, colors, Container } from 'curbo-components-library'

export const ImageBox = styled(Box)({
  backgroundColor: colors.background,
  borderRadius: '10px',
  border: `1px solid ${colors.border}`,
  padding: '11px',
  width: '338px',
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  minWidth: '280px',
  width: '340px !important',
  maxWidth: '98% !important',
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})
