import { SelectItem } from 'curbo-components-library'

import { TrimColumnField } from 'constants/operation/carSettings/columnField'
import { TrimFieldSelectTextType } from 'models/text/carSettingsListing'

export const createTrimFieldSelectItems = (
  text: TrimFieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: TrimColumnField.ID,
    },
    {
      label: text.name,
      value: TrimColumnField.NAME,
    },
    {
      label: text.carName,
      value: TrimColumnField.CAR_NAME,
    },
    {
      label: text.year,
      value: TrimColumnField.YEAR,
    },
    {
      label: text.createdAt,
      value: TrimColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: TrimColumnField.STATUS,
    },
  ]
}

export const createTrimFieldSelectLabels = (
  text: TrimFieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    name: text.name,
    createdAt: text.createdAt,
    status: text.status,
    carName: text.name,
    year: text.year,
  }
}

export const TrimDefaultFields = [
  TrimColumnField.ID,
  TrimColumnField.NAME,
  TrimColumnField.CAR_NAME,
  TrimColumnField.YEAR,
  TrimColumnField.CREATED_AT,
  TrimColumnField.STATUS,
]
