import {
  formControlClasses,
  inputBaseClasses,
  selectClasses,
  styled,
} from '@mui/material'
import {
  Box,
  BREAK_POINTS,
  colors,
  Container,
  FONT_WEIGHT,
  TextField,
} from 'curbo-components-library'

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  width: '100%',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('form')({
  justifyContent: 'flex-start',
  alignItems: 'left',
  width: '100%',
  minHeight: '305px',
  marginBottom: '2rem',
})

export const FormContent = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '1.5rem',
  rowGap: '2rem',
  '> div': {
    width: 300,
  },
})

export const StyledTextFieldContainer = styled(Container)({
  width: '100%',
  padding: 0,
  borderRadius: 0,
  marginBottom: '20px',
  '> p': {
    fontSize: '14px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledTextField = styled(TextField)({
  width: '100% !important',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '12px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '12px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
  },
})

export const RoleContent = styled(Box)({
  width: 800,
  display: 'grid',
  gridTemplateColumns: '45% 45%',
  columnGap: '30px',
  [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
    width: '100%',
  },
})
