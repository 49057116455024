import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { SelectChangeEvent, Typography } from '@mui/material'
import { Box, colors, ErrorMessage, Option } from 'curbo-components-library'

import FeatureList from './FeatureList'
import { FeatureBox, StyledSelect } from './styles'

type FeatureSetCellProps = {
  label: string
  values: string[]
  endAdornment?: string
  edit?: boolean
  onChange?: (event: SelectChangeEvent<unknown>) => void
  onRemove: (e: React.MouseEvent, id: number) => void
  name?: string
  options: Option[]
  defaultOptions: Option[]
  error?: boolean
  errorText?: string
  labelError?: boolean
}

const FeatureSetCell = ({
  label,
  values,
  endAdornment,
  edit,
  onChange,
  name,
  options,
  onRemove,
  defaultOptions,
  error,
  errorText,
  labelError,
}: FeatureSetCellProps) => {
  const renderOption = (value: string): string => {
    if (options.length === 0)
      return defaultOptions.find((option) => option.value === value)!.name
    return options.find((option) => option.value === value)!.name
  }

  return (
    <Box>
      <Typography
        color={labelError || error ? colors.lightRed : colors.black}
        variant="body1"
      >
        {label}
      </Typography>
      {!edit ? (
        <FeatureList values={values} renderOption={renderOption} />
      ) : (
        <>
          <StyledSelect
            name={name}
            value={values}
            multiple
            onChange={onChange}
            endAdornment={endAdornment}
            variant="outlined"
            label={label}
            error={error}
            options={options.length > 0 ? options : defaultOptions}
            formControlStyles={{
              width: '100%',
              height: 'auto',
              minHeight: '29px',
            }}
            renderValue={(selected: unknown) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '5px',
                  width: '100%',
                  minHeight: '23px',
                  overflowY: 'auto',
                }}
              >
                {(selected as string[]).map((item, index) => (
                  <FeatureBox
                    key={item}
                    label={renderOption(item) || item}
                    clickable
                    onDelete={(e: React.MouseEvent) => onRemove(e, index)}
                    deleteIcon={
                      <ClearIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                ))}
              </Box>
            )}
          />
          {error && errorText ? (
            <ErrorMessage
              sx={{
                paddingLeft: '0',
                marginTop: '0.25rem',
                alignItems: 'flex-start',
              }}
              text={errorText}
            />
          ) : null}
        </>
      )}
    </Box>
  )
}

export default FeatureSetCell
