import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ScheduleManagement from 'pages/Operation/ScheduleManagement'

import { OPERATION_SUB_ROUTES } from 'constants/routes'

export const OperationRouter = () => (
  <Switch>
    <Route
      exact
      path={OPERATION_SUB_ROUTES.SCHEDULE_MANAGEMENT}
      component={ScheduleManagement}
    />
  </Switch>
)
