import { gql } from '@apollo/client'

export const GET_SETTING = gql`
  query getSetting {
    data: getSetting {
      website
      country {
        countryCode
        website
        flag
        name
      }
      currency {
        code
        symbol
        name
      }
    }
  }
`

export const GET_BACK_OFFICE_COUNTRY_CONFIG = gql`
  query getBackOfficeCountryConfig {
    data: getBackOfficeCountryConfig {
      countryCode
      website
      flag
      name
    }
  }
`

export const GET_EXTERNAL_HELP_LINKS = gql`
  query getExternalHelpLinks {
    data: getSetting {
      externalHelpLinks {
        name
        url
      }
    }
  }
`
