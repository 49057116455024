import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

import {
  CarSettingsKey,
  carSettingsListingFields,
} from 'constants/operation/carSettings/listing'
import { ERROR_SUBROUTES } from 'constants/routes'

import CarSettingsListingPage from '..'

const CarSettingsListingWrapper = () => {
  const { setting } = useParams<{ setting?: CarSettingsKey }>()
  const routeExists = setting && setting in carSettingsListingFields

  if (!routeExists) return <Redirect to={ERROR_SUBROUTES.NOT_FOUND_ERROR} />

  return <CarSettingsListingPage route={setting} />
}
export default CarSettingsListingWrapper
