import React from 'react'
import {
  autocompleteClasses,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material'
import { colors, FONT_WEIGHT } from 'curbo-components-library'

export const StyledTextField = styled(TextField)({
  [`.${autocompleteClasses.inputRoot}`]: {
    fontSize: '12px',
    color: colors.gray,
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
})

export const StyledLink = styled('a')({
  textDecoration: 'none',

  '&:hover': {
    cursor: 'pointer',
  },
})

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.commonWhite,
    width: 426,
    maxWidth: 500,
  },
}))
