import React from 'react'
import { Dashboard, ManageAccounts } from '@mui/icons-material'
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import SettingsIcon from '@mui/icons-material/Settings'
import { colors } from 'curbo-components-library'

import { SidebarItem } from 'components/General/Sidebar'

import { UserRoles } from 'models/role'

import {
  CAR_SETTINGS_SUB_ROUTES,
  CUSTOMER_RELATIONSHIP_SUB_ROUTES,
  GENERAL_SETTINGS_SUB_ROUTES,
  INSPECTION_SUB_ROUTES,
  INSPECTOR_SUB_ROUTES,
  INVENTORY_SUB_ROUTES,
  OPERATION_SUB_ROUTES,
} from './routes'

export type SidebarMenuTextType = {
  dashboard: string
  inspections: {
    title: string
    listing: string
    inspectors: {
      title: string
      inspectorsListing: string
      calendar: string
    }
  }
  operations: {
    title: string
    dashboard: string
    dealers: string
    spots: string
    outOfSpot: string
    states: string
    cities: string
    carSettings: {
      title: string
      menu: string
      brands: string
      models: string
      version: string
      country: string
    }
    cars: string
    scheduleManagement: string
  }
  generalSettings: {
    title: string
    motives: string
    rejection: string
    answers: string
    hours: string
    userManagement: string
  }
  inventory: {
    title: string
    preInventory: string
  }
  customerRelationship: {
    title: string
    leadsListing: string
    leadsKanbanView: string
  }
}

export const createSideBarItems = (
  text: SidebarMenuTextType
): SidebarItem[] => {
  const {
    inspections,
    inventory,
    operations,
    generalSettings,
    customerRelationship,
  } = text
  return [
    {
      name: inspections.title.toUpperCase(),
      key: 'inspection-module',
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.INSPECTOR_SUPERVISOR,
        UserRoles.INSPECTOR_MANAGER,
      ],
      hideInProduction: false,
      children: [
        {
          icon: <Dashboard fontSize="small" />,
          name: inspections.listing,
          key: 'inspection-dashboard',
          url: INSPECTION_SUB_ROUTES.INSPECTION_LISTING,
        },
        {
          icon: <ManageAccounts fontSize="small" />,
          name: inspections.inspectors.title,
          key: 'inspection-inspectors-1',
          children: [
            {
              name: inspections.inspectors.inspectorsListing,
              key: 'inspection-inspectors-listing-registered',
              url: INSPECTOR_SUB_ROUTES.INSPECTOR_LISTING,
            },
            {
              name: inspections.inspectors.calendar,
              key: 'inspector-calendar',
              url: INSPECTOR_SUB_ROUTES.INSPECTOR_CALENDAR,
            },
          ],
        },
      ],
    },
    {
      name: operations.title.toUpperCase(),
      key: 'operations-module',
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.OPERATION_SPECIALIST,
        UserRoles.OPERATION_SUPERVISOR,
      ],
      hideInProduction: false,
      children: [
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: operations.carSettings.title,
          key: 'operation-carSettings',
          url: CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU,
          allowedRoles: [
            UserRoles.ADMIN,
            UserRoles.OPERATION_SPECIALIST,
            UserRoles.OPERATION_SUPERVISOR,
          ],
          children: [
            {
              name: operations.carSettings.menu,
              key: 'car-menu',
              url: CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU,
            },
            {
              name: operations.carSettings.brands,
              key: 'car-brand-listing',
              url: CAR_SETTINGS_SUB_ROUTES.BRAND_LISTING,
            },
            {
              name: operations.carSettings.models,
              key: 'car-model-listing',
              url: CAR_SETTINGS_SUB_ROUTES.MODEL_LISTING,
            },
            {
              name: operations.carSettings.version,
              key: 'car-trim-listing',
              url: CAR_SETTINGS_SUB_ROUTES.TRIM_LISTING,
            },
            {
              name: operations.carSettings.country,
              key: 'car-country-listing',
              url: CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_LISTING,
            },
          ],
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: operations.scheduleManagement,
          key: 'operation-scheduleManagement',
          url: OPERATION_SUB_ROUTES.SCHEDULE_MANAGEMENT,
          allowedRoles: [
            UserRoles.ADMIN,
            UserRoles.OPERATION_SPECIALIST,
            UserRoles.OPERATION_SUPERVISOR,
          ],
        },
      ],
    },
    {
      name: generalSettings.title.toUpperCase(),
      key: 'general-setting-module',
      allowedRoles: [UserRoles.ADMIN],
      hideInProduction: false,
      children: [
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.motives,
          key: 'absence-motives-1',
          url: GENERAL_SETTINGS_SUB_ROUTES.ABSENCE_MOTIVES,
          allowedRoles: [UserRoles.ADMIN],
          hideInProduction: true,
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.rejection,
          key: 'rejection-motives-1',
          url: GENERAL_SETTINGS_SUB_ROUTES.REJECTION_MOTIVES,
          allowedRoles: [UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR],
          hideInProduction: true,
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.answers,
          key: 'AnswersConfiguration-1',
          url: GENERAL_SETTINGS_SUB_ROUTES.ANSWERS_CONFIGURATION,
          allowedRoles: [UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR],
          hideInProduction: true,
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.hours,
          key: 'hours-1',
          url: GENERAL_SETTINGS_SUB_ROUTES.HOURS_CREATION,
          allowedRoles: [UserRoles.ADMIN, UserRoles.OPERATION_SUPERVISOR],
          hideInProduction: true,
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.userManagement,
          key: 'user-management-listing',
          url: GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_LISTING,
          allowedRoles: [UserRoles.ADMIN],
        },
      ],
    },
    {
      name: inventory.title.toUpperCase(),
      key: 'inventory-module',
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.INVENTORY_SPECIALIST,
        UserRoles.INVENTORY_SUPERVISOR,
        UserRoles.INVENTORY_READER,
      ],
      children: [
        {
          icon: <Dashboard fontSize="small" />,
          name: 'Dashboard',
          key: 'inventory-dashboard',
          hideInProduction: true,
        },
        {
          icon: <FormatListNumberedIcon fontSize="small" />,
          name: inventory.preInventory,
          key: 'pre-iventory-listing',
          url: INVENTORY_SUB_ROUTES.PRE_INVENTORY_LISTING,
        },
        {
          icon: <FormatListBulletedIcon fontSize="small" />,
          name: inventory.title,
          key: 'inventory-listing',
          url: INVENTORY_SUB_ROUTES.INVENTORY_LISTING,
        },
      ],
    },
    {
      name: customerRelationship.title.toUpperCase(),
      key: 'crm-module',
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.CUSTOMER_CARE_READER,
        UserRoles.CUSTOMER_CARE_SPECIALIST,
        UserRoles.CUSTOMER_CARE_SUPERVISOR,
      ],
      children: [
        {
          icon: <FormatListBulletedIcon fontSize="small" />,
          name: customerRelationship.leadsListing,
          key: 'lead-listing',
          url: CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_LISTING,
        },
        {
          icon: <DeveloperBoardIcon fontSize="small" />,
          name: customerRelationship.leadsKanbanView,
          key: 'leads-kanban',
          url: CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_BOARD,
        },
      ],
    },
  ]
}
