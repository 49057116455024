import React from 'react'
import { ButtonNavigator, Slider } from 'curbo-components-library'
import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  maxValue,
  minValue,
} from 'components/Inventory/Common/NumberValueInput'

import { VEHICLE_CREATION_OPTION } from 'constants/PreInventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { SixthStepModel } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/PreInventory/creation'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextFieldContainer,
} from 'styles/creation'

type FormikKeys =
  | 'cylinders'
  | 'fuelCapacity'
  | 'horsePower'
  | 'horsePowerRpm'
  | 'mpg'
  | 'mpgCity'
  | 'mpgHgw'
  | 'torque'
  | 'torqueRpm'

const initialValue: SixthStepModel = {
  cylinders: 1,
  fuelCapacity: 1,
  horsePower: 1,
  horsePowerRpm: 1,
  mpg: 1,
  mpgCity: 1,
  mpgHgw: 1,
  torque: 1,
  torqueRpm: 1,
}

const VehiclePower = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryBack,
  handleSecondaryContinue,
}: VehicleCreationProps) => {
  const {
    text: {
      subSteps: { sixthStep: translation },
      buttons,
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const initialData = vehicleData.vehicleInformation.sixthStep || initialValue

  const validationSchema = yup.object().shape({
    cylinders: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    fuelCapacity: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    horsePower: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    horsePowerRpm: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    mpg: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    mpgCity: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    mpgHgw: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    torque: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    torqueRpm: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
  })

  const formik = useFormik<SixthStepModel>({
    initialValues: initialData,
    validationSchema,
    onSubmit: (values) => {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
        payload: {
          ...vehicleData.vehicleInformation,
          sixthStep: values,
        },
      })
      handleSecondaryContinue()
    },
  })

  const handleSliderChange = (value: number | number[], field: FormikKeys) => {
    formik.setFieldValue(field, Number(value))
  }

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer title={translation.mpgLabel}>
            <Slider
              value={formik.values.mpg}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'mpg')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />
            {formik.touched.mpg && formik.errors.mpg ? (
              <StyledErrorMessage text={formik.errors.mpg} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.cityMpgLabel}>
            <Slider
              value={formik.values.mpgCity}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'mpgCity')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.mpgCity && formik.errors.mpgCity ? (
              <StyledErrorMessage text={formik.errors.mpgCity} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.highwayMpgLabel}>
            <Slider
              value={formik.values.mpgHgw}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'mpgHgw')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />
            {formik.touched.mpgHgw && formik.errors.mpgHgw ? (
              <StyledErrorMessage text={formik.errors.mpgHgw} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.fuelCapacityLabel}>
            <Slider
              value={formik.values.fuelCapacity}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'fuelCapacity')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.fuelCapacity && formik.errors.fuelCapacity ? (
              <StyledErrorMessage text={formik.errors.fuelCapacity} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.cylindersLabel}>
            <Slider
              value={formik.values.cylinders}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'cylinders')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.cylinders && formik.errors.cylinders ? (
              <StyledErrorMessage text={formik.errors.cylinders} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.torqueLabel}>
            <Slider
              value={formik.values.torque}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'torque')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />
            {formik.touched.torque && formik.errors.torque ? (
              <StyledErrorMessage text={formik.errors.torque} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.torqueRpmLabel}>
            <Slider
              value={formik.values.torqueRpm}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'torqueRpm')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.torqueRpm && formik.errors.torqueRpm ? (
              <StyledErrorMessage text={formik.errors.torqueRpm} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.horsePowerLabel}>
            <Slider
              value={formik.values.horsePower}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'horsePower')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.horsePower && formik.errors.horsePower ? (
              <StyledErrorMessage text={formik.errors.horsePower} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.horsePowerRpmLabel}>
            <Slider
              value={formik.values.horsePowerRpm}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'horsePowerRpm')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.horsePowerRpm && formik.errors.horsePowerRpm ? (
              <StyledErrorMessage text={formik.errors.horsePowerRpm} />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        translation={buttons}
        previousFunction={handleSecondaryBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehiclePower
