import {
  BaseEntity,
  ExtendedStatus,
  Option,
  StepperModel,
} from 'curbo-components-library'

import Photos from 'components/Inventory/Creation/Photos'
import Summary from 'components/PreInventory/Creation/Summary'
import GeneralInformation from 'components/PreInventory/Creation/VehicleInformation/GeneralInformation'
import VehicleDetail from 'components/PreInventory/Creation/VehicleInformation/VehicleDetail'
import VehicleFeatures from 'components/PreInventory/Creation/VehicleInformation/VehicleFeatures'
import VehicleMeasurements from 'components/PreInventory/Creation/VehicleInformation/VehicleMeasurements'
import VehiclePower from 'components/PreInventory/Creation/VehicleInformation/VehiclePower'
import VehicleSpacing from 'components/PreInventory/Creation/VehicleInformation/VehicleSpacing'

import {
  InventoryColor,
  VehicleInformationSelectOptions,
} from 'models/services/inventory/detail'
import {
  VehicleCreationAction,
  VehicleCreationDataType,
} from 'models/services/PreInventory/creation'
import { StepperTextType } from 'models/text/General'

export type VehicleCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSecondaryBack: () => void
  handleSecondaryContinue: () => void
  currentSecondaryStep: number
  vehicleData: VehicleCreationDataType
  updateVehicleData: (action: VehicleCreationAction) => void
  brands: BaseEntity[]
  statusList: ExtendedStatus[]
  vehicleSelectOptions: VehicleInformationSelectOptions
  colorList: InventoryColor[]
  countryVersionList: Option[]
}

export const INITIAL_VEHICLE_CREATION_STEPS: Array<
  React.FC<VehicleCreationProps>
> = [
  GeneralInformation,
  VehicleDetail,
  VehicleFeatures,
  VehicleSpacing,
  VehicleMeasurements,
  VehiclePower,
]

export const INITIAL_VEHICLE_MAIN_STEPS: Array<React.FC<VehicleCreationProps>> =
  [GeneralInformation, Photos, Summary]

export const createVehicleStepperItems = (
  text: Partial<StepperTextType>
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep as string,
      hasPercentage: true,
      totalSubSteps: 6,
    },
    { label: text.secondStep as string },
    {
      label: text.thirdStep as string,
    },
  ]

  return stepperItems
}
