import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql'

import { COUNTRY_CODE, GRAPHQL_CLIENT_URI } from 'constants/environment'
import { TOKEN_ERRORS } from 'constants/error'
import { LANGUAGE_KEY } from 'constants/language'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'constants/localStorage'
import { Language } from 'models/language'

import { REFRESH_TOKEN } from 'graphQL/User/mutations'

export type CustomErrorStructure = {
  field: string
  message: string
}

export type CustomGraphQLError = GraphQLError & {
  errors: Array<CustomErrorStructure>
  code: string
}

export const fetchNewToken = async () => {
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY)
  const params = {
    query: REFRESH_TOKEN,
    variables: {
      refreshTokenVars: {
        refreshToken: refreshToken ? JSON.parse(refreshToken) : '',
      },
    },
  }
  const request = await fetch(GRAPHQL_CLIENT_URI, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
  return request.json()
}
/* eslint-disable  consistent-return  */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  // Mostly taken from this post https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
  if (graphQLErrors) {
    const customGraphQLErrors = graphQLErrors as CustomGraphQLError[]
    for (const error of customGraphQLErrors) {
      const { message, locations, path, code } = error
      switch (code) {
        case TOKEN_ERRORS.EXPIRED_TOKEN:
        case TOKEN_ERRORS.MALFORMED_TOKEN:
          if (typeof window !== undefined) {
            window.localStorage.removeItem(ACCESS_TOKEN_KEY)
            window.location.replace(window.location.origin)
          }
          break
        default:
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
      }
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    }
  }
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers, noTranslation }) => {
  // get the authentication token from local storage if it exists
  let languageCode = 'es'
  if (typeof window !== 'undefined') {
    const token = window.localStorage
      .getItem(ACCESS_TOKEN_KEY)
      ?.replace(/"/g, '')
    try {
      languageCode = (
        JSON.parse(window.localStorage.getItem(LANGUAGE_KEY) || '') as Language
      )?.code
    } catch (e) {
      languageCode = 'es'
    }
    // return the headers to the context so httpLink can read them

    // noLanguageCode is a boolean which can be set in the query
    // context to disable translation feature from the backend
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        country: COUNTRY_CODE,
        ...(!noTranslation && { 'content-language': languageCode }),
      },
    }
  }
  return {
    headers: {
      ...headers,
    },
  }
})

const httpLink = new HttpLink({
  uri: GRAPHQL_CLIENT_URI,
})

export const graphqlClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})
