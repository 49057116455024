import React from 'react'
import { ButtonNavigator, Slider } from 'curbo-components-library'
import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  maxValue,
  minValue,
} from 'components/Inventory/Common/NumberValueInput'

import { VEHICLE_CREATION_OPTION } from 'constants/PreInventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { FourthStepModel } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/PreInventory/creation'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextFieldContainer,
} from 'styles/creation'

type FormikKeys =
  | 'frontLegRoom'
  | 'backLegRoom'
  | 'frontHeadRoom'
  | 'backHeadRoom'
  | 'engineDisplacement'
  | 'cargoCapacity'
  | 'cargo'

const initialValue: FourthStepModel = {
  frontLegRoom: 1,
  backLegRoom: 1,
  frontHeadRoom: 1,
  backHeadRoom: 1,
  engineDisplacement: 1,
  cargoCapacity: 1,
  cargo: 1,
}

const VehicleSpacing = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryBack,
  handleSecondaryContinue,
}: VehicleCreationProps) => {
  const {
    text: {
      subSteps: { fourthStep: translation },
      buttons,
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const initialData = vehicleData.vehicleInformation.fourthStep || initialValue

  const validationSchema = yup.object().shape({
    frontLegRoom: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    backLegRoom: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    frontHeadRoom: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    backHeadRoom: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    engineDisplacement: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    cargoCapacity: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    cargo: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
  })

  const formik = useFormik<FourthStepModel>({
    initialValues: initialData,
    validationSchema,
    onSubmit: (values) => {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
        payload: {
          ...vehicleData.vehicleInformation,
          fourthStep: values,
        },
      })
      handleSecondaryContinue()
    },
  })

  const handleSliderChange = (value: number | number[], field: FormikKeys) => {
    formik.setFieldValue(field, Number(value))
  }

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer title={translation.frontLegLabel}>
            <Slider
              value={formik.values.frontLegRoom}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'frontLegRoom')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.frontLegRoom && formik.errors.frontLegRoom ? (
              <StyledErrorMessage text={formik.errors.frontLegRoom} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.backLegLabel}>
            <Slider
              value={formik.values.backLegRoom}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'backLegRoom')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.backLegRoom && formik.errors.backLegRoom ? (
              <StyledErrorMessage text={formik.errors.backLegRoom} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.frontHeadLabel}>
            <Slider
              value={formik.values.frontHeadRoom}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'frontHeadRoom')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.frontHeadRoom && formik.errors.frontHeadRoom ? (
              <StyledErrorMessage text={formik.errors.frontHeadRoom} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.backHeadLabel}>
            <Slider
              value={formik.values.backHeadRoom}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'backHeadRoom')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.backHeadRoom && formik.errors.backHeadRoom ? (
              <StyledErrorMessage text={formik.errors.backHeadRoom} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.engineDisplacementLabel}>
            <Slider
              value={formik.values.engineDisplacement}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'engineDisplacement')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.engineDisplacement &&
            formik.errors.engineDisplacement ? (
              <StyledErrorMessage text={formik.errors.engineDisplacement} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.cargoCapacityLabel}>
            <Slider
              value={formik.values.cargoCapacity}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'cargoCapacity')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.cargoCapacity && formik.errors.cargoCapacity ? (
              <StyledErrorMessage text={formik.errors.cargoCapacity} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.cargoWeightLabel}>
            <Slider
              value={formik.values.cargo}
              handleValueChange={(newValue: number) => {
                handleSliderChange(newValue, 'cargo')
              }}
              maxValue={maxValue}
              minValue={minValue}
              withTextField
            />

            {formik.touched.cargo && formik.errors.cargo ? (
              <StyledErrorMessage text={formik.errors.cargo} />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        translation={buttons}
        previousFunction={handleSecondaryBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehicleSpacing
