import { styled } from '@mui/material/styles'
import { Box, BREAK_POINTS, colors, Container } from 'curbo-components-library'

export const PersonBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '65% 35%',
  columnGap: '27px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '100%',
    columnGap: 0,
  },
})

export const StyledContainer = styled(Container)({
  padding: '0px !important',
})

export const ImageBox = styled(Box)({
  backgroundColor: colors.background,
  borderRadius: '10px',
  border: `1px solid ${colors.border}`,
  padding: '11px',
  width: '338px',
})

export const StyledBox = styled(Box)({
  borderRadius: '10px',
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 1rem',
  width: 'auto',
})

export const contentStyle = {
  padding: '18px',
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
}
