import { styled } from '@mui/material'
import { Box, colors } from 'curbo-components-library'

export const Layout = styled(Box)({
  position: 'relative',
  maxWidth: '1380px',
  width: '100%',
})

export const ContentContainer = styled(Box)({
  maxWidth: '1380px',
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${colors.commonWhite}`,
  borderRadius: '0 0 10px 10px',
  backgroundColor: colors.commonWhite,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '2rem',
})
