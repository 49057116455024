import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'
import {
  Box,
  buildDetailRoute,
  Button,
  CalendarRangeType,
  checkSearchEmptiness,
  cleanFilters,
  cypressAddButton,
  ExtendedStatus,
  FieldFilter,
  Filter,
  FilterByCriteria,
  FilterEntryVariableType,
  FilteringOption,
  FilterInputVariable,
  generateFilterInput,
  GenericData,
  GenericInputVariable,
  getIsoDate,
  InspectionStatusMap,
  ListingFilterType,
  PENDING_STATUS,
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSearch,
  serializeSortModel,
  Table,
  TabObjects,
  Title,
  Tooltip,
  UrlParamNames,
  useNotification,
  verifyParam,
} from 'curbo-components-library'

import DateFilter from 'components/Inspection/Listing/DateFilter'

import { InspectionColumnField, pendingHash } from 'constants/inspection'
import { inventoryStaticFields } from 'constants/Inspection/listing'
import { routes } from 'constants/routes'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { FiltersByCriteriaTranslationType } from 'models/filtersByCriteria'
import { UserRoles } from 'models/role'
import {
  DeleteInspectionResponse,
  DeleteInspectionsResponse,
  ListDealerInspection,
  ListStatus,
} from 'models/services/inspection/listing'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/Inspection/listing'

import {
  DELETE_INSPECTION,
  DELETE_INSPECTIONS,
} from 'graphQL/Inspection/Listing/mutations'
import {
  GET_INSPECTION_MODELS,
  GET_INSPECTION_STATUSES,
  LIST_DEALER_INSPECTIONS,
} from 'graphQL/Inspection/Listing/queries'

import { StyledTextField } from 'styles/inspection/listing'

const registeredInspectionTab = 'registered-inspections'

const InspectionListingPage = () => {
  const { show } = useNotification()
  const location = useLocation()
  const { validateAllowedRoles } = useUser()
  const isSupervisor = validateAllowedRoles([
    UserRoles.ADMIN,
    UserRoles.INSPECTOR_SUPERVISOR,
  ])
  const { search, hash } = location

  const defaultSortedFields = [...defaultFields].sort()

  const [tabValue, setTabValue] = useState<string | number>(
    location.hash === pendingHash ? PENDING_STATUS : registeredInspectionTab
  )

  const [modelsList, setModelsList] = useState<FilteringOption[]>([])
  const [brandsList, setBrandsList] = useState<FilteringOption[]>([])

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )
  const [searchInput, setSearchInput] = useState<string>(searchValue || '')

  const [selectedLanguage] = useLocale()
  const history = useHistory()
  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const { text } = useTranslation(textFiles.INSPECTION_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { text: notificationText } = useTranslation(textFiles.NOTIFICATION)
  const {
    filtersByCriteria,
  }: { filtersByCriteria: FiltersByCriteriaTranslationType } = text

  const { fieldOrEntryOptions: filterOptionText } = filtersByCriteria

  const fieldOrEntryOptions: Record<string, FilterEntryVariableType> = {
    make: {
      name: filterOptionText.make,
      type: 'string',
    },
    model: {
      name: filterOptionText.model,
      type: 'string',
    },
    year: {
      name: filterOptionText.year,
      type: 'string',
    },
    id: {
      name: filterOptionText.id,
      type: 'string',
    },
    status: {
      name: filterOptionText.status,
      type: 'string',
    },
  }

  const [data, setData] = useState<GridRowsProp>([])
  const [inspectionCount, setInspectionCount] = useState<number>(0)

  const [status, setStatus] = useState<ExtendedStatus[]>([])

  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(dateRange.fromDate, 'date'),
      toDate: getIsoDate(dateRange.toDate, 'date'),
    }
  }, [dateRange])

  const {
    data: inspectionData,
    loading: inspectionLoading,
    refetch: refetchInspection,
  } = useQuery<ListDealerInspection, FilterInputVariable>(
    LIST_DEALER_INSPECTIONS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          limit: Number(pageSize),
          start: (page - 1) * Number(pageSize),
          where: {
            text_search: searchValue,
            date_gte: fromDate,
            date_lte: toDate,
            ...(tabValue === PENDING_STATUS
              ? { status_eq: PENDING_STATUS }
              : { status_ne: PENDING_STATUS }),
            ...cleanFilters(filterInput),
          },
          sort:
            sortModel.length > 0 && sortModel[0].sort
              ? {
                  [sortModel[0].field]: sortModel[0].sort,
                }
              : undefined,
        },
      },
      onError() {
        show({
          updatedSeverity: 'error',
        })
      },
    }
  )

  const { data: statusData, loading: statusLoading } = useQuery<
    ListStatus,
    FilterInputVariable
  >(GET_INSPECTION_STATUSES, {
    variables: {
      input: {
        sort: {
          slug: 'asc',
        },
      },
    },
  })

  const [
    getInspectionModels,
    { loading: inspectionModelsLoading, called: inspectionModelsCalled },
  ] = useLazyQuery<GenericData<FilteringOption[]>>(GET_INSPECTION_MODELS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      if (response.data) {
        setModelsList(response.data)
      }
    },
  })

  const [
    getInspectionBrands,
    { loading: inspectionBrandsLoading, called: inspectionBrandsCalled },
  ] = useLazyQuery<GenericData<FilteringOption[]>>(GET_INSPECTION_MODELS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      if (response.data) {
        setBrandsList(response.data)
      }
    },
  })

  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: notificationText.notificationText.deleteFail,
    })
  }

  const handleRefetchInspections = async () => {
    try {
      const response = await refetchInspection()

      if (response) {
        const inspections = response.data.listDealerInspections
        if (inspections.data.length === 0 && inspections.count > 0) {
          setPage(1)
        }
      }
      show({
        updatedSeverity: 'success',
        message: notificationText.notificationText.deleteSuccess,
      })
    } catch {
      handleShowError()
    }
  }

  const [removeInspection] = useMutation<
    DeleteInspectionResponse,
    GenericInputVariable<string>
  >(DELETE_INSPECTION, {
    onCompleted() {
      handleRefetchInspections()
    },
    onError() {
      handleShowError()
    },
  })

  const [removeInspections] = useMutation<
    DeleteInspectionsResponse,
    GenericInputVariable<string[]>
  >(DELETE_INSPECTIONS, {
    onCompleted() {
      handleRefetchInspections()
    },
    onError() {
      handleShowError()
    },
  })

  const deleteInspection = React.useCallback((id: GridRowId) => {
    setDeleteItem(id)
  }, [])

  const editInspection = React.useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.INSPECTION_DETAIL))
    },
    [history]
  )

  const handleCellClick = (params: GridCellParams) => {
    const routed = buildDetailRoute(params.row.id, routes.INSPECTION_DETAIL)
    history.push(routed)
  }

  const createInspection = () => {
    history.push(routes.INSPECTION_CREATION)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleRemoveInspection = async (id: string) => {
    removeInspection({
      variables: {
        input: id,
      },
    })
  }

  const handleRemoveMultipleInspections = async (ids: string[]) => {
    removeInspections({
      variables: {
        input: ids,
      },
    })
  }

  const createColumns = useCallback(
    (statuses?: ExtendedStatus[]) => {
      const {
        id: idColumn,
        statusBackend: statusColumn,
        actions: actionsColumn,
        isoDate: dateColumn,
        standard: standardColumn,
      } = commonListColumns(
        InspectionStatusMap,
        selectedLanguage,
        generalText,
        editInspection,
        isSupervisor ? deleteInspection : undefined,
        statuses
      )

      return [
        { ...idColumn, hide: !selectedFields.includes(idColumn.field) },
        {
          ...dateColumn,
          headerName: text.table.date,
          hide: !selectedFields.includes(dateColumn.field),
        },
        {
          ...dateColumn,
          field: InspectionColumnField.CREATED_AT,
          hide: !selectedFields.includes(InspectionColumnField.CREATED_AT),
          headerName: text.table.createdAt,
        },
        {
          ...standardColumn,
          field: InspectionColumnField.CAR_NAME,
          headerName: text.table.carName,
          hide: !selectedFields.includes(InspectionColumnField.CAR_NAME),
          sortable: false,
          flex: 2,
          renderCell: (params: GridRenderCellParams) => {
            const { year, model } = params.row.car
            const title = `${year} ${model.brand.name} ${model.name}`
            return <Tooltip>{title}</Tooltip>
          },
        },
        {
          ...statusColumn,
          hide: !selectedFields.includes(statusColumn.field),
          sortable: tabValue !== PENDING_STATUS,
        },
        {
          ...actionsColumn,
          hide: !selectedFields.includes(actionsColumn.field),
        },
      ]
    },
    [
      selectedLanguage,
      generalText,
      editInspection,
      deleteInspection,
      selectedFields,
      text.table.date,
      text.table.createdAt,
      text.table.carName,
      tabValue,
      isSupervisor,
    ]
  )

  const [columns, setColumns] = useState<GridColDef[]>(createColumns())

  const tabs: TabObjects[] = [
    {
      id: 1,
      item: {
        label: text.registeredTab,
        value: registeredInspectionTab,
      },
    },
    {
      id: 2,
      item: {
        label: text.incomingTab,
        value: PENDING_STATUS,
      },
    },
  ]

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const submitSearchValue = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  const handleTabChange = (newValue: string | number) => {
    if (newValue === PENDING_STATUS)
      history.replace({ ...location, hash: pendingHash })
    else history.replace({ ...location, hash: '#' })
    setPage(1)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  const getPageCount = () => {
    return Math.ceil(inspectionCount / pageSize)
  }

  const handleFieldEntrySelect = (field: string) => {
    switch (field) {
      case 'make':
        if (!inspectionBrandsCalled) {
          getInspectionBrands()
        }
        break
      case 'model':
        if (!inspectionModelsCalled) {
          getInspectionModels()
        }
        break
      default:
        break
    }
  }

  const filtersLoading = inspectionBrandsLoading || inspectionModelsLoading

  useEffect(() => {
    if (inspectionData) {
      const { count, data: inspData } = inspectionData.listDealerInspections
      setData(
        inspData.map((inspection) => {
          return { ...inspection }
        })
      )
      setInspectionCount(count)
    }
  }, [inspectionData])

  useEffect(() => {
    if (statusData) {
      const formattedStatuses = statusData.getInspectionStatuses.map(
        (currentStatus) => ({
          ...currentStatus,
          slug: currentStatus.slug.toLowerCase().replace('_', '-'),
        })
      )
      setColumns(createColumns(formattedStatuses))
      setStatus(formattedStatuses)
    }
  }, [statusData, createColumns])

  useEffect(() => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = selectedFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }, [createColumns, selectedFields])

  useEffect(() => {
    if (hash && hash === pendingHash) {
      setTabValue(PENDING_STATUS)
    } else {
      setTabValue(registeredInspectionTab)
    }
  }, [hash])

  return (
    <Box width="100%">
      <Title
        icon={<PlaylistAddCheckIcon />}
        handleTabChange={handleTabChange}
        subtitle={`${inspectionCount} ${text.description}`}
        tabs={tabs}
        tabValue={tabValue}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent={
          tabValue === registeredInspectionTab ? 'space-between' : 'flex-end'
        }
        marginTop="2rem"
        width="100%"
      >
        {tabValue === registeredInspectionTab ? (
          <Button
            startIcon={<AddIcon />}
            size="medium"
            onClick={createInspection}
            style={{ minWidth: '160px', marginRight: '20px' }}
            data-cy={cypressAddButton}
          >
            {text.newInspectionButton}
          </Button>
        ) : null}

        <Box display="flex" flex="1 0.8 auto" justifyContent="flex-end">
          <Box marginRight="1rem" flex="0 1 430px">
            <StyledTextField
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterTypes={{
                year: 'number',
              }}
              filtersByCriteriaText={{
                ...filtersByCriteria,
                fieldOrEntryOptions,
              }}
              filterCardText={filtersByCriteria}
              labels={generalText.filterByCriteria}
              filterInput={filterInput}
              statusList={status}
              handleFieldEntrySelect={handleFieldEntrySelect}
              loadingSelect={filtersLoading}
              options={{
                model: modelsList,
                make: brandsList,
              }}
              staticFields={inventoryStaticFields}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
              text={generalText.fieldFilter}
            />
          </Box>
          <Box>
            <DateFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              maxDate={null}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        dateRange={dateRange}
        searchValue={searchValue}
        data={data}
        onCellClick={handleCellClick}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={Number(pageSize)}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={inspectionLoading || statusLoading}
        handleRemoveItem={handleRemoveInspection}
        handleRemoveMultipleItems={handleRemoveMultipleInspections}
        checkboxSelection={isSupervisor}
        hideDownloadModal
        filterCardText={filtersByCriteria}
        downloadModalText={generalText.downloadReport}
        text={generalText.tableText}
      />
    </Box>
  )
}
export default InspectionListingPage
