import { INSPECTION_CREATION_OPTION } from 'constants/Inspection/creation'
import {
  InspectionCreationAction,
  InspectionCreationModel,
} from 'models/inspectionCreation'

const inspectionCreationReducer = (
  state: InspectionCreationModel,
  action: InspectionCreationAction
): InspectionCreationModel => {
  switch (action.type) {
    case INSPECTION_CREATION_OPTION.UPDATE_DEALER_INFORMATION: {
      const { payload } = action

      return {
        ...state,
        dealerInformation: payload,
      }
    }
    case INSPECTION_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION: {
      const { payload } = action

      return { ...state, vehicleInformation: payload }
    }

    case INSPECTION_CREATION_OPTION.UPDATE_SCHEDULING_INFORMATION:
      return { ...state, schedulingInformation: action.payload }

    default:
      return state
  }
}

export default inspectionCreationReducer
