import React, { useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { ClickAwayListener, CSSObject } from '@mui/material'
import MuiPopper from '@mui/material/Popper'
import {
  Box,
  CalendarRangeType,
  DateRangeCalendarTabType,
  ExtendedCalendarRange,
  FilterButton,
} from 'curbo-components-library'

export type CarSettingDateRangeFilterProps = {
  handleChangeDateRange: (newDateRange: CalendarRangeType) => void
  dateRange: CalendarRangeType
  title: string
  maxDate?: Date | null
  sx?: CSSObject
  labelSx?: CSSObject
  calendarTabValue: DateRangeCalendarTabType | boolean
  handleCalendarTabChange: (newValue: DateRangeCalendarTabType) => void
}

/**
 * Specific Date Range Filter for Car Setting Listing, where calendar tabValue is
 * passed as prop, can be moved to Common if it's used somewhere else in the future
 */
const CarSettingDateRangeFilter = ({
  dateRange,
  handleChangeDateRange,
  title,
  maxDate,
  sx,
  labelSx,
  calendarTabValue,
  handleCalendarTabChange,
}: CarSettingDateRangeFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <FilterButton
        id="fields-filter-popper"
        onClick={handleClick}
        text={title}
        icon={<DateRangeIcon fontSize="small" />}
        sx={sx}
        labelSx={labelSx}
      />
      <MuiPopper
        id="fields-filter-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box marginTop="0.5rem">
            <ExtendedCalendarRange
              tabValue={calendarTabValue}
              handleTabChange={handleCalendarTabChange}
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              extendedFields
              maxDate={maxDate}
            />
          </Box>
        </ClickAwayListener>
      </MuiPopper>
    </>
  )
}
export default CarSettingDateRangeFilter
