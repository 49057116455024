import React from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  addCommas,
  BaseIdEntity,
  Box,
  ColorBox,
  formatDateLocalizedTime,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
  SummaryItem,
  useNotification,
} from 'curbo-components-library'

import Skeleton from 'components/Common/Skeleton'
import Collapse from 'components/Inventory/Common/Collapse'

import { CUSTOMER_RELATIONSHIP_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import {
  CreateSmcInput,
  CreateTestDriveInput,
  CTAEnum,
} from 'models/services/customerRelationship/lead/creation'
import {
  LeadCreationProps,
  leadToSmcTransformer,
  leadToTdTransformer,
} from 'utils/Lead/creation'

import {
  CREATE_SELL,
  CREATE_TEST_DRIVE,
} from 'graphQL/CustomerRelationship/Lead/Creation/mutations'

import { elementStyleWithoutPadding, SectionContainer } from 'styles/creation'

const SummaryInfo = ({
  handleBack,
  leadData,
  expectedTimes,
}: LeadCreationProps) => {
  const { text } = useTranslation(textFiles.LEAD_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const translation = text.summary
  const history = useHistory()
  const { show } = useNotification()
  const [selectedLanguage] = useLocale()
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''
  const {
    generalInformation,
    carInformation,
    appointmentInformation,
    clientExpInformation,
    vehicleInformation,
  } = leadData

  const isSmcCta = generalInformation.cta === CTAEnum.SellMyCar
  const {
    carBrand,
    carModel,
    carYear,
    carTrimLevel,
    budget,
    carMileage,
    exteriorColor,
    interiorColor,
  } = carInformation
  const inspectionTime =
    appointmentInformation && appointmentInformation.inspectionDay
      ? `${appointmentInformation.inspectionDay.name} ${appointmentInformation.meridiam}`
      : ''

  const [createTD, { loading: submitLoading }] = useMutation<
    GenericData<{ testDrive: BaseIdEntity }>,
    GenericInputVariable<CreateTestDriveInput>
  >(CREATE_TEST_DRIVE, {
    onError() {
      show({
        message: translation.leadFail,
        updatedSeverity: 'error',
      })
    },
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.leadSuccess,
      })
      history.push(CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_LISTING)
    },
  })

  const [createSmc, { loading: mutationLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateSmcInput>
  >(CREATE_SELL, {
    onError() {
      show({
        message: translation.leadFail,
        updatedSeverity: 'error',
      })
    },
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.leadSuccess,
      })
      history.push(CUSTOMER_RELATIONSHIP_SUB_ROUTES.LEAD_LISTING)
    },
  })

  const isLoading = mutationLoading || submitLoading
  const handleSubmit = () => {
    if (isSmcCta) {
      const transformedLead = leadToSmcTransformer(leadData)
      createSmc({
        variables: {
          input: {
            ...transformedLead,
          },
        },
      })
    } else {
      const transformedLead = leadToTdTransformer(leadData)
      createTD({
        variables: {
          input: {
            ...transformedLead,
          },
        },
      })
    }
  }
  const expectedTimeLabel: string =
    (clientExpInformation &&
      clientExpInformation.duration &&
      expectedTimes.filter(
        (expTime) => expTime.id === clientExpInformation.duration
      )[0].label) ||
    ''
  return (
    <>
      <LoadingAnimation
        showAnimation={isLoading}
        styles={{
          zIndex: 2,
          position: 'absolute',
          width: 'unset',
          height: 'unset',
        }}
      />
      <Box
        sx={{
          opacity: isLoading ? '0.5' : 'unset',
          alignSelf: 'flex-start',
        }}
      >
        <Skeleton
          title={`${translation.title} ${
            text.callToActions[generalInformation.cta]
          }`}
          description={translation.description}
          previousFunction={handleBack}
          nextFunction={handleSubmit}
          gridContainerStyles={{
            gridTemplateColumns: '100% !important',
            width: '100%',
          }}
          submitLoading={isLoading}
          translation={generalText.buttons}
        >
          <Collapse
            defaultExpanded
            title={translation.clientInformation}
            sx={{ margin: '0px!important' }}
          >
            <SectionContainer>
              <SummaryItem
                title={translation.name}
                text={generalInformation.name}
                width="auto"
                elementStyle={elementStyleWithoutPadding}
              />
              <SummaryItem
                title={translation.lastName}
                text={generalInformation.lastName}
                width="auto"
                elementStyle={elementStyleWithoutPadding}
              />
              <SummaryItem
                title={translation.phoneNumber}
                text={
                  <NumberFormat
                    value={generalInformation.phoneNumber}
                    format="+1 (###) ### ####"
                    isNumericString
                    displayType="text"
                  />
                }
                width="auto"
                elementStyle={elementStyleWithoutPadding}
              />
              <SummaryItem
                title={translation.email}
                text={generalInformation.email}
                width="auto"
                elementStyle={elementStyleWithoutPadding}
              />
            </SectionContainer>
          </Collapse>
          {carInformation && carInformation.carBrand ? (
            <Collapse
              title={translation.vehicleInformation}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer>
                {carBrand && (
                  <SummaryItem
                    title={translation.make}
                    text={carBrand.name}
                    width="auto"
                    elementStyle={elementStyleWithoutPadding}
                  />
                )}
                {carModel && (
                  <SummaryItem
                    title={translation.model}
                    text={carModel.name}
                    width="auto"
                    elementStyle={elementStyleWithoutPadding}
                  />
                )}
                {carYear && (
                  <SummaryItem
                    title={translation.year}
                    text={carYear.name}
                    width="auto"
                    elementStyle={elementStyleWithoutPadding}
                  />
                )}
                {carTrimLevel && (
                  <SummaryItem
                    title={translation.trimName}
                    text={carTrimLevel.name}
                    width="auto"
                    elementStyle={elementStyleWithoutPadding}
                  />
                )}
                {carMileage && carMileage > 0 ? (
                  <SummaryItem
                    title={translation.mileage}
                    text={`${addCommas(carMileage)} ${translation.milesUnit}`}
                    width="auto"
                    elementStyle={elementStyleWithoutPadding}
                  />
                ) : null}
                {exteriorColor && (
                  <SummaryItem
                    title={translation.exteriorColor}
                    text={exteriorColor.name}
                    icon={
                      <ColorBox
                        height={18}
                        width={18}
                        hexCode={exteriorColor.hexCode}
                        styles={{ marginRight: '0.5rem' }}
                      />
                    }
                    width="auto"
                    elementStyle={elementStyleWithoutPadding}
                  />
                )}
                {interiorColor && (
                  <SummaryItem
                    title={translation.interiorColor}
                    text={interiorColor.name}
                    icon={
                      <ColorBox
                        height={18}
                        width={18}
                        hexCode={interiorColor.hexCode}
                        styles={{ marginRight: '0.5rem' }}
                      />
                    }
                    width="auto"
                    elementStyle={elementStyleWithoutPadding}
                  />
                )}
                {budget && budget > 0 && (
                  <SummaryItem
                    title={translation.budget}
                    text={`${addCommas(budget)} ${priceCurrency}$`}
                    width="auto"
                    elementStyle={elementStyleWithoutPadding}
                  />
                )}
              </SectionContainer>
            </Collapse>
          ) : null}
          {vehicleInformation &&
          vehicleInformation.brand &&
          vehicleInformation.brand.length > 0 ? (
            <Collapse
              title={translation.vehicleInformation}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer>
                <SummaryItem
                  title={translation.make}
                  text={vehicleInformation.brand}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
                <SummaryItem
                  title={translation.model}
                  text={vehicleInformation.model}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
                <SummaryItem
                  title={translation.year}
                  text={vehicleInformation.year}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
                <SummaryItem
                  title={translation.trimName}
                  text={vehicleInformation.trim}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
              </SectionContainer>
            </Collapse>
          ) : null}
          {clientExpInformation && clientExpInformation.price > 0 ? (
            <Collapse
              title={translation.saleExpectation}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer>
                <SummaryItem
                  title={translation.minPrice}
                  text={`${addCommas(
                    clientExpInformation.minPrice || 0
                  )} ${priceCurrency}$`}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
                <SummaryItem
                  title={translation.maxPrice}
                  text={`${addCommas(
                    clientExpInformation.maxPrice || 0
                  )} ${priceCurrency}$`}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
                <SummaryItem
                  title={translation.clientExpectation}
                  text={`${addCommas(
                    clientExpInformation.price
                  )} ${priceCurrency}$`}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
                <SummaryItem
                  title={translation.timeExpectation}
                  text={expectedTimeLabel}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
              </SectionContainer>
            </Collapse>
          ) : null}
          {appointmentInformation &&
          appointmentInformation.address !== undefined ? (
            <Collapse
              title={translation.appointmentInformation}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer>
                <SummaryItem
                  title={translation.location}
                  text={appointmentInformation.address?.address || ''}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
                <SummaryItem
                  title={translation.day}
                  text={formatDateLocalizedTime(
                    appointmentInformation.appointmentDate || new Date(),
                    'P',
                    selectedLanguage.code
                  )}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
                <SummaryItem
                  title={translation.time}
                  text={inspectionTime}
                  width="auto"
                  elementStyle={elementStyleWithoutPadding}
                />
              </SectionContainer>
            </Collapse>
          ) : null}
        </Skeleton>
      </Box>
    </>
  )
}

export default SummaryInfo
