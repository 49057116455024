import { DAY_ENUM } from 'constants/date'
import { meridiamOptions } from 'constants/inspection'
import {
  AppointmentLeadInformation,
  CarInfoError,
  CarLeadInformation,
  ClientExpectationInfoError,
  ClientExpectationLeadInformation,
  CTAEnum,
  GeneralLeadInformation,
  VehicleInformationModel,
} from 'models/services/customerRelationship/lead/creation'

export enum LEAD_CREATION_OPTION {
  UPDATE_GENERAL_INFORMATION = 'updateGeneralInformation',
  UPDATE_CAR_INFORMATION = 'updateCarInformation',
  UPDATE_APPOINTMENT_INFORMATION = 'updateAppointmentInfomration',
  UPDATE_CLIENT_EXPECTATION_INFORMATION = 'updateClientExpectationInformation',
  UPDATE_ACTION_INFORMATION = 'updateActionInformation',
}

export const initialGeneralInformation: GeneralLeadInformation = {
  email: '',
  name: '',
  lastName: '',
  phoneNumber: '',
  cta: CTAEnum.TestDrive,
}

export const initialCarInformation: CarLeadInformation = {
  carBrand: null,
  carModel: null,
  carYear: null,
  carTrimLevel: null,
  budget: 0,
  currency: 'USD',
  carMileage: 0,
  exteriorColor: null,
  interiorColor: null,
  currentModelList: [],
  currentYearList: [],
  currentTrimLevelList: [],
  id: 0,
  licensePlate: '',
}

export const initialVehicleInformation: VehicleInformationModel = {
  idOrSlug: '',
  brand: '',
  mainPicture: '',
  model: '',
  year: 0,
  trim: '',
}

export const initialCarErrors: CarInfoError = {
  brand: false,
  model: false,
  year: false,
  budget: false,
  exteriorColor: false,
  interiorColor: false,
}

export const initialAppointmentInformation: AppointmentLeadInformation = {
  address: undefined,
  appointmentDate: null,
  meridiam: meridiamOptions[0].value,
  time: '',
  weekCalendar: null,
  currentDayKey: DAY_ENUM.MONDAY,
}

export const initialClientExpectationInformation: ClientExpectationLeadInformation =
  {
    duration: '',
    price: 0,
    minPrice: 0,
    maxPrice: 0,
  }

export const initialClientExpectationErrors: ClientExpectationInfoError = {
  price: false,
}
