import { styled, Typography } from '@mui/material'
import {
  Box,
  BREAK_POINTS,
  colors,
  Container,
  FONT_WEIGHT,
} from 'curbo-components-library'

export const StyledBrandImg = styled('img')({
  alignSelf: 'center',
  objectFit: 'contain',
  height: '194px',
  width: '194px',
})

export const StyledDataText = styled(Typography)({
  color: colors.black,
  fontWeight: FONT_WEIGHT.BOLD,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})

export const StyledDataTitle = styled(Typography)({
  color: colors.gray,
  fontWeight: FONT_WEIGHT.MEDIUM,
})

export const StyledContainer = styled(Container)({
  height: '100%',
  padding: '0',
  width: '350px',
})

export const ImgContainer = styled(Container)({
  height: '100%',
  padding: '0',
  width: '350px',
  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    width: '450px',
  },
})

export const VehicleBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '33% 66%',
  columnGap: '40px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '100%',
    columnGap: 0,
    rowGap: '20px',
  },
})

export const InformationBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  '> div:first-of-type': {
    marginRight: '4rem',
  },
})

export const StyledButton = styled('label')({
  width: '30px',
  height: '30px',
  position: 'relative',
  display: 'flex',
  right: '10%',
  top: '90%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    color: colors.gray,
  },
})
