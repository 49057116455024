import { gql } from '@apollo/client'

export const GET_ORGANIZATION_USER_BY_ID = gql`
  query getOrganizationUserById($input: ID!) {
    data: getOrganizationUserById(id: $input) {
      profilePicture
      id
      name
      lastName
      email
      telephoneNumber
      address
      roles {
        area {
          name
          slug
        }
        value: id
        name
        slug
      }
      status {
        id
        name
        description
        slug
        backgroundColor
        textColor
      }
    }
  }
`

export const LIST_ORGANIZATION_USERS_COUNT = gql`
  query listOrganizationUsers {
    data: listOrganizationUsers {
      count
    }
  }
`

export const FETCH_EMPLOYEE_STATUSES = gql`
  query fetchEmployeeStatus {
    data: getEmployeeStatuses {
      id
      name
      description
      slug
      backgroundColor
      textColor
    }
  }
`
