import { useContext } from 'react'

import { LocaleContext } from 'context/LocaleContext'
import { Language } from 'models/language'

/**
 * Returns current language and a function to update it
 */
export const useLocale = (): [
  Language,
  (selectedLanguage: Language) => void
] => {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider')
  }

  const { selectedLanguage, updateLanguage } = context

  return [selectedLanguage, updateLanguage]
}

export default useLocale
