import { BaseEntity } from 'curbo-components-library'

import { DAY_ENUM } from 'constants/date'
import { LEAD_CREATION_OPTION } from 'constants/Lead/creation'
import { Address } from 'models/map'
import { Brand, Color } from 'models/services/car'
import { HourTimeType, InspectionWeekCalendar } from 'models/services/curboSpot'

// import { Address } from '../../map'
// import { Brand, Color } from '../car'
// import { HourTimeType, InspectionWeekCalendar } from '../curboSpot'

// Will be added accordingly to the different flows addition
// export enum CTAEnum {
//   TestDrive = 'testDrive',
//   FindMyCar = 'findMyCar',
//   SellMyCar = 'sellMyCar',
//   Prequalify = 'prequalify',
//   Financing = 'financing',
//   PayCash = 'payCash',
//   Maintenance = 'maintenance',
// }

export enum CTAEnum {
  TestDrive = 'testDrive',
  SellMyCar = 'sellMyCar',
}

export type GeneralLeadInformation = {
  email: string
  name: string
  lastName: string
  phoneNumber: string
  cta: CTAEnum
  [key: string]: string | CTAEnum
}

export type CarLeadInformation = {
  carBrand: Brand | null
  carModel: BaseEntity | null
  carYear: BaseEntity | null
  carTrimLevel: BaseEntity | null
  budget?: number
  currency?: string
  carMileage?: number
  exteriorColor?: Color | null
  interiorColor?: Color | null
  licensePlate?: string
  currentModelList: BaseEntity[]
  currentYearList: BaseEntity[]
  currentTrimLevelList: BaseEntity[]
  id: number
}

export type AppointmentLeadInformation = {
  time: string
  meridiam: string
  address: Address | undefined
  appointmentDate: Date | null
  inspectionDay?: HourTimeType | null
  weekCalendar: InspectionWeekCalendar | null
  currentDayKey: DAY_ENUM
}

export type CarInfoError = {
  brand: boolean
  model: boolean
  year: boolean
  budget?: boolean
  exteriorColor?: boolean
  interiorColor?: boolean
  licensePlate?: boolean
}

export type ClientExpectationLeadInformation = {
  duration: string
  price: number
  minPrice?: number
  maxPrice?: number
}

export type VehicleInformationModel = {
  idOrSlug: string
  brand: string
  mainPicture: string
  model: string
  year: number
  trim: string
}

export type ClientExpectationInfoError = {
  price: boolean
}

export type LeadCreationModel = {
  generalInformation: GeneralLeadInformation
  carInformation: CarLeadInformation
  appointmentInformation?: AppointmentLeadInformation
  clientExpInformation?: ClientExpectationLeadInformation
  vehicleInformation?: VehicleInformationModel
}

export type LeadCreationAction =
  | {
      type: LEAD_CREATION_OPTION.UPDATE_GENERAL_INFORMATION
      payload: GeneralLeadInformation
    }
  | {
      type: LEAD_CREATION_OPTION.UPDATE_CAR_INFORMATION
      payload: CarLeadInformation
    }
  | {
      type: LEAD_CREATION_OPTION.UPDATE_APPOINTMENT_INFORMATION
      payload: AppointmentLeadInformation
    }
  | {
      type: LEAD_CREATION_OPTION.UPDATE_CLIENT_EXPECTATION_INFORMATION
      payload: ClientExpectationLeadInformation
    }
  | {
      type: LEAD_CREATION_OPTION.UPDATE_ACTION_INFORMATION
      payload: VehicleInformationModel
    }

export type SearchUserResultType = {
  id: string
  email: string
  name: string
  lastName: string
  telephoneNumber: string
  [key: string]: string
}

export type SearchUserVariable = {
  where: {
    // // eslint-disable-next-line camelcase
    email: string
  }
}

export type FetchBasePriceVariables = {
  where: {
    carModel: string
    year: number
    trimLevel?: string
  }
}

export type TimeType = 'week' | 'month' | 'days'

export type ExpectedTimes = {
  id: string
  days: number
  label: string
  timeType: TimeType
}

export type BasePrice = {
  id: string
  price: number
}

export type PriceSetting = {
  marketPrice: number
  curboPrice: number
}

export type CreateSmcInput = {
  address: string
  carModel: string
  curboSpot?: string
  currencyCode: string
  date: string
  email: string
  expectedTime: string
  exteriorColor: string
  inspectionHour: string
  interiorColor: string
  lastName: string
  latitude: number
  licensePlate: string
  longitude: number
  name: string
  origin: string
  price: number
  telephoneNumber: string
  trimLevel?: string
  year: number
}

export enum CallToActionOriginEnum {
  MOBILE = 'MOBILE',
  WEB = 'WEB',
}

export type CreateTestDriveInput = {
  address?: string
  car: string
  curboSpot?: string
  date: string | null
  email: string
  lastName?: string
  latitude?: number
  longitude?: number
  name: string
  origin?: CallToActionOriginEnum
  telephoneNumber: string
  testDriveHours?: string
}
