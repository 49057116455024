import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material'
import {
  Box,
  ButtonNavigator,
  DatePicker,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
  TimePicker,
  validateGraphQLErrorCode,
} from 'curbo-components-library'
import { getDay, getHours, getMinutes, isToday } from 'date-fns'

import LocationSection from 'components/Common/Location'

import { DAY_ENUM, emptyWeekCalendar, weekDay } from 'constants/date'
import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import { meridiamOptions } from 'constants/inspection'
import { LEAD_CREATION_OPTION } from 'constants/Lead/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { Address } from 'models/map'
import { InspectionWeekCalendar } from 'models/services/curboSpot'
import { CTAEnum } from 'models/services/customerRelationship/lead/creation'
import { getDisabledDayNumbers } from 'utils/calendarUtils'
import { LeadCreationProps } from 'utils/Lead/creation'

import { GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID } from 'graphQL/Common/Dealer/queries'
import { GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID } from 'graphQL/Operations/OutOfSpot/queries'

import { StyledErrorMessage } from 'styles/creation'

import { StyledBox, StyledContainer, StyledContent } from './style'

export type SchedulingError = {
  calendar: boolean
  time: boolean
  address: boolean
}

const initialErrors: SchedulingError = {
  address: false,
  calendar: false,
  time: false,
}

const AppointmentInfo = ({
  handleSecondaryBack,
  handleSecondaryContinue,
  leadData,
  updateLeadData,
  curboSpots,
}: LeadCreationProps) => {
  const { userDealer } = useUser()
  const { appointmentInformation } = leadData

  const userSpot = userDealer?.curboSpot
  const [errors, setErrors] = useState<SchedulingError>(initialErrors)
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(
    appointmentInformation?.appointmentDate || new Date()
  )
  const [time, setTime] = useState<string>(appointmentInformation?.time || '')
  const [meridiam, setMeridiam] = useState<string>(
    appointmentInformation?.meridiam || 'AM'
  )
  const [address, setAddress] = useState<Address | undefined>(
    appointmentInformation?.address
  )
  const [dateKey, setDateKey] = useState<DAY_ENUM>(
    appointmentInformation?.currentDayKey || DAY_ENUM.MONDAY
  )
  const [disabledDays, setDisabledDays] = useState<number[]>([])
  const [weekCalendarData, setWeekCalendarData] =
    useState<InspectionWeekCalendar | null>(
      appointmentInformation?.weekCalendar || null
    )

  const { text } = useTranslation(textFiles.LEAD_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { appointmentStep: translation } = text
  const isSmc = leadData.generalInformation.cta === CTAEnum.SellMyCar
  useQuery<
    GenericData<InspectionWeekCalendar>,
    GenericInputVariable<string | null>
  >(
    isSmc
      ? GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID
      : GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID,
    {
      variables: {
        input:
          address && address.originFromSpot
            ? address.id
            : userDealer?.curboSpot?.id || null,
      },
      onCompleted(response) {
        const responseWeekCalendar = { ...response.data }
        setWeekCalendarData(responseWeekCalendar)
      },
      onError(error) {
        const { errorExists } = validateGraphQLErrorCode(
          error,
          ENTITY_NOT_FOUND_ERROR
        )

        if (errorExists) {
          setWeekCalendarData(emptyWeekCalendar)
        }
      },
    }
  )

  const handleSchedulingDateChange = (date: Date | null) => {
    if (date) {
      const dateNumber = getDay(date)
      const dayKey = weekDay[dateNumber]
      setDateKey(dayKey)
      const storedDate = new Date(date.setHours(0, 0, 0, 0))
      setAppointmentDate(storedDate)
    }
    setTime('')
  }

  const handleHourChange = (event: SelectChangeEvent<unknown>) => {
    const selectedHour = event.target.value as string
    setTime(selectedHour)
  }
  const handleMeridiamChange = (event: SelectChangeEvent<unknown>) => {
    const selectedMeridiam = event.target.value as string
    setMeridiam(selectedMeridiam)
    setTime('')
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    setAppointmentDate(null)
    setTime('')
  }
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const handleSubmit = () => {
    if (!address || !appointmentDate || !time) {
      setErrors({
        address: !address && true,
        calendar: !appointmentDate && true,
        time: !time && true,
      })
    } else {
      setErrors(initialErrors)
      updateLeadData({
        type: LEAD_CREATION_OPTION.UPDATE_APPOINTMENT_INFORMATION,
        payload: {
          address,
          appointmentDate,
          meridiam,
          time,
          inspectionDay: weekCalendarData
            ? weekCalendarData[dateKey].find(
                (weekCalendar) => weekCalendar.value === time
              )
            : null,
          weekCalendar: weekCalendarData,
          currentDayKey: dateKey,
        },
      })
      handleSecondaryContinue()
    }
  }

  useEffect(() => {
    if (weekCalendarData) {
      // here we are trying to get the number of the days that doesn't have any schedule
      // to disable them in the calendar
      const disabledDayNumbers: number[] =
        getDisabledDayNumbers(weekCalendarData)
      setDisabledDays(disabledDayNumbers)
    }
  }, [weekCalendarData])

  const curHour = getHours(new Date()) * 100
  const curMinutes = getMinutes(new Date())
  const curContinentalTime = curHour + curMinutes
  const isSameDay = appointmentDate && isToday(appointmentDate)
  return (
    <StyledBox>
      {!weekCalendarData ? (
        <LoadingAnimation showAnimation={!weekCalendarData} />
      ) : (
        <StyledContent>
          <StyledContainer>
            <LocationSection
              translation={translation}
              address={address}
              showError={errors.address}
              mapReadOnly
              handleAddressChange={handleAddressChange}
              containerStyling={{
                width: 'inherit',
                maxWidth: '600px',
                height: '600px',
                marginBottom: '1em',
                paddingRight: '3rem',
              }}
              circleCenter={{
                lat: address?.lat || userSpot?.lat || 0,
                lng: address?.lng || userSpot?.lng || 0,
              }}
              curboSpots={curboSpots}
            />
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              columnGap="3rem"
              justifyContent="left"
              paddingRight="1rem"
            >
              <Box>
                <DatePicker
                  value={appointmentDate}
                  translation={translation}
                  onChange={handleSchedulingDateChange}
                  disabled={!address}
                  shouldDisableDate={(disabledDate) =>
                    disabledDays.includes(disabledDate.getDay())
                  }
                  startSameDay
                />
                {errors.calendar && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
                <TimePicker
                  translation={translation}
                  hourOptions={weekCalendarData[dateKey]
                    .filter((day) => (meridiam === 'AM' ? day.am : !day.am))
                    .filter((hour) => {
                      if (!isSameDay) {
                        return true
                      }
                      const { continentalTime } = hour
                      return continentalTime! > curContinentalTime
                    })}
                  hour={time}
                  hourChange={handleHourChange}
                  meridiamOptions={meridiamOptions}
                  meridiam={meridiam}
                  meridiamChange={handleMeridiamChange}
                  disabled={!appointmentDate}
                />
                {errors.time && (
                  <StyledErrorMessage
                    sx={{ marginBottom: '0.5rem' }}
                    text={validationText.fieldRequired}
                  />
                )}
              </Box>
            </Box>
          </StyledContainer>
          <ButtonNavigator
            previousFunction={handleSecondaryBack}
            nextFunction={handleSubmit}
            confirmButtonType="submit"
            translation={generalText.buttons}
          />
        </StyledContent>
      )}
    </StyledBox>
  )
}

export default AppointmentInfo
