import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  InputAdornment,
  InputBaseComponentProps,
  Typography,
} from '@mui/material'
import {
  Autocomplete,
  AutocompleteItem,
  BaseEntity,
  ButtonNavigator,
  ColorBox,
  FilterInput,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  Slider,
} from 'curbo-components-library'

import NumberInput, { eventType } from 'components/General/NumberInput'

import { maxMileage } from 'constants/car'
import {
  initialErrors,
  initialVehicleInfo,
  INSPECTION_CREATION_OPTION,
} from 'constants/Inspection/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { VehicleInfoError, VehicleInformationModel } from 'models/inspection'
import { Color, ModelTrimYears } from 'models/services/car'
import { InspectionCreationProps } from 'utils/Inspection/creation'

import { GET_INSPECTION_MODELS } from 'graphQL/Common/CarFeature/queries'
import {
  GET_INSPECTION_TRIM_LEVELS,
  GET_INSPECTION_TRIM_YEARS,
} from 'graphQL/Inspection/Creation/queries'

import {
  BoxContainer,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const VehicleInformation = ({
  inspectionData,
  handleBack,
  brands,
  updateInspectionData,
  colorList,
  handleContinue,
}: InspectionCreationProps) => {
  const [vehicleInfo, setVehicleInfo] =
    useState<VehicleInformationModel>(initialVehicleInfo)

  const [models, setModels] = useState<BaseEntity[]>(
    vehicleInfo.currentModelList
  )
  const [years, setYears] = useState<BaseEntity[]>(vehicleInfo.currentYearList)

  const [errors, setErrors] = useState<VehicleInfoError>(initialErrors)

  const { text: translation } = useTranslation(textFiles.INSPECTION_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { vehicleInformation } = translation
  const [trimLevelsList, setTrimLevelsList] = useState<BaseEntity[]>(
    vehicleInfo.currentTrimLevelList
  )
  const [fetchModels, { loading: modelsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    GenericInputVariable<FilterInput>
  >(GET_INSPECTION_MODELS, {
    onCompleted(response) {
      setModels(response.data)
    },
  })

  const [fetchYears, { loading: yearsLoading }] = useLazyQuery<
    GenericData<ModelTrimYears>,
    GenericInputVariable<string>
  >(GET_INSPECTION_TRIM_YEARS, {
    onCompleted(response) {
      setYears(
        response.data.trimYears
          .sort((trimYearA, trimYearB) => trimYearB - trimYearA)
          .map((trimYear) => {
            return {
              name: String(trimYear),
              id: trimYear,
            }
          })
      )
    },
  })

  const [fetchTrims, { loading: trimsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    FilterInputVariable
  >(GET_INSPECTION_TRIM_LEVELS, {
    onCompleted(response) {
      setTrimLevelsList(response.data)
    },
  })

  const handleSelectBrand = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleBrand: value,
        vehicleModel: null,
        vehicleYear: null,
        vehicleTrimLevel: null,
      }
    })
    fetchModels({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            brand_eq: value.id,
          },
        },
      },
    })
  }

  const handlePriceChange = (e: eventType) => {
    const { target } = e
    const { value } = target
    if (value && Number(value) > 0) {
      setVehicleInfo((prevInfo) => {
        return {
          ...prevInfo,
          price: Number(value),
        }
      })
    }
  }

  const handleSelectModel = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleModel: value,
        vehicleYear: null,
        vehicleTrimLevel: null,
      }
    })
    fetchYears({
      variables: {
        input: value.id as string,
      },
    })
  }

  const handleSelectYear = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleYear: value,
        vehicleTrimLevel: null,
      }
    })
    fetchTrims({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            year: value.id,
            carModel: vehicleInfo.vehicleModel?.id,
          },
        },
      },
    })
  }

  const handleSliderChange = (newValue: number) => {
    setVehicleInfo({ ...vehicleInfo, vehicleMileage: newValue as number })
  }

  const handleSelectTrim = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleTrimLevel: value,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        trim: false,
      }
    })
  }

  const handleSubmit = () => {
    const {
      vehicleBrand,
      vehicleModel,
      vehicleYear,
      exteriorColor,
      interiorColor,
      price,
    } = vehicleInfo

    if (
      !vehicleBrand ||
      !vehicleModel ||
      !vehicleYear ||
      !exteriorColor ||
      !interiorColor ||
      !price
    ) {
      setErrors({
        brand: !vehicleBrand && true,
        model: !vehicleModel && true,
        year: !vehicleYear && true,
        exteriorColor: !exteriorColor && true,
        interiorColor: !interiorColor && true,
        price: (!price || price < 1) && true,
      })
      return
    }
    setErrors(initialErrors)
    updateInspectionData({
      type: INSPECTION_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
      payload: vehicleInfo,
      index: 0,
    })
    handleContinue()
  }

  useEffect(() => {
    if (typeof inspectionData.vehicleInformation === 'undefined') {
      setVehicleInfo(initialVehicleInfo)
    } else {
      const { currentModelList, currentYearList } =
        inspectionData.vehicleInformation
      setVehicleInfo(inspectionData.vehicleInformation)
      setModels(currentModelList)
      setYears(currentYearList)
    }
  }, [inspectionData.vehicleInformation])

  return (
    <StyledContainer>
      <StyledForm sx={{ minHeight: '200px' }}>
        <div>
          <StyledTextFieldContainer title={vehicleInformation.vehicleBrand}>
            <Autocomplete
              options={brands}
              onChangeHandler={handleSelectBrand}
              placeholder={vehicleInformation.vehicleBrand}
              value={vehicleInfo.vehicleBrand}
              disablePortal={false}
              testId="brands-autocomplete"
            />
            {errors.brand && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleModel}>
            <Autocomplete
              options={models}
              onChangeHandler={handleSelectModel}
              placeholder={vehicleInformation.vehicleModel}
              value={vehicleInfo.vehicleModel}
              disabled={modelsLoading || !vehicleInfo.vehicleBrand}
              disablePortal={false}
              testId="models-autocomplete"
            />
            {errors.model && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleYear}>
            <Autocomplete
              options={years}
              onChangeHandler={handleSelectYear}
              placeholder={vehicleInformation.vehicleYear}
              value={vehicleInfo.vehicleYear}
              disabled={yearsLoading || !vehicleInfo.vehicleModel}
              disablePortal={false}
              testId="years-autocomplete"
            />
            {errors.year && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={vehicleInformation.vehicleTrimLevel}>
            <Autocomplete
              options={trimLevelsList}
              onChangeHandler={handleSelectTrim}
              placeholder={vehicleInformation.vehicleTrimLevel}
              value={vehicleInfo.vehicleTrimLevel}
              disabled={trimsLoading || !vehicleInfo.vehicleYear}
              disablePortal={false}
              testId="trims-autocomplete"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleMileage}>
            <Slider
              value={vehicleInfo.vehicleMileage}
              handleValueChange={handleSliderChange}
              maxValue={maxMileage}
              withTextField
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.exteriorColor}>
            <BoxContainer>
              <Autocomplete
                options={colorList}
                onChangeHandler={(value) => {
                  setVehicleInfo((prevInfo) => {
                    return {
                      ...prevInfo,
                      exteriorColor: value as Color,
                    }
                  })
                }}
                placeholder={vehicleInformation.exteriorColor}
                value={vehicleInfo.exteriorColor}
                disablePortal={false}
                testId="exterior-colors-autocomplete"
                width="75%"
              />
              <ColorBox
                hexCode={vehicleInfo.exteriorColor?.hexCode as string}
                width="20%"
                height={38}
              />
            </BoxContainer>
            {errors.exteriorColor && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.interiorColor}>
            <BoxContainer>
              <Autocomplete
                options={colorList}
                onChangeHandler={(value) => {
                  setVehicleInfo((prevInfo) => {
                    return {
                      ...prevInfo,
                      interiorColor: value as Color,
                    }
                  })
                }}
                placeholder={vehicleInformation.interiorColor}
                value={vehicleInfo.interiorColor}
                disablePortal={false}
                testId="interior-colors-autocomplete"
                width="75%"
              />
              <ColorBox
                hexCode={vehicleInfo.interiorColor?.hexCode as string}
                width="20%"
                height={38}
              />
            </BoxContainer>
            {errors.interiorColor && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehiclePrice}>
            <StyledTextField
              variant="outlined"
              name="price"
              value={vehicleInfo.price}
              InputProps={{
                inputComponent:
                  NumberInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">
                      {vehicleInfo.currency}
                    </Typography>
                  </InputAdornment>
                ),
              }}
              onChange={handlePriceChange}
              error={errors.price}
            />
            {errors.price ? (
              <StyledErrorMessage text={validationText.fieldRequired} />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        previousFunction={handleBack}
        nextFunction={handleSubmit}
        translation={generalText.buttons}
      />
    </StyledContainer>
  )
}

export default VehicleInformation
