import { gql } from '@apollo/client'

export const GET_DEALER_CASE_BOARD = gql`
  query getDealerCaseBoard($input: FilterCaseBoardInput!) {
    listData: getDealerCaseBoard(input: $input) {
      count
      cursor
      data {
        id
        name
        slug
        count
        backgroundColor
        textColor
        caseData: data {
          caseId
          id
          slug
          type # cta
          origin #source
          estimatedValue #estimated Value
          customer {
            name
            lastName
            telephoneNumber
          }
          car {
            title
          }
          customerCareRepresentative {
            name
            lastName
            id
          }
        }
      }
    }
  }
`
