import { styled } from '@mui/material'
import { Box, colors } from 'curbo-components-library'

export const Divider = styled('span')({
  display: 'inline-block',
  borderBottom: `1px solid ${colors.border}`,
  lineHeight: 0,
  width: '100%',
})

export const RepresentativeNumberBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  width: '95%',
})
