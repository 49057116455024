import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Box,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material'
import {
  CalendarRangeType,
  colors,
  FilterInputVariable,
  formatDateLocalizedTime,
  GenericData,
  getIsoDate,
  LoadingAnimation,
  useNotification,
  WeeklyRangeCalendarTabType,
} from 'curbo-components-library'
import { addDays, getDate } from 'date-fns'

import { TableNative } from 'components/Common/TableNative'

import { inspectorItems } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { Inspector } from 'models/calendar'
import { DistributionCalendarWeek } from 'models/services/inspector'

import { GET_INSPECTORS_CALENDAR } from 'graphQL/Inspector/Calendar/queries'

import { CalendarTableBody } from './CalendarTableBody/CalendarTableBody'
import { Footer } from './Footer'
import { ButtonsBox, StyledRowBody, StyledRowHeader } from './style'
import WeeklyRangeFilter from './WeeklyRangeFilter'

type DayCellProps = { day: Date }
const DayCell = ({ day }: DayCellProps) => {
  const [currentLanguage] = useLocale()
  return (
    <TableCell align="center" sx={{ maxWidth: '120px', minWidth: '120px' }}>
      <Typography variant="subtitle1" color={colors.gray}>
        {formatDateLocalizedTime(
          day,
          'eeee',
          currentLanguage.code
        ).toUpperCase()}
      </Typography>
      <Typography variant="h3">{getDate(day)}</Typography>
    </TableCell>
  )
}

const DistributionCalendar = () => {
  const { show } = useNotification()

  const { text: translation } = useTranslation(textFiles.INSPECTOR_CALENDAR)
  const { general } = translation
  const { dateRange: rangeText } = general

  const [dateRange, setDateRange] = useState<CalendarRangeType>({
    fromDate: new Date(),
    toDate: addDays(new Date(), 6),
  })
  const [data, setData] = useState<Inspector[]>([])

  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    Number(inspectorItems[0].value)
  )
  const [calendarTabValue, setCalendarTabValue] = useState<
    WeeklyRangeCalendarTabType | boolean
  >(WeeklyRangeCalendarTabType.THIS_WEEK)

  const fromDate = useMemo(() => {
    return getIsoDate(dateRange.fromDate)
  }, [dateRange.fromDate])

  const firstDay = dateRange.fromDate!

  const dayInNumbers = [
    { name: 'day1', date: firstDay },
    { name: 'day2', date: addDays(firstDay, 1) },
    { name: 'day3', date: addDays(firstDay, 2) },
    { name: 'day4', date: addDays(firstDay, 3) },
    { name: 'day5', date: addDays(firstDay, 4) },
    { name: 'day6', date: addDays(firstDay, 5) },
    { name: 'day7', date: addDays(firstDay, 6) },
  ]

  const { loading: calendarLoading } = useQuery<
    GenericData<DistributionCalendarWeek>,
    FilterInputVariable
  >(GET_INSPECTORS_CALENDAR, {
    variables: {
      input: {
        where: { from: fromDate! },
      },
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
    onCompleted(response) {
      if (response) {
        setData(
          response.data.inspectors.map((row) => {
            return {
              data: row.tags.map((tag) => {
                return {
                  isAbsent: tag.isAbsent,
                  data: tag.info,
                  date: new Date(tag.date),
                }
              }),
              id: row.id,
              name: row.name,
              profilePicture: row.profilePicture,
            }
          })
        )
      }
    },
  })

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(event.target.value as number)
  }

  const handleCalendarTabChange = (newValue: WeeklyRangeCalendarTabType) => {
    setCalendarTabValue(newValue)
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  useEffect(() => {
    setPage(1)
  }, [data])

  return (
    <Box width="100%" display="grid">
      <TableNative
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          height: '645px',
        }}
        tableProps={{
          stickyHeader: true,
          size: 'small',
          sx: {
            marginLeft: '0px !important',
            marginRight: '0px !important',
            borderSpacing: '0px',
            width: '100%',
            height: calendarLoading ? '100%' : undefined,
          },
        }}
        maxHeight="645px"
      >
        <TableHead sx={{ overflow: 'auto' }}>
          <StyledRowHeader>
            <TableCell
              align="center"
              sx={{
                paddingX: 'auto',
                paddingY: 'auto',
                width: '304px',
                maxWidth: '304px',
                minWidth: '304px',
                overflow: 'hidden',
                position:
                  data.length === 0 || calendarLoading ? 'inherit' : 'absolute',
                top: 'auto',
                zIndex: '3',
              }}
            >
              <ButtonsBox>
                <WeeklyRangeFilter
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  title={rangeText}
                  sx={{
                    boxShadow: 'none',
                    border: 'none',
                    width: '100%',
                  }}
                  labelSx={{
                    fontSize: '13px',
                    marginY: 'auto',
                    marginX: 'auto',
                  }}
                  calendarTabValue={calendarTabValue}
                  handleCalendarTabChange={handleCalendarTabChange}
                />
              </ButtonsBox>
            </TableCell>
            {dayInNumbers.map((day) => (
              <DayCell key={`day_${day.date}`} day={day.date} />
            ))}
          </StyledRowHeader>
        </TableHead>
        {calendarLoading ? (
          <TableBody>
            <StyledRowBody>
              <TableCell colSpan={8}>
                <LoadingAnimation showAnimation={calendarLoading} />
              </TableCell>
            </StyledRowBody>
          </TableBody>
        ) : (
          <CalendarTableBody
            filteredData={data}
            rowsPerPage={rowsPerPage}
            page={page}
            dayInNumbers={dayInNumbers}
          />
        )}
      </TableNative>

      {calendarLoading || data.length === 0 ? null : (
        <Footer
          currentPage={page}
          rowLabelText={general.entriesLabel}
          selectItems={inspectorItems}
          pageSize={rowsPerPage}
          pageCount={Math.ceil(data.length / rowsPerPage)}
          onPageChange={handleChangePage}
          onSelectChange={handleChangeRowsPerPage}
          entries={data.length}
          dateRange={dateRange}
          buttonLabel={general.download}
          hideDownloadButton
        />
      )}
    </Box>
  )
}

export default DistributionCalendar
