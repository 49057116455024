import { gql } from '@apollo/client'

export const GET_INVENTORY_CAR_BY_ID = gql`
  query getInventoryCarById($input: ID!) {
    data: getDealerInventoryCarById(id: $input) {
      id
      slug
      brand {
        name
        icon
      }
      carModel {
        name
      }
      trimLevel {
        name
      }
      year
      countryVersion {
        value: id
        name
      }
      mainPicture
      categories {
        value: id
        name
      }
      exteriorColor {
        value: id
        name
        hexCode
      }
      interiorColor {
        value: id
        name
        hexCode
      }
      transmission {
        name
        value: id
      }
      fuelType {
        name
        value: id
      }
      driveTrain {
        name
        value: id
      }
      features {
        name
        value: id
      }
      bodyStyle {
        name
        value: id
      }
      interiorPictures
      exteriorPictures
      mileage
      vinNumber
      chassisNumber
      licensePlate
      frontLegRoom
      backLegRoom
      frontHeadRoom
      backHeadRoom
      engineDisplacement
      cargoCapacity
      cargo
      length
      width
      seats
      doors
      mpg
      mpgCity
      mpgHgw
      fuelCapacity
      cylinders
      torque
      torqueRpm
      horsePower
      horsePowerRpm
      publishedAt
      status {
        backgroundColor
        textColor
        slug
        name
        description
      }
      priceInfo {
        basePrice
      }
    }
  }
`

export const GET_COLORS = gql`
  query getColors($input: FilterInput) {
    data: getColors(input: $input) {
      value: id
      hexCode
      name
    }
  }
`
