import { parseISO } from 'date-fns'

import { LocalAbsenceType } from 'components/Inspector/Absence'

/**
 * Returns a dateRange from an absence input
 */
export const getRangeFromAbsence = (absence: LocalAbsenceType) => {
  const range = {
    fromDate: parseISO(absence.startDate),
    toDate: parseISO(absence.endDate),
  }
  return range
}

/**
 * Returns a date if value given is a ISO string otherwise
 * returns null
 */
export const parseIsoStringToDate = (date: string | null | undefined) => {
  if (!date) return null

  return parseISO(date)
}
