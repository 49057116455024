import { SelectItem } from 'curbo-components-library'

import { UserManagementColumnField } from 'constants/UserManagement/listing'
import { FieldSelectTextType } from 'models/text/userManagementListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.picture,
      value: UserManagementColumnField.PICTURE,
    },
    {
      label: text.id,
      value: UserManagementColumnField.ID,
    },
    {
      label: text.name,
      value: UserManagementColumnField.NAME,
    },
    {
      label: text.email,
      value: UserManagementColumnField.EMAIL,
    },
    {
      label: text.phone,
      value: UserManagementColumnField.PHONE,
    },
    {
      label: text.address,
      value: UserManagementColumnField.ADDRESS,
    },
    {
      label: text.roles,
      value: UserManagementColumnField.ROLES,
    },
    {
      label: text.actions,
      value: UserManagementColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    picture: text.picture,
    id: text.id,
    name: text.name,
    email: text.email,
    address: text.address,
    roles: text.roles,
    actions: text.actions,
  }
}

export const defaultFields = [
  UserManagementColumnField.PICTURE,
  UserManagementColumnField.ID,
  UserManagementColumnField.NAME,
  UserManagementColumnField.EMAIL,
  UserManagementColumnField.PHONE,
  UserManagementColumnField.ADDRESS,
  UserManagementColumnField.ROLES,
  UserManagementColumnField.ACTIONS,
]
