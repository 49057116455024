import { IconButton, styled } from '@mui/material'
import Chip from '@mui/material/Chip'
import {
  Box,
  boxShadow,
  Button,
  colors,
  FONT_WEIGHT,
  Select,
} from 'curbo-components-library'

export const StyledBox = styled(Box)({
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  textAlign: 'left',
  minHeight: '150px',
  height: 'fit-content',
  padding: '18px',
  width: 'auto',
})

export const StyledContainer = styled(Box)({
  color: colors.black,
  backgroundColor: colors.background,
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  justifyContent: 'flex-start',
  borderRadius: '10px',
  padding: '0 30px',
  minHeight: '150px',
  height: '100%',
})

export const StyledIcon = styled(IconButton)({
  backgroundColor: colors.commonWhite,
  borderRadius: '4px',
  textAlign: 'left',
  height: '25px',
  width: '25px',
  alignSelf: 'flex-end',
  position: 'absolute',
  cursor: 'pointer',
  top: 10,
  right: 10,
  boxShadow,
})

export const FeatureBox = styled(Chip)({
  backgroundColor: colors.cyan,
  color: colors.darkBlue,
  fontWeight: FONT_WEIGHT.MEDIUM,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-evenly',
  width: 'auto',
  padding: '0 0.5rem',
  height: '27px',
  borderRadius: 4,
  margin: '0',
})

export const AddButton = styled(Button)({
  width: '15%',
  height: '38px !important',
})

export const StyledSelect = styled(Select)({
  height: 'unset',
  minHeight: 40,
  maxHeight: 75,
  '> div': {
    overflow: 'auto !important',
    maxHeight: 60,
  },
})
