import { gql } from '@apollo/client'

export const CREATE_TEST_DRIVE = gql`
  mutation createTestDrive($input: CreateTestDriveInput!) {
    data: createTestDrive(input: $input) {
      testDrive {
        id
      }
    }
  }
`

export const CREATE_SELL = gql`
  mutation createSellMyCar($input: CreateSellMyCarInput!) {
    data: createSellMyCar(input: $input) {
      id
    }
  }
`
