import { Box, styled, TableRow } from '@mui/material'
import { colors } from 'curbo-components-library'

import { TableBodyRow } from 'components/Common/TableNative'

export const StyledRowHeader = styled(TableRow)({
  th: {
    borderBottom: `1px ${colors.background} solid !important`,
    borderRight: `1px ${colors.background} solid !important`,
    backgroundColor: 'white !important',
    height: '67px',
  },
  'th:last-child': {
    borderRight: 'none !important',
  },
})
export const StyledRowBody = styled(TableBodyRow)({
  td: {
    borderBottom: `1px ${colors.background} solid !important`,
    borderRight: `1px ${colors.background} solid !important`,
    borderRadius: '0 !important',
  },
  'td:first-of-type': {
    position: 'sticky',
    left: '0px',
    background: colors.commonWhite,
    width: '304px',
  },
  'td:last-child': {
    borderRight: 'none !important',
  },
})

export const ButtonsBox = styled(Box)({
  display: 'flex',
  padding: '10px',
  backgroundColor: colors.background,
  borderRadius: '10px',
})
