import React from 'react'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import BuildIcon from '@mui/icons-material/Build'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import { Option } from 'curbo-components-library'

import FeatureContainer from 'components/Detail/FeatureContainer'
import NameContainer from 'components/Detail/NameContainer'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/detail'
import { TrimDetailDataType } from 'models/services/operation/carSettings/detail'

import { StyledContainer } from 'styles/detail'

import { SettingBox } from './style'

type Props = {
  information: TrimDetailDataType
  featuresOptions: Option[]
}

const TrimSettings = ({ information, featuresOptions }: Props) => {
  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const { carSettings: translation } = text
  const { settings: settingsText } = translation

  const { features, bodyStyle, transmission, driveTrain, fuelType } =
    information

  const nameInformation: NameInformation[] = [
    {
      id: 1,
      icon: <BuildIcon />,
      label: settingsText.transmissionLabel,
      name: transmission.name,
    },
    {
      id: 2,
      icon: <AirportShuttleIcon />,
      label: settingsText.bodyStyleLabel,
      name: bodyStyle.name,
    },
    {
      id: 3,
      icon: <CarRepairIcon />,
      label: settingsText.driveTrainLabel,
      name: driveTrain.name,
    },
    {
      id: 4,
      icon: <LocalGasStationIcon />,
      label: settingsText.fuelTypeLabel,
      name: fuelType.name,
    },
  ]

  const featuresList: string[] = features.map(
    (feature) => feature.value as string
  )

  return (
    <StyledContainer>
      <SettingBox>
        <NameContainer
          title={translation.settings.title}
          description={translation.settings.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={nameInformation}
          editDisabled
        />
        <FeatureContainer
          title={translation.featureSet.title}
          description={translation.featureSet.description}
          height="fit-content"
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            width: '771px',
            height: 'fit-content',
          }}
          information={featuresList}
          options={featuresOptions}
          editDisabled
        />
      </SettingBox>
    </StyledContainer>
  )
}

export default TrimSettings
