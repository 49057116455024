import React, { useEffect, useState } from 'react'
import { Box, colors, ExtendedAvatar } from 'curbo-components-library'

import {
  ImgContainer,
  VehicleBox,
} from 'components/Inventory/Detail/VehicleSummary/style'

import { CaseTypeEnum } from 'constants/Lead/listings'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  DetailCar,
  LeadDetail,
  SimpleCar,
} from 'models/services/customerRelationship/lead/detail'

import placeholderCar from 'images/blueDefaultAvatar.png'

import CarDataContent from './CarDataContent'
import SimpleCarContent from './SimpleCarContent'
import { GridBox } from './style'

type VehicleInformationProps = {
  carData: DetailCar | null
  maintenanceCar: SimpleCar | null
  sellMyCarData: SimpleCar | null
  findMyCarData: SimpleCar | null
  leadData: LeadDetail
}

const VehicleInformation = ({
  carData,
  leadData,
  maintenanceCar,
  sellMyCarData,
  findMyCarData,
}: VehicleInformationProps) => {
  const {
    text: { vehicleInformation: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)

  const [simpleCar, setSimpleCar] = useState<SimpleCar | null>(null)

  const { type } = leadData

  const showCarData = [
    CaseTypeEnum.BUY,
    CaseTypeEnum.PREQUALIFY,
    CaseTypeEnum.TEST_DRIVE,
  ].includes(type)

  const showSimpleCarData = [
    CaseTypeEnum.MAINTENANCE,
    CaseTypeEnum.SELL_MY_CAR,
    CaseTypeEnum.FIND_MY_CAR,
  ].includes(type)

  // We can probably look into doing this into a useReducer function
  // in the future with more time
  useEffect(() => {
    switch (type) {
      case CaseTypeEnum.MAINTENANCE:
        if (maintenanceCar) {
          setSimpleCar(maintenanceCar)
        }
        break
      case CaseTypeEnum.SELL_MY_CAR:
        if (sellMyCarData) {
          setSimpleCar(sellMyCarData)
        }
        break
      case CaseTypeEnum.FIND_MY_CAR:
        if (findMyCarData) {
          setSimpleCar(findMyCarData)
        }
        break
      default:
        break
    }
  }, [maintenanceCar, type, sellMyCarData, findMyCarData])

  return (
    <Box width="100%">
      {carData && showCarData && (
        <VehicleBox>
          <ImgContainer
            title={translation.vehicleThumbnailTitle}
            description={translation.vehicleThumbnailDescription}
            contentStyle={{
              height: '100%',
            }}
          >
            <Box>
              <section
                style={{
                  height: '100%',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <Box
                  display="flex"
                  borderRadius="10px"
                  padding="1rem"
                  sx={{
                    height: '365px',
                    width: '365px',
                    border: `1px solid ${colors.border}`,
                  }}
                >
                  <ExtendedAvatar
                    image={carData.mainPicture || placeholderCar}
                    variant="rounded"
                    height="100%"
                    width="100%"
                    sx={{
                      height: '100%',
                      width: '100%',
                    }}
                    isDownloadable
                  />
                </Box>
              </section>
            </Box>
          </ImgContainer>
          <CarDataContent carData={carData} />
        </VehicleBox>
      )}
      {showSimpleCarData && simpleCar && (
        <GridBox>
          <SimpleCarContent carData={simpleCar} />
        </GridBox>
      )}
    </Box>
  )
}

export default VehicleInformation
