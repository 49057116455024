import React, { useState } from 'react'
import {
  Box,
  BREAK_POINTS,
  colors,
  Option,
  useWindowDimensions,
} from 'curbo-components-library'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import {
  ImgContainer,
  StyledImg,
} from 'components/Operations/Dealer/Detail/DealerInfo/style'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { ExtendedRole } from 'models/services/General/UserManagement/detail'
import { handleUpdateUserType, UserDetailData } from 'models/services/user'

import placeholderImage from 'images/blueDefaultAvatar.png'

import { RolesManager } from './RoleManager'
import { GridBox } from './style'

type Props = {
  userInformation: UserDetailData
  availableRoles: ExtendedRole[]
  availableAreas: Option[]
  handleUpdateUser: ({
    newContact,
    newPersonalInfo,
    newRoles,
  }: handleUpdateUserType) => void
  loading: boolean
  submitLoading: boolean
}

const UserInformation = ({
  loading,
  userInformation,
  availableRoles,
  availableAreas,
  handleUpdateUser,
  submitLoading,
}: Props) => {
  const { width: windowWidth } = useWindowDimensions()
  const smallScreen = windowWidth && windowWidth <= BREAK_POINTS.XL

  const [personalInfo, setPersonalInfo] = useState<NameInformation[]>(
    userInformation.personal
  )
  const [contact, setContact] = useState<NameInformation[]>(
    userInformation.contact
  )
  const { picture } = userInformation
  const [roles, setRoles] = useState<ExtendedRole[]>(userInformation.roles)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const { text } = useTranslation(textFiles.USER_MANAGEMENT_DETAIL)
  const { userInformation: translation } = text

  const handleSetSelectedRoles = (selectedRoles: ExtendedRole[]) => {
    setRoles(selectedRoles)
    setHasUnsavedChanges(true)
  }

  const handleSetPersonalInfo = (newPersonalInfo: NameInformation[]) => {
    setPersonalInfo(newPersonalInfo)
    setHasUnsavedChanges(true)
  }

  const handleSetContact = (newContact: NameInformation[]) => {
    setContact(newContact)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    const body = {
      newPersonalInfo: personalInfo,
      newContact: contact,
      newRoles: roles,
    }

    handleUpdateUser(body)

    setHasUnsavedChanges(false)
  }

  const resetState = () => {
    setPersonalInfo(userInformation.personal)
    setContact(userInformation.contact)
    setRoles(userInformation.roles)
    setHasUnsavedChanges(false)
  }

  return (
    <Box>
      <GridBox order="unset">
        <Box>
          <ImgContainer
            title={translation.images}
            description={translation.imagesDescription}
            height="330px"
            width="336px"
            maxWidth="336px"
            marginBottom="5rem"
          >
            <section
              style={{
                position: 'relative',
              }}
            >
              {picture ? (
                <StyledImg
                  src={picture}
                  srcSet={picture}
                  alt="user-picture"
                  loading="lazy"
                  sx={{
                    borderRadius: '10px',
                    border: `1px solid ${colors.border}`,
                  }}
                />
              ) : (
                <StyledImg
                  src={placeholderImage}
                  srcSet={placeholderImage}
                  alt="user-picture"
                  loading="lazy"
                  sx={{
                    borderRadius: '10px',
                    border: `1px solid ${colors.border}`,
                  }}
                />
              )}
            </section>
          </ImgContainer>
          {!smallScreen ? null : (
            <RolesManager
              loading={loading}
              availableRoles={availableRoles}
              availableAreas={availableAreas}
              selectedRoles={roles}
              handleChangeRoles={handleSetSelectedRoles}
            />
          )}
          {!smallScreen && (
            <SaveChangesBlock
              handleSaveChanges={handleSaveChanges}
              resetState={resetState}
              submitLoading={submitLoading}
              shouldRender={hasUnsavedChanges}
            />
          )}
        </Box>

        <Box paddingTop="2.8rem">
          <NameContainer
            width="100%"
            containerSx={{
              padding: '0px !important',
              paddingTop: '1rem !important',
            }}
            information={personalInfo}
            handleSetInformation={handleSetPersonalInfo}
          />
          <NameContainer
            title={translation.contact}
            description={translation.contactDescription}
            height="313px"
            width="100%"
            containerSx={{
              padding: '0px !important',
              paddingTop: '1rem !important',
            }}
            information={contact}
            handleSetInformation={handleSetContact}
          />
        </Box>
        {smallScreen ? null : (
          <RolesManager
            loading={loading}
            availableRoles={availableRoles}
            availableAreas={availableAreas}
            selectedRoles={roles}
            handleChangeRoles={handleSetSelectedRoles}
          />
        )}
      </GridBox>
      {smallScreen && (
        <SaveChangesBlock
          handleSaveChanges={handleSaveChanges}
          resetState={resetState}
          submitLoading={submitLoading}
          shouldRender={hasUnsavedChanges}
        />
      )}
    </Box>
  )
}

export default UserInformation
