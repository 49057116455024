export enum PublicationStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum InventoryStatus {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not-available',
  SOLD = 'sold',
  PENDING = 'pending',
  COMING_SOON = 'coming-soon',
  TO_REVIEW = 'to-review',
}

export enum InspectionStatus {
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  IN_PROCESS = 'in-process',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  FOR_REPAIR = 'for-repair',
  ABORTED = 'aborted',
  PATCHING_UP = 'patching-up',
}

export enum InspectionSlugStatus {
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  IN_PROCESS = 'IN_PROCESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FOR_REPAIR = 'FOR_REPAIR',
  ABORTED = 'ABORTED',
  PATCHING_UP = 'PATCHING_UP',
}

export enum InventorySlugStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  SOLD = 'SOLD',
  PENDING = 'PENDING',
  COMING_SOON = 'COMING_SOON',
  TO_REVIEW = 'TO_REVIEW',
}
