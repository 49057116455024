export const unknownCurboSpotId = 'unknown-curbo-spot'

export enum InspectorColumnField {
  PICTURE = 'picture',
  INSPECTOR_NAME = 'name',
  CURBO_SPOT = 'curboSpot',
  ID = 'id',
  PROVINCE = 'province',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
}
