import React, { useCallback, useEffect, useState } from 'react'
import { DirectionsCar, Person } from '@mui/icons-material'
import { SelectChangeEvent, Typography } from '@mui/material'
import {
  Box,
  colors,
  FONT_WEIGHT,
  formatFullName,
  formatPhoneNumber,
} from 'curbo-components-library'

import Accordion from 'components/Common/Accordion'
import IconTextBox from 'components/Detail/IconTextBox'
import EditContainer from 'components/Inventory/Common/EditContainer'
import InformationCell from 'components/Inventory/Common/InformationCell'

import { CaseTypeEnum } from 'constants/Lead/listings'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  DetailCar,
  LeadDetail,
  SimpleCar,
  UpdateCaseInput,
} from 'models/services/customerRelationship/lead/detail'

import { StyledGrid } from 'styles/inventory/detail'

import SellMyCarSection from '../SellMyCarSection'
import TestDriveSection from '../TestDriveSection'

import { StyledSelect } from './style'

const showSpecialistAccordion = (
  financialAdvisor: string | null,
  salesPerson: string | null,
  bds: string | null
): boolean => {
  if (!financialAdvisor && !salesPerson && !bds) return false
  return true
}

type CallToActionProps = {
  carData: DetailCar | null
  leadData: LeadDetail
  loading: boolean
  handleUpdateCase: (value: UpdateCaseInput) => Promise<boolean | LeadDetail>
  handleTabChange: (value: number) => void
  handleCallLazyQueries: (type: CaseTypeEnum, leadData: LeadDetail) => void
  sellMyCarData: SimpleCar | null
}

const CalltoAction = ({
  leadData,
  loading,
  carData,
  handleTabChange,
  handleUpdateCase,
  handleCallLazyQueries,
  sellMyCarData,
}: CallToActionProps) => {
  const {
    text: { overview: translation, callToAction: ctaTranslation },
  } = useTranslation(textFiles.LEAD_DETAIL)
  const {
    activeCallToActions,
    customer,
    testDrive,
    financialAdvisor,
    sellRepresentative,
    customerCareRepresentative,
    type,
  } = leadData

  const [edit, setEdit] = useState<boolean>(false)

  const [callToAction, setCallToAction] = useState<string>(type)
  const [cancelChanges, setCancelChanges] = useState<boolean>(false)
  const [saveChanges, setSaveChanges] = useState<boolean>(false)

  const customerFullName = formatFullName(customer.name, customer.lastName)
  const customerPhone = customer.telephoneNumber
    ? formatPhoneNumber(customer.telephoneNumber)
    : ''

  const financialAdvisorName =
    financialAdvisor &&
    formatFullName(financialAdvisor.name, financialAdvisor.lastName)
  const salesPersonName =
    sellRepresentative &&
    formatFullName(sellRepresentative.name, sellRepresentative.lastName)
  const customerReprensentativeFullName =
    customerCareRepresentative &&
    formatFullName(
      customerCareRepresentative.name,
      customerCareRepresentative.lastName
    )

  const showSpecialist = showSpecialistAccordion(
    financialAdvisorName,
    salesPersonName,
    customerReprensentativeFullName
  )

  const handleOnChangeCTA = (event: SelectChangeEvent<unknown>) => {
    setCallToAction(event.target.value as string)
  }

  const handleCloseEdit = () => {
    setEdit(false)
  }

  const handleResetChanges = useCallback(() => {
    setCancelChanges(false)
    setSaveChanges(false)
  }, [])

  const handleCancelEdit = () => {
    setEdit(false)
    setCancelChanges(true)
  }

  const handleEdit = async () => {
    setEdit(true)
  }

  const handleSaveEdit = () => {
    setSaveChanges(true)
  }

  useEffect(() => {
    if (!sellMyCarData && callToAction === CaseTypeEnum.SELL_MY_CAR) {
      handleCallLazyQueries(CaseTypeEnum.SELL_MY_CAR, leadData)
    }
  }, [sellMyCarData, handleCallLazyQueries, callToAction, leadData])

  return (
    <Box
      minHeight="500px"
      width="100%"
      sx={{
        opacity: loading ? '0.5' : 'unset',
      }}
    >
      <Box borderBottom={`1px solid ${colors.border}`}>
        <EditContainer
          edit={edit}
          handleCancelEdit={handleCancelEdit}
          handleSaveEdit={handleSaveEdit}
          handleEdit={handleEdit}
          loading={loading}
          isEditable
        />
        {leadData.activeCallToActions.length > 0 && (
          <Box alignItems="center" display="flex" marginBottom="1rem">
            <Typography
              color={colors.black}
              fontWeight={FONT_WEIGHT.BOLD}
              variant="h6"
            >
              CTA:
            </Typography>
            <StyledSelect
              options={activeCallToActions}
              value={callToAction}
              variant="standard"
              onChange={handleOnChangeCTA}
            />
          </Box>
        )}
        <Box
          alignItems="center"
          display="flex"
          justifyContent="flex-start"
          marginBottom="2rem"
        >
          <IconTextBox
            description={customerPhone}
            name={customerFullName}
            title={translation.clientLabel}
            icon={<Person fontSize="large" sx={{ color: colors.blue }} />}
            width="250px"
            onClick={() => {
              handleTabChange(1)
            }}
          />
          {carData && (
            <IconTextBox
              description={carData.trimLevel.name}
              title={translation.vehicleLabel}
              name={`${carData.brand.name} ${carData.carModel.name} ${carData.year}`}
              icon={
                <DirectionsCar fontSize="large" sx={{ color: colors.blue }} />
              }
              width="250px"
              onClick={() => {
                handleTabChange(2)
              }}
            />
          )}
        </Box>
      </Box>
      {showSpecialist && (
        <Accordion
          defaultExpanded
          description={ctaTranslation.specialistDescription}
          title={ctaTranslation.specialistTitle}
        >
          <StyledGrid>
            {salesPersonName && (
              <InformationCell
                label={ctaTranslation.salesPersonLabel}
                value={salesPersonName}
              />
            )}
            {financialAdvisorName && (
              <InformationCell label="F&I" value={financialAdvisorName} />
            )}
            {customerReprensentativeFullName && (
              <InformationCell
                label="BDS"
                value={customerReprensentativeFullName}
              />
            )}
          </StyledGrid>
        </Accordion>
      )}
      {callToAction === CaseTypeEnum.SELL_MY_CAR && sellMyCarData && (
        <SellMyCarSection
          edit={edit}
          sellMyCarInfo={leadData.sellMyCarInfo}
          cancelChanges={cancelChanges}
          saveChanges={saveChanges}
          handleResetChanges={handleResetChanges}
          handleCloseEdit={handleCloseEdit}
          handleUpdateCase={handleUpdateCase}
          sellMyCarData={sellMyCarData}
        />
      )}
      {callToAction === CaseTypeEnum.TEST_DRIVE && testDrive && (
        <TestDriveSection
          edit={edit}
          testDriveId={testDrive.id}
          cancelChanges={cancelChanges}
          saveChanges={saveChanges}
          handleResetChanges={handleResetChanges}
          handleCloseEdit={handleCloseEdit}
        />
      )}
    </Box>
  )
}

export default CalltoAction
