import SquareIcon from '@mui/icons-material/Square'
import { Pagination, paginationClasses, styled } from '@mui/material'
import { Box, colors, FONT_WEIGHT } from 'curbo-components-library'

export const StyledBox = styled(Box)({
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  height: '330px',
  textAlign: 'left',
  padding: '18px',
  width: 'auto',
})

export const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-icon': {
    fontSize: '1.5rem!important',
  },
  [`& .${paginationClasses.ul}`]: {
    borderRadius: '4px',
    height: '35px',
    width: '100%',
    justifyContent: 'space-between',
    'li:first-of-type': {
      color: colors.background,
      borderRight: `1px solid ${colors.border}`,
      height: '50%',
    },

    'li:last-child': {
      color: colors.background,
      borderLeft: `1px solid ${colors.border}`,
      height: '50%',
    },

    '.Mui-selected': {
      flexDirection: 'column',
      paddingTop: '2px',
      backgroundColor: 'transparent',
      color: colors.blue,
    },

    '.MuiPaginationItem-previousNext': {
      color: colors.placeholderGray,
      marginTop: '-5px',
    },
  },
})

export const StyledStripe = styled(Box)({
  color: colors.placeholderGray,
  backgroundColor: colors.commonWhite,
  fontWeight: FONT_WEIGHT.MEDIUM,
  fontSize: 14,
  height: 30,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.lightGray} !important`,
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: {
      color: colors.black,
      marginRight: '7px',
    },
  },
})

export const StyledSquareIcon = styled(SquareIcon)({
  color: colors.blue,
  borderRadius: '50px',
  marginRight: '8px',
  fontSize: 20,
})
