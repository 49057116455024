import React, { useState } from 'react'
import { Box, getIsoDate, Option } from 'curbo-components-library'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Accordion from 'components/Common/Accordion'
import DatePickerCell from 'components/Inventory/Common/DatePickerCell'
import EditContainer from 'components/Inventory/Common/EditContainer'
import InformationCell from 'components/Inventory/Common/InformationCell'
import SelectCell from 'components/Inventory/Common/SelectCell'

import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import {
  Bant,
  Customer,
  LeadDetail,
  UpdateCustomerInput,
} from 'models/services/customerRelationship/lead/detail'
import { parseIsoStringToDate } from 'utils/date'

import { StyledGrid } from 'styles/inventory/detail'

type FormikModel = {
  address: string | null
  birthDate: Date | null
  documentId: string | null
  lastName: string | null
  name: string
  telephoneNumber: string | null
  zipCode: string | null
  documentType: string
}

const formatCustomerIntoFormikModel = (customer: Customer): FormikModel => {
  const {
    address,
    birthDate,
    documentId,
    documentType,
    lastName,
    telephoneNumber,
    zipCode,
  } = customer

  return {
    ...customer,
    address: address || '',
    birthDate: parseIsoStringToDate(birthDate),
    documentType: documentType || '',
    documentId: documentId || '',
    lastName: lastName || '',
    telephoneNumber: telephoneNumber || '',
    zipCode: zipCode || '',
  }
}

const verifyIfBantEmpty = (bant: Bant): boolean => {
  const {
    decisionMakerKnoledge,
    hasBudget,
    hasReasonToPurchase,
    knowDecisionMakerName,
    purchaseTimeFrame,
  } = bant

  if (
    !decisionMakerKnoledge &&
    !hasBudget &&
    !hasReasonToPurchase &&
    !knowDecisionMakerName &&
    !purchaseTimeFrame
  )
    return true

  return false
}

type ClientInformationProps = {
  isLoading: boolean
  leadData: LeadDetail
  handleUpdateCustomer: (
    customer: UpdateCustomerInput,
    customerId: string
  ) => Promise<boolean | Customer>
}

const ClientInformation = ({
  isLoading,
  leadData,
  handleUpdateCustomer,
}: ClientInformationProps) => {
  const [selectedLanguage] = useLocale()
  const {
    text: { client: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const phoneValidation = validationText.errorMessagePhoneNumberDigits.replace(
    '%d',
    '10'
  )

  const { bant, customer, workingInfo } = leadData
  const {
    hasBudget,
    decisionMakerKnoledge,
    hasReasonToPurchase,
    knowDecisionMakerName,
    purchaseTimeFrame,
  } = bant

  const [edit, setEdit] = useState<boolean>(false)
  const isBantEmpty = verifyIfBantEmpty(bant)

  // hard-coded untill further notice
  const documentTypes: Option[] = [
    {
      name: translation.idLabel,
      value: 'ID',
    },
    {
      name: translation.driverLicenseLabel,
      value: 'DRIVING_LICENSE',
    },
    {
      name: translation.passportLabel,
      value: 'PASSPORT',
    },
  ]

  const defaultOption: Option = documentTypes.find(
    (documentType) => documentType.value === customer.documentType
  ) || { name: '-', value: 'n/a' }

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(validationText.fieldRequired)
      .max(50, validationText.errorMessageMaxCharacters.replace('%d', 51)),
    lastName: yup
      .string()
      .max(50, validationText.errorMessageMaxCharacters.replace('%d', 51)),
    telephoneNumber: yup.string().length(10, phoneValidation).nullable(),
    documentId: yup
      .string()
      .min(4, validationText.errorMessageAtleast.replace('%d', 4))
      .max(10, validationText.errorMessageMaxCharacters.replace('%d', 13)),
    address: yup
      .string()
      .min(5, validationText.errorMessageAtleast.replace('%d', 5))
      .max(100, validationText.errorMessageMaxCharacters.replace('%d', 101)),
    zipCode: yup
      .string()
      .min(3, validationText.errorMessageAtleast.replace('%d', 3))
      .max(14, validationText.errorMessageMaxCharacters.replace('%d', 15)),
    birthDate: yup.date().nullable().typeError(validationText.invalidDate),
  })

  const formik = useFormik<FormikModel>({
    initialValues: formatCustomerIntoFormikModel(customer),
    validationSchema,
    onSubmit: async (values) => {
      const {
        address,
        documentId,
        documentType,
        lastName,
        name,
        telephoneNumber,
        zipCode,
        birthDate,
      } = values
      if (formik.dirty) {
        const updateResponse = await handleUpdateCustomer(
          {
            address: address || undefined,
            documentId: documentId || undefined,
            documentType: documentType || undefined,
            lastName: lastName || undefined,
            name,
            telephoneNumber: telephoneNumber || undefined,
            zipCode: zipCode || undefined,
            birthDate: getIsoDate(birthDate),
          },
          customer.id
        )
        // after submit, we need to get the new data and reset form to clean the dirty flag
        if (updateResponse) {
          if (typeof updateResponse !== 'boolean')
            formik.resetForm({
              values: formatCustomerIntoFormikModel(updateResponse),
            })
          setEdit(false)
        }
      } else setEdit(false)
    },
  })

  const handleCancelEdit = () => {
    setEdit(false)
    formik.resetForm({ values: formatCustomerIntoFormikModel(customer) })
  }

  const handleEdit = async () => {
    setEdit(true)
  }

  const handleSaveEdit = () => {
    formik.handleSubmit()
  }

  const handleDateChange = (newDate: Date | null, name?: string) => {
    if (!name) return

    formik.setValues({
      ...formik.values,
      [name]: newDate,
    })
  }

  return (
    <Box
      minHeight="650px"
      sx={{
        opacity: isLoading ? '0.5' : 'unset',
      }}
    >
      <EditContainer
        edit={edit}
        handleCancelEdit={handleCancelEdit}
        handleSaveEdit={handleSaveEdit}
        handleEdit={handleEdit}
        loading={false}
        isEditable
      />
      <Accordion
        defaultExpanded
        sx={{
          margin: '0px!important',
        }}
        title={translation.clientInformationTitle}
      >
        <StyledGrid>
          <InformationCell
            label={translation.nameLabel}
            value={formik.values.name}
            edit={edit}
            onChange={formik.handleChange}
            name="name"
            error={formik.touched.name && Boolean(formik.errors.name)}
            errorText={formik.errors.name}
          />
          <InformationCell
            label={translation.lastNameLabel}
            value={formik.values.lastName}
            edit={edit}
            onChange={formik.handleChange}
            name="lastName"
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            errorText={formik.errors.lastName}
          />
          <InformationCell
            label={translation.phoneLabel}
            value={formik.values.telephoneNumber}
            edit={edit}
            onChange={formik.handleChange}
            name="telephoneNumber"
            error={
              formik.touched.telephoneNumber &&
              Boolean(formik.errors.telephoneNumber)
            }
            errorText={formik.errors.telephoneNumber}
          />
          <InformationCell
            label={translation.emailLabel}
            value={customer.email}
          />
          <SelectCell
            label={translation.documentTypeLabel}
            value={formik.values.documentType}
            defaultOption={defaultOption}
            options={documentTypes}
            onChange={formik.handleChange}
            name="documentType"
            edit={edit}
          />
          <InformationCell
            label={translation.documentLabel}
            value={formik.values.documentId}
            edit={edit}
            onChange={formik.handleChange}
            name="documentId"
            error={
              formik.touched.documentId && Boolean(formik.errors.documentId)
            }
            errorText={formik.errors.documentId}
          />
          <DatePickerCell
            edit={edit}
            error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
            name="birthDate"
            errorText={formik.errors.birthDate}
            handleDateChange={handleDateChange}
            language={selectedLanguage.code}
            label={translation.birthDateLabel}
            value={formik.values.birthDate}
          />
          <InformationCell
            label={translation.addressLabel}
            value={formik.values.address}
            edit={edit}
            onChange={formik.handleChange}
            name="address"
            error={formik.touched.address && Boolean(formik.errors.address)}
            errorText={formik.errors.address}
          />
          <InformationCell
            label={translation.postalCodeLabel}
            value={formik.values.zipCode}
            edit={edit}
            onChange={formik.handleChange}
            name="zipCode"
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            errorText={formik.errors.zipCode}
          />
        </StyledGrid>
      </Accordion>
      {workingInfo && (
        <Accordion defaultExpanded title={translation.workInformationTitle}>
          <StyledGrid>
            <InformationCell
              label={translation.workplaceLabel}
              value={workingInfo.company}
            />
            <InformationCell
              label={translation.timeLabel}
              value={`${workingInfo.experience} ${
                translation[workingInfo.experienceTimeUnit]
              }`}
            />
          </StyledGrid>
        </Accordion>
      )}
      {!isBantEmpty && (
        <Accordion defaultExpanded title="Bant score">
          <StyledGrid>
            {hasBudget && (
              <InformationCell
                label={translation.hasBudgetLabel}
                value={hasBudget.name}
              />
            )}
            {purchaseTimeFrame && (
              <InformationCell
                label={translation.timePeriodLabel}
                value={purchaseTimeFrame.name}
              />
            )}
            {decisionMakerKnoledge && (
              <InformationCell
                label={translation.knownDecisionLabel}
                value={decisionMakerKnoledge.name}
              />
            )}
            {knowDecisionMakerName && (
              <InformationCell
                label={translation.decisionMakerLabel}
                value={knowDecisionMakerName.name}
              />
            )}
            {hasReasonToPurchase && (
              <InformationCell
                label={translation.reasonToPurchaseLabel}
                value={hasReasonToPurchase.name}
              />
            )}
          </StyledGrid>
        </Accordion>
      )}
    </Box>
  )
}

export default ClientInformation
