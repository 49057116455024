import { inputLabelClasses, selectClasses, styled } from '@mui/material'
import { Box, BREAK_POINTS, colors, Select } from 'curbo-components-library'

export const StyledSelect = styled(Select)({
  width: '95%',
  height: '29px',
  [`.${selectClasses.select}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px',
    [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
      width: '70px',
    },
  },
})

export const TextContainer = styled(Box)({
  marginLeft: '0.5rem',
  width: '100%',
})

export const formControlStyles: React.CSSProperties = {
  width: '100%',
  height: '29px',
  [`.${inputLabelClasses.root}`]: {
    top: '-9px',
  },
}

export const onClickStyles: React.CSSProperties = {
  cursor: 'pointer',
  border: `1px solid ${colors.border}`,
  borderRadius: '6px',
  height: '102px',
  padding: '0.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  marginRight: '20px',
}
