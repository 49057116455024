import { gql } from '@apollo/client'

export const GET_MODEL_BY_ID = gql`
  query getModelById($input: ID!) {
    data: getModelById(id: $input) {
      brand {
        name
        icon
      }
      name
      status
    }
  }
`

export const LIST_COMPACT_TRIM_LEVELS = gql`
  query listTrimLevels($input: FilterInput) {
    listData: listTrimLevels(input: $input) {
      count
      data {
        id
        name
        year
      }
    }
  }
`
