import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import SettingsPage from 'pages/Account/Settings'

import { routes } from 'constants/routes'

export const AccountRouter = () => (
  <Switch>
    <Route path={routes.ACCOUNT_SETTINGS} component={SettingsPage} />
    <Redirect to={routes.ACCOUNT_SETTINGS} />
  </Switch>
)
