import { BaseEntity, ExtendedStatus } from 'curbo-components-library'

import { Image } from 'components/Common/Carousel'

import { VEHICLE_CREATION_OPTION } from 'constants/PreInventory/creation'
import { TrimDetailDataType } from 'models/services/CarSettings/detail'

export enum CarProviderEnum {
  CLIENT = 'CLIENT',
  CURBO_SPOT = 'CURBO_SPOT',
  DEALER = 'DEALER',
}

export type FirstStepModel = {
  make: BaseEntity | null
  model: BaseEntity | null
  vehicleYear: BaseEntity | null
  trimLevel: BaseEntity | null
  status: ExtendedStatus | null
  currentModelList: BaseEntity[]
  currentYearList: BaseEntity[]
  currentTrimLevelList: BaseEntity[]
  basePrice: number
}
export type SecondStepModel = {
  categories: string[]
  interiorColor: string
  exteriorColor: string
  vinNumber: string
  chassisNumber: string
  countryVersion: string
  licensePlate: string
}

export type ThirdStepModel = {
  transmission: string
  bodyStyle: string
  driveTrain: string
  fuelType: string
  features: string[]
  detailedTrimLevel: TrimDetailDataType | null
}

export type FourthStepModel = {
  frontLegRoom: number
  backLegRoom: number
  frontHeadRoom: number
  backHeadRoom: number
  engineDisplacement: number
  cargoCapacity: number
  cargo: number
}

export type FifthStepModel = {
  length: number
  width: number
  height: number
  seats: number
  doors: number
}

export type SixthStepModel = {
  mpg: number
  mpgCity: number
  mpgHgw: number
  fuelCapacity: number
  cylinders: number
  torque: number
  torqueRpm: number
  horsePower: number
  horsePowerRpm: number
}

export type VehicleInformationModel = {
  firstStep: FirstStepModel
  secondStep: SecondStepModel
  thirdStep: ThirdStepModel | null
  fourthStep: FourthStepModel | null
  fifthStep: FifthStepModel | null
  sixthStep: SixthStepModel | null
}

export type VehiclePhotosModel = {
  thumbnailPicture: string
  thumbnail: File
  interiorPictures: File[]
  interiorPictureImages: Image[]
  exteriorPictures: File[]
  exteriorPictureImages: Image[]
}

export type VehicleCreationDataType = {
  vehicleInformation: VehicleInformationModel
  vehiclePhotos: VehiclePhotosModel
}

export type VehicleCreationAction =
  | {
      type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION
      payload: VehicleInformationModel
    }
  | {
      type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_PHOTOS
      payload: VehiclePhotosModel
    }
