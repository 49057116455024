import { gql } from '@apollo/client'

export const UPDATE_TEST_DRIVE = gql`
  mutation updateTestDrive($input: UpdateInternalTestDriveInput!) {
    data: updateTestDrive(input: $input) {
      id
    }
  }
`

export const UPDATE_SELL_MY_CAR = gql`
  mutation updateSellMyCar($input: UpdateSellMyCarInput!) {
    data: updateSellMyCar(input: $input) {
      id
    }
  }
`
