import React from 'react'
import { Typography } from '@mui/material'
import {
  Box,
  colors,
  ExtendedAvatar,
  FONT_WEIGHT,
} from 'curbo-components-library'

import { StyledContainer } from './style'

type CardProps = {
  image?: string
  name: string
}

const InspectorCard = ({ image, name }: CardProps) => {
  return (
    <StyledContainer>
      <ExtendedAvatar
        variant="circular"
        image={image}
        sx={{ width: 'unset', height: 'unset' }}
      />
      <Box
        display="flex"
        flexDirection="column"
        width="auto"
        marginLeft="0.5rem"
      >
        <Typography
          variant="body2"
          fontWeight={FONT_WEIGHT.MEDIUM}
          color={colors.black}
        >
          {name}
        </Typography>
      </Box>
    </StyledContainer>
  )
}

export default InspectorCard
