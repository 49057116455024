export const inventoryStaticFields = [
  'status',
  'certified',
  'exteriorColor',
  'interiorColor',
  'carModel',
  'brand',
  'bodyStyle',
  'countryVersion',
  'fuelType',
  'transmission',
  'driveTrain',
  'dealer',
  'categories',
  'features',
]

export enum InventoryColumnField {
  ID = 'id',
  SLUG = 'slug',
  PICTURE = 'picture',
  VEHICLE = 'vehicle',
  TRIM = 'trim',
  PRICE = 'price',
  MILEAGE = 'mileage',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
}

export const unpublishedHash = '#unpublished'
