import React from 'react'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Divider, Typography } from '@mui/material'
import { Box, colors } from 'curbo-components-library'

import { DetailDescriptionBox } from './style'

type DetailSubHeaderProps = {
  text?: string
  component?: JSX.Element
  extraText?: string
}
export const DetailSubHeader = ({
  extraText,
  text,
  component,
}: DetailSubHeaderProps) => {
  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <DetailDescriptionBox>
          <PlaylistAddCheckIcon fontSize="medium" sx={{ color: colors.gray }} />
          {component || (
            <Typography variant="body2" color={colors.gray} marginLeft="0.5rem">
              {text}
            </Typography>
          )}
        </DetailDescriptionBox>
        {extraText && (
          <Typography variant="body2" color={colors.gray}>
            {extraText}
          </Typography>
        )}
      </Box>

      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
        }}
      />
    </>
  )
}
