import React from 'react'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Divider, Typography } from '@mui/material'
import { colors } from 'curbo-components-library'

import { DetailDescriptionBox } from './style'

type DetailSubHeaderProps = {
  text?: string
  component?: JSX.Element
}
export const DetailSubHeader = ({ text, component }: DetailSubHeaderProps) => {
  return (
    <>
      <DetailDescriptionBox>
        <PlaylistAddCheckIcon fontSize="medium" sx={{ color: colors.gray }} />
        {component || (
          <Typography variant="body2" color={colors.gray} marginLeft="0.5rem">
            {text}
          </Typography>
        )}
      </DetailDescriptionBox>
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
        }}
      />
    </>
  )
}
