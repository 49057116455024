import React from 'react'
import { SelectChangeEvent } from '@mui/material'
import {
  Box,
  CalendarRangeType,
  colors,
  RowFooterSelect,
  SelectItem,
} from 'curbo-components-library'

import DownloadPlanning from '../DownloadPlanning'

import { StyledFooterContainer, StyledPagination } from './style'

export type FooterProps = {
  /**
   * Page currently being displayed
   */
  currentPage: number
  /**
   * Function to manage the change of the page
   */
  onPageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void
  /**
   * Number of elements(entries) of the table
   */
  pageCount: number
  /**
   * Rows per page
   */
  pageSize: number
  /**
   * Array of items being used for the select of rows per page
   */
  selectItems: SelectItem[]
  /**
   ** Function to manage the change of the rowsPerPage
   */
  onSelectChange: (event: SelectChangeEvent<number>) => void
  /**
   ** Text to display next to select row size select
   */
  rowLabelText: string

  /**
   * Range of dates of the data to be downloaded
   */
  dateRange: CalendarRangeType
  /**
   ** How many data rows are to be downloaded
   */
  entries: number
  /**
   * Download Button Label
   */
  buttonLabel: string
  /**
   * Boolean to hide Download Button
   */
  hideDownloadButton: boolean
}

export const Footer = ({
  currentPage,
  onPageChange,
  onSelectChange,
  pageCount,
  pageSize,
  selectItems,
  rowLabelText,
  entries,
  dateRange,
  buttonLabel,
  hideDownloadButton = false,
}: FooterProps) => {
  return (
    <StyledFooterContainer sx={{ backgroundColor: colors.altGray }}>
      <RowFooterSelect
        pageSize={pageSize}
        onSelectChange={onSelectChange}
        selectItems={selectItems}
        rowLabelText={rowLabelText}
      />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        position="sticky"
      >
        <StyledPagination
          count={pageCount}
          onChange={onPageChange}
          page={currentPage}
          variant="text"
          size="small"
          shape="rounded"
          siblingCount={0}
        />
        {!hideDownloadButton && (
          <DownloadPlanning
            entries={entries}
            reportName={{
              date: '12/12/12',
              processName: 'InspectorsPlanning',
            }}
            dateRange={dateRange}
            buttonLabel={buttonLabel}
          />
        )}
      </Box>
    </StyledFooterContainer>
  )
}
