import { accordionSummaryClasses, styled } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { colors } from 'curbo-components-library'

export const StyledAccordion = styled(Accordion)({
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${colors.border}`,
  },
  '&:before': {
    display: 'none',
  },
  boxShadow: 'none',
})

export const StyledAccordionDetail = styled(AccordionDetails)({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 10,
  rowGap: 20,

  '> div': {
    minWidth: '265px',
    width: 'auto',
  },
})

export const StyledAccordionSummary = styled(AccordionSummary)({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'flex-start',
  padding: 0,
  [`.${accordionSummaryClasses.expandIconWrapper}`]: {
    marginRight: 10,
  },
  [`.${accordionSummaryClasses.content}`]: {
    p: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      fontSize: 14,
      color: colors.altBlack,
    },
  },
})
