import { styled } from '@mui/material/styles'
import { Box, BREAK_POINTS, colors, Container } from 'curbo-components-library'

export const ClientBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '40% 30%',
  justifyContent: 'space-between',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '60% 40%',
  },
})

export const StyledContainer = styled(Container)({
  padding: '0.5rem 1.2rem !important',
})

export const contentStyle = {
  padding: '18px',
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  innerWidth: 'full',
}
