import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridAlignment,
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'
import {
  BaseIdEntity,
  Box,
  buildDetailRoute,
  Button,
  checkSearchEmptiness,
  cleanFilters,
  colors,
  cypressAddButton,
  ExtendedStatus,
  FieldFilter,
  Filter,
  FilterByCriteria,
  FilteringOption,
  FilterInputVariable,
  FONT_WEIGHT,
  generateFilterInput,
  GenericData,
  GenericUpdateVariable,
  getIsoDate,
  ListingFilterType,
  Option,
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSearch,
  serializeSortModel,
  Table,
  Title,
  Tooltip,
  UrlParamNames,
  useNotification,
  verifyParam,
} from 'curbo-components-library'
import { endOfDay, startOfDay } from 'date-fns'

import StatusTag from 'components/Common/StatusTag'
import SpecialistPicker from 'components/CustomerRelationship/Lead/Listing/SpecialistPicker'
import DateRangeFilter from 'components/Inspection/Listing/DateFilter'

import {
  defaultRange,
  LeadColumnField,
  leadStaticFields,
} from 'constants/Lead/listings'
import { routes } from 'constants/routes'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { CalendarRangeType } from 'models/date'
import { FiltersByCriteriaTranslationType } from 'models/filtersByCriteria'
import { UserRoles } from 'models/role'
import {
  ListBds,
  ListLead,
  UpdateCaseInput,
} from 'models/services/customerRelationship/lead/listing'
import { formatStringIntoFilteringOption } from 'utils/basicUtil'
import { getLeadsFieldOrEntryOptions } from 'utils/CustomerRelationship/Lead/common'
import {
  addCommas,
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
  formatLeads,
} from 'utils/CustomerRelationship/Lead/listing'

import { UPDATE_CASE } from 'graphQL/CustomerRelationship/Lead/Common/mutations'
import {
  GET_BDS,
  GET_CASE_ORIGIN_ENUMS,
  GET_CASE_STEPS,
  GET_CASE_TYPE_ENUMS,
} from 'graphQL/CustomerRelationship/Lead/Common/queries'
import {
  GET_LEAD_STEPS,
  LIST_DEALER_LEADS,
} from 'graphQL/CustomerRelationship/Lead/Listing/queries'

import { StyledTextField } from 'styles/inspection/listing'

import { LeadCustomFilterOption } from '../Board'

const LeadListingPage = () => {
  // Left supervisor validations commented for future edit and delete functionalities
  const { validateAllowedRoles } = useUser()
  const admin = validateAllowedRoles([UserRoles.ADMIN])

  const { show } = useNotification()
  const [selectedLanguage] = useLocale()
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''
  const history = useHistory()
  const { text } = useTranslation(textFiles.LEAD_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const {
    filtersByCriteria,
  }: { filtersByCriteria: FiltersByCriteriaTranslationType } = text
  const { fieldOrEntryOptions: filterOptionText } = filtersByCriteria
  const location = useLocation()
  const { search } = location

  const fieldOrEntryOptions = getLeadsFieldOrEntryOptions(filterOptionText)

  const defaultSortedFields = [...defaultFields].sort()

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) ||
      defaultRange,
    serializeRange,
    defaultRange
  )

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )
  const [searchInput, setSearchInput] = useState<string>(searchValue || '')

  const [leadQuantity, setLeadQuantity] = useState<number>(0)
  const [leadData, setLeadData] = useState<GridRowsProp>([])
  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const [customFilterOptions, setCustomFilterOptions] =
    useState<LeadCustomFilterOption>({
      customerCareRepresentative: [],
      origin: [],
      type: [],
    })

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(
        dateRange.fromDate ? startOfDay(dateRange.fromDate) : null
      ),
      toDate: getIsoDate(dateRange.toDate ? endOfDay(dateRange.toDate) : null),
    }
  }, [dateRange])

  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)

  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const getPageCount = () => {
    return Math.ceil(leadQuantity / pageSize)
  }
  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: generalText.notificationText.error,
    })
  }

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      history.push(buildDetailRoute(params.row.id, routes.LEAD_DETAIL))
    },
    [history]
  )

  const editLead = React.useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.LEAD_DETAIL))
    },
    [history]
  )

  const { loading: stepsLoading, data: stepsResponse } = useQuery<
    GenericData<ExtendedStatus[]>,
    FilterInputVariable
  >(GET_LEAD_STEPS, {
    variables: {
      input: {
        where: {
          name_nin: ['Lead - Financing', 'New Lead Crm'],
        },
      },
    },
  })

  const { loading: bdsLoading, data: bdsResponse } = useQuery<
    ListBds,
    FilterInputVariable
  >(GET_BDS)

  const { loading: leadsLoading } = useQuery<ListLead, FilterInputVariable>(
    LIST_DEALER_LEADS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          limit: pageSize,
          start: (page - 1) * pageSize,
          where: {
            text_search: searchValue,
            createdAt_gte: fromDate,
            createdAt_lte: toDate,
            ...cleanFilters(filterInput),
          },
          sort:
            sortModel.length > 0 && sortModel[0].sort
              ? {
                  [sortModel[0].field]: sortModel[0].sort,
                }
              : undefined,
        },
      },
      onCompleted(response) {
        const { data, count } = response.listDealerLeads
        setLeadQuantity(count)
        setLeadData(formatLeads(data))
      },
      onError() {
        handleShowError()
      },
    }
  )

  const { data: stepsData, loading: caseStepLoading } = useQuery<
    GenericData<ExtendedStatus[]>,
    FilterInputVariable
  >(GET_CASE_STEPS, {
    variables: {
      input: {
        where: {
          type: 'LEAD',
        },
        sort: { sequenceNumber: 'asc' },
      },
    },
  })

  const [updateCase] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateCaseInput>
  >(UPDATE_CASE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: text.specialistSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: text.specialistFail,
      })
    },
    refetchQueries: [LIST_DEALER_LEADS],
  })

  const { loading: typesLoading } = useQuery<GenericData<string[]>>(
    GET_CASE_TYPE_ENUMS,
    {
      onCompleted(response) {
        setCustomFilterOptions((prevCustomFilter) => {
          return {
            ...prevCustomFilter,
            type: formatStringIntoFilteringOption(response.data),
          }
        })
      },
    }
  )

  const { loading: originsLoading } = useQuery<GenericData<string[]>>(
    GET_CASE_ORIGIN_ENUMS,
    {
      onCompleted(response) {
        setCustomFilterOptions((prevCustomFilter) => {
          return {
            ...prevCustomFilter,
            origin: formatStringIntoFilteringOption(response.data),
          }
        })
      },
    }
  )

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const createColumns = useCallback(
    (
      statuses?: ExtendedStatus[],
      fields?: string[],
      bdsSpecialists?: FilteringOption[]
    ) => {
      const hiddenField = (field: string) => {
        return fields && !fields.includes(field)
      }
      const {
        id: idColumn,
        isoDate: dateColumn,
        standard: standardColumn,
        statusBackend: statusColumn,
      } = commonListColumns(
        undefined,
        selectedLanguage,
        text,
        editLead,
        undefined,
        statuses
      )
      return [
        { ...idColumn, hide: true },
        {
          ...standardColumn,
          field: LeadColumnField.ID,
          headerName: text.table.id,
          hide: hiddenField(LeadColumnField.ID),
          flex: 2,
          sortable: true,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>{params.row.caseId}</Tooltip>
          ),
        },
        {
          ...standardColumn,
          field: LeadColumnField.CLIENT,
          headerName: text.table.client,
          hide: hiddenField(LeadColumnField.CLIENT),
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>{params.row.client}</Tooltip>
          ),
        },
        {
          ...standardColumn,
          field: LeadColumnField.EMAIL,
          headerName: text.table.customerEmail,
          hide: hiddenField(LeadColumnField.EMAIL),
          flex: 1,
          sortable: true,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>{params.row.customerEmail}</Tooltip>
          ),
        },
        {
          ...standardColumn,
          field: LeadColumnField.PHONE,
          headerName: text.table.phone,
          hide: hiddenField(LeadColumnField.PHONE),
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>{params.row.phone}</Tooltip>
          ),
        },
        {
          ...standardColumn,
          field: LeadColumnField.VEHICLE,
          headerName: text.table.vehicle,
          hide: hiddenField(LeadColumnField.VEHICLE),
          flex: 2,
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>{params.row.vehicle}</Tooltip>
          ),
        },
        {
          ...standardColumn,
          field: LeadColumnField.CTA,
          headerName: text.table.cta,
          hide: hiddenField(LeadColumnField.CTA),
          flex: 1,
          sortable: true,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>{params.row.cta}</Tooltip>
          ),
        },
        {
          ...standardColumn,
          field: LeadColumnField.SOURCE,
          headerName: text.table.source,
          hide: hiddenField(LeadColumnField.SOURCE),
          flex: 1,
          sortable: true,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>
              <span
                style={{
                  color: colors.blue,
                  fontWeight: FONT_WEIGHT.MEDIUM,
                }}
              >
                {params.row.source}
              </span>
            </Tooltip>
          ),
        },
        {
          ...dateColumn,
          hide: hiddenField(LeadColumnField.CREATED_AT),
          field: LeadColumnField.CREATED_AT,
          sortable: true,
          flex: 1,
          headerName: text.table.createdAt,
        },
        {
          ...standardColumn,
          field: LeadColumnField.PRICE,
          headerName: text.table.price,
          hide: hiddenField(LeadColumnField.PRICE),
          flex: 1,
          sortable: true,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>
              {`${priceCurrency} ${addCommas(params.row.price)}`}
            </Tooltip>
          ),
        },
        {
          ...statusColumn,
          field: LeadColumnField.STEP,
          hide: hiddenField(LeadColumnField.STEP),
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            const { textColor, name, backgroundColor } = params.row.step

            return (
              <StatusTag
                backgroundColor={backgroundColor}
                color={textColor}
                tagSx={{
                  width: '100px !important',
                }}
              >
                {name}
              </StatusTag>
            )
          },
        },
        {
          ...standardColumn,
          field: LeadColumnField.SPECIALIST,
          headerName: text.table.specialist,
          hide: hiddenField(LeadColumnField.SPECIALIST),
          flex: 1,
          sortable: false,
          headerAlign: 'center' as GridAlignment,
          align: 'center' as GridAlignment,
          renderCell: (params: GridRenderCellParams) => {
            const { name, value } = params.row.specialist
            const handleUpdateSpecialist = (id: string) => {
              updateCase({
                variables: {
                  input: {
                    where: {
                      id: params.row.id,
                    },
                    data: { customerCareRepresentative: id },
                  },
                },
              })
            }
            return (
              <SpecialistPicker
                text={text.assignedSpecialist}
                specialist={{ name, value }}
                options={bdsSpecialists as Option[]}
                handleUpdate={handleUpdateSpecialist}
              />
            )
          },
        },
      ]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLanguage, text, editLead, priceCurrency]
  )

  const [columns, setColumns] = useState<GridColDef[]>(createColumns())

  const handleSelectItem = (valueInput: string) => {
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }
  const submitSearchValue = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  const createLead = () => {
    history.push(routes.LEAD_CREATION)
  }

  useEffect(() => {
    if (stepsResponse?.data && bdsResponse?.getBds.data && stepsData) {
      const formattedSpecialists: FilteringOption[] =
        bdsResponse?.getBds.data.map((res) => ({
          name: `${res.name} ${res.lastName}`,
          value: res.value as string,
          disabled: false,
        }))

      setCustomFilterOptions((prevCustomFilter) => {
        return {
          ...prevCustomFilter,
          customerCareRepresentative: formattedSpecialists,
        }
      })
      setColumns(
        createColumns(stepsData.data, selectedFields, formattedSpecialists)
      )
    }
  }, [
    stepsResponse,
    createColumns,
    selectedFields,
    bdsResponse,
    updateCase,
    stepsData,
  ])

  return (
    <Box width="100%">
      <Title
        icon={<PlaylistAddCheckIcon />}
        title={text.title}
        subtitle={`${leadQuantity} ${text.description}`}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent={admin ? 'space-between' : 'flex-end'}
        marginTop="2rem"
        width="100%"
      >
        {admin ? (
          <Button
            startIcon={<AddIcon />}
            size="medium"
            onClick={createLead}
            data-cy={cypressAddButton}
          >
            {text.newLeadButton}
          </Button>
        ) : null}
        <Box display="flex">
          <Box marginRight="1rem" width="450px">
            <StyledTextField
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterInput={filterInput}
              filterCardText={filtersByCriteria}
              filtersByCriteriaText={{
                ...filtersByCriteria,
                fieldOrEntryOptions,
              }}
              labels={generalText.filterByCriteria}
              staticFields={leadStaticFields}
              options={customFilterOptions}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
              text={generalText.fieldFilter}
            />
          </Box>
          <Box>
            <DateRangeFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              maxDate={null}
            />
          </Box>
        </Box>
      </Box>

      <Table
        columns={columns}
        dateRange={dateRange}
        searchValue={searchValue}
        data={leadData}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={
          leadsLoading ||
          stepsLoading ||
          bdsLoading ||
          caseStepLoading ||
          typesLoading ||
          originsLoading
        }
        checkboxSelection={false}
        rowHeight={110}
        hideDownloadModal
        text={generalText.tableText}
        downloadModalText={generalText.downloadReport}
        filterCardText={text.filterByCriteria}
        onCellClick={handleCellClick}
      />
    </Box>
  )
}

export default LeadListingPage
