import React from 'react'
import { DirectionsCar } from '@mui/icons-material'
import { BaseEntity } from 'curbo-components-library'

import NameContainer from 'components/Detail/NameContainer'
import { PaginationList } from 'components/General/PaginationList'
import ImageBox from 'components/Operation/CarSettings/Common/ImageBox'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { BasicDetailDataType } from 'models/services/operation/carSettings/detail'

import placeholderImage from 'images/blueDefaultAvatar.png'

import { GridBox, StyledContainer } from 'styles/detail'

type Props = {
  brandData: BasicDetailDataType
  handlePageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void
  pageCount: number
  totalCount: number
  modelList: BaseEntity[]
  modelsLoading: boolean
  actualPage: number
  pageSize: number
}

const BrandInformation = ({
  actualPage,
  brandData,
  pageCount,
  totalCount,
  handlePageChange,
  modelList,
  modelsLoading,
  pageSize,
}: Props) => {
  const { name, icon } = brandData

  const { text } = useTranslation(textFiles.BRAND_DETAIL)

  const { brandInformation: translation } = text

  const initialNameInformation = [
    {
      id: 1,
      icon: <DirectionsCar />,
      label: translation.brandLabel,
      name,
    },
  ]

  return (
    <StyledContainer>
      <GridBox>
        <section>
          <ImageBox
            height="100%"
            width="100%"
            variant="rounded"
            image={icon || placeholderImage}
            containerSx={{
              height: '363px',
              width: '363px',
              marginTop: '1rem',
              padding: '1rem',
              position: 'relative',
            }}
          />
        </section>

        <NameContainer
          title={translation.title}
          description={translation.description}
          width={399}
          containerSx={{
            padding: '1rem 0 0 !important',
          }}
          information={initialNameInformation}
          editDisabled
        />
        <PaginationList
          actualPage={actualPage}
          handlePageChange={handlePageChange}
          items={modelList}
          pageCount={pageCount}
          totalCount={totalCount}
          loading={modelsLoading}
          pageSize={pageSize}
          title={translation.modelTitle}
          description={translation.modelDescription}
          countText={translation.countModels}
        />
      </GridBox>
    </StyledContainer>
  )
}

export default BrandInformation
