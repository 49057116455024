import React from 'react'
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { Button, colors, FONT_WEIGHT } from 'curbo-components-library'

import Link from 'components/Common/Link'

export const StyledButton = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  fontSize: '12px',
  width: '75px',
  height: '25px',
  backgroundColor: colors.background,
  cursor: 'pointer',

  ':hover': {
    backgroundColor: colors.gray,
  },

  svg: {
    width: '12px',
    height: '12px',
  },

  '&:hover': {
    backgroundColor: colors.lightBlue,
    color: colors.lightWhite,
  },
})

export const StyledVerticalButton = styled(Button)({
  display: 'flex',
  justifyContent: 'left !important',
  borderRadius: '0px !important',
  alignItems: 'center',
  fontSize: '12px !important',
  fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
  width: '80px',
  height: '25px !important',
  backgroundColor: colors.background,
  color: `${colors.black} !important`,
  cursor: 'pointer',
  paddingLeft: '0.5rem',
  svg: {
    width: '12px',
    height: '12px',
  },

  '&:hover': {
    backgroundColor: `${colors.lightBlue} !important`,
    color: `${colors.lightWhite} !important`,
  },
})

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.commonWhite,
    width: 426,
    maxWidth: 500,
  },
}))

export const StyledLink = styled(Link)({
  position: 'relative',
  top: '-10px',
  '&:hover': {
    textDecoration: 'none',
  },
})
