import React from 'react'
import { SxProps, Typography } from '@mui/material'
import {
  Box,
  colors,
  InspectionStatusEnum,
  InspectorStatusEnum,
  PublicationStatusEnum,
  StatusStyles as InspectionStatusStyles,
  TemplateStatusEnum,
  UserStatusEnum,
} from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

type TagProps = {
  status?:
    | InspectionStatusEnum
    | InspectorStatusEnum
    | TemplateStatusEnum
    | PublicationStatusEnum
    | UserStatusEnum
  backgroundColor?: string
  color?: string
  text?: string
  boxStyle?: SxProps
  textStyle?: SxProps
}

const Tag = ({
  status = InspectionStatusEnum.APPROVED,
  backgroundColor,
  color,
  text,
  boxStyle,
  textStyle,
}: TagProps) => {
  const { text: translation } = useTranslation(textFiles.GENERAL)

  return (
    <Box sx={boxStyle}>
      <Typography
        variant="body2"
        sx={{
          width: '120px',
          height: '37px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          userSelect: 'none',
          border: `2px solid ${colors.commonWhite}`,
          color: color || InspectionStatusStyles[status].color,
          backgroundColor:
            backgroundColor || InspectionStatusStyles[status].backgroundColor,
          textAlign: 'center',
          ...textStyle,
        }}
      >
        {text || translation.status[status]}
      </Typography>
    </Box>
  )
}

export default Tag
