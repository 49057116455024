import React from 'react'
import { Avatar, colors } from 'curbo-components-library'

type FlagProps = {
  image: string
}

const Flag = ({ image }: FlagProps) => {
  return (
    <Avatar
      height={20}
      image={image}
      width={32}
      variant="square"
      style={{
        border: `2px solid ${colors.commonWhite}`,
        borderRadius: '4px',
      }}
    />
  )
}

export default Flag
