import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import AddCircle from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  ClickAwayListener,
  IconButton,
  Popover,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  BaseIdEntity,
  Button,
  GenericData,
  GenericInputVariable,
  getDateRangeString,
  LoadingAnimation,
} from 'curbo-components-library'
import { parseISO, startOfToday } from 'date-fns'

import Link from 'components/Common/Link'
import {
  TableBodyRow,
  TableCell,
  TableNative,
} from 'components/Common/TableNative'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  CreateAbsenceInput,
  InspectorAbsence,
  InspectorOptionSelect,
  UpdateAbsenceInput,
} from 'models/services/inspector/detail'

import {
  CREATE_ABSENCE,
  END_ABSENCE,
  UPDATE_ABSENCE,
} from 'graphQL/Inspector/Detail/mutations'
import { GET_ABSENCE_REASONS } from 'graphQL/Inspector/Detail/queries'

import AbsencePopper from './AbsencePopper'
import {
  ClockIcon,
  IconButtonStyle,
  RecordButtonSx,
  RedHistoryIcon,
  StyledBox,
  TableTitle,
} from './style'

export type AbsenceReasonType = {
  name: string
  id: string
  slug: string
}

export type LocalAbsenceType = {
  key: number
} & InspectorAbsence

export type AbsenceManagerProps = {
  inspectorId: string
  activeAbsences: LocalAbsenceType[]
  pastAbsences: LocalAbsenceType[]
  refreshFunction: () => void
}

const AbsenceManager = ({
  inspectorId,
  activeAbsences,
  pastAbsences,
  refreshFunction,
}: AbsenceManagerProps) => {
  const { show } = useNotification()
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)
  const { personal: translation } = text
  const { absenceManager } = translation

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [editingStatus, setEditingStatus] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [reasons, setReasons] = useState<InspectorOptionSelect[]>([])
  const [motivesAnchor, setMotivesAnchor] = React.useState<HTMLElement | null>(
    null
  )
  const openRedirect = Boolean(motivesAnchor)

  const handleRedirectClose = () => {
    setMotivesAnchor(null)
  }

  const [selectedAbsence, setSelectedAbsence] = useState<
    LocalAbsenceType | undefined
  >(activeAbsences[0])

  const newAbsence = (length: number) => {
    const date = startOfToday().toISOString()
    return {
      key: length + 1,
      id: '',
      startDate: date,
      endDate: date,
      reason: reasons[0],
    } as LocalAbsenceType
  }

  const getMotive = (id: string) => {
    return reasons.find((reason) => id === reason.id)?.name
  }

  const { loading: loadingAbsenceReasons } = useQuery<
    GenericData<InspectorOptionSelect[]>
  >(GET_ABSENCE_REASONS, {
    onCompleted({ data }) {
      setReasons(data)
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const [createAbsence, { loading: submitCreateLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateAbsenceInput>
  >(CREATE_ABSENCE, {
    onCompleted() {
      refreshFunction()
      show({
        updatedSeverity: 'success',
        message: translation.summary.success,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.summary.failure,
      })
    },
  })

  const [updateAbsence, { loading: editAbsenceLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateAbsenceInput>
  >(UPDATE_ABSENCE, {
    onCompleted() {
      refreshFunction()
      show({
        updatedSeverity: 'success',
        message: translation.summary.modify,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.summary.failure,
      })
    },
  })

  const [endAbsence, { loading: endAbsenceLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(END_ABSENCE, {
    onCompleted() {
      refreshFunction()
      setSelectedAbsence(newAbsence(activeAbsences.length))
      show({
        updatedSeverity: 'success',
        message: translation.summary.end,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.summary.failure,
      })
    },
  })

  const noAbsenceReasons = reasons.length === 0

  const handleEditingStatus = (status: boolean) => {
    setEditingStatus(status)
  }

  const handleOpenPopper = (
    event: React.MouseEvent<HTMLButtonElement>,
    edit: boolean,
    absence: LocalAbsenceType
  ) => {
    setAnchorEl(event.currentTarget)
    setEditMode(edit)
    setOpen((prev) => !prev)
    setSelectedAbsence(absence)
  }

  const handlePopperClose = () => {
    if (editingStatus) {
      return
    }
    setEditMode(false)
    setOpen(false)
  }

  const addNewAbsence = (selAbsence: CreateAbsenceInput) => {
    createAbsence({ variables: { input: selAbsence } })
  }

  const handleEndFunction = (id: string) => {
    endAbsence({ variables: { input: id } })
  }

  const modifyAbsence = (updateInput: UpdateAbsenceInput) => {
    updateAbsence({ variables: { input: updateInput } })
  }

  if (
    loadingAbsenceReasons ||
    submitCreateLoading ||
    endAbsenceLoading ||
    editAbsenceLoading
  ) {
    return (
      <LoadingAnimation
        showAnimation={
          loadingAbsenceReasons ||
          endAbsenceLoading ||
          submitCreateLoading ||
          editAbsenceLoading
        }
      />
    )
  }

  return (
    <StyledBox>
      <Box display="flex" flexDirection="row">
        <ClockIcon />
        <Typography>{absenceManager.active}</Typography>
      </Box>
      <TableNative
        maxHeight={150}
        style={{ overflowX: 'hidden' }}
        tableProps={{
          stickyHeader: true,
          size: 'small',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={TableTitle}>
              {absenceManager.tableRowDate}
            </TableCell>
            <TableCell align="left" sx={TableTitle}>
              {absenceManager.tableRowMotive}
            </TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeAbsences.map((row) => (
            <TableBodyRow extraButton key={row.id}>
              <TableCell align="left" body>
                <Typography variant="subtitle1" sx={{ width: '138px' }}>
                  {getDateRangeString({
                    fromDate: parseISO(row.startDate),
                    toDate: parseISO(row.endDate),
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left" body>
                <Typography variant="subtitle1" sx={{ width: '56px' }}>
                  {getMotive(row.reason.id)}
                </Typography>
              </TableCell>
              <TableCell sx={{ paddingY: '0px' }}>
                <IconButton
                  disabled={noAbsenceReasons}
                  onClick={(e) => handleOpenPopper(e, true, row)}
                  sx={{ fontSize: '14px', ...IconButtonStyle }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </TableNative>
      <Box>
        {reasons.length === 0 && (
          <Typography sx={{ paddingTop: '5px', textDecoration: 'underline' }}>
            {absenceManager.noReasons}
          </Typography>
        )}
        <Box
          onMouseEnter={(e) =>
            noAbsenceReasons && setMotivesAnchor(e.currentTarget)
          }
        >
          <Button
            disabled={noAbsenceReasons}
            sx={RecordButtonSx}
            onClick={(e) =>
              handleOpenPopper(e, false, newAbsence(activeAbsences.length))
            }
          >
            {reasons.length !== 0 ? <AccessTimeFilledIcon /> : <AddCircle />}
            <Typography color="white">
              {reasons.length !== 0
                ? absenceManager.record
                : absenceManager.createMotive}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        <RedHistoryIcon />
        <Typography>{absenceManager.past}</Typography>
      </Box>
      <TableNative
        maxHeight={145}
        tableProps={{
          stickyHeader: true,
          size: 'small',
          'aria-label': 'a dense table',
          style: { width: '235px' },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={TableTitle}>
              {absenceManager.tableRowDate}
            </TableCell>
            <TableCell align="left" sx={TableTitle}>
              {absenceManager.tableRowMotive}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pastAbsences.map((row) => (
            <TableBodyRow key={row.id}>
              <TableCell body>
                <Typography variant="subtitle1" sx={{ width: '138px' }}>
                  {getDateRangeString({
                    fromDate: parseISO(row.startDate),
                    toDate: parseISO(row.endDate),
                  })}
                </Typography>
              </TableCell>
              <TableCell body>
                <Typography variant="subtitle1" sx={{ width: '56px' }}>
                  {getMotive(row.reason.id)}
                </Typography>
              </TableCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </TableNative>
      {reasons.length > 0 && activeAbsences.length >= 0 && open ? (
        <AbsencePopper
          open={open}
          anchor={anchorEl}
          handleClose={handlePopperClose}
          edit={editMode}
          editingStatus={editingStatus}
          handleEditingStatus={handleEditingStatus}
          inspectorId={inspectorId}
          selectedAbsence={
            editMode ? selectedAbsence! : newAbsence(activeAbsences.length)
          }
          reasons={reasons}
          addNewAbsence={addNewAbsence}
          editAbsence={modifyAbsence}
          endAbsence={handleEndFunction}
        />
      ) : null}
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={openRedirect}
        anchorEl={motivesAnchor}
      >
        <ClickAwayListener onClickAway={() => setMotivesAnchor(null)}>
          <Box
            width="250px"
            onMouseLeave={handleRedirectClose}
            padding="10px"
            paddingRight="50px"
          >
            <Typography variant="body1">
              {absenceManager.noMotives1}{' '}
              <Link to={routes.ABSENCE_MOTIVES}>
                {absenceManager.noMotivesLink}
              </Link>{' '}
              {absenceManager.noMotives2}
            </Typography>
          </Box>
        </ClickAwayListener>
      </Popover>
    </StyledBox>
  )
}

export default AbsenceManager
