import React, { useReducer, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  BodyContainerWithHeader,
  createSimpleStepperItems,
  CurboSpot,
  FilterInputVariable,
  GenericData,
  Stepper,
} from 'curbo-components-library'

import { BackContainer } from 'components/Common/BackContainer'

import { DAY_ENUM } from 'constants/date'
import { meridiamOptions } from 'constants/inspection'
import { mapViewConstant } from 'constants/map'
import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import {
  DealerInformationModel,
  SchedulingModel,
  VehicleInformationModel,
} from 'models/inspection'
import {
  InspectionCreationAction,
  InspectionCreationModel,
} from 'models/inspectionCreation'
import { Address } from 'models/map'
import { Brand, Color } from 'models/services/car'
import { Dealer } from 'models/services/curboSpot'
import inspectionCreationReducer from 'reducers/Inspection/inspectionCreationReducer'
import { INITIAL_INSPECTION_CREATION_STEPS as steps } from 'utils/Inspection/creation'

import {
  GET_INSPECTION_BRANDS,
  GET_INSPECTION_COLORS,
} from 'graphQL/Common/CarFeature/queries'
import { GET_CURBO_SPOTS, GET_DEALERS } from 'graphQL/Common/Dealer/queries'

const initialDealerInformationState: DealerInformationModel = {
  template: null,
}
const initialVehicleInformationState: VehicleInformationModel = {
  vehicleBrand: null,
  vehicleModel: null,
  vehicleYear: null,
  vehicleTrimLevel: null,
  price: 0,
  currency: 'USD',
  vehicleMileage: 0,
  exteriorColor: null,
  interiorColor: null,
  currentModelList: [],
  currentYearList: [],
  currentTrimLevelList: [],
  id: 0,
}

const initialSchedulingState: SchedulingModel = {
  address: undefined,
  appointmentDate: null,
  inspector: null,
  meridiam: meridiamOptions[0].value,
  time: '',
  weekCalendar: null,
  currentDayKey: DAY_ENUM.MONDAY,
}

const initialData: InspectionCreationModel = {
  dealerInformation: initialDealerInformationState,
  vehicleInformation: initialVehicleInformationState,
  schedulingInformation: initialSchedulingState,
}

const InspectionCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentCountry] = useSetting()
  const { userDealer } = useUser()

  const getDealerAddres = (): Address | undefined => {
    if (userDealer) {
      const { id, name, latitude, longitude, address } = userDealer
      const dealerAddress = {
        id,
        name,
        lat: latitude,
        lng: longitude,
        address,
        originFromSpot: false,
      }
      return dealerAddress
    }
    return undefined
  }

  const selectedAddress =
    getDealerAddres() || mapViewConstant[currentCountry?.countryCode || 'DOM']
  const defaultData = {
    ...initialData,
    schedulingInformation: {
      ...initialData.schedulingInformation,
      address: selectedAddress,
    },
  }
  const [inspectionData, dispatch] = useReducer(
    inspectionCreationReducer,
    defaultData,
    () => {
      const dealerAddress = getDealerAddres()
      return {
        ...defaultData,
        schedulingInformation: {
          ...defaultData.schedulingInformation,
          address:
            dealerAddress ||
            mapViewConstant[currentCountry?.countryCode || 'DOM'],
        },
      }
    }
  )
  const [dealers, setDealers] = useState<Dealer[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  const [colorList, setColorList] = useState<Color[]>([])
  const [curboSpots, setCurboSpots] = useState<CurboSpot[]>([])

  const { text } = useTranslation(textFiles.INSPECTION_CREATION)

  const stepperItems = createSimpleStepperItems(text.stepper)

  useQuery<GenericData<Dealer[]>, FilterInputVariable>(GET_DEALERS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setDealers(response.data)
    },
  })

  useQuery<GenericData<Brand[]>, FilterInputVariable>(GET_INSPECTION_BRANDS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setBrands(response.data)
    },
  })

  useQuery<GenericData<Color[]>, FilterInputVariable>(GET_INSPECTION_COLORS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setColorList(response.data)
    },
  })

  useQuery<GenericData<CurboSpot[]>, FilterInputVariable>(GET_CURBO_SPOTS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCurboSpots(response.data)
    },
  })

  const updateInspectionData = (action: InspectionCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  return (
    <>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
      >
        <Stepper
          currentStep={currentStep}
          stepperItems={stepperItems}
          barWidth="200px"
          topSx={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        />
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          inspectionData,
          updateInspectionData,
          dealers,
          brands,
          colorList,
          curboSpots,
        })}
      </BodyContainerWithHeader>
    </>
  )
}

export default InspectionCreationPage
