import { gql } from '@apollo/client'

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    data: createUser(input: $input) {
      id
    }
  }
`

export const CREATE_ORG_USER = gql`
  mutation createOrganizationUser($input: CreateOrganizationUserInput!) {
    data: createOrganizationUser(input: $input) {
      id
    }
  }
`
