import React, { useReducer, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  BaseEntity,
  BodyContainerWithHeader,
  createSimpleStepperItems,
  ExtendedStatus,
  FilterInputVariable,
  GenericData,
  LoadingAnimation,
  Option,
  Stepper,
} from 'curbo-components-library'

import GoBackButton from 'components/Common/GoBackButton'

import { initialData } from 'constants/PreInventory/creation'
import { defaultPreInventorySortModel } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  InventoryColor,
  VehicleInformationSelectOptions,
} from 'models/services/inventory/detail'
import { VehicleCreationAction } from 'models/services/PreInventory/creation'
import { InventoryStatus } from 'models/status'
import { vehicleCreationReducer } from 'reducers/PreInventory/vehicleCreationReducer'
import {
  createVehicleStepperItems,
  INITIAL_VEHICLE_CREATION_STEPS as secondarySteps,
  INITIAL_VEHICLE_MAIN_STEPS as mainSteps,
} from 'utils/PreInventory/creation'

import { GET_COUNTRY_VERSIONS } from 'graphQL/Common/Car/queries'
import { GET_INSPECTION_BRANDS } from 'graphQL/Common/CarFeature/queries'
import { GET_VEHICLE_INFORMATION_SELECT_OPTIONS } from 'graphQL/Common/Vehicle/queries'
import { GET_COLORS } from 'graphQL/Inventory/Detail/queries'
import { GET_CAR_STATUSES } from 'graphQL/Inventory/Listing/queries'

export const initialSelectOptions: VehicleInformationSelectOptions = {
  bodyStyles: [],
  categories: [],
  driveTrains: [],
  features: [],
  fuelTypes: [],
  transmissions: [],
}

const stepperProps = {
  barWidth: '12vw',
  topSx: {
    width: '100%',
    justifyContent: 'start',
    alignItems: 'end',
  },
}

const PreInventoryCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentSecondaryStep, setCurrentSecondaryStep] = useState<number>(0)
  const [vehicleData, dispatch] = useReducer(
    vehicleCreationReducer,
    initialData
  )

  const [brands, setBrands] = useState<BaseEntity[]>([])
  const [statusList, setStatusList] = useState<ExtendedStatus[]>([])
  const [vehicleSelectOptions, setVehicleSelectOptions] =
    useState<VehicleInformationSelectOptions>(initialSelectOptions)
  const [colorList, setColorList] = useState<InventoryColor[]>([])
  const [countryVersionList, setCountryVersionList] = useState<Option[]>([])
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const { text } = useTranslation(textFiles.VEHICLE_CREATION)
  const stepperItems = createVehicleStepperItems(text.stepper)
  const secondaryStepperItems = createSimpleStepperItems(text.stepper)

  const { loading: brandsLoading } = useQuery<
    GenericData<BaseEntity[]>,
    FilterInputVariable
  >(GET_INSPECTION_BRANDS, {
    variables: {
      input: {
        sort: {
          [defaultPreInventorySortModel.field]:
            defaultPreInventorySortModel.sort,
        },
      },
    },
    onCompleted(response) {
      if (response.data) {
        setBrands(response.data)
      }
    },
  })

  const { loading: statusLoading } = useQuery<
    GenericData<ExtendedStatus[]>,
    FilterInputVariable
  >(GET_CAR_STATUSES, {
    variables: {
      input: {
        sort: {
          [defaultPreInventorySortModel.field]:
            defaultPreInventorySortModel.sort,
        },
        where: {
          slug_in: [InventoryStatus.TO_REVIEW],
        },
      },
    },
    onCompleted(response) {
      setStatusList(response.data)
    },
  })

  const { loading: vehicleSelectOptionsLoading } = useQuery<
    VehicleInformationSelectOptions,
    FilterInputVariable
  >(GET_VEHICLE_INFORMATION_SELECT_OPTIONS, {
    variables: {
      input: {
        sort: {
          [defaultPreInventorySortModel.field]:
            defaultPreInventorySortModel.sort,
        },
      },
    },
    onCompleted(response) {
      setVehicleSelectOptions(response)
    },
  })

  const { loading: colorsLoading } = useQuery<
    GenericData<InventoryColor[]>,
    FilterInputVariable
  >(GET_COLORS, {
    variables: {
      input: {
        sort: {
          [defaultPreInventorySortModel.field]:
            defaultPreInventorySortModel.sort,
        },
      },
    },
    onCompleted(response) {
      setColorList(response.data)
    },
  })

  const { loading: countryVersionsLoading } = useQuery<
    GenericData<Option[]>,
    FilterInputVariable
  >(GET_COUNTRY_VERSIONS, {
    variables: {
      input: {
        sort: {
          [defaultPreInventorySortModel.field]:
            defaultPreInventorySortModel.sort,
        },
      },
    },
    onCompleted(response) {
      setCountryVersionList(response.data)
    },
  })

  /**
   * Util functions
   */
  const updateVehicleData = (action: VehicleCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleSecondaryContinue = () => {
    /**
     * we will save the data in the component
     */
    if (currentSecondaryStep + 1 < 6)
      setCurrentSecondaryStep(currentSecondaryStep + 1)
    else setCurrentStep((step) => step + 1)
  }

  const handleSecondaryBack = () => {
    /**
     * we will save the data in the component
     */
    if (currentSecondaryStep - 1 >= 0)
      setCurrentSecondaryStep((step) => step - 1)
    else setCurrentStep((step) => step - 1)
  }

  const stepsProps = {
    handleContinue,
    handleBack,
    handleSecondaryContinue,
    handleSecondaryBack,
    currentSecondaryStep,
    vehicleData,
    updateVehicleData,
    brands,
    statusList,
    vehicleSelectOptions,
    colorList,
    countryVersionList,
  }

  if (
    brandsLoading ||
    statusLoading ||
    vehicleSelectOptionsLoading ||
    colorsLoading ||
    countryVersionsLoading
  )
    return <LoadingAnimation showAnimation />

  return (
    <>
      <GoBackButton>{generalText.buttons.backButton}</GoBackButton>
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
      >
        <Stepper
          currentStep={currentStep}
          currentSecondaryStep={currentSecondaryStep}
          secondaryStepAmount={6}
          stepperItems={
            currentStep === 0 ? stepperItems : secondaryStepperItems
          }
          {...stepperProps}
        />

        {currentStep === 0
          ? React.createElement(
              secondarySteps[currentSecondaryStep],
              stepsProps
            )
          : null}
        {currentStep > 0
          ? React.createElement(mainSteps[currentStep], stepsProps)
          : null}
      </BodyContainerWithHeader>
    </>
  )
}

export default PreInventoryCreationPage
