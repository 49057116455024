export const operations = 'operations'
export const carSettings = `${operations}/carSettings`
export const inventoryRoot = 'inventory'
export const userManagementRoot = 'userManagement'
const customerRelationshipRoot = 'customerRelationship'

export const carSettingsTextFile = {
  CAR_SETTINGS_HOME: `${carSettings}/home`,
  BRAND_LISTING: `${carSettings}/listing/brandListing`,
  MODEL_LISTING: `${carSettings}/listing/modelListing`,
  TRIM_LISTING: `${carSettings}/listing/trimListing`,
  COUNTRY_VERSION_LISTING: `${carSettings}/listing/countryVersionListing`,
  BRAND_DETAIL: `${carSettings}/detail/brandDetail`,
  MODEL_DETAIL: `${carSettings}/detail/modelDetail`,
  TRIM_DETAIL: `${carSettings}/detail/trimDetail`,
  COUNTRY_VERSION_DETAIL: `${carSettings}/detail/countryVersionDetail`,
}

export const inspectionTextFile = {
  INSPECTION_DASHBOARD: 'inspectionDashboard',
  INSPECTION_LISTING: 'inspectionListing',
  INSPECTION_DETAIL: 'inspectionDetail',
  INSPECTION_CREATION: 'inspectionCreation',
}

export const inspectorTextFile = {
  INSPECTOR_DETAIL: 'inspectorDetail',
  INSPECTOR_DASHBOARD: 'inspectorDashboard',
  INSPECTOR_LISTING: 'inspectorListing',
  INSPECTOR_CALENDAR: 'inspectorCalendar',
}

export const operationsTextFile = {
  SCHEDULE_MANAGEMENT: `${operations}/scheduleManagement`,
}

export const inventoryTextFile = {
  INVENTORY_DETAIL: `${inventoryRoot}/inventoryDetail`,
  PRE_INVENTORY_LISTING: `${inventoryRoot}/preInventoryListing`,
  INVENTORY_LISTING: `${inventoryRoot}/inventoryListing`,
  VEHICLE_CREATION: `${inventoryRoot}/vehicleCreation`,
}

export const userManagementTextFile = {
  USER_MANAGEMENT_LISTING: `${userManagementRoot}/listing`,
  USER_MANAGEMENT_DETAIL: `${userManagementRoot}/detail`,
  USER_MANAGEMENT_CREATION: `${userManagementRoot}/creation`,
}

export const customerRelationshipTextFile = {
  LEAD_BOARD: `${customerRelationshipRoot}/leadBoard`,
  LEAD_DETAIL: `${customerRelationshipRoot}/leadDetail`,
  LEAD_LISTING: `${customerRelationshipRoot}/leadListing`,
  LEAD_CREATION: `${customerRelationshipRoot}/leadCreation`,
}

export const textFiles = {
  ACCOUNT: 'myAccount',
  ASK_FOR_HELP: 'askForHelp',
  AUTH: 'auth',
  ERROR: 'errorPages',
  FORGOT_PASSWORD: 'forgotPassword',
  GENERAL: 'general',
  LOGIN: 'login',
  NOTIFICATION: 'notification',
  VALIDATION: 'validation',
  DOWNLOAD_REPORT: 'downloadReport',
  ...carSettingsTextFile,
  ...inspectionTextFile,
  ...inspectorTextFile,
  ...userManagementTextFile,
  ...carSettingsTextFile,
  ...inspectionTextFile,
  ...inventoryTextFile,
  ...operationsTextFile,
  ...customerRelationshipTextFile,
}
