import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import ForgotPasswordPage from 'pages/Auth/ForgotPassword'
import LoginPage from 'pages/Auth/Login'
import ResetPassword from 'pages/Auth/ResetPassword'

import AuthLayout from 'components/Auth/Layout'

import { routes } from 'constants/routes'
import useUser from 'hooks/useUser'

export const AuthRouter = () => {
  const { user } = useUser()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { from } = (location.state as any) || {
    from: { pathname: '/' },
  }

  return (
    <AuthLayout>
      <Switch>
        <Route path={routes.FORGOT_PASSWORD} component={ForgotPasswordPage} />
        <Route path={routes.RESET_PASSWORD} component={ResetPassword} />

        {/* TODO: RE-ROUTE TO HOME WHEN THERE'S A HOMEPAGE */}
        {user !== undefined ? (
          <Redirect
            to={from.pathname.length > 1 ? from : routes.ACCOUNT_SETTINGS}
          />
        ) : null}
        <Route path={routes.LOGIN} component={LoginPage} />
      </Switch>
    </AuthLayout>
  )
}
