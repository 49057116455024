import { styled, Typography } from '@mui/material'
import { Box, colors, FONT_WEIGHT } from 'curbo-components-library'

export const StyledDisclaimerText = styled(Typography)({
  marginLeft: '0.5em',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.gray,
})

export const StyledStatusContainer = styled(Box)({
  display: 'flex',
  margin: '1em 0',
})
