import { CurboSpot } from 'curbo-components-library'

import InspectionDealerInformation from 'components/Inspection/Creation/DealerInfo'
import InspectionScheduling from 'components/Inspection/Creation/Scheduling'
import InspectionSummary from 'components/Inspection/Creation/Summary'
import VehicleInformation from 'components/Inspection/Creation/VehicleInfo'

import {
  InspectionCreationAction,
  InspectionCreationModel,
} from 'models/inspectionCreation'
import { Brand, Color } from 'models/services/car'
import { Dealer } from 'models/services/curboSpot'

export type InspectionCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  dealers: Dealer[]
  brands: Brand[]
  colorList: Color[]
  curboSpots: CurboSpot[]
  inspectionData: InspectionCreationModel
  updateInspectionData: (action: InspectionCreationAction) => void
}

export const INITIAL_INSPECTION_CREATION_STEPS: Array<
  React.FC<InspectionCreationProps>
> = [
  InspectionDealerInformation,
  VehicleInformation,
  InspectionScheduling,
  InspectionSummary,
]
