import { gql } from '@apollo/client'

export const LIST_DEALER_INSPECTIONS = gql`
  query listDealerInspections($input: FilterInput) {
    listDealerInspections(input: $input) {
      count
      data {
        id
        date
        createdAt
        status {
          backgroundColor
          id
          textColor
          name
        }
        car {
          model {
            name
            brand {
              name
            }
          }
          year
        }
      }
    }
  }
`

export const GET_INSPECTION_STATUSES = gql`
  query getInspectionStatuses($input: FilterInput) {
    getInspectionStatuses(input: $input) {
      backgroundColor
      description
      id
      name
      textColor
      slug
    }
  }
`

export const GET_INSPECTION_MODELS = gql`
  query getInspectionModels($input: FilterInput) {
    data: getInspectionModels(input: $input) {
      value: id
      name
      brand {
        id
      }
    }
  }
`

export const GET_INSPECTION_BRANDS = gql`
  query getInspectionBrands($input: FilterInput) {
    data: getInspectionBrands(input: $input) {
      value: id
      name
      models {
        id
      }
    }
  }
`
