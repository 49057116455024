import { Chip, styled } from '@mui/material'
import { Box, colors, FONT_WEIGHT } from 'curbo-components-library'

export const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  padding: '0 0.5rem',
})

export const StyledChip = styled(Chip)({
  backgroundColor: colors.commonWhite,
  fontWeight: FONT_WEIGHT.MEDIUM,
  fontSize: '12px',
  height: '15px',
})
