import { SelectItem } from 'curbo-components-library'

import { BaseColumnField } from 'constants/operation/carSettings/columnField'
import { BaseSelectTextType } from 'models/text/carSettingsListing'

export const createBaseFieldSelectItems = (
  text: BaseSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: BaseColumnField.ID,
    },
    {
      label: text.name,
      value: BaseColumnField.NAME,
    },
    {
      label: text.createdAt,
      value: BaseColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: BaseColumnField.STATUS,
    },
  ]
}

export const createBaseFieldSelectLabels = (
  text: BaseSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    createdAt: text.createdAt,
    status: text.status,
    name: text.name,
  }
}

export const baseDefaultFields = [
  BaseColumnField.ID,
  BaseColumnField.NAME,
  BaseColumnField.CREATED_AT,
  BaseColumnField.STATUS,
]
