export enum BaseColumnField {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  PICTURE = 'picture',
}

export enum ModelColumnField {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  PICTURE = 'picture',
  BRAND_NAME = 'brandName',
}

export enum TrimColumnField {
  ID = 'id',
  CAR_NAME = 'carName',
  NAME = 'name',
  YEAR = 'year',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
}
