import { styled } from '@mui/material/styles'
import { Box, colors } from 'curbo-components-library'

export const StyledContainer = styled(Box)({
  backgroundColor: colors.background,
  borderRadius: '10px',
  border: `1px solid ${colors.border}`,
  padding: '11px',
  width: '338px',
})
