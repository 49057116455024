import React from 'react'
import { Box, Typography } from '@mui/material'
import { colors } from 'curbo-components-library'

import { ExtendedRole } from 'models/services/General/UserManagement/detail'

import { flexStyle, StyledSquareIcon } from './style'

export type StaticRoleFieldProps = {
  role?: ExtendedRole
}
export const StaticRoleField = ({ role }: StaticRoleFieldProps) => {
  return (
    <Box sx={flexStyle}>
      <StyledSquareIcon />
      {role && (
        <>
          <Typography variant="body1">{role.area.name}</Typography>
          <Typography
            variant="body1"
            marginX="10px"
            color={colors.placeholderGray}
          >
            -
          </Typography>
          <Typography variant="body1" color={colors.placeholderGray}>
            {role.name}
          </Typography>
        </>
      )}
    </Box>
  )
}
