import { styled } from '@mui/material'
import {
  Box,
  BREAK_POINTS,
  colors,
  Container,
  FONT_WEIGHT,
} from 'curbo-components-library'

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  minHeight: '383px',
  width: '100%',
  '> div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
})

export const StyledItem = styled(Container)({
  maxHeight: 190,
  color: colors.placeholderGray,
  borderRadius: 0,
  padding: 0,
  '& > div:nth-of-type(2)': {
    margin: 0,
  },
  ul: {
    paddingLeft: '35px',
    fontSize: 14,
    fontWeight: FONT_WEIGHT.MEDIUM,
    li: {
      '&::marker': {
        fontSize: 16,
        color: colors.blue,
      },
      span: {
        color: colors.black,
      },
      marginBottom: '12px',
    },
  },
})

export const GridContainer = styled('div')({
  marginTop: 16,
  display: 'grid',
  gridTemplateColumns: '45% 45%',
  rowGap: 20,
  width: '100%',
  [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
    gridTemplateColumns: '45% 45%',
  },
})
