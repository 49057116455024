import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  Autocomplete,
  AutocompleteItem,
  BaseEntity,
  ButtonNavigator,
  ColorBox,
  FilterInput,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  Slider,
} from 'curbo-components-library'

import { eventType } from 'components/General/NumberInput'

import { maxMileage } from 'constants/car'
import {
  initialCarErrors,
  initialCarInformation,
  LEAD_CREATION_OPTION,
} from 'constants/Lead/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Color, ModelTrimYears } from 'models/services/car'
import {
  CarInfoError,
  CarLeadInformation,
  CTAEnum,
} from 'models/services/customerRelationship/lead/creation'
import { LeadCreationProps } from 'utils/Lead/creation'

import { GET_INSPECTION_MODELS } from 'graphQL/Common/CarFeature/queries'
import {
  GET_INSPECTION_TRIM_LEVELS,
  GET_INSPECTION_TRIM_YEARS,
} from 'graphQL/Inspection/Creation/queries'

import {
  BoxContainer,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const CarInformation = ({
  leadData,
  handleSecondaryContinue,
  handleSecondaryBack,
  brands,
  updateLeadData,
  colorList,
}: LeadCreationProps) => {
  const isSmcCta = leadData.generalInformation.cta === CTAEnum.SellMyCar
  const [carInfo, setCarInfo] = useState<CarLeadInformation>(
    leadData.carInformation
  )

  const [models, setModels] = useState<BaseEntity[]>(carInfo.currentModelList)
  const [years, setYears] = useState<BaseEntity[]>(carInfo.currentYearList)

  const [errors, setErrors] = useState<CarInfoError>(initialCarErrors)

  const { text: translation } = useTranslation(textFiles.LEAD_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { carInformationStep } = translation
  const [trimLevelsList, setTrimLevelsList] = useState<BaseEntity[]>(
    carInfo.currentTrimLevelList
  )
  const [fetchModels, { loading: modelsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    GenericInputVariable<FilterInput>
  >(GET_INSPECTION_MODELS, {
    onCompleted(response) {
      setModels(response.data)
    },
  })

  const [fetchYears, { loading: yearsLoading }] = useLazyQuery<
    GenericData<ModelTrimYears>,
    GenericInputVariable<string>
  >(GET_INSPECTION_TRIM_YEARS, {
    onCompleted(response) {
      setYears(
        response.data.trimYears
          .sort((trimYearA, trimYearB) => trimYearB - trimYearA)
          .map((trimYear) => {
            return {
              name: String(trimYear),
              id: trimYear,
            }
          })
      )
    },
  })

  const [fetchTrims, { loading: trimsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    FilterInputVariable
  >(GET_INSPECTION_TRIM_LEVELS, {
    onCompleted(response) {
      setTrimLevelsList(response.data)
    },
  })

  const handleSelectBrand = (value: AutocompleteItem) => {
    setCarInfo((prevInfo) => {
      return {
        ...prevInfo,
        carBrand: value,
        carModel: null,
        carYear: null,
        carTrimLevel: null,
      }
    })
    fetchModels({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            brand_eq: value.id,
          },
        },
      },
    })
  }

  const handleLicenseChange = (e: eventType) => {
    const { target } = e
    const { value } = target
    if (value && value.length > 0) {
      setCarInfo((prevInfo) => {
        return {
          ...prevInfo,
          licensePlate: value,
        }
      })
    }
  }

  const handleSelectModel = (value: AutocompleteItem) => {
    setCarInfo((prevInfo) => {
      return {
        ...prevInfo,
        carModel: value,
        carYear: null,
        carTrimLevel: null,
      }
    })
    fetchYears({
      variables: {
        input: value.id as string,
      },
    })
  }

  const handleSelectYear = (value: AutocompleteItem) => {
    setCarInfo((prevInfo) => {
      return {
        ...prevInfo,
        carYear: value,
        carTrimLevel: null,
      }
    })
    fetchTrims({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            year: value.id,
            carModel: carInfo.carModel?.id,
          },
        },
      },
    })
  }

  const handleSliderChange = (newValue: number) => {
    setCarInfo({ ...carInfo, carMileage: newValue as number })
  }

  const handleSelectTrim = (value: AutocompleteItem) => {
    setCarInfo((prevInfo) => {
      return {
        ...prevInfo,
        carTrimLevel: value,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        trim: false,
      }
    })
  }

  const handleSubmit = () => {
    const {
      carBrand,
      carModel,
      carYear,
      exteriorColor,
      interiorColor,
      licensePlate,
    } = carInfo
    if (isSmcCta) {
      if (
        !carBrand ||
        !carModel ||
        !carYear ||
        !exteriorColor ||
        !interiorColor ||
        !licensePlate
      ) {
        setErrors({
          brand: !carBrand && true,
          model: !carModel && true,
          year: !carYear && true,
          exteriorColor: !exteriorColor && true,
          interiorColor: !interiorColor && true,
          licensePlate: !licensePlate && true,
        })
        return
      }
    }
    setErrors(initialCarErrors)
    updateLeadData({
      type: LEAD_CREATION_OPTION.UPDATE_CAR_INFORMATION,
      payload: carInfo,
    })
    handleSecondaryContinue()
  }

  useEffect(() => {
    if (typeof leadData.carInformation === 'undefined') {
      setCarInfo(initialCarInformation)
    } else {
      const { currentModelList, currentYearList } = leadData.carInformation
      setCarInfo(leadData.carInformation)
      setModels(currentModelList)
      setYears(currentYearList)
    }
  }, [leadData.carInformation])

  return (
    <StyledContainer>
      <StyledForm sx={{ minHeight: '200px' }}>
        <div>
          <StyledTextFieldContainer title={carInformationStep.carBrand}>
            <Autocomplete
              options={brands}
              onChangeHandler={handleSelectBrand}
              placeholder={carInformationStep.carBrand}
              value={carInfo.carBrand}
              disablePortal={false}
              testId="brands-autocomplete"
            />
            {errors.brand && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={carInformationStep.carModel}>
            <Autocomplete
              options={models}
              onChangeHandler={handleSelectModel}
              placeholder={carInformationStep.carModel}
              value={carInfo.carModel}
              disabled={modelsLoading || !carInfo.carBrand}
              disablePortal={false}
              testId="models-autocomplete"
            />
            {errors.model && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={carInformationStep.carYear}>
            <Autocomplete
              options={years}
              onChangeHandler={handleSelectYear}
              placeholder={carInformationStep.carYear}
              value={carInfo.carYear}
              disabled={yearsLoading || !carInfo.carModel}
              disablePortal={false}
              testId="years-autocomplete"
            />
            {errors.year && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          {isSmcCta ? (
            <>
              <StyledTextFieldContainer title={carInformationStep.carTrimLevel}>
                <Autocomplete
                  options={trimLevelsList}
                  onChangeHandler={handleSelectTrim}
                  placeholder={carInformationStep.carTrimLevel}
                  value={carInfo.carTrimLevel}
                  disabled={trimsLoading || !carInfo.carYear}
                  disablePortal={false}
                  testId="trims-autocomplete"
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={carInformationStep.licensePlate}>
                <StyledTextField
                  variant="outlined"
                  name="licensePlate"
                  value={carInfo.licensePlate}
                  placeholder={carInformationStep.licensePlate}
                  onChange={handleLicenseChange}
                  error={errors.licensePlate}
                />
                {errors.licensePlate && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={carInformationStep.carMileage}>
                <Slider
                  value={carInfo.carMileage!}
                  handleValueChange={handleSliderChange}
                  maxValue={maxMileage}
                  withTextField
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                title={carInformationStep.exteriorColor}
              >
                <BoxContainer>
                  <Autocomplete
                    options={colorList}
                    onChangeHandler={(value) => {
                      setCarInfo((prevInfo) => {
                        return {
                          ...prevInfo,
                          exteriorColor: value as Color,
                        }
                      })
                    }}
                    placeholder={carInformationStep.exteriorColor}
                    value={carInfo.exteriorColor}
                    disablePortal={false}
                    testId="exterior-colors-autocomplete"
                    width="75%"
                  />
                  <ColorBox
                    hexCode={carInfo.exteriorColor?.hexCode as string}
                    width="20%"
                    height={38}
                  />
                </BoxContainer>
                {errors.exteriorColor && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                title={carInformationStep.interiorColor}
              >
                <BoxContainer>
                  <Autocomplete
                    options={colorList}
                    onChangeHandler={(value) => {
                      setCarInfo((prevInfo) => {
                        return {
                          ...prevInfo,
                          interiorColor: value as Color,
                        }
                      })
                    }}
                    placeholder={carInformationStep.interiorColor}
                    value={carInfo.interiorColor}
                    disablePortal={false}
                    testId="interior-colors-autocomplete"
                    width="75%"
                  />
                  <ColorBox
                    hexCode={carInfo.interiorColor?.hexCode as string}
                    width="20%"
                    height={38}
                  />
                </BoxContainer>
                {errors.interiorColor && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
              </StyledTextFieldContainer>
            </>
          ) : null}
        </div>
      </StyledForm>
      <ButtonNavigator
        previousFunction={handleSecondaryBack}
        nextFunction={handleSubmit}
        translation={generalText.buttons}
      />
    </StyledContainer>
  )
}

export default CarInformation
