import { Languages, LanguagesWithKey } from 'models/language'

export const LANGUAGES: Languages = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Español',
    code: 'es',
  },
]

export const LANGUAGES_JSON: LanguagesWithKey = {
  en: {
    name: 'English',
    code: 'en',
  },
  es: {
    name: 'Español',
    code: 'es',
  },
}

export const LANGUAGE_KEY = 'lang'
