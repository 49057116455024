import { gql } from '@apollo/client'

export const GET_CASE_STEPS = gql`
  query getCaseSteps($input: FilterInput) {
    data: getCaseSteps(input: $input) {
      slug
      name
      id
      textColor
      backgroundColor
      description
    }
  }
`

export const GET_BDS = gql`
  query getBds($input: FilterInput) {
    getBds(input: $input) {
      data {
        value: id
        name
        lastName
      }
    }
  }
`

export const GET_CASE_TYPE_ENUMS = gql`
  query getCaseTypeEnums {
    data: getCaseTypeEnums
  }
`

export const GET_CASE_ORIGIN_ENUMS = gql`
  query getCaseOriginEnums {
    data: getCaseOriginEnums
  }
`
