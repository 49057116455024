import { formControlClasses, styled } from '@mui/material'
import { Container } from 'curbo-components-library'

export const StyledContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  marginBottom: '20px',
  '> p': {
    fontSize: '14px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})
