import { gql } from '@apollo/client'

import { INSPECTION_WEEK_SCHEDULE_FRAGMENT } from 'graphQL/Operations/OutOfSpot/queries'

export const GET_DEALERS = gql`
  query getDealers($input: FilterInput) {
    data: getDealers(input: $input) {
      address
      value: id
      name
      telephoneNumber
      dealerContacts {
        agent
        telephoneNumber
      }
    }
  }
`

export const GET_CURBO_SPOTS = gql`
  query getInspectionCurboSpots($input: FilterInput) {
    data: getInspectionCurboSpots(input: $input) {
      name
      id
      slug
      latitude
      longitude
      address
    }
  }
`

export const GET_PUBLISHED_CURBO_SPOTS = gql`
  query getCurboSpots($input: FilterInput) {
    data: getPublishCurboSpots(input: $input) {
      name
      id
      slug
      latitude
      longitude
      address
    }
  }
`

export const GET_ALT_CURBO_SPOTS = gql`
  query {
    data: getInspectionCurboSpots {
      address
      telephoneNumber
      id
      name
    }
  }
`

export const GET_TEST_DRIVE_CURBO_SPOTS = gql`
  query getTestDriveCurboSpots($input: FilterInput) {
    data: getTestDriveCurboSpots(input: $input) {
      name
      id
      slug
      latitude
      longitude
      address
    }
  }
`

export const GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID = gql`
  ${INSPECTION_WEEK_SCHEDULE_FRAGMENT}
  query getInspectionWeekCalendarByCurboSpotId($input: ID) {
    data: getInspectionWeekCalendarByCurboSpotId(id: $input) {
      ...InspectionWeekScheduleFragment
    }
  }
`
