import React from 'react'
import { Typography } from '@mui/material'
import { Box, FONT_WEIGHT } from 'curbo-components-library'

import { Container, StyledChip } from './style'

type CaseTitleProps = {
  backgroundColor: string
  count: number
  textColor: string
  title: string
}

const CaseTitle = ({
  backgroundColor,
  count,
  textColor,
  title,
}: CaseTitleProps) => {
  return (
    <Box
      borderRadius="8px"
      height="26px"
      width="310px"
      sx={{
        backgroundColor,
      }}
      marginTop="1rem"
    >
      <Container>
        <Typography
          color={textColor}
          fontWeight={FONT_WEIGHT.MEDIUM}
          variant="subtitle1"
        >
          {title}
        </Typography>
        <StyledChip label={count} sx={{ color: textColor }} />
      </Container>
    </Box>
  )
}

export default CaseTitle
