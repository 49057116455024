import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import {
  ClickAwayListener,
  Fade,
  Popper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import {
  Box,
  Button,
  colors,
  cypressCancelButton,
  cypressSaveButton,
  ExtendedStatus,
  FONT_WEIGHT,
} from 'curbo-components-library'

import Tag from 'components/Common/Tag'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledDisclaimerText, StyledStatusContainer } from './style'

type GenericStatusChangePopperProps = {
  status: ExtendedStatus
  loading?: boolean
  statusList: ExtendedStatus[]
  handleSaveStatus: (newStatus: ExtendedStatus) => void
}

const GenericStatusChangePopper = ({
  status,
  statusList,
  handleSaveStatus,
  loading,
}: GenericStatusChangePopperProps) => {
  const {
    text: { statusChangePopper: translation, buttons: buttonsText },
  } = useTranslation(textFiles.GENERAL)

  // manage status popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [radioSelect, setRadioSelect] = useState<ExtendedStatus>(status)

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prevState) => !prevState)
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus = statusList.find(
      (currentStatus) => currentStatus.slug === event.target.value
    )
    setRadioSelect(newStatus!)
  }

  const handleSave = () => {
    handleSaveStatus(radioSelect)
    setOpen(false)
  }

  const handleCancel = () => {
    setRadioSelect(status)
    setOpen(false)
  }

  useEffect(() => {
    setRadioSelect(status)
  }, [status])

  if (statusList.length === 0) return null

  return (
    <>
      <Box
        sx={{
          width: '42px',
          height: '37px',
          color: loading ? colors.black : status.textColor,
          backgroundColor: loading ? colors.gray : status.backgroundColor,
          border: `2px solid ${colors.commonWhite}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onMouseEnter={handleMouseOver}
        onClick={!loading ? handlePopperOpen : undefined}
        data-cy="status-changer"
      >
        <EditIcon />
      </Box>
      <Popper
        id="status-popover"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        style={{
          zIndex: 1500,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false)
              setRadioSelect(status)
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  backgroundColor: colors.commonWhite,
                  width: '530px',
                  padding: '1.75em',
                  borderRadius: '4px',
                  boxShadow: `0px 3px 6px ${colors.shadow}`,
                }}
              >
                <Typography variant="body1" color={colors.blue}>
                  {translation.statusTitle}
                </Typography>

                <Box
                  sx={{
                    margin: '0.5em 0',
                    fontSize: '14px',
                    fontWeight: FONT_WEIGHT.REGULAR,
                    color: colors.black,
                  }}
                >
                  {translation.statusDescriptionFirstPart}{' '}
                  <b>{translation.statusDescriptionSecondPart}</b>{' '}
                  {translation.statusDescriptionThirdPart}{' '}
                  <b>{translation.statusDescriptionFourthPart}</b>{' '}
                  {translation.statusDescriptionFifthPart}
                </Box>

                <RadioGroup
                  onChange={handleRadioChange}
                  value={radioSelect.slug}
                >
                  {statusList &&
                    statusList.map((currentStatus) => (
                      <StyledStatusContainer key={currentStatus.slug}>
                        <Radio
                          sx={{
                            color: colors.blue,
                          }}
                          value={currentStatus.slug}
                          data-cy={`${currentStatus.slug}-radio-button`}
                        />
                        <Tag
                          backgroundColor={currentStatus.backgroundColor}
                          color={currentStatus.textColor}
                          text={currentStatus.name}
                        />
                        <StyledDisclaimerText>
                          {currentStatus.description}
                        </StyledDisclaimerText>
                      </StyledStatusContainer>
                    ))}
                </RadioGroup>

                <Box
                  sx={{
                    margin: '2em 0 0.5em 0',
                    ' > button': {
                      height: '35px !important',
                      minWidth: '80px',
                    },
                  }}
                >
                  <Button
                    type="button"
                    buttonType="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                    sx={{ marginRight: '1em' }}
                    testId={cypressSaveButton}
                  >
                    {buttonsText.save}
                  </Button>
                  <Button
                    type="button"
                    buttonType="secondary"
                    onClick={handleCancel}
                    testId={cypressCancelButton}
                  >
                    {buttonsText.cancel}
                  </Button>
                </Box>
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}

export default GenericStatusChangePopper
