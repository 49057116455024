import { SelectItem } from 'curbo-components-library'

import { PreInventoryColumnField } from 'constants/preinventory'
import { FieldSelectTextType } from 'models/text/PreInventoryListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: PreInventoryColumnField.ID,
    },
    {
      label: text.slug,
      value: PreInventoryColumnField.SLUG,
    },
    {
      label: text.picture,
      value: PreInventoryColumnField.PICTURE,
    },
    {
      label: text.vehicle,
      value: PreInventoryColumnField.VEHICLE,
    },
    {
      label: text.trim,
      value: PreInventoryColumnField.TRIM,
    },
    {
      label: text.price,
      value: PreInventoryColumnField.PRICE,
    },
    {
      label: text.mileage,
      value: PreInventoryColumnField.MILEAGE,
    },
    {
      label: text.createdAt,
      value: PreInventoryColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: PreInventoryColumnField.STATUS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    slug: text.slug,
    picture: text.picture,
    vehicle: text.vehicle,
    trim: text.trim,
    price: text.price,
    mileage: text.mileage,
    status: text.status,
  }
}

export const defaultFields = [
  PreInventoryColumnField.PICTURE,
  PreInventoryColumnField.VEHICLE,
  PreInventoryColumnField.TRIM,
  PreInventoryColumnField.STATUS,
]
