import { Option } from 'curbo-components-library'

import Information from 'components/UserManagement/Creation/Information'
import Roles from 'components/UserManagement/Creation/Roles'
import Summary from 'components/UserManagement/Creation/Summary'

import {
  RolesOption,
  UserCreationAction,
  UserCreationModel,
} from 'models/userCreation'

export type UserCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  userData: UserCreationModel
  updateUserData: (action: UserCreationAction) => void
  handleSubmit: () => void
  submitLoading: boolean
  roleOptions: RolesOption[]
  areaOptions: Option[]
}

export const USER_CREATION_STEPS: Array<React.FC<UserCreationProps>> = [
  Information,
  Roles,
  Summary,
]
