import React, { useState } from 'react'
import {
  AutocompleteItem,
  BaseEntity,
  ButtonNavigator,
  ErrorMessage,
} from 'curbo-components-library'

import TemplatePicker from 'components/Inspection/Detail/Appointment/TemplatePicker'

import { INSPECTION_CREATION_OPTION } from 'constants/Inspection/creation'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { InspectionCreationProps } from 'utils/Inspection/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextFieldContainer,
} from 'styles/creation'

const DealerInformation = ({
  handleContinue,
  inspectionData,
  updateInspectionData,
}: InspectionCreationProps) => {
  const { text: translation } = useTranslation(textFiles.INSPECTION_CREATION)
  const { dealerInformation } = translation
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [hasError, setHasError] = useState<boolean>(false)
  const [selectedLanguage] = useLocale()
  const isEng = selectedLanguage.code === 'en'
  const templates = [
    {
      id: 'COMPLETE',
      name: isEng ? 'Complete' : 'Completa',
    },
    {
      id: 'PARTIAL',
      name: isEng ? 'Partial' : 'Parcial',
    },
    {
      id: 'EXPRESS',
      name: isEng ? 'Express' : 'Expresa',
    },
  ]

  const [selectedTemplate, setSelectedTemplate] = useState<BaseEntity | null>(
    inspectionData.dealerInformation.template
  )
  const handleTemplateChange = (value: AutocompleteItem) => {
    setSelectedTemplate(
      templates.filter((template) => template.id === value.id)[0]
    )
  }

  const handleSubmit = () => {
    if (!selectedTemplate) {
      setHasError(true)
      return
    }

    updateInspectionData({
      type: INSPECTION_CREATION_OPTION.UPDATE_DEALER_INFORMATION,
      payload: {
        template: selectedTemplate,
      },
    })

    handleContinue()
  }

  return (
    <StyledContainer>
      <StyledForm>
        <div>
          <StyledTextFieldContainer>
            <TemplatePicker
              translation={dealerInformation.templateText}
              templates={templates}
              value={selectedTemplate}
              onChange={handleTemplateChange}
            />
            {hasError && (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={validationText.fieldRequired}
              />
            )}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonNavigator
        isFirstStep
        nextFunction={handleSubmit}
        translation={generalText.buttons}
      />
    </StyledContainer>
  )
}

export default DealerInformation
