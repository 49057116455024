import { styled } from '@mui/material'
import { boxShadow, colors, Container } from 'curbo-components-library'

import Link from 'components/Common/Link'

export const StyledForm = styled('form')({
  alignItems: 'center',
  backgroundColor: colors.commonWhite,
  borderRadius: '10px',
  boxShadow,
  display: 'flex',
  flexDirection: 'column',
  padding: '3rem 4rem',
  width: '60%',
})

export const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginLeft: '5em',
})

export const StyledLink = styled(Link)({
  fontSize: '14px',
  fontWeight: 'normal',
  marginTop: '2rem',
})

export const StyledTextfieldContainer = styled('div')({
  width: '100%',
  height: '92px',
  position: 'relative',
})

export const ModelBottomContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
  background: colors.background,
  height: 90,
  paddingTop: 30,
  paddingBottom: 30,
})
