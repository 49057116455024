import { gql } from '@apollo/client'

// Inspections fragments
export const INSPECTION_HOUR_TIME_FRAGMENT = gql`
  fragment InspectionHourTimeFragment on InspectionHours {
    am
    value: id
    name
    continentalTime
  }
`

export const TEST_DRIVE_HOUR_TIME_FRAGMENT = gql`
  fragment TestDriveHourTimeFragment on TestDriveHours {
    am
    value: id
    name
  }
`

export const INSPECTION_WEEK_SCHEDULE_FRAGMENT = gql`
  ${INSPECTION_HOUR_TIME_FRAGMENT}
  fragment InspectionWeekScheduleFragment on InspectionWeekCalendar {
    id
    monday {
      ...InspectionHourTimeFragment
    }
    tuesday {
      ...InspectionHourTimeFragment
    }
    wednesday {
      ...InspectionHourTimeFragment
    }
    thursday {
      ...InspectionHourTimeFragment
    }
    friday {
      ...InspectionHourTimeFragment
    }
    saturday {
      ...InspectionHourTimeFragment
    }
    sunday {
      ...InspectionHourTimeFragment
    }
  }
`
export const TEST_DRIVE_WEEK_SCHEDULE_FRAGMENT = gql`
  ${TEST_DRIVE_HOUR_TIME_FRAGMENT}
  fragment TestDriveWeekScheduleFragment on TestDriveWeekCalendar {
    id
    monday {
      ...TestDriveHourTimeFragment
    }
    tuesday {
      ...TestDriveHourTimeFragment
    }
    wednesday {
      ...TestDriveHourTimeFragment
    }
    thursday {
      ...TestDriveHourTimeFragment
    }
    friday {
      ...TestDriveHourTimeFragment
    }
    saturday {
      ...TestDriveHourTimeFragment
    }
    sunday {
      ...TestDriveHourTimeFragment
    }
  }
`

export const GET_SELF_INSPECTION_WEEK_CALENDAR = gql`
  ${INSPECTION_WEEK_SCHEDULE_FRAGMENT}
  query selfInspectionWeekCalendar {
    data: selfInspectionWeekCalendar {
      ...InspectionWeekScheduleFragment
    }
  }
`

export const GET_SELF_TEST_DRIVE_WEEK_CALENDAR = gql`
  ${TEST_DRIVE_WEEK_SCHEDULE_FRAGMENT}
  query selfTestDriveWeekCalendar {
    data: selfTestDriveWeekCalendar {
      ...TestDriveWeekScheduleFragment
    }
  }
`
