import { gql } from '@apollo/client'

export const GET_MODELS = gql`
  query getModels($input: FilterInput) {
    data: getModels(input: $input) {
      value: id
      name
      brand {
        id
      }
    }
  }
`
