import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import {
  Box,
  BREAK_POINTS,
  Toast,
  useNotification,
  useWindowDimensions,
} from 'curbo-components-library'

import ForbiddenErrorPage from 'pages/ErrorPage/Forbidden'
import NotFoundErrorPage from 'pages/ErrorPage/NotFound'
import ResolutionErrorPage from 'pages/ErrorPage/ResolutionError'
import HomePage from 'pages/Home'

import RoleRoute from 'components/Auth/RoleRoute'
import Sidebar from 'components/General/Sidebar'

import {
  ACCOUNT_ROUTE,
  AUTH_ROUTE,
  CAR_SETTINGS_ROUTE,
  CUSTOMER_RELATIONSHIP_ROUTE,
  GENERAL_SETTINGS_ROUTE,
  INSPECTION_ROUTE,
  INSPECTOR_ROUTE,
  INVENTORY_ROUTE,
  OPERATION_ROUTE,
  routes,
} from 'constants/routes'
import { createSideBarItems } from 'constants/sidebar'
import { textFiles } from 'constants/textFiles'
import UserProvider from 'context/UserContext'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/role'
import { filterSidebarItems } from 'utils/basicUtil'

import { StyledLayout } from 'styles/protected'

import { AccountRouter } from './AccountRouter'
import { AuthRouter } from './AuthRouter'
import { CarSettingsRouter } from './CarSettingsRouter'
import { CustomerRelationshipRouter } from './CustomerRelationshipRouter'
import { GeneralSettingsRouter } from './GeneralSettingsRouter'
import { InspectionRouter } from './InspectionRouter'
import { InspectorRouter } from './InspectorRouter'
import { InventoryRouter } from './InventoryRouter'
import { OperationRouter } from './OperationRouter'
import ProtectedRoute from './ProtectedRouter'

const ProtectedRoutes = () => {
  const { text } = useTranslation(textFiles.GENERAL)
  const { userRoles: loggedUserRoles } = useUser()
  const { sidebar: translation } = text
  const sidebarElements = filterSidebarItems(
    createSideBarItems(translation),
    loggedUserRoles
  )
  return (
    <StyledLayout>
      <Sidebar items={sidebarElements} />
      <Box padding="130px 100px 50px 0px" width="100%">
        <Switch>
          <Route path={ACCOUNT_ROUTE}>
            <AccountRouter />
          </Route>
          <RoleRoute
            path={INVENTORY_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.INVENTORY_SPECIALIST,
              UserRoles.INVENTORY_SUPERVISOR,
              UserRoles.INVENTORY_READER,
            ]}
          >
            <InventoryRouter />
          </RoleRoute>
          <Route path={CUSTOMER_RELATIONSHIP_ROUTE}>
            <CustomerRelationshipRouter />
          </Route>
          <RoleRoute
            path={INSPECTION_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.INSPECTOR_SUPERVISOR,
              UserRoles.INSPECTOR_MANAGER,
            ]}
          >
            <InspectionRouter />
          </RoleRoute>
          <RoleRoute
            path={INSPECTOR_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.INSPECTOR_SUPERVISOR,
              UserRoles.INSPECTOR_MANAGER,
            ]}
          >
            <InspectorRouter />
          </RoleRoute>
          <RoleRoute
            path={CAR_SETTINGS_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.OPERATION_SPECIALIST,
              UserRoles.OPERATION_SUPERVISOR,
            ]}
          >
            <CarSettingsRouter />
          </RoleRoute>
          <RoleRoute
            path={GENERAL_SETTINGS_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.INSPECTOR_SUPERVISOR,
              UserRoles.OPERATION_SUPERVISOR,
            ]}
          >
            <GeneralSettingsRouter />
          </RoleRoute>
          <RoleRoute
            path={OPERATION_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.OPERATION_SPECIALIST,
              UserRoles.OPERATION_SUPERVISOR,
            ]}
          >
            <OperationRouter />
          </RoleRoute>

          <Route exact path={routes.HOME}>
            <HomePage />
          </Route>
          <Redirect to={routes.NOT_FOUND_ERROR} />
        </Switch>
      </Box>
    </StyledLayout>
  )
}

export const AppRouter = () => {
  const { close, notificationData, notificationOpen } = useNotification()
  const {
    text: { notificationText: translation },
  } = useTranslation(textFiles.NOTIFICATION)
  const window = useWindowDimensions()
  const isSmallWindow =
    typeof window.width !== 'undefined' && window.width < BREAK_POINTS.LG

  return (
    <>
      <Router>
        <UserProvider>
          <Toast
            showToast={notificationOpen}
            handleClose={close}
            variant="alert"
            severity={notificationData.severity}
            description={notificationData.message}
            title={translation[notificationData.severity]}
          />
          <Switch>
            <Route path={AUTH_ROUTE} component={AuthRouter} />
            <Route exact path={routes.RESOLUTION_ERROR}>
              <ResolutionErrorPage />
            </Route>

            {isSmallWindow ? (
              <Redirect push to={routes.RESOLUTION_ERROR} />
            ) : null}

            <Route exact path={routes.NOT_FOUND_ERROR}>
              <NotFoundErrorPage />
            </Route>

            <Route exact path={routes.UNAUTHORIZED_ERROR}>
              <ForbiddenErrorPage />
            </Route>

            <ProtectedRoute>
              <ProtectedRoutes />
            </ProtectedRoute>
          </Switch>
        </UserProvider>
      </Router>
    </>
  )
}
