import React from 'react'
import {
  CSSObject,
  InputAdornment,
  InputBaseComponentProps,
  Typography,
} from '@mui/material'
import { Box, colors, ErrorMessage } from 'curbo-components-library'

import { StyledTextField } from './styles'

const formatValue = (
  value?: string | null | number,
  endAdornment?: string,
  thousandSeparator?: boolean
) => {
  if (value || value === 0) {
    const returnValue = thousandSeparator
      ? parseFloat(String(value)).toLocaleString('en')
      : value

    return endAdornment ? `${returnValue} ${endAdornment}` : returnValue
  }

  return '-'
}

type InformationCellProps = {
  label: string
  value?: string | null | number
  endAdornment?: string
  startAdornment?: React.ReactNode
  edit?: boolean
  onChange?:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | (() => void)
  name?: string
  inputComponent?: React.ElementType<InputBaseComponentProps> | undefined
  thousandSeparator?: boolean
  error?: boolean
  errorText?: string
  labelError?: boolean
  valueColor?: string
  onBlur?:
    | React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined
  children?: React.ReactNode
  multiline?: boolean
  inputSx?: CSSObject
  rowsNumber?: number
}

const InformationCell: React.FC<InformationCellProps> = ({
  label,
  value,
  endAdornment,
  startAdornment,
  edit,
  onChange,
  name,
  inputComponent,
  thousandSeparator,
  error,
  errorText,
  labelError,
  valueColor,
  onBlur,
  children,
  multiline,
  inputSx,
  rowsNumber,
}: InformationCellProps) => {
  const formattedValue = formatValue(value, endAdornment, thousandSeparator)

  return (
    <Box>
      <Typography
        color={labelError || error ? colors.lightRed : colors.black}
        variant="body1"
      >
        {label}
      </Typography>
      {!edit ? (
        <Box alignItems="center" display="flex">
          {startAdornment}
          <Typography color={valueColor} variant="body2">
            {formattedValue}
          </Typography>
        </Box>
      ) : (
        <>
          {children ? (
            <>{children}</>
          ) : (
            <StyledTextField
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{endAdornment}</Typography>
                  </InputAdornment>
                ),
                inputComponent,
              }}
              error={error}
              multiline={multiline}
              rows={rowsNumber}
              sx={inputSx}
              placeholder={label}
            />
          )}
          {error && errorText ? (
            <ErrorMessage
              sx={{
                paddingLeft: '0',
                marginTop: '0.25rem',
                alignItems: 'flex-start',
              }}
              text={errorText}
            />
          ) : null}
        </>
      )}
    </Box>
  )
}

export default InformationCell
