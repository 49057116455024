import React, { useState } from 'react'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  BoxProps,
  Card,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import {
  Box,
  colors,
  Container,
  cypressCancelButton,
  cypressConfirmButton,
  FONT_WEIGHT,
  Modal,
} from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import {
  AddContainer,
  NoteContainer,
  StyledNoteCard,
  StyledNoteList,
  StyledText,
} from './style'

export type NoteType = {
  index: number
  text: string
}

type TextType = {
  title: string
  description: string
  placeholder: string
  question: string
  confirm: string
  decline: string
  noComments: string
}

export type NotesProps = {
  /**
   ** All the strings representing the notes
   */
  data: NoteType[]

  /**
   ** Height of the notes container
   */
  height?: string | number

  /**
   ** Width of the notes container
   */
  width?: string | number

  /**
   ** Props for the general container
   */
  containerProps?: BoxProps

  /**
   ** Handler for modifying the notes array
   */
  handleDataChange: (data: NoteType[]) => void

  /**
   ** When `true`, user can't add or delete notes
   */
  isDisabled?: boolean

  // Custom text to use different from the default one
  customText?: TextType
}

const Notes = ({
  data,
  height = '475px',
  width,
  containerProps,
  handleDataChange,
  isDisabled,
  customText,
}: NotesProps) => {
  const [creating, setCreating] = useState<boolean>(false)
  const [newNote, setNewNote] = useState<NoteType>({
    index: data.length === 0 ? 0 : data[data.length - 1].index + 1,
    text: '',
  })
  const [deletionModal, setDeletionModal] = useState<boolean>(false)
  const [selectedNote, setSelectedNote] = useState<number>(0)

  const { text } = useTranslation(textFiles.GENERAL)
  const notesComponentText = customText || text.notesComponentText

  const handleCloseModal = () => {
    setDeletionModal(false)
  }
  const handleOpenModal = (index: number) => {
    setSelectedNote(index)
    setDeletionModal(true)
  }

  const removeElement = () => {
    const newNotes = data.filter((note) => note.index !== selectedNote)
    handleDataChange(newNotes)
    handleCloseModal()
  }
  const addNote = (noteData: NoteType) => {
    const newData = [...data]
    if (noteData.text !== '') {
      newData.push(noteData)
      handleDataChange(newData)
      setCreating(false)
    }
    setNewNote(noteData)
  }

  return (
    <Container
      title={notesComponentText.title}
      description={notesComponentText.description}
      maxWidth={350}
      sx={{
        padding: '0px',
      }}
      {...containerProps}
    >
      <Card variant="outlined" sx={{ borderRadius: '10px', height: '100%' }}>
        <Box
          style={{ padding: '1rem 0rem 1rem 0rem' }}
          height={height}
          width={width}
        >
          <StyledNoteList
            sx={{
              maxHeight: height,
              minHeight: height,
              paddingLeft: '1rem',
            }}
            subheader={<Card />}
          >
            {creating ? (
              <NoteContainer style={{ display: 'flex', flexDirection: 'row' }}>
                <StyledNoteCard>
                  <TextField
                    id="note-creator"
                    placeholder={notesComponentText.placeholder}
                    aria-label="note-content"
                    onChange={(e) =>
                      setNewNote({
                        index:
                          data.length === 0
                            ? 0
                            : data[data.length - 1].index + 1,
                        text: e.target.value,
                      })
                    }
                    minRows={3}
                    variant="standard"
                    inputProps={{
                      maxLength: 102,
                      style: {
                        fontSize: '12px',
                        fontWeight: FONT_WEIGHT.MEDIUM,
                      },
                    }}
                    multiline
                    fullWidth
                    autoFocus
                    data-cy="comment-input"
                  />
                </StyledNoteCard>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    <IconButton
                      onClick={() => {
                        addNote(newNote)
                      }}
                      data-cy={cypressConfirmButton}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        setCreating(false)
                      }}
                      data-cy={cypressCancelButton}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </NoteContainer>
            ) : null}
            {data.length === 0 && !creating ? (
              <Typography
                color={colors.gray}
                textAlign="center"
                variant="body1"
                fontWeight={FONT_WEIGHT.MEDIUM}
              >
                {notesComponentText.noComments}
              </Typography>
            ) : (
              data
                .slice(0)
                .reverse()
                .map((note) => (
                  <NoteContainer key={note.index} maxWidth={width}>
                    <StyledNoteCard>
                      <StyledText variant="subtitle1">{note.text}</StyledText>
                    </StyledNoteCard>
                    {!isDisabled && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <IconButton
                          disabled={isDisabled}
                          onClick={() => handleOpenModal(note.index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </NoteContainer>
                ))
            )}
          </StyledNoteList>
        </Box>
        <AddContainer>
          <IconButton
            color="primary"
            aria-label="create note"
            onClick={() => setCreating(true)}
            size="large"
            disabled={isDisabled}
            data-cy="add-comment-button"
          >
            <AddCircleRoundedIcon sx={{ fontSize: '2.1rem' }} />
          </IconButton>
        </AddContainer>
      </Card>
      <Modal
        open={deletionModal}
        handleClose={handleCloseModal}
        enableBottom={{
          leftButtonText: notesComponentText.confirm,
          primaryFunction: removeElement,
          rightButtonText: notesComponentText.decline,
          secondaryFunction: handleCloseModal,
        }}
      >
        <Box textAlign="center" paddingTop="10px">
          <DeleteForeverIcon color="primary" />
          <StyledText variant="h6" width="250px" margin="auto">
            {notesComponentText.question}
          </StyledText>
        </Box>
      </Modal>
    </Container>
  )
}
export default Notes
