import React, { useReducer, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  BodyContainerWithHeader,
  createSimpleStepperItems,
  CurboSpot,
  FilterInputVariable,
  GenericData,
  Stepper,
} from 'curbo-components-library'

import { BackContainer } from 'components/Common/BackContainer'

import {
  initialAppointmentInformation,
  initialCarInformation,
  initialClientExpectationInformation,
  initialGeneralInformation,
  initialVehicleInformation,
} from 'constants/Lead/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Brand, Color } from 'models/services/car'
import {
  CTAEnum,
  ExpectedTimes,
  LeadCreationAction,
  LeadCreationModel,
} from 'models/services/customerRelationship/lead/creation'
import { leadCreationReducer } from 'reducers/Lead/leadCreationReducer'
import {
  createLeadStepperItems,
  INITIAL_LEAD_CREATION_STEPS as mainSteps,
  SMC_LEAD_CREATION_STEPS as smcSteps,
  TD_LEAD_CREATION_STEPS as tdSteps,
} from 'utils/Lead/creation'

import {
  GET_INSPECTION_BRANDS,
  GET_INSPECTION_COLORS,
} from 'graphQL/Common/CarFeature/queries'
import { GET_CURBO_SPOTS } from 'graphQL/Common/Dealer/queries'
import { FETCH_EXPECTED_TIMES } from 'graphQL/CustomerRelationship/Lead/Creation/queries'

const initialData: LeadCreationModel = {
  generalInformation: initialGeneralInformation,
  carInformation: initialCarInformation,
  appointmentInformation: initialAppointmentInformation,
  clientExpInformation: initialClientExpectationInformation,
  vehicleInformation: initialVehicleInformation,
}

const stepperProps = {
  barWidth: '12vw',
  topSx: {
    width: '100%',
    justifyContent: 'start',
    alignItems: 'end',
  },
}

const LeadCreationPage = () => {
  const { text } = useTranslation(textFiles.LEAD_CREATION)
  const stepperItems = createSimpleStepperItems(text.stepper)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentSecondaryStep, setCurrentSecondaryStep] = useState<number>(0)
  const [leadData, dispatch] = useReducer(leadCreationReducer, initialData)
  const [expectedTimes, setExpectedTimes] = useState<ExpectedTimes[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  const [colorList, setColorList] = useState<Color[]>([])
  const [curboSpots, setCurboSpots] = useState<CurboSpot[]>([])

  const secondarySteps =
    leadData.generalInformation.cta === CTAEnum.TestDrive ? tdSteps : smcSteps
  const secondaryStepperItems = createLeadStepperItems(
    text.stepper,
    secondarySteps.length
  )

  useQuery<GenericData<Brand[]>, FilterInputVariable>(GET_INSPECTION_BRANDS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setBrands(response.data)
    },
  })

  useQuery<GenericData<Color[]>, FilterInputVariable>(GET_INSPECTION_COLORS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setColorList(response.data)
    },
  })

  useQuery<GenericData<CurboSpot[]>, FilterInputVariable>(GET_CURBO_SPOTS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCurboSpots(response.data)
    },
  })
  useQuery<GenericData<ExpectedTimes[]>>(FETCH_EXPECTED_TIMES, {
    onCompleted(response) {
      const responseData = response.data
      setExpectedTimes(responseData)
    },
  })

  const updateLeadData = (action: LeadCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleSecondaryContinue = () => {
    /**
     * we will save the data in the component
     */
    if (currentSecondaryStep + 1 < secondarySteps.length)
      setCurrentSecondaryStep(currentSecondaryStep + 1)
    else setCurrentStep((step) => step + 1)
  }

  const handleSecondaryBack = () => {
    /**
     * we will save the data in the component
     */
    if (currentSecondaryStep - 1 >= 0)
      setCurrentSecondaryStep((step) => step - 1)
    else setCurrentStep((step) => step - 1)
  }

  const componentProps = {
    handleContinue,
    handleBack,
    leadData,
    updateLeadData,
    brands,
    expectedTimes,
    colorList,
    curboSpots,
    handleSecondaryBack,
    handleSecondaryContinue,
  }

  return (
    <>
      <BackContainer />
      <BodyContainerWithHeader title={text.title} subtitle={text.description}>
        <Stepper
          currentStep={currentStep}
          stepperItems={
            currentStep !== 1 ? stepperItems : secondaryStepperItems
          }
          currentSecondaryStep={currentSecondaryStep}
          secondaryStepAmount={secondarySteps.length}
          {...stepperProps}
        />
        {currentStep !== 1
          ? React.createElement(mainSteps[currentStep], componentProps)
          : React.createElement(
              secondarySteps[currentSecondaryStep],
              componentProps
            )}

        {}
      </BodyContainerWithHeader>
    </>
  )
}

export default LeadCreationPage
