import { styled } from '@mui/material'
import ListItemButton, {
  listItemButtonClasses,
} from '@mui/material/ListItemButton'
import { colors } from 'curbo-components-library'

export const SubCategory = styled('p')({
  fontSize: '16px',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  color: '#585858',
  paddingLeft: '13px',
  margin: '0.75em auto',
  '& > svg': {
    marginLeft: '1em',
    paddingRight: '0.5em',
  },
})

export const StyledListItemButton = styled(ListItemButton)({
  padding: '0',
  '& > svg': {
    paddingLeft: '1em',
  },
  [`&.${listItemButtonClasses.selected}`]: {
    bgColor: colors.commonWhite,
    '& > svg': {
      color: colors.lightBlue,
    },
    '& > p': {
      fontWeight: 600,
      bgColor: colors.commonWhite,
      color: colors.lightBlue,
    },
  },
})
