import React, { useState } from 'react'
import {
  CallToAction,
  DirectionsCar,
  Person,
  PersonSearch,
} from '@mui/icons-material'
import { SelectChangeEvent } from '@mui/material'
import {
  Box,
  colors,
  Container,
  formatFullName,
  formatPhoneNumber,
  Option,
} from 'curbo-components-library'

import IconTextBox from 'components/Detail/IconTextBox'
import EditContainer from 'components/Inventory/Common/EditContainer'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  DetailCar,
  LeadDetail,
  UpdateCaseInput,
} from 'models/services/customerRelationship/lead/detail'

type OverviewProps = {
  isEditable: boolean
  carData: DetailCar | null
  leadData: LeadDetail
  loading: boolean
  specialistOptions: Option[]
  handleUpdateCase: (input: UpdateCaseInput) => void
}

const Overview = ({
  isEditable,
  leadData,
  loading,
  specialistOptions,
  handleUpdateCase,
  carData,
}: OverviewProps) => {
  const {
    text: { overview: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)
  const { activeCallToActions, customer, customerCareRepresentative, type } =
    leadData
  const customerCareId = customerCareRepresentative
    ? customerCareRepresentative.id
    : ''

  const [specialist, setSpecialist] = useState<string | number>(customerCareId)
  const [edit, setEdit] = useState<boolean>(false)

  const customerFullName = formatFullName(customer.name, customer.lastName)
  const customerPhone = customer.telephoneNumber
    ? formatPhoneNumber(customer.telephoneNumber)
    : ''
  const customerReprensentativeFullName =
    customerCareRepresentative &&
    formatFullName(
      customerCareRepresentative.name,
      customerCareRepresentative.lastName
    )

  const handleCancelEdit = () => {
    setEdit(false)
    setSpecialist(customerCareId)
  }

  const handleEdit = async () => {
    setEdit(true)
  }

  const handleSaveEdit = () => {
    setEdit(false)
    handleUpdateCase({
      customerCareRepresentative: String(specialist),
    })
  }

  const handleSpecialistChange = (event: SelectChangeEvent<unknown>) => {
    setSpecialist(event.target.value as string)
  }

  return (
    <Box
      minHeight="500px"
      width="100%"
      sx={{
        opacity: loading ? '0.5' : 'unset',
      }}
    >
      <EditContainer
        edit={edit}
        handleCancelEdit={handleCancelEdit}
        handleSaveEdit={handleSaveEdit}
        handleEdit={handleEdit}
        loading={loading}
        isEditable={isEditable}
      />
      <Container
        description={translation.generalInformationDescription}
        title={translation.generalInformationTitle}
      >
        <Box display="flex" justifyContent="space-between">
          <IconTextBox
            description={customerPhone}
            name={customerFullName}
            title={translation.clientLabel}
            icon={<Person fontSize="large" sx={{ color: colors.blue }} />}
          />

          <IconTextBox
            description={
              customerReprensentativeFullName
                ? translation.salespersonLabel
                : undefined
            }
            name={
              customerReprensentativeFullName ||
              translation.specialistPlaceholder
            }
            title={translation.specialistLabel}
            icon={<PersonSearch fontSize="large" sx={{ color: colors.blue }} />}
            edit={edit}
            options={specialistOptions}
            value={specialist}
            onChange={handleSpecialistChange}
            placeholder={translation.specialistPlaceholder}
          />
          {carData && (
            <IconTextBox
              description={carData.trimLevel.name}
              title={translation.vehicleLabel}
              name={`${carData.brand.name} ${carData.carModel.name} ${carData.year}`}
              icon={
                <DirectionsCar fontSize="large" sx={{ color: colors.blue }} />
              }
            />
          )}
          <IconTextBox
            description={`${activeCallToActions.length} ${translation.activeCtaLabel}`}
            name={type}
            title="Call to action"
            icon={<CallToAction fontSize="large" sx={{ color: colors.blue }} />}
          />
        </Box>
      </Container>
    </Box>
  )
}

export default Overview
