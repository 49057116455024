import { styled } from '@mui/material'
import { Box, BREAK_POINTS, colors } from 'curbo-components-library'

import Link from 'components/Common/Link'

export const Layout = styled(Box)({
  position: 'relative',
  maxWidth: '1380px',
  width: '100%',
})

export const StyledLink = styled(Link)({
  position: 'absolute',
  top: '-40px',
  '&:hover': {
    textDecoration: 'none',
  },
})

export const ContentContainer = styled(Box)({
  maxWidth: '1380px',
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${colors.commonWhite}`,
  borderRadius: '0 0 10px 10px',
  backgroundColor: colors.commonWhite,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '2rem',
})

export const GridBox = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '33% 33% 33%',
  columnGap: '20px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 50%',
    columnGap: '10px',
    rowGap: '20px',
  },
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
})
