import React from 'react'
import { useHistory } from 'react-router-dom'
import Fade from '@mui/material/Fade'
import List from '@mui/material/List'
import MuiPopper from '@mui/material/Popper'
import { colors } from 'curbo-components-library'

import { SidebarItem } from 'components/General/Sidebar'

import { StyledListItemButton } from './style'

type PopperProps = {
  items: SidebarItem[]
  open: boolean
  /**
   *  Element that the popper component will take as anchor
   * initial position to be rendered
   * */
  anchorEl: HTMLElement | null
  handlePopperClose: () => void
  handlePopperOpen: () => void

  /**
   * Optional props to highlight a Sidebar item using this popper
   */
  changeSelectedSubcategory?: (index: number) => void
  indexToHighlight?: number
}

/**
 * This component generates a Tooltip which contains a list of
 * sub-items, appended to the anchorEl component passed in props
 * */
const Popper: React.FC<PopperProps> = ({
  items,
  open,
  anchorEl,
  handlePopperClose,
  handlePopperOpen,
  changeSelectedSubcategory,
  indexToHighlight,
}) => {
  const history = useHistory()

  return (
    <MuiPopper
      id="mouse-over-popover"
      open={open}
      onMouseEnter={handlePopperOpen}
      onMouseLeave={handlePopperClose}
      anchorEl={anchorEl}
      placement="right"
      transition
      style={{
        zIndex: 1500,
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <List
            sx={{
              width: 'auto',
              maxWidth: '270px',
              margin: '0.25rem',
              backgroundColor: colors.commonWhite,
              padding: '0.75rem 0.5rem',
              borderRadius: '5px',
              boxShadow: '3px 3px 10px #33333333',
            }}
          >
            {items.map((item: SidebarItem) => {
              const { key, onClick, url, icon, name } = item
              return (
                <StyledListItemButton
                  key={key}
                  onClick={() => {
                    if (onClick) onClick()
                    if (url) history.push(url)
                    if (changeSelectedSubcategory && indexToHighlight)
                      changeSelectedSubcategory(indexToHighlight)
                  }}
                  data-cy={`${item.key}-popper-item`}
                >
                  {icon || null}
                  {name}
                </StyledListItemButton>
              )
            })}
          </List>
        </Fade>
      )}
    </MuiPopper>
  )
}

export default Popper
