import React from 'react'
import { Box, ColorBox, colors } from 'curbo-components-library'

import {
  InformationBox,
  StyledBrandImg,
  StyledContainer,
  StyledDataText,
  StyledDataTitle,
} from 'components/Inventory/Detail/VehicleSummary/style'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { SimpleCar } from 'models/services/customerRelationship/lead/detail'

type SimpleCarContentProps = {
  carData: SimpleCar
}

const SimpleCarContent = ({ carData }: SimpleCarContentProps) => {
  const {
    text: { vehicleInformation: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)

  const { carModel, exteriorColor, interiorColor, trimLevel, year } = carData
  const { brand } = carModel

  return (
    <Box>
      <InformationBox>
        <StyledContainer
          title={translation.basicInformationTitle}
          description={translation.basicInformationDescription}
          contentStyle={{
            height: '100%',
          }}
          sx={{
            width: '350px',
          }}
        >
          <Box
            border={`1px solid ${colors.border}`}
            borderRadius="10px"
            padding="1rem"
          >
            <Box
              display="flex"
              flexDirection="column"
              borderRadius="10px"
              padding="2rem"
              sx={{ backgroundColor: colors.background, height: '465px' }}
            >
              {brand.icon && (
                <StyledBrandImg
                  src={brand.icon}
                  srcSet={brand.icon}
                  alt="brand logo"
                  loading="lazy"
                />
              )}
              <Box>
                <StyledDataText variant="h5">{brand.name}</StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.makeLabel}
                </StyledDataTitle>
              </Box>
              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">{carModel.name}</StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.modelLabel}
                </StyledDataTitle>
              </Box>
              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">{year}</StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.yearLabel}
                </StyledDataTitle>
              </Box>
              {trimLevel && (
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">{trimLevel.name}</StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.trimLabel}
                  </StyledDataTitle>
                </Box>
              )}
            </Box>
          </Box>
        </StyledContainer>
        {(interiorColor || exteriorColor) && (
          <StyledContainer
            title={translation.catalogTitle}
            description={translation.catalogDescription}
            contentStyle={{
              height: '100%',
            }}
            sx={{
              width: '350px',
            }}
          >
            <Box
              border={`1px solid ${colors.border}`}
              borderRadius="10px"
              padding="1rem"
            >
              <Box
                display="flex"
                flexDirection="column"
                borderRadius="10px"
                padding="2rem"
                sx={{ backgroundColor: colors.background, height: '465px' }}
              >
                {exteriorColor && (
                  <Box marginTop="1.5rem">
                    <Box alignItems="center" display="flex">
                      <ColorBox
                        height={27}
                        width={27}
                        styles={{
                          border: `1px solid ${colors.gray}`,
                          boxShadow: 'none',
                        }}
                        hexCode={exteriorColor.hexCode}
                      />
                      <StyledDataText marginLeft="1rem" variant="h5">
                        {exteriorColor.name}
                      </StyledDataText>
                    </Box>

                    <StyledDataTitle variant="body1">
                      {translation.exteriorColorLabel}
                    </StyledDataTitle>
                  </Box>
                )}
                {interiorColor && (
                  <Box marginTop="1.5rem">
                    <Box alignItems="center" display="flex">
                      <ColorBox
                        height={27}
                        width={27}
                        styles={{
                          border: `1px solid ${colors.gray}`,
                          boxShadow: 'none',
                        }}
                        hexCode={interiorColor.hexCode}
                      />
                      <StyledDataText marginLeft="1rem" variant="h5">
                        {interiorColor.name}
                      </StyledDataText>
                    </Box>

                    <StyledDataTitle variant="body1">
                      {translation.interiorColorLabel}
                    </StyledDataTitle>
                  </Box>
                )}
              </Box>
            </Box>
          </StyledContainer>
        )}
      </InformationBox>
    </Box>
  )
}

export default SimpleCarContent
