import React from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Typography } from '@mui/material'
import { Box, boxShadow, colors, Tooltip } from 'curbo-components-library'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { ExternalLink } from 'models/services/customerRelationship/lead/detail'

import { StyledLink } from './style'

type ExternalHelpLinkProps = {
  links: ExternalLink[]
}

const LinkList = ({ links }: ExternalHelpLinkProps) => (
  <Box
    boxShadow={boxShadow}
    minWidth="240px"
    sx={{ backgroundColor: colors.commonWhite }}
    padding="1rem"
    display="flex"
    flexDirection="column"
  >
    {links.map((link) => (
      <StyledLink
        key={link.url}
        target="_blank"
        href={link.url}
        rel="noreferrer"
      >
        {link.name}
      </StyledLink>
    ))}
  </Box>
)

const ExternalHelpLink = ({ links }: ExternalHelpLinkProps) => {
  const {
    text: { general: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)

  return (
    <Box display="flex" justifyContent="flex-end" marginTop="1rem" width="100%">
      <Tooltip
        customPopper={<LinkList links={links} />}
        boxSx={{
          width: 'unset',
        }}
        placement="top-end"
      >
        <Box alignItems="center" color={colors.green} display="flex">
          <HelpOutlineIcon fontSize="small" />
          <Typography
            color={colors.green}
            marginLeft="0.25rem"
            variant="body2"
            sx={{ textDecoration: 'underline' }}
          >
            {translation.externalHelpLinkLabel}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  )
}

export default ExternalHelpLink
