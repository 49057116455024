import React from 'react'
import { SelectChangeEvent, Typography } from '@mui/material'
import { Box, colors, FONT_WEIGHT, Option } from 'curbo-components-library'

import {
  formControlStyles,
  onClickStyles,
  StyledSelect,
  TextContainer,
} from './style'

type IconTextBoxProps = {
  description?: string
  icon?: React.ReactNode
  name: string
  title: string
  width?: string
  options?: Option[]
  edit?: boolean
  value?: string | number
  onChange?: (event: SelectChangeEvent<unknown>) => void
  placeholder?: string
  onClick?: () => void
}

const IconTextBox = ({
  description = '',
  icon,
  name,
  title,
  width = '100%',
  options = [],
  edit,
  value = '',
  onChange,
  placeholder,
  onClick,
}: IconTextBoxProps) => {
  const hasOnClick = !!onClick
  return (
    <Box
      onClick={onClick}
      width={width}
      sx={hasOnClick ? onClickStyles : undefined}
    >
      <Typography variant="body1">{title}</Typography>
      <Box alignItems="center" display="flex" marginTop="10px" width="100%">
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: colors.cyan,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              height: '50px',
              width: '50px',
            }}
          >
            {icon}
          </Box>
        </Box>

        <TextContainer>
          {!edit ? (
            <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
              {name}
            </Typography>
          ) : (
            <StyledSelect
              options={options}
              variant="outlined"
              formControlStyles={formControlStyles}
              value={value}
              label={placeholder}
              onChange={onChange}
            />
          )}
          <Typography color={colors.lightBlack} variant="body2">
            {description}
          </Typography>
        </TextContainer>
      </Box>
    </Box>
  )
}

export default IconTextBox
