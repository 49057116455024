import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import UserManagemenCreationPage from 'pages/GeneralSettings/UserManagement/Creation'
import UserManagemenDetailPage from 'pages/GeneralSettings/UserManagement/Detail'
import UserManagemenListingPage from 'pages/GeneralSettings/UserManagement/Listing'

import RoleRoute from 'components/Auth/RoleRoute'

import {
  GENERAL_SETTINGS_SUB_ROUTES,
  USER_MANAGEMENT_ROUTE,
} from 'constants/routes'
import { UserRoles } from 'models/role'

export const GeneralSettingsRouter = () => (
  <Switch>
    <RoleRoute allowedRoles={[UserRoles.ADMIN]} path={USER_MANAGEMENT_ROUTE}>
      <Switch>
        <Route
          path={GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_DETAIL}
          component={UserManagemenDetailPage}
        />
        <Route
          path={GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_LISTING}
          component={UserManagemenListingPage}
        />

        <Route
          path={GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_CREATION}
          component={UserManagemenCreationPage}
        />
      </Switch>
    </RoleRoute>
    <Redirect to={GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_LISTING} />
  </Switch>
)
