import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Box,
  BREAK_POINTS,
  colors,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
} from 'curbo-components-library'

import ImageCarousel from 'components/Common/Carousel'
import Notes, { NoteType } from 'components/Common/Notes'

import { getEstimatedSellTimeLabel } from 'constants/Inspection/detail'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useWindowDimensions from 'hooks/useWindowDimensions'
import {
  InspectionVehicleInformation,
  SellMyCarType,
} from 'models/services/inspection/detail'

import { GET_INSPECTION_CAR_BY_ID } from 'graphQL/Inspection/Detail/queries'

import placeholderCar from 'images/blueDefaultAvatar.png'

import {
  ImgContainer,
  StyledBrandImg,
  StyledCarouselContainer,
  StyledContainer,
  StyledDataText,
  StyledDataTitle,
  VehicleBox,
} from './style'

const initialVehicleInformationData: InspectionVehicleInformation = {
  car: {
    model: {
      id: '',
      name: '',
      brand: {
        id: '',
        name: '',
        icon: null,
      },
    },
    year: 0,
    trim: {
      id: '',
      name: '',
    },
  },
  currencyCode: null,
  price: null,
  steps: null,
}

type VehicleInformationProps = {
  id: string
  notes: NoteType[]
  handleNotesChange: (newNotes: NoteType[]) => void
  /**
   * Optional prop to render text with the estimated time to sell the vehicle
   */
  expectedTimeData?: SellMyCarType
}

const VehicleInformation = ({
  id,
  notes,
  handleNotesChange,
  expectedTimeData,
}: VehicleInformationProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)
  const { vehicleInformation: translation } = text
  const [imageList, setImageList] = useState<string[]>([placeholderCar])
  const { width: windowWidth } = useWindowDimensions()
  const isViewportXL = windowWidth && windowWidth >= BREAK_POINTS.XL

  const [vehicleInformationData, setVehicleInformationData] =
    useState<InspectionVehicleInformation>(initialVehicleInformationData)

  const { car, currencyCode, price } = vehicleInformationData

  const estimatedSellTimeLabel = expectedTimeData
    ? getEstimatedSellTimeLabel(expectedTimeData, translation)
    : ''

  const { loading } = useQuery<
    GenericData<InspectionVehicleInformation>,
    GenericInputVariable<string>
  >(GET_INSPECTION_CAR_BY_ID, {
    variables: { input: id },
    onCompleted(response) {
      setVehicleInformationData(response.data)
      const { steps } = response.data
      if (steps) {
        // this converts the nested array into a single array of string containing the pictures urls
        const pictures = steps.flatMap((step) =>
          step.checkpoints.flatMap((checkpoint) =>
            checkpoint.questions.flatMap((question) =>
              question.pictures.map((picture) => picture)
            )
          )
        )
        if (pictures.length > 0) {
          setImageList(pictures)
        }
      }
    },
  })

  if (loading) return <LoadingAnimation showAnimation={loading} />

  return (
    vehicleInformationData && (
      <VehicleBox>
        <ImgContainer
          title={translation.vehicleGalleryTitle}
          description={translation.vehicleGalleryDescription}
          contentStyle={{
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <StyledCarouselContainer
            width={isViewportXL ? '100%' : '62vmax'}
            minWidth={isViewportXL ? 'inherit' : '734px'}
            maxWidth={isViewportXL ? 'inherit' : '1360px'}
          >
            <ImageCarousel
              imageList={imageList.map((image, index) => {
                return { url: image, id: String(index) }
              })}
              imageWidth={isViewportXL ? '100%' : '390px'}
              imageHeight={isViewportXL ? '322px' : '292px'}
              axis={isViewportXL ? 'vertical' : 'horizontal'}
              height={isViewportXL ? '682px' : 'inherit'}
              width={isViewportXL ? '100%' : 'auto'}
            />
          </StyledCarouselContainer>
        </ImgContainer>
        <StyledContainer
          title={translation.specificationTitle}
          description={translation.specificationDescription}
          contentStyle={{
            height: '100%',
          }}
          sx={{
            width: '330px',
          }}
        >
          <Box
            border={`1px solid ${colors.border}`}
            borderRadius="10px"
            padding="1rem"
          >
            <Box
              display="flex"
              flexDirection="column"
              borderRadius="10px"
              padding="2rem"
              sx={{ backgroundColor: colors.background, height: '585px' }}
            >
              {car.model.brand.icon && (
                <StyledBrandImg
                  src={car.model.brand.icon}
                  srcSet={car.model.brand.icon}
                  alt="brand logo"
                  loading="lazy"
                />
              )}
              <Box>
                <StyledDataText variant="h5">
                  {car.model.brand.name}
                </StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.brandLabel}
                </StyledDataTitle>
              </Box>
              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">{car.model.name}</StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.modelLabel}
                </StyledDataTitle>
              </Box>
              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">{car.year}</StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.yearLabel}
                </StyledDataTitle>
              </Box>
              {car.trim && (
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">{car.trim.name}</StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.trimLabel}
                  </StyledDataTitle>
                </Box>
              )}

              {price ? (
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">
                    {currencyCode && currencyCode.toUpperCase()}{' '}
                    {price.toLocaleString('en-US')}
                  </StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.budgetLabel}
                  </StyledDataTitle>
                </Box>
              ) : null}

              {estimatedSellTimeLabel && (
                <Box marginTop="1.5rem">
                  <StyledDataText variant="h5">
                    {`${expectedTimeData?.expectedTime.label} ${estimatedSellTimeLabel}`}
                  </StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.estimatedSellLabel}
                  </StyledDataTitle>
                </Box>
              )}
            </Box>
          </Box>
        </StyledContainer>

        <Notes
          data={notes}
          height="565px"
          width="100%"
          customText={translation.notesComponentText}
          handleDataChange={handleNotesChange}
        />
      </VehicleBox>
    )
  )
}

export default VehicleInformation
