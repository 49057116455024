import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { Typography } from '@mui/material'
import {
  addCommas,
  Box,
  ColorBox,
  colors,
  FONT_WEIGHT,
} from 'curbo-components-library'

import CopyToClipboard from 'components/Common/CopyToClipboard'
import CertifiedTag from 'components/Inventory/Common/CertifiedTag'
import {
  InformationBox,
  StyledBrandImg,
  StyledContainer,
  StyledDataText,
  StyledDataTitle,
} from 'components/Inventory/Detail/VehicleSummary/style'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { DetailCar } from 'models/services/customerRelationship/lead/detail'

type CarDataContentProps = {
  carData: DetailCar
}

const CarDataContent = ({ carData }: CarDataContentProps) => {
  const {
    text: { vehicleInformation: translation },
  } = useTranslation(textFiles.LEAD_DETAIL)

  const {
    brand,
    carModel,
    certified,
    countryVersion,
    exteriorColor,
    interiorColor,
    id,
    trimLevel,
    mileage,
    slug,
    year,
  } = carData

  return (
    <Box>
      <InformationBox>
        <StyledContainer
          title={translation.basicInformationTitle}
          description={translation.basicInformationDescription}
          contentStyle={{
            height: '100%',
          }}
          sx={{
            width: '350px',
          }}
        >
          <Box
            border={`1px solid ${colors.border}`}
            borderRadius="10px"
            padding="1rem"
          >
            <Box
              display="flex"
              flexDirection="column"
              borderRadius="10px"
              padding="2rem"
              sx={{ backgroundColor: colors.background, height: '465px' }}
            >
              {brand.icon && (
                <StyledBrandImg
                  src={brand.icon}
                  srcSet={brand.icon}
                  alt="brand logo"
                  loading="lazy"
                />
              )}
              <Box>
                <StyledDataText variant="h5">
                  {carData.brand.name}
                </StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.makeLabel}
                </StyledDataTitle>
              </Box>
              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">{carModel.name}</StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.modelLabel}
                </StyledDataTitle>
              </Box>
              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">{year}</StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.yearLabel}
                </StyledDataTitle>
              </Box>
              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">{trimLevel.name}</StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.trimLabel}
                </StyledDataTitle>
              </Box>
            </Box>
          </Box>
        </StyledContainer>
        <StyledContainer
          title={translation.catalogTitle}
          description={translation.catalogDescription}
          contentStyle={{
            height: '100%',
          }}
          sx={{
            width: '350px',
          }}
        >
          <Box
            border={`1px solid ${colors.border}`}
            borderRadius="10px"
            padding="1rem"
          >
            <Box
              display="flex"
              flexDirection="column"
              borderRadius="10px"
              padding="2rem"
              sx={{ backgroundColor: colors.background, height: '465px' }}
            >
              {carData.categories.length > 0 ? (
                <Box>
                  <StyledDataText variant="h5">
                    {carData.categories
                      .map((category) => category.name)
                      .join(', ')}
                  </StyledDataText>
                  <StyledDataTitle variant="body1">
                    {translation.categoriesLabel}
                  </StyledDataTitle>
                </Box>
              ) : null}
              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">
                  {countryVersion.name}
                </StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.countryVersionLabel}
                </StyledDataTitle>
              </Box>

              <Box marginTop="1.5rem">
                <StyledDataText variant="h5">
                  {`${addCommas(mileage)}`}
                </StyledDataText>
                <StyledDataTitle variant="body1">
                  {translation.mileageLabel}
                </StyledDataTitle>
              </Box>

              <Box marginTop="1.5rem">
                <Box alignItems="center" display="flex">
                  <ColorBox
                    height={27}
                    width={27}
                    styles={{
                      border: `1px solid ${colors.gray}`,
                      boxShadow: 'none',
                    }}
                    hexCode={exteriorColor.hexCode}
                  />
                  <StyledDataText marginLeft="1rem" variant="h5">
                    {exteriorColor.name}
                  </StyledDataText>
                </Box>

                <StyledDataTitle variant="body1">
                  {translation.exteriorColorLabel}
                </StyledDataTitle>
              </Box>
              <Box marginTop="1.5rem">
                <Box alignItems="center" display="flex">
                  <ColorBox
                    height={27}
                    width={27}
                    styles={{
                      border: `1px solid ${colors.gray}`,
                      boxShadow: 'none',
                    }}
                    hexCode={interiorColor.hexCode}
                  />
                  <StyledDataText marginLeft="1rem" variant="h5">
                    {interiorColor.name}
                  </StyledDataText>
                </Box>

                <StyledDataTitle variant="body1">
                  {translation.interiorColorLabel}
                </StyledDataTitle>
              </Box>
            </Box>
          </Box>
        </StyledContainer>
      </InformationBox>
      <Box marginTop="1.5rem">
        <Box display="flex" justifyContent="flex-start">
          <Box display="flex">
            <DirectionsCarIcon fontSize="medium" sx={{ color: colors.black }} />
            <Typography
              variant="body2"
              color={colors.black}
              margin="0 1rem 0 0.25rem"
              fontWeight={FONT_WEIGHT.BOLD}
            >
              {'Slug: '}
              <CopyToClipboard color={colors.blue} textToCopy={slug} />
            </Typography>
          </Box>
          <Box display="flex">
            <DirectionsCarIcon fontSize="medium" sx={{ color: colors.black }} />
            <Typography
              variant="body2"
              color={colors.black}
              margin="0 1rem 0 0.25rem"
              fontWeight={FONT_WEIGHT.BOLD}
            >
              {'ID: '}
              <CopyToClipboard color={colors.blue} textToCopy={id} />
            </Typography>
          </Box>
          <CertifiedTag certified={certified} />
        </Box>
      </Box>
    </Box>
  )
}

export default CarDataContent
