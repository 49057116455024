import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ApolloError, useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Divider, Typography } from '@mui/material'
import {
  BaseEntity,
  Box,
  colors,
  DetailNavTab,
  FilterInputVariable,
  generateTabItems,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
  NavBarItem,
  PublicationStatusEnum,
  TabPanel,
  useNotification,
} from 'curbo-components-library'

import BrandInformation from 'components/Operation/Brand/Detail/BrandInformation'
import PublicationStatusTag from 'components/Operation/CarSettings/Common/PublicationStatusTag'

import { CAR_SETTINGS_SUB_ROUTES, routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  BasicDetailDataType,
  ListModelType,
} from 'models/services/operation/carSettings/detail'

import { LIST_MODELS } from 'graphQL/CarSettings/Listing/queries'
import { GET_BRAND_BY_ID } from 'graphQL/Operations/Brand/Detail/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/detail'

const pageSize = 10

const BrandDetailPage = () => {
  const { carSettingsId } = useParams<{ carSettingsId: string }>()

  const { text } = useTranslation(textFiles.BRAND_DETAIL)
  const { general: translation } = text
  const { text: notificationTranslation } = useTranslation(
    textFiles.NOTIFICATION
  )
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [tab] = useState<number>(0)
  const [status, setStatus] = useState<PublicationStatusEnum>(
    PublicationStatusEnum.PUBLISHED
  )
  const [brandData, setBrandData] = useState<BasicDetailDataType | null>(null)
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)
  const [page, setPage] = useState<number>(1)
  const [models, setModels] = useState<BaseEntity[]>([])
  const [totalModelCount, setTotalModelCount] = useState<number>(0)

  const { show } = useNotification()

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading: loadingBrandData } = useQuery<
    GenericData<BasicDetailDataType>,
    GenericInputVariable<string>
  >(GET_BRAND_BY_ID, {
    variables: {
      input: carSettingsId,
    },
    onCompleted(queryData) {
      setBrandData(queryData.data)
      setStatus(queryData.data.status)
    },
    onError(error) {
      show({
        updatedSeverity: 'error',
        message: notificationTranslation.notificationText.error,
      })
      setApolloError(error)
    },
  })

  const { loading: modelsLoading } = useQuery<
    ListModelType,
    FilterInputVariable
  >(LIST_MODELS, {
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          brand: carSettingsId,
        },
      },
    },
    onCompleted(response) {
      setTotalModelCount(response.listData.count)
      setModels(response.listData.data)
    },
  })

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const getPageCount = () => {
    return Math.ceil(totalModelCount / pageSize)
  }

  if (loadingBrandData) return <LoadingAnimation showAnimation />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return brandData ? (
    <Layout>
      <StyledLink to={CAR_SETTINGS_SUB_ROUTES.BRAND_LISTING}>
        <Typography variant="h3" color={colors.blue}>
          {generalText.buttons.backButton}
        </Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{carSettingsId}
        </Typography>
        <PublicationStatusTag
          published={status === PublicationStatusEnum.PUBLISHED}
          text={generalText.status[status]}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        padding="0.75rem 0.25rem"
        width="20%"
      >
        <PlaylistAddCheckIcon fontSize="medium" sx={{ color: colors.gray }} />
        <Typography variant="body2" color={colors.gray} marginLeft="0.5rem">
          {translation.description}
        </Typography>
      </Box>
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
        }}
      />
      <DetailNavTab tab={tab} items={initialItems} />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <BrandInformation
            brandData={brandData}
            handlePageChange={handleChangePage}
            pageCount={getPageCount()}
            totalCount={totalModelCount}
            modelList={models}
            actualPage={page}
            modelsLoading={modelsLoading}
            pageSize={pageSize}
          />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default BrandDetailPage
