import { styled } from '@mui/material'
import { Box, BREAK_POINTS } from 'curbo-components-library'

export const DownloadBox = styled(Box)({
  width: '295px',
  marginRight: 'auto',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    width: '205px',
    marginRight: 'inherit',
  },
})
