import gql from 'graphql-tag'

export const GET_CAR_BY_ID_OR_SLUG = gql`
  query getAvailableDealerCarBySlugOrId($input: String!) {
    data: getAvailableDealerCarBySlugOrId(input: $input) {
      id
      brand {
        name
      }
      mainPicture
      model: carModel {
        name
      }
      year
      trimLevel {
        name
      }
    }
  }
`

export const SEARCH_USER = gql`
  query searchUserEmail($input: FilterInput) {
    data: getCustomers(input: $input) {
      name
      lastName
      phoneNumber: telephoneNumber
    }
  }
`

export const FETCH_EXPECTED_TIMES = gql`
  query fetchSellExpectedTimes {
    data: getExpectedTimes(input: { sort: { days: asc } }) {
      days
      id
      label
      timeType
    }
  }
`

export const FETCH_CAR_BASE_PRICE = gql`
  query fetchCarBasePrice($input: FilterInput) {
    data: getBasePrices(input: $input) {
      id
      price
    }
  }
`

export const FETCH_PRICE_SETTING = gql`
  query fetchPriceSetting {
    data: getPriceSetting {
      marketPrice
      curboPrice
    }
  }
`
