import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Box, Divider } from '@mui/material'
import {
  colors,
  GenericData,
  LoadingAnimation,
  Title,
  useNotification,
  validateGraphQLErrorCode,
} from 'curbo-components-library'

import Information from 'components/Operation/Schedule/Information'

import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import {
  arrayOfNamedDays,
  emptyInputWeekCalendar,
  initialScheduleState,
} from 'constants/operation/schedule'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  HoursPair,
  ScheduleModel,
  ScheduleResponse,
  UpdateScheduleInputType,
} from 'models/schedule'
import { SpotWeekCalendar } from 'models/services/curboSpot'

import {
  UPDATE_INSPECTIONS_WEEK_CALENDAR,
  UPDATE_TEST_DRIVE_WEEK_CALENDAR,
} from 'graphQL/Operations/Schedule/mutations'
import {
  GET_SELF_INSPECTION_WEEK_CALENDAR,
  GET_SELF_TEST_DRIVE_WEEK_CALENDAR,
} from 'graphQL/Operations/Schedule/queries'

const StyledBody = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 3rem',
  justifyContent: 'start',
  alignContent: 'center',
  marginTop: '50px',
  backgroundColor: colors.commonWhite,
}

const ScheduleManagement = () => {
  const { text } = useTranslation(textFiles.SCHEDULE_MANAGEMENT)

  const { show } = useNotification()

  const [inspectionSchedules, setInspectionSchedules] =
    useState<ScheduleResponse>(initialScheduleState.inspections)
  const [testDriveSchedules, setTestDriveSchedules] =
    useState<ScheduleResponse>(initialScheduleState.testDrive)

  const getWeekData = (weekData: SpotWeekCalendar) => {
    const week = { ...weekData }
    const {
      id,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    } = week

    return {
      responseId: id,
      responseWeekCalendar: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
    }
  }

  const { loading: inspectionLoading } = useQuery<
    GenericData<SpotWeekCalendar>
  >(GET_SELF_INSPECTION_WEEK_CALENDAR, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: null,
    },
    onCompleted(response) {
      const { responseWeekCalendar } = getWeekData(response.data)
      setInspectionSchedules(responseWeekCalendar)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        ENTITY_NOT_FOUND_ERROR
      )
      if (errorExists) {
        setInspectionSchedules(initialScheduleState.inspections)
      }
    },
  })

  const { loading: testDriveLoading } = useQuery<GenericData<SpotWeekCalendar>>(
    GET_SELF_TEST_DRIVE_WEEK_CALENDAR,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: null,
      },
      onCompleted(response) {
        const { responseWeekCalendar } = getWeekData(response.data)
        setTestDriveSchedules(responseWeekCalendar)
      },
      onError(error) {
        const { errorExists } = validateGraphQLErrorCode(
          error,
          ENTITY_NOT_FOUND_ERROR
        )
        if (errorExists) {
          setTestDriveSchedules(initialScheduleState.testDrive)
        }
      },
    }
  )

  const [updateTestDriveWeekCalendar, { loading: updateTestdriveLoading }] =
    useMutation<SpotWeekCalendar, UpdateScheduleInputType>(
      UPDATE_TEST_DRIVE_WEEK_CALENDAR,
      {
        onCompleted() {
          show({
            updatedSeverity: 'success',
            message: text.successMessage,
          })
        },
        onError() {
          show({
            updatedSeverity: 'error',
            message: text.errorTestdrive,
          })
        },
      }
    )

  const [updateInspectionsWeekCalendar, { loading: updateInspectionLoading }] =
    useMutation<SpotWeekCalendar, UpdateScheduleInputType>(
      UPDATE_INSPECTIONS_WEEK_CALENDAR,
      {
        onCompleted() {
          show({
            updatedSeverity: 'success',
            message: text.successMessage,
          })
        },
        onError() {
          show({
            updatedSeverity: 'error',
            message: text.errorInspection,
          })
        },
      }
    )

  const updateSchedules = async (newSchedules: HoursPair) => {
    const testDriveSchedule = { ...emptyInputWeekCalendar }
    const inspectionsSchedule = { ...emptyInputWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      inspectionsSchedule[day] = newSchedules.inspections[day]
        .filter((h) => h.checked)
        .map((h) => h.value)
      testDriveSchedule[day] = newSchedules.testDrive[day]
        .filter((h) => h.checked)
        .map((h) => h.value)
    })

    try {
      updateTestDriveWeekCalendar({
        variables: {
          input: {
            ...testDriveSchedule,
          },
        },
      })
      updateInspectionsWeekCalendar({
        variables: {
          input: {
            ...inspectionsSchedule,
          },
        },
      })
      setTestDriveSchedules(newSchedules.testDrive)
      setInspectionSchedules(newSchedules.inspections)

      return true
    } catch (error) {
      show({
        updatedSeverity: 'error',
        message: text.errorMessage,
      })
      return false
    }
  }

  const schedules: ScheduleModel = {
    inspections: inspectionSchedules,
    testDrive: testDriveSchedules,
  }

  const calendarLoading = updateInspectionLoading && updateTestdriveLoading
  const configurationLoading = {
    calendarLoading,
    submitLoading: false,
  }

  const isLoading = testDriveLoading || inspectionLoading

  if (isLoading) return <LoadingAnimation showAnimation={isLoading} />

  return (
    <Box sx={{ ...StyledBody }}>
      <Title
        title={text.title}
        containerSx={{
          backgroundColor: colors.commonWhite,
        }}
        subtitle={text.description}
        disableDivider
      />
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
          marginTop: '30px',
          width: '100%',
        }}
      />
      <Information
        schedules={schedules}
        updateSchedules={updateSchedules}
        configurationLoading={configurationLoading}
      />
    </Box>
  )
}

export default ScheduleManagement
