import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ApolloError, useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Divider, Typography } from '@mui/material'
import {
  Box,
  colors,
  DetailNavTab,
  generateTabItems,
  GenericData,
  GenericInputVariable,
  LoadingAnimation,
  NavBarItem,
  PublicationStatusEnum,
  TabPanel,
  useNotification,
} from 'curbo-components-library'

import PublicationStatusTag from 'components/Operation/CarSettings/Common/PublicationStatusTag'
import CountryVersionInformation from 'components/Operation/CountryVersion/Detail/Information'

import { CAR_SETTINGS_SUB_ROUTES, routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { TranslationDetailDataType } from 'models/services/operation/carSettings/detail'

import { GET_COUNTRY_VERSION_BY_ID } from 'graphQL/Operations/CountryVersion/Detail/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/detail'

const CountryVersionDetailPage = () => {
  const { carSettingsId } = useParams<{ carSettingsId: string }>()

  const { text } = useTranslation(textFiles.COUNTRY_VERSION_DETAIL)
  const { general: translation } = text
  const { text: notificationTranslation } = useTranslation(
    textFiles.NOTIFICATION
  )
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [tab] = useState<number>(0)
  const [status, setStatus] = useState<PublicationStatusEnum>(
    PublicationStatusEnum.PUBLISHED
  )
  const [countryVersionData, setCountryVersionData] =
    useState<TranslationDetailDataType | null>()
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const { show } = useNotification()

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading } = useQuery<
    GenericData<TranslationDetailDataType>,
    GenericInputVariable<string>
  >(GET_COUNTRY_VERSION_BY_ID, {
    variables: {
      input: carSettingsId,
    },
    onCompleted(response) {
      setCountryVersionData(response.data)
      setStatus(response.data.status)
    },
    onError(error) {
      show({
        updatedSeverity: 'error',
        message: notificationTranslation.notificationText.error,
      })
      setApolloError(error)
    },
    context: {
      noTranslation: true,
    },
  })

  if (loading) return <LoadingAnimation showAnimation />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return countryVersionData ? (
    <Layout>
      <StyledLink to={CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_LISTING}>
        <Typography variant="h3" color={colors.blue}>
          {generalText.buttons.backButton}
        </Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{carSettingsId}
        </Typography>
        <PublicationStatusTag
          published={status === PublicationStatusEnum.PUBLISHED}
          text={generalText.status[status]}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        padding="0.75rem 0.25rem"
        width="20%"
      >
        <PlaylistAddCheckIcon fontSize="medium" sx={{ color: colors.gray }} />
        <Typography variant="body2" color={colors.gray} marginLeft="0.5rem">
          {translation.description}
        </Typography>
      </Box>
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
        }}
      />
      <DetailNavTab tab={tab} items={initialItems} />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <CountryVersionInformation countryVersion={countryVersionData} />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default CountryVersionDetailPage
