import { CurboSpot, StepperModel } from 'curbo-components-library'

import AppointmentInfo from 'components/CustomerRelationship/Lead/Creation/AppointmentInfo'
import CarInfo from 'components/CustomerRelationship/Lead/Creation/CarInfo'
import GeneralInfo from 'components/CustomerRelationship/Lead/Creation/GeneralInfo'
import ClientExpectationInfo from 'components/CustomerRelationship/Lead/Creation/PriceExpInfo'
import SummaryInfo from 'components/CustomerRelationship/Lead/Creation/SummaryInfo'
import VehicleDetal from 'components/CustomerRelationship/Lead/Creation/VehicleDetail'

import { Brand, Color } from 'models/services/car'
import {
  CreateSmcInput,
  CreateTestDriveInput,
  ExpectedTimes,
  LeadCreationAction,
  LeadCreationModel,
} from 'models/services/customerRelationship/lead/creation'
import { StepperTextType } from 'models/text/General'

export type LeadCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSecondaryBack: () => void
  handleSecondaryContinue: () => void
  leadData: LeadCreationModel
  expectedTimes: ExpectedTimes[]
  updateLeadData: (action: LeadCreationAction) => void
  brands: Brand[]
  curboSpots: CurboSpot[]
  colorList: Color[]
}

export const leadToSmcTransformer = (lead: LeadCreationModel) => {
  const {
    generalInformation,
    carInformation,
    appointmentInformation,
    clientExpInformation,
  } = lead
  const {
    name,
    lastName,
    phoneNumber: telephoneNumber,
    email,
  } = generalInformation
  const {
    carModel,
    carYear,
    carTrimLevel,
    interiorColor,
    exteriorColor,
    licensePlate,
  } = carInformation
  const { address, inspectionDay, appointmentDate } = appointmentInformation!

  const { duration, price } = clientExpInformation!

  const isoDate = new Date(appointmentDate!).toISOString()
  const result = {
    address: (address?.address as string) || '',
    carModel: (carModel?.id as string) || '',
    ...(address &&
      address.originFromSpot && { curboSpot: address.id as string }),
    currencyCode: 'USD',
    date: isoDate as string,
    email,
    expectedTime: (duration as string) || '',
    exteriorColor: (exteriorColor?.id as string) || '',
    inspectionHour: (inspectionDay?.value as string) || '',
    interiorColor: (interiorColor?.id as string) || '',
    lastName,
    latitude: Number(address!.lat || 0),
    licensePlate: licensePlate || '',
    longitude: Number(address!.lng),
    name,
    origin: 'WEB',
    price: price || 0,
    telephoneNumber,
    ...(carTrimLevel && {
      trimLevel: carTrimLevel.id as string,
    }),
    year: Number(carYear?.name || 0),
  } as CreateSmcInput

  return result
}

export const leadToTdTransformer = (lead: LeadCreationModel) => {
  const { generalInformation, vehicleInformation, appointmentInformation } =
    lead
  const {
    name,
    lastName,
    phoneNumber: telephoneNumber,
    email,
  } = generalInformation
  const { address, inspectionDay, appointmentDate } = appointmentInformation!

  const isoDate = new Date(appointmentDate!).toISOString()
  const result = {
    address: (address?.address as string) || '',
    car: vehicleInformation?.idOrSlug,
    ...(address &&
      address.originFromSpot && { curboSpot: address.id as string }),
    date: isoDate as string,
    email,
    lastName,
    latitude: Number(address!.lat || 0),
    longitude: Number(address!.lng),
    name,
    origin: 'WEB',
    telephoneNumber,
    testDriveHours: (inspectionDay?.value as string) || '',
  } as CreateTestDriveInput

  return result
}

export const INITIAL_LEAD_CREATION_STEPS: Array<React.FC<LeadCreationProps>> = [
  GeneralInfo,
  GeneralInfo, // Workaround to handle some edge case
  SummaryInfo,
]

export const SMC_LEAD_CREATION_STEPS: Array<React.FC<LeadCreationProps>> = [
  CarInfo,
  ClientExpectationInfo,
  AppointmentInfo,
]

export const FMC_LEAD_CREATION_STEPS: Array<React.FC<LeadCreationProps>> = [
  CarInfo,
]

export const TD_LEAD_CREATION_STEPS: Array<React.FC<LeadCreationProps>> = [
  VehicleDetal,
  AppointmentInfo,
]

export const createLeadStepperItems = (
  text: Partial<StepperTextType>,
  totalSubStep: number
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep as string,
    },
    {
      label: text.secondStep as string,
      hasPercentage: true,
      totalSubSteps: totalSubStep,
    },
    {
      label: text.thirdStep as string,
    },
  ]

  return stepperItems
}
