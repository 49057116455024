import { FilterEntryVariableType } from 'curbo-components-library'

export const getLeadsFieldOrEntryOptions = (
  text: Record<string, string>
): Record<string, FilterEntryVariableType> => {
  const fieldOrEntryOptions: Record<string, FilterEntryVariableType> = {
    caseId: {
      name: text.caseId,
      type: 'string',
    },
    slug: {
      name: text.slug,
      type: 'string',
    },
    car: {
      name: text.car,
      type: 'string',
    },
    customerEmail: {
      name: text.customerEmail,
      type: 'string',
    },
    type: {
      name: text.type,
      type: 'string',
    },
    // TODO: HIDDEN UNTIL BACKEND DOES SOMETHING
    // customerCareRepresentative: {
    //   name: text.customerCareRepresentative,
    //   type: 'string',
    // },
    origin: {
      name: text.origin,
      type: 'string',
    },
  }

  return fieldOrEntryOptions
}
