import { styled } from '@mui/material/styles'
import { colors, Container } from 'curbo-components-library'

export const StyledContainer = styled(Container)({
  padding: '0.5rem 1.2rem !important',
})

export const contentStyle = {
  padding: '18px',
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  innerWidth: 'full',
}
