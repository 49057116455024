import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'
import {
  ButtonNavigator,
  ButtonNavigatorTextModel,
  colors,
  FONT_WEIGHT,
} from 'curbo-components-library'

import { GridContainer, StyledContainer, StyledForm } from './style'

type Props = {
  title: string
  description: string
  submitLoading?: boolean
  isFirstStep?: boolean
  previousFunction?: () => void
  nextFunction?: () => void
  confirmButtonType?: 'button' | 'submit' | 'reset'
  children: ReactNode
  containerStyles?: React.CSSProperties
  formStyles?: React.CSSProperties
  bodyStyles?: React.CSSProperties
  gridContainerStyles?: React.CSSProperties
  translation?: ButtonNavigatorTextModel
}

/*
This component is used in any of the summary step of a setting creation process, 
as a skeleton structure that contains everything needed for holding the rest of components
*/

const Skeleton: React.FC<Props> = ({
  title,
  description,
  submitLoading,
  isFirstStep = false,
  previousFunction,
  nextFunction,
  confirmButtonType = 'button',
  children,
  containerStyles,
  formStyles = { minHeight: '380px' },
  bodyStyles,
  translation,
  gridContainerStyles = {
    display: 'flex',
    width: '95%',
  },
}: Props) => {
  return (
    <StyledContainer sx={{ ...containerStyles }}>
      <StyledForm sx={{ ...formStyles }}>
        <div style={{ ...bodyStyles }}>
          <Typography
            variant="h5"
            color={colors.black}
            fontWeight={FONT_WEIGHT.BOLD}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color={colors.placeholderGray}
            fontWeight={FONT_WEIGHT.MEDIUM}
          >
            {description}
          </Typography>
          <GridContainer sx={gridContainerStyles}>{children}</GridContainer>
        </div>
        <br />
        <ButtonNavigator
          isFirstStep={isFirstStep}
          previousFunction={previousFunction}
          nextFunction={nextFunction}
          confirmButtonType={confirmButtonType}
          isLastStep
          loading={submitLoading}
          translation={translation}
        />
      </StyledForm>
    </StyledContainer>
  )
}

export default Skeleton
